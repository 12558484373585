import React from 'react';
import HeroSlider from '../HeroSlider/HeroSlider';
import Resources from './Resources';
// import News from '../../components/News/News.js';

const ResourcesPage = () => {
  return (
    <>
      <section id="home">
        <HeroSlider />
      </section>
      {/* <section id="solutions">
        <Card />
      </section> */}
      {/* <section id="news">
        <News />
      </section> */}
      {/* <section id="aboutus"> */}
        <Resources />
      {/* </section> */}
    </>
  );
};

export default ResourcesPage;