// ToolsComponent.js

import React from 'react';
import { Card } from 'react-bootstrap';
import './Tools.css';


// const CardComponent = ({ image, date, title, text, url, alt, topic }) => (
const CardComponent = ({ image, title, url, alt, topic }) => (
  <Card className="fixed-width-card d-flex flex-row"> 
    <div className="card-img-container">
      <Card.Img
        variant="left"
        src={image}
        className="card-img-left"
        alt={alt}
      />
    </div>
    <Card.Body className="flex-grow-1">
      {/* {date && <p className="card-date card-date-small">{date}</p>} */}
      <Card.Title className="custom-card-title">{title}</Card.Title>
      {/* <hr className="custom-card-divider" /> */}
      {/* <Card.Text className="custom-card-text">{text}</Card.Text> */}
      {url && (
        <Card.Text className="custom-card-text">
          <a href={url} target="_blank" rel="noopener noreferrer">{url}</a>
        </Card.Text>
      )}
    </Card.Body>
  </Card>
);

export default CardComponent;