import React from 'react';
import './svgstyle.css'; // Assuming you have extracted styles to a separate CSS file

const svgmap = () => {
  return (
    
    <svg
      className="d_map" // Added class for applying CSS to the svg element
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      // viewBox="0 0 1160 740"
      viewBox="0 0 1160 740"
      // style={{ enableBackground: 'new 0 0 1160 740' }}
      style={{ enableBackground: 'new 0 0 1160 740' }}
      xmlSpace="preserve"
    >


      {/* Define a drop shadow filter for a 3D effect */}
      <defs>
        
        <filter id="dropshadow" height="150%">
          <feGaussianBlur in="SourceAlpha" stdDeviation="0.3" /> {/* stdDeviation is the amount of blur */}
          <feOffset dx="2" dy="2" result="offsetblur" /> {/* dx and dy are the shadow offset */}
          <feComponentTransfer>
            <feFuncA type="linear" slope="0.2" /> {/* slope is the transparency of the shadow */}
          </feComponentTransfer>
          <feMerge>
            <feMergeNode /> {/* this contains the offset blurred image */}
            <feMergeNode in="SourceGraphic" /> {/* this contains the element that the filter is applied to */}
          </feMerge>
        </filter>
      </defs>

<div></div>
<div></div>
<div></div>
<div></div>

      {/* Inline styles removed for brevity, consider moving them to a CSS file */}
      <g id="map">
        <a xlinkHref="../about/huizhou.html" id="dmap_huizhou" className="focus">
          <title>惠州</title>
          <path id="Huizhou_3_"
            className="gba0"
            d="M894.6037598,411.703186v-0.1880188h-0.1879883v0.1880188l0.1879883,0.1879578v0.5638428
        l-0.1879883,0.1879578v0.3759766l-0.1879272,0.9398193l-0.1879883-0.1880188h-0.1879883v-0.3759155h-0.1879272
        l-0.1879883-0.1879272h-0.1879272l-0.1879883-0.1879578v-0.1879578l-0.1879883-0.1880188h-0.1879272l-0.1879883-0.1879578
        l-0.1879272-0.1879272l-0.1879883-0.3759155h-0.5639038v-0.1879578h-0.5638428l-0.1879883-0.1880188v-0.375885l0.1879883-0.1879578
        h0.1879272l-0.1879272-0.1879578h-0.3759766v-0.5639038h0.5639038v-0.1879578v-0.1879578v-0.1879578l0.3759155-0.5639038h0.1879883
        l0.1879883-0.1879578h0.1879272l0.1879883,0.1879578v0.1879883l0.5638428-0.1879883h0.7518921l0.1879272,0.1879883h0.3759766
        l0.1879272,0.1879578h0.1879883l0.1879272,0.1879578v0.1879578h0.1879883v0.1879578v0.1879578v0.5639038l-0.1879883,0.3759155
        v0.5639038H894.6037598z M439.3597412,629.5512085v-0.1879883l-0.1879578,0.1879883H439.3597412z M826.3735962,462.6409302
        h-0.1879883h-0.1879883v0.1879578v0.1879578h0.1879883h0.3759155v-0.1879578h-0.1879272V462.6409302z M827.5013428,461.8890686
        h-0.3759155v0.1879578h-0.1879883v0.1879272h0.7518311v-0.1879272h-0.1879272V461.8890686z M832.3883667,465.8361816h0.1879272
        l0.1879883-0.1879272v-0.1879578v-0.1879578h-0.3759155l-0.1879883-0.1879578h-0.1879272v0.1879578v0.1879578l0.1879272,0.1879578
        h0.1879883V465.8361816z M834.0799561,465.8361816v-0.375885v-0.1879578h-0.1879272v-0.1879578h-0.7518311l0.1879883,0.1879578
        v0.1879578l0.1879272,0.1879578h-0.3759155v0.375885h0.3759155v0.1879578v0.1880188l0.1879883,0.1879578l0.1879272-0.1879578
        v-0.1880188l-0.1879272-0.1879578l0.1879272-0.1879578H834.0799561z M832.0124512,464.5204773l0.1879272,0.1879578
        l0.1879883-0.1879578l0.1879272-0.1879578v0.1879578l0.1879883-0.1879578h0.3759155l0.1879883,0.1879578h0.5638428v-0.1879578
        h0.3759766v0.1879578h0.1879272v-0.1879578l-0.1879272-0.1879578l-0.1880493-0.1880188v-0.375885h0.1880493l0.1879272,0.1879578
        h0.1879883v-0.1879578v-0.1879578h0.3758545v-0.1879578v-0.1879578h0.3759766v-0.1879578v-0.1879883v-0.1879578
        l-0.1879883-0.1879578l-0.1879883-0.1879578l-0.1879272-0.1879578h-0.1879272l-0.1879883,0.1879578h-0.1879272
        l-0.1880493,0.1879578l-0.1879272,0.1879578h-0.1879272l-0.1879883,0.1879578h-0.1879272l-0.1879883-0.1879578h-0.1879883
        l0.1879883-0.1879578v-0.1879578l-0.7518311,0.000061h0.1879272v0.1878967h-0.1879272l-0.1879883-0.1878967l-0.1879272-0.1879578
        h-0.3759766v-0.1879578v-0.1880188l-0.1879272-0.1879578l-0.1879883-0.1879272v-0.1879578h-0.1879272v0.1879578
        l-0.1879883,0.1879272v0.1879578h-0.1879272l-0.1879883,0.1880188l-0.1879883,0.1879578h-0.3759155l-0.1879272-0.1879578
        l-0.1879883-0.1880188h-0.1879883l-0.1879272,0.1880188l-0.1879883,0.1879578h-0.3759155l-0.1879272,0.1879578h-0.1879272
        l-0.1880493,0.1879272v0.1879578l0.1880493,0.1879578v0.1880188h0.5638428v0.1879578h-0.1879883v0.1879272h-0.3758545
        l0.1879272,0.1879578h-0.1879272v0.1879578h0.3758545v-0.1879578h0.1879883v0.1879578h0.1879272v0.1879578v0.1879578v0.375946
        v0.1879578v0.1879578h0.1879883v0.1879578l0.1879272,0.1878967l0.1879883,0.1880188h0.1879883v0.1879578l0.1879272,0.1879578
        h0.1879883v-0.1879578h0.1879272l0.1879883,0.1879578l0.1879883-0.1879578v-0.1879578h0.1879272l0.1879883-0.1880188h0.1879272
        v-0.1879578h0.1879883v-0.1879272v-0.1879578h0.1879272l0.1879883-0.1879578v0.1879578H832.0124512z M835.9595947,467.527832
        h-0.1879272l0.1879272,0.1879578V467.527832z M835.0197754,466.4000549h-0.1879272v0.1879578h-0.1879272l-0.1879883,0.1879883
        v0.1879578v0.1879578l-0.1879883-0.1879578l-0.1879883,0.1879578v0.1879578h-0.1879272v0.1879578v0.1879578h-0.1879272
        l-0.1879883,0.1879578v0.1879883h-0.1879272v0.1879578l-0.1880493,0.1879578v0.1879578h-0.1879272v0.1879578l-0.1879272,0.1879578
        h-0.1879883v0.1879883l-0.1879272,0.1879578l0.1879272,0.1879578l0.1879883,0.1879578l0.1879272-0.1879578v0.1879578h0.1879272
        v-0.1879578v-0.1879578h0.7518921v0.1879578h0.1879272l0.1879883-0.1879578l0.1879883-0.1879578v-0.1879883h0.1879883
        l0.1879272-0.1879578h0.1879272l0.1879883-0.1879578v-0.1879578l-0.1879883-0.1879578h-0.1879272v-0.1879578l0.1879272-0.1879883
        h0.1879883v-0.1879578h0.1879272l0.1879883-0.1879578h0.1879883v-0.1879578l0.1879272-0.1879578h-0.1879272v-0.1879578h0.1879272
        v-0.1879578v-0.1879883h-0.9398193V466.4000549z M838.4031372,474.4824829h-0.1879883v-0.1879578h-0.1879883v0.1879578
        l-0.1879272,0.1879578l-0.1879883,0.1879578h-0.5638428v0.1879578h0.1879272l0.1879883,0.1879272h0.3759155l0.1879272,0.1879578
        l0.1879883-0.1879578h0.1879883v-0.1879272v-0.1879578h0.1879272v-0.1879578h-0.1879272V474.4824829z M808.8930664,421.4772339
        l-0.1879883,0.1879578v0.1879578h0.1879883v0.1879578v0.1879578h0.1879272v0.1879578l0.1879883-0.1879578h0.1879883v-0.3759155
        h0.1878662v0.1879578l0.1880493,0.1879578h0.1879272v-0.1879578v-0.1879578h-0.1879272h-0.1879883v-0.1879578v-0.1879578
        l-0.1879272-0.1879883l-0.1879883,0.1879883H808.8930664z M809.8328857,421.4772339v0.1879578h0.5638428v-0.1879578h0.3759155
        v-0.1879578h-0.9397583V421.4772339z M811.3364868,420.5374146v0.1879578v0.1879578h0.3759766v-0.1879578l-0.1879883-0.1879578
        H811.3364868z M810.9606323,421.2892761h0.1879883v-0.1879883h-0.1879883V421.2892761z M811.1485596,420.1614685h-0.1879883
        v0.1879578h-0.1879272v0.1879578h-0.1879883v-0.1879578v-0.1879578v-0.1879578h-0.1879272l-0.3759155-0.1879578v0.1879578
        v0.1879578l0.1879883,0.1879578h-0.1879883v0.1879578v0.1879883h-0.1879883v-0.1879883h-0.1879883v0.1879883l-0.1879272,0.1879578
        v0.1879578v0.1879578h0.000061h0.1878662v-0.1879578h0.5639648v-0.1879578h0.5638428l0.1879272-0.1879578v-0.1879883h0.1879883
        V420.1614685z M809.0809937,420.5374146v-0.1879578h-0.3759766l-0.1879272,0.1879578l0.1879272,0.1879578l0.1879883-0.1879578
        H809.0809937z M823.5540771,413.9587402l0.1879272-0.1879578v-0.3759155h0.1879883v-0.1879883h0.1879883v-0.1879578h0.1879272
        v-0.1879578h-0.1879272l-0.1879883-0.1879578h-0.1879883v-0.1879578h-0.1879272v0.1879578h-0.1879883v0.1879578h-0.5638428
        v-0.1879578h-0.1879272v0.1879578h-0.1880493l-0.1879272-0.1879578h-0.1879883v0.1879578h-0.1879272l-0.1879883,0.1879578
        h-0.1879272l-0.1879883,0.1879578h-0.1879883v0.375946h0.1879883v0.1879578h0.3759155l0.1879883,0.1879578H823.5540771z
        M823.7420044,414.8985596v0.1879578h0.1879883l0.1879883,0.1879578v-0.1879578h0.1879272v-0.3759155l-0.1879272,0.1879578
        H823.7420044z M823.5540771,415.8383789l-0.1879883,0.1879578h-0.3759155v0.1879578h-0.1879883v-0.1879578h-0.3759155
        l-0.1879272,0.1879578h-0.1879883v-0.1879578l-0.1879883,0.1879578v0.1879272h0.1879883l0.1879883,0.1879578h0.5638428v-0.1879578
        h0.3759155l0.1879883-0.1879272h0.1879883v-0.1879578h1.1277466l-0.1879272-0.1879578H823.5540771z M827.5012817,416.7781982
        h0.1879883v-0.1879578v-0.1880188h-0.1879883l-0.1879272,0.1880188h-0.3759155v0.1879578h-0.1879883v0.1879578h0.5639038
        L827.5012817,416.7781982z M830.8846436,411.703186l-0.1879883,0.1879883v0.1879578h-0.1879883l0.1879883,0.1879578h0.5638428
        l-0.1879272,0.1879578h0.1879272v0.1879578h0.1879883v0.1879578h0.1879272v0.1879578h0.1879883l0.1879272-0.1879578v-0.1879578
        v-0.1879578h-0.5638428l-0.1879883-0.1879883v0.0000305l-0.1879272-0.1879578v-0.1879578L830.8846436,411.703186z
        M826.7494507,420.5374146h0.3759155l-0.1879883-0.1879883h-0.1879272V420.5374146z M827.3132935,421.8531494h0.1879883v-0.1879578
        v-0.375946l-0.1879883-0.1879578h-0.9397583v-0.1879578h-0.1879883v0.1879578l-0.1879883,0.1879578l0.1879883,0.1879578
        l-0.1879883,0.1879883l-0.1879272-0.1879883v0.375946l-0.1879883,0.1879578v0.1879578l-0.1879272,0.1879578h-0.1879883v0.1879578
        v0.1879883l-0.1879272,0.1879578l0.1879272,0.1879578h0.1879883l0.1879272,0.1879578h0.1879883v-0.1879578h0.3759155
        l-0.1879883-0.1879578v-0.1879578h0.3759766v0.1879578h0.1879272v-0.1879578l0.1879883-0.1879883v-0.1879578l0.1879272-0.1879578
        v-0.1879578h0.1879883L827.3132935,421.8531494z M829.1929321,422.9809265v0.3759155h0.3759155l0.1879272,0.1879578h0.3759155
        v-0.1879578v-0.1879578h0.1879883l-0.1879883-0.1879578H829.1929321z M832.0123901,423.9206848v0.1880188l0.1879272,0.1879578
        h0.3759155l-0.1879272-0.1879578h-0.1879883v-0.1880188H832.0123901z M833.1401367,424.6725464h0.3759155v-0.1879272h-0.3759155
        V424.6725464z M833.5160522,419.2216797v0.3759155h0.1879272l0.1879883,0.1879578h0.1879883v-0.1879578v-0.1879578v-0.1879578
        h-0.1879883H833.5160522z M834.6437988,420.5374146l0.3759155-0.1879578h-0.1879883L834.6437988,420.5374146z
        M835.7715454,422.2290649l0.1879883,0.1879578v-0.1879578v-0.1879578l-0.1879883-0.1879578v0.1879578h-0.3758545
        l-0.1879883,0.1879578H835.7715454z M834.0799561,420.3494568h-0.1879883h-0.1879883v0.1879578h0.1879883l0.1879883,0.1879578
        l-0.1879883,0.1879578v0.3759155l-0.1879883,0.1879578v0.9398193h0.5639038l-0.1879272,0.1879578h-0.1879883v0.7518616h0.1879883
        l-0.1879883,0.375885v0.1880188h0.1879883v0.1879578h0.5638428l0.1879272-0.1879578v-1.3157654v-0.1879578v-0.1879578v-0.1879578
        v-0.5639038l-0.1879272-0.1879578v-0.1879578h-0.1879272v-0.5638733v-0.1879578h-0.1879883v-0.1879883L834.0799561,420.3494568z
        M835.0197144,420.1614685v0.1879883l0.1879883,0.1879578v-0.1879578v-0.1879883l-0.1879883-0.1879578V420.1614685z
        M830.1327515,432.9429016v0.375885h0.9398193v-0.1879578h0.7518311v-0.1879272l-0.1879883-0.1879578h-0.5638428
        l-0.1879272,0.1879578h-0.1879883v-0.1879578h-0.3759766L830.1327515,432.9429016z M830.508728,429.3716125l0.1879272,0.1879578
        h0.3759155v-0.1879578l-0.1879272-0.1879578h-0.3759155V429.3716125z M833.328125,430.4993896h0.1879272v-0.3759155h-0.1879272
        v0.1879578V430.4993896z M833.1401367,430.6873474v0.3759155h0.1879883v-0.3759155v-0.1879578h-0.1879883V430.6873474z
        M832.5762329,431.6271667h0.1880493l0.1879272-0.1879578l-0.1879272-0.1879578h-0.1880493l-0.1879272,0.1879578
        L832.5762329,431.6271667z M833.7040405,429.3716125v0.1879578h0.1879272v-0.1879578H833.7040405z M833.1401367,429.1836548
        l0.1879272,0.1879578h0.1879883l0.1879883-0.1879578h0.1879272v-0.1879578l0.1879883-0.1879883v-0.1879578l-0.1879883-0.1879578
        h-0.1879272l-0.1879883-0.1879578v0.1879578l-0.1879883,0.1879578v0.1879578l-0.1879272,0.1879883V429.1836548z
        M833.5160522,427.8679199v0.1879578v0.1879578l0.1879883-0.1879578l0.1879272-0.1879578h-0.1879272v-0.1879578
        L833.5160522,427.8679199z M850.8085327,409.2596741h-0.1879272v0.1879578v0.1879578h0.1879272l0.1879883,0.1879883v-0.5639038
        v-0.1879578v-0.1879578l-0.1879883-0.1879272v-0.1879578h-0.3759155v0.1879578h-0.3759155l-0.1879272,0.1879272h-0.1879883
        l0.1879883,0.1879578h0.9397583V409.2596741z M849.6807861,409.2596741h0.1879883v-0.1879578h-0.1879883V409.2596741z
        M873.1760254,449.6715088v0.1879578h-0.1879272v0.1879272h0.3759155v-0.1879272h0.1879883l0.1879272-0.1879578h0.1879883
        V449.48349l-0.1879883-0.1879578v-0.1879578v-0.1878967h-0.3759155l-0.1879883,0.1878967l0.1879883,0.1879578v0.1879578
        L873.1760254,449.6715088z M872.0482788,453.2427673h-0.1879272l-0.1879883-0.1879578v0.1879578v0.1879578l-0.1879883,0.1879578
        v0.1879578l0.1879883,0.1879578v-0.1879578h0.1879883v-0.1879578l0.1879272-0.1879578V453.2427673z M874.6796875,452.4909058
        l-0.1879272,0.1879578h-0.3759155v-0.1879578h-0.1879272v-0.1879578h-0.3759766v-0.1879578h-0.3759155l-0.1879272,0.1879578
        l-0.1879883,0.1879578l-0.1879272,0.1879578v0.1879578h-0.1879883l-0.1879883,0.1879578v0.1879883v0.1879578l-0.1879272,0.1879578
        v0.3759155l0.1879272,0.1879578v0.1879883h0.1879883v0.1879578l-0.1879883,0.1879578v0.1879578h0.7518921V454.74646h-0.1879883
        v-0.3759155l0.1879883-0.1879883h0.7518311v-0.3759155l0.1879883-0.1879578l0.1879272-0.1879578v-0.1879578l0.1879272,0.1879578
        h0.1879883v0.1879578v0.1879578h0.3759766v-0.1879578l0.1879272-0.1879578h0.1879883v-0.5639038v-0.1879578l-0.1879883-0.1879578
        H874.6796875z M880.1306152,443.6567078v0.1879578h0.3759766v-0.1879578h-0.1879883H880.1306152z M879.1907959,447.0400391
        h0.1879883v-0.1879578h-0.1879883V447.0400391z M900.2425537,420.1614685h-0.7518311l0.1879883,0.1879578h0.7518311v-0.1879578
        v-0.1879578h-0.1879883V420.1614685z M902.3101807,418.6577759h0.1879883v-0.1879578h-0.1879883V418.6577759z
        M903.4379272,418.0938721v-0.1879578H903.25v0.1879578h-0.1879883v0.1879578L903.25,418.4697876h0.3758545v-0.1879578h0.1879883
        l-0.1879883-0.1879578H903.4379272z M812.2763672,415.8383179v-0.1879578v-0.1879578h0.1879272v-0.1879272h0.1879883v-0.1879578
        h-0.1879883v-0.1879578h-0.1879272v-0.1879578l-0.1879272-0.1880188v-0.1879578h-0.3759155v0.1879578h-0.1880493v0.1880188
        h0.1880493v0.1879578h0.1879272l-0.1879272,0.1879578v0.1879578v0.1879272l0.1879272,0.1879578h0.1879883v0.1879578H812.2763672z
        M836.5234985,402.868988v-0.1879578h-0.1879883h-0.3759155v0.1879578h0.1879272v0.1879578h0.5639038v-0.1879578h0.1879883
        v-0.1879578h-0.1879883L836.5234985,402.868988z M831.8244019,438.2058411v1.1277771l0.1879883,0.1879578v0.3759155
        l0.1879272,0.1879578l0.1879883,0.1879578v0.375946h0.3759155l0.1879272-0.1879883h0.3759766l0.1879272-0.1879578h0.3759155
        l0.1879883,0.1879578h0.1879272l0.1879883,0.1879883v0.7518311h0.1879883l0.1879272,0.1879272v-0.1879272l0.1879883,0.1879272
        v0.3759766h-0.1879883v0.1879578v0.375885h0.1879883v0.1879578l0.1879272,0.1880188h0.1879883l0.1879883,0.1879578v0.1879578
        h0.1879272v-0.1879578h0.1879883v-0.3759766v-0.1879578h-0.1879883v-0.1879272h0.1879883v-0.1879578l-0.1879883-0.1879578
        v-0.1879578h0.1879883v-0.1880188h-0.3759155v-0.1879272h0.1879272v-0.1879578h-0.1879272l-0.1879883-0.1879578
        l0.1879883-0.1879578h-0.1879883l-0.1879883-0.1879578l0.1879883-0.1879883h0.1879883v-0.1879578l-0.1879883-0.1879578h-0.3759155
        l-0.1879883-0.1879578h-0.1879272l-0.3759766-0.1879578h-0.1879272l0.1879272-0.1879578l-0.1879272-0.1879578v0.1879578h-0.1879883
        l-0.3759155-0.1879578h-0.1879272l-0.1879883-0.1879883h-0.1879883v-0.1879578l0.1879883-0.1879578v-0.5638733h-0.1879883
        l-0.1879272-0.1879883v-0.1879578h-0.3759155v0.375946l-0.1879883,0.1879578h-0.1879272L831.8244019,438.2058411z
        M836.5234985,440.2734375h-0.1879883v0.1879578l-0.1879883,0.1879578v0.1879578h-0.1879272v0.3759155v0.1879578h0.1879272
        v0.1879883l0.1879883,0.1879578h0.1879883v-0.1879578h0.1879272v-0.375946v-0.3759155v-0.3759155h-0.1879272V440.2734375z
        M832.5762329,441.4011841v0.5639038l0.1880493-0.1880188h0.1879272l0.1879272-0.1879578v-0.1879272h-0.1879272
        l-0.1879272-0.1879578L832.5762329,441.4011841z M831.6364746,441.5891724l-0.1879883-0.1879883l-0.1879883,0.1879883v0.1879578
        h0.1879883L831.6364746,441.5891724z M831.6364136,442.3410034h0.1879883l-0.1879883-0.1879578l-0.1879272-0.1879578h-0.1879883
        v0.1879578v0.1879578h0.1879883v0.1879272L831.6364136,442.3410034z M831.8244019,440.2734375h0.1879883v-0.1879578h-0.1879883
        V440.2734375z M833.7040405,437.6419373l0.1879272,0.1879578h0.1879883l0.1879883-0.1879578l0.1879272-0.1879578
        l-0.1879272-0.1879578h-0.5639038V437.6419373z M887.8370972,438.0178528v-0.1879578h-0.3759155v0.1879578v0.1879578h0.3759155
        V438.0178528z M888.2130127,439.7095337h-0.5639038v0.1879578h0.3759155L888.2130127,439.7095337z M887.0852051,439.8974915
        v0.1879578l0.1879883,0.1879578h0.1879883h0.1879272v-0.1879578v-0.1879578h-0.1879272v0.1879578h-0.1879883v-0.1879578
        H887.0852051z M890.0926514,430.1234436h0.1879272l0.1879883-0.1879578h-0.3759155V430.1234436z M851.3724365,429.9355164
        h-0.3759155v0.1879578v0.1879578l0.1879883,0.1879578v0.1879578l-0.1879883,0.1879578l0.1879883,0.1879578h0.1879272v-0.1879578
        l0.1879883-0.1879578v-0.3759155l-0.1879883-0.1879578V429.9355164z M850.4326172,430.4993896v0.1879578h0.3759766v-0.1879578
        v-0.1879578h-0.3759766V430.4993896z M849.3048706,438.9576721v-0.3759155l-0.1879883-0.1879578h0.1879883v-0.3759155h-0.1879883
        l-0.1879272-0.1879883l-0.1879272-0.1879578h-0.3759155v0.1879578l0.1879272,0.1879883h-0.1879272h-0.3759766v0.1879578h0.1879272
        v0.1879578h0.1880493v0.1879578h-0.1880493v0.1879578h-0.1879272v0.3759155v0.1879883h0.1879272l0.1880493,0.1879578h0.1879272
        v0.1879578h0.1879883l0.1879272,0.1879578h0.3759155v-0.1879578h0.1879272l0.1880493-0.1879578v-0.1879578v-0.1879883h-0.1880493
        L849.3048706,438.9576721z M986.8930664,307.1962891l-0.9398804-0.9398193l-0.3759155-0.9397583l-0.3758545-1.5037231v-1.6916504
        l-0.1879883-1.6916809l-0.5638428-1.8796082l-1.3157959-1.5037231l-1.3157959-1.3157349l-1.6915894-0.5638428h-1.6916504
        l-2.6314697-0.1879578l-1.1278076-0.9398193l-1.8795776-2.0675964l-0.7518921-0.7518311l-1.6915894-0.5639038l-1.1278076,0.3759155
        l-0.9397583,1.5037231l-1.1278687,0.7518311l-1.1276855-0.7518311l-0.3759155-1.5037231l-1.3157959-5.4508667l-0.3759155-1.5037231
        l-1.5037231-0.5638733l-1.1277466-0.5638733l-0.9398193-1.1277771l-0.7518311-1.1277771l-1.1277466-1.1277466l-1.5037231-0.9398193
        l-1.3157959-0.1879578l-2.0675659,0.5638428l-0.7518311,0.3759155l-1.8796387,0.1880188l-0.7518311-0.9398193l-0.5638428-0.9398193
        l-0.9398804-0.1879578l-2.0675659,0.5638428l-1.5036621,1.1277771l-2.0675659,0.5639038l-0.9398193-0.1879272l-1.3157349-1.1277771
        h-1.6917114l-1.5036011,1.1277771l-1.5037231,1.1277466l-1.8796387,1.5036926l-2.0675659,1.3157349h-1.5036621
        l-0.5639648-1.1277771l-2.2555542-0.9398193h-0.9397583l-1.5037231,0.7518616l-1.3157349,1.3157349l-1.8795776,2.0675964
        l-2.6315308,1.6916199l-0.75177,0.9398193l-1.1278076,0.9398193l-2.6314697,1.5036926l-1.8796387,0.5639038l-1.6915894-0.5639038
        l-3.0074463-0.5638733l-0.7518311-0.1879883l-1.3157349-0.1878967l-1.8796387,0.375885L903.25,291.2195435l-0.5639648,1.3157349
        l-0.1879272,1.3157349l-0.7518921,1.3157349l-1.6915894,0.9398193l-1.1278076-0.1879578l-0.7518311-0.5638733l-0.5639038-0.9398193
        l-1.1277466-1.5037231l-0.9398193-1.3157349l-0.7518921-1.5036926l-0.3759155-1.5036621l-0.1879272-0.9398193l-0.7518921-1.6916504
        l-1.6915894-0.1879883l-2.819458,0.375946l-1.3157349,0.5638733l-1.3157349,1.5036621l0.1879272,1.8796387l0.7518921,2.2555542
        l-0.1879883,2.0675964l-0.3759155,2.6314697l-0.1879883,0.5638428l-0.7518311,3.0074158l-1.5037231,3.1953735l-1.5036621,2.443512
        l-1.6916504,1.3157349l-2.0675659,1.5036621l-0.9398193,1.5037537l-0.7518921,1.8795776l-0.1879272,2.0675964v1.5036926
        l-0.3759155,1.8795776l-0.3759155,1.6916809l-1.1277466,1.5036926l-2.067627,0.5638428l-1.6916504-0.5638428l-1.6916504-1.1277466
        l-0.9398193-1.1277466l-1.3157349-1.6917114l-1.6916504-2.0675659l-1.5037231-1.1277771l-2.0675659-1.1277771l-1.1277466-1.8796082
        v-1.3157349l0.5638428-1.8796387l0.5639038-2.0675659l-0.1879272-1.5036926l0.1879272-0.9398193l-0.7518311-1.1277771
        l-1.3157349-1.1277771l-1.1278076-0.9397583l-0.5639038-0.9398193l0.1879883-1.5037537l0.7518311-1.6916199l-0.1879272-1.5036926
        l-0.5639038-1.5037231l-0.5639038-1.1277161l-1.5036621-1.1277771l-1.6917114-0.1880188l-2.2554932,0.1880188l-1.5037231-0.5639343
        l-1.1277466-1.1277466l-0.7518311-1.8796082l-0.3759766-0.9398193l-1.1277466-1.3157349l-1.5037231-1.1277771l-1.5036621-2.0675964
        l-0.9398193-1.8796387l-0.5638428-2.6314392l-1.5037231-2.8194275l-1.3157349-1.5037231h-2.067627l-1.6916504,0.5639038
        l-1.6916504,1.5036926l-2.2554932,1.3157349l-1.3157959-1.1277771l-1.1277466-1.5036926l-0.1879883-0.3759155l1.1278076-0.1879272
        h0.3759155l1.6916504-1.8796387l4.1351929-6.5787048l0.9397583-0.5638733l4.6990967-1.1277771l0.3759155-0.5639038v-0.5638428
        l-0.1879272-0.7518921l-0.3759766-0.7518005l-1.6916504-3.1953735l-0.5639038-1.3157196l0.1879883-0.9398193l0.7518311-0.9398193
        l0.5639038-1.1277771l0.5639038-0.5638733l0.5639038-0.9398193l0.3759155-1.1277618l-0.1879883-0.7518768l-0.3759155-0.1879425
        l-0.3759155-0.1879578l-0.7518921-0.1879578l-0.9397583,0.1879578h-1.1278076l-2.2555542-0.1879578l-1.1277466-0.7518158
        l-0.5639038-0.7518616l-0.1879883-0.5638733l-0.1879272-1.1277618l0.1879272-1.8796539l-0.3758545-2.4434967v-0.563858
        l-0.5639648,0.1879578l-0.5638428,0.3759003l-0.3759155,0.1879578l-0.7518311,0.9398193l-0.9398193,0.7518616l-0.3759155,0.3759155
        l-1.3157349,1.3157196l-1.3157349,1.1277771l-0.7518921,0.563858l-0.5639038,0.7518768l-0.5638428,1.3157196l-0.7518921,0.5639191
        l-0.9398193,0.1879578l-0.3758545-0.5639191l-0.3759155-2.0675507l-0.5639038-1.3157196l-0.5639038-0.7518616l-1.1277466-0.7518768
        l-2.4435425-1.5036774l-3.7592163-2.4434967l-1.3157349-1.6916962v-1.1277771l0.3759155-0.9397583l1.1278076-0.9398193
        l0.5638428-1.5036926l-0.3759155-0.9398193l-1.3157349-1.1277771l-1.3157349-0.7518616l-1.1277466-1.1277771l-0.5639038-1.3157196
        l-0.9398193-2.6314545l-0.3759155-1.5036926l-0.3759155-1.6916809l-1.1278076-1.1277771l-1.1277466-1.1277771l-0.7518921-1.1277618
        l-0.5638428-1.1277771l-0.9398193-1.3157349l-1.8796387-0.9398193l-1.5036621-0.3759003l-2.067627-0.3759155h-2.2555542
        l-2.6314087,0.9398193l-2.4435425,0.563858l-0.7518311,0.3759155l-2.2554932,0.1880035l-0.7518921-0.7518616l-0.9398193-1.1277771
        l-0.7518311-0.9398193l-0.3759766-1.1277771l-0.5638428-0.563858l-0.3759766-0.5639191h-0.5638428l-1.6916504-0.3759003
        l-0.3759155-0.1879578l-0.9398193-0.5639191l-5.8268433-1.1277618l-1.3157349-0.5638733l-0.1879272-0.7518616l-0.1879883-1.3157349
        l-0.3759155-1.3157196l-1.1277466-0.9398193l-0.7518921-0.3759155l-1.1277466-0.1879425l-0.5638428-0.5639191l-0.3759766-1.5036774
        l0.1879272-0.5639191l0.3759766-1.3157196l0.5638428-0.7518158l0.3759766-0.5639191l0.5638428-1.5036774l-0.1879272-0.5638733
        v-0.9398193l-0.7518921-1.3157196l-1.1277466-0.9398193l-0.3759155-0.1879578l-1.1278076-1.1277771l-0.7518311-1.6916351
        v-1.6916809l0.3759766-2.0675964l0.5638428-1.3157349l0.5639038-1.5036774l0.7518311-1.1277771l1.1277466-1.8796387
        l0.1879883-0.9397736l0.1879883-0.9398193l0.3759155-0.9398193l0.5638428-1.1277771h-0.5638428l-0.3759155-1.6916351
        l-0.9398193-0.7518616l-1.1277466-0.1879578l-1.1278076,0.3759155l-1.6917114,1.1277771l-1.8795776-0.1879578l-0.3759155,0.1879578
        l-0.7518311-0.3759155h-1.1278076l-0.9398193-0.5639038l-1.1277466-0.3759155l-0.7518921-0.3759003l-0.75177-0.3759613
        l-0.5639648-0.7518158l-0.9397583-0.1879578l-0.5639038-1.1277771l-1.1277466-0.7518616l-2.067627-0.9398193l-0.9397583-1.3157349
        l-0.1879883-1.3157196l0.3759155-1.6916351l-0.7518311-1.5037384l-1.5037231,0.3759613l-0.7518311,1.1277771l-1.3157349,1.1277161
        l-1.3157349,0.3759613l-1.3157349-0.3759613l-1.1277466-1.1277161l-1.6917114-0.7518768l-2.819397-1.3157196l-1.1277466-0.5639191
        l-2.6314697-0.1879425l-0.9398193,0.3759003l-0.3759155,0.1880035l-1.5037231,2.0675507l-1.3157349,0.9398193l-2.4435425,1.3157196
        l-1.6915894,0.5639191l-3.0074463,0.7518158l-2.0675659,0.3759613l-2.6314697,0.1879425l-2.6314697-0.1879425l-4.1351929-3.9472351
        l-0.5638428,0.375946l-1.3157349,1.6916351l-0.1879272,0.9398346l-0.9398193,0.9398193l-0.7518921,0.7518158l0.1879272,1.1277618
        l-0.5638428,2.0675964l-0.1879272,1.8796387v2.0675507l0.3759155,1.5037231l0.5639038,1.6916504l0.5638428,1.6916809
        l0.3759155,0.7518158l0.7518311,0.9398193l1.1278076,1.3157349l0.3759155,1.3157196l-0.3759155,1.1277771l-1.1278076,1.1277771
        l-1.6915894,1.5036774l-1.1278076,1.3157806l-0.5639038,0.563858l-0.5638428,1.1277771v1.3157349l0.1879272,0.9398193
        l0.9398193,1.3157196l0.9397583,0.1879578l1.5037842,0.7518616l0.5638428,0.7518616v0.7518311l-0.7518311,1.1277618
        l-0.7518311,0.3759155l-1.3157349,0.1880035l-1.3157349,0.3759003l-1.8796387,0.3759155l-1.3157349,0.3759613l-0.5639038,0.1879425
        h-0.9397583l-1.1278076,0.1879578l-0.9398193,0.1879578l-1.6916504,0.3759155l-1.5037231,1.6916809l-1.5036621,1.6916351
        l-2.6314697,0.9398193l-5.0750122,0.1879578l-2.4434814,0.1879425l-1.8795776,1.5037384l-0.1879883,1.1277771l-0.3759766,1.8795929
        l-0.5638428,1.5036774l-1.1277466,1.5037384l-2.067627,1.3157196l-2.4434814,0.563858l-2.067627,0.5639191l-1.5036621,0.3759003
        l-0.3759155,1.8796539l-1.1277466,0.1879425l-1.1278076-0.3759003l-1.6916504-0.3759613l-1.6916504,0.7518616l-1.1278076,1.8796387
        l-0.5639038,1.8795929v2.819458l0.9398193,1.3157349l0.9398193,1.8795929l0.3759155,1.1277771h0.3759766l1.6916504,0.5639038
        l1.8796387,0.7518616l0.5638428,1.3156891l0.1879272,2.6315002l0.1879883,1.6916351l-0.3759155,1.5036926l-0.3759155,1.1277618
        l0.9398193,1.5037384l1.1278076,1.3157196l0.3758545,1.1277771l0.5639038,1.3157349l0.9398193,0.7518158l1.6916504,0.9398193
        l0.9398193,1.1277771l6.9545898,5.4509125l1.8796387,0.7518158l1.6916504,1.6916962l0.9398193,0.375885v0.1879578
        l-1.6916504,1.1277771l1.6916504,0.9398193l1.3156738,0.1879578l1.6917114-0.3759155h1.8795776l2.067627,0.3759155
        l-0.9398193,0.9398193l-1.6916504,0.1879578l-0.7518921,0.1879578l-0.9398193,0.7518616v0.5639038l-0.1879272,1.3157349v2.2555542
        l0.7518311,1.8795776l1.6916504,0.5638733l2.819458-0.3759155l1.3157349,0.1879578l0.1879883,0.7518616l-0.9398193,1.1277771
        l-0.1879883,0.7518311l0.7518921,1.3157043l1.3157349,1.3157959l0.3759155,1.3157349l-0.1879883,1.5036621l-0.7518311,0.7518616
        l-0.5639038,0.7518311l-0.5639038,0.7518616l-0.3759155,1.8796082l0.5639038,1.5037231l0.9398193,1.5036621l0.5639038,2.2555542
        l-0.5639038,0.5638733l-0.9398193,0.375946l-1.5037231,1.3157349l-2.0675659,0.9398193l-3.0073853-0.1879883l-2.819458-0.9397888
        l-2.4434814-0.9398193l-2.4435425-0.9398193l-3.3833008-0.3759155l-1.5036621,1.3157349v2.8194275l0.5639038,2.4434814
        l0.1879272,1.6916809l-0.5639038,2.8194275l-1.1277466,2.6314697l-1.1278076,0.7518616l-0.9397583,1.1277771l-0.1879883,1.5036621
        l1.8796387,0.7518616l1.3157349,0.7518616l0.1879272,2.6314697l0.1879272,0.9398193l0.5639648,1.1277771l0.3759155,2.6314697
        l-1.3157349,1.5036621l-0.9398193,1.5036926v1.6916809l1.6916504,1.6916504v1.5037231l-0.1879272,0.9397583l-2.6315308,3.5712891
        h-0.3759155l-1.5036621,0.7518616l-0.7518921,0.5638733l-0.9398193,0.9398193l-0.3759155,0.9398193l-1.1277466,1.3157043
        l-0.5638428,0.3759766l-0.1880493,0.1879578l8.2703247,3.3833008h0.9398804l3.1953125,0.1879578l2.819458,1.1277771
        l1.5037231,0.3759155l2.2555542-0.5638733l1.8795776-0.1879883l1.5036621-0.1879578h1.8796387l1.3157349,0.1879578
        l3.0074463,2.0675964l2.2555542,2.6314392l1.6915894,0.3759766l0.9398193,0.1879578l0.3759155,0.1879272l0.5639038,0.1879578
        h0.5639038l0.3759155-0.1879578l0.3759155-0.375885l0.5639038-0.5639343l1.5037231-1.1277466l1.3157349-0.9398193
        l1.3156738-0.1879578l1.1278076-0.1879578h3.1953735l1.1277466,0.5639038l1.3157349,0.7518311l0.7518311,1.3157043
        l0.5639038,0.9398193l0.3759155,1.3157349l0.7518921,1.1277771l0.5638428,1.5037231l0.5639038,1.3157349l0.5638428,0.7518311
        l1.3157349,0.9398193l0.7518921,0.1879578h0.9398193l0.7518311-0.3759766l1.3156738,1.6916809l1.1278076,0.9398193
        l1.6916504,1.6916504l0.1879883,1.3157349v0.5639038l-2.067627,1.8795776l-0.75177,0.3759766l-0.9398193,0.5638428
        l-0.9398193,0.7518616l-0.3759155,0.5638733l-0.1879883,0.3759766l0.3759155,0.5638428l0.5639038,0.1879578l0.1879272,0.3759155
        l0.1879883,0.5639038l0.3759155,0.7518616h1.3157349l1.1277466,0.9397888l0.5639038,0.1879883l0.9398193,0.1879578h0.9398193
        l0.9398193-0.1879578l1.3157349-0.375946l0.5638428-0.1879578l0.3759766-0.3759155l0.5638428-0.375946l0.7518921-0.5638733
        h0.5638428l1.1277466-0.7518616l0.3759766,0.5639038l1.6916504-0.375946l1.1277466-0.1879578l1.6917114,0.375946
        l1.1277466,1.1277771l0.7518311,1.8796082l-0.5638428,2.4434814l-1.6917114,1.8796387l-0.5638428,2.2555542l0.1879272,1.6916504
        l0.9398193,1.5037231l1.5037231,0.7518311l2.2555542,0.375885l1.6915894,0.7518616l0.5639038,0.5639343l0.5639038,2.0675354
        v1.6916809l-0.3759155,1.1277771l-0.1879883,1.1277771l-0.3759155,0.9398193l-0.5639038,1.3157349l-0.5638428,1.3157349
        l-0.1879883,2.2555237l0.5639038,2.443512l-0.5639038,2.819397v0.7518921l-0.3759155,0.7518005l-0.3759155,0.5639038
        l-0.1879272,0.7518921l-0.1880493,0.375885l0.3759766,0.5638733l0.1879883,0.1879883v0.5638733l0.1879272,0.5638733
        l0.1879272,0.1879883v0.9397888l0.3759766,0.1879883h0.1879883l0.3758545,0.1879578h0.1879883l0.1879272,0.1879578h1.1278076
        l0.1879272,0.1879578l0.1880493,0.5639038v0.1879578l0.1879272,0.1879578h0.3759155l0.3759155,0.5638428v0.1880188
        l0.1879883,0.1879578h0.3759155v-0.1879578l0.1879272-0.1880188h0.3759155l0.1879883,0.1880188l-0.1879883,0.1879578h0.9398193
        l0.1879883-0.1879578l0.5639038-0.5639038l1.1277466-0.9398193v-0.1879578h-0.1879272v-0.3759155l0.3759155-0.375946v-0.1879578
        l0.1879272-0.1879578l1.1278076-0.3759155l0.5639038-0.375946h0.3759155l0.5638428,0.375946l0.3759766,0.1879578
        l0.1879272,0.1879578l0.1879272-0.1879578v-0.3759155h0.1879883l-0.1879883-0.1879883v-0.1879578l-0.1879272-0.1879578v-0.1879578
        h0.1879272l0.3759155-0.3759155V394.03479l0.1879883-0.1879883h0.1879883l0.1879272,0.1879883h0.7518311l0.1879883,0.1879578
        v0.3759155h0.3759155v0.5638733h0.3759155l0.5639038-0.3759155v-0.1879578l0.3759155-0.1879578h0.9398193l0.1879883-0.1879578
        l0.1879272-0.1879578h0.3759155l0.1879883-0.1879883h0.1879883l0.1879272-0.1879578v-0.1879578h0.9398193l0.5638428,0.3759155
        h0.1879883v0.1879883h0.1879883v-0.1879883h0.1879883v-0.1879578l0.1879272-0.1879578l0.3759155-0.1879578h0.1879272
        l0.3759766-0.1879578h0.3759155l0.3759155-0.1879272l0.3759766,0.1879272l0.3758545,0.1879578h0.9398193l0.3759155,0.3759155
        h0.7518921v0.1879578h0.1879272v0.375946l-0.1879272,0.1879578v0.1879578l-0.1879883,0.1879578l-0.3759155,0.3759155v0.375946
        l0.1879272,0.1879578l0.1879883,0.1879578v0.3759155l-0.1879883,0.1879883v0.1879578l-0.1879272,0.1879578v0.1879578
        l-0.3759155,0.1879578l-0.3759155,0.1879578l-0.1879883,0.1879883v0.1879578h-0.1879883v0.3759155l-0.1879272,0.1879578v0.1879272
        h0.1879272v0.1880188l0.3759766,0.3759155v0.5638428l0.5638428-0.375885h0.5639038l0.1879883,0.1879272l0.3759155,0.1879578
        v0.3759766l0.1879272,0.375885h0.3759155l0.1879883-0.1879272l0.1879883,0.1879272h0.1879272l0.1879883,0.5638733v0.5639038
        h-0.1879883v0.1879578l0.1879883,0.1879578l0.1879272,0.1879578h0.7518921v-0.1879578h0.1879272l0.1879883-0.1879578h0.7518311
        v0.1879578l0.3759155,0.1879578v0.1880188h0.5639038l0.3759155,0.1879272v0.1879578h0.5638428l0.9398193,0.1879578
        l0.1879883,0.1879578l0.1879883,0.1879578v0.3759155l1.3157349-0.5638733h0.1879272l0.3759155-0.1879578v-0.5639038
        l-0.1879272-0.1879578v-0.1879578l-0.1879883-0.1879578v-1.5036621l-0.1879272-0.1879578l0.3759155-0.3759766h0.5639038
        l0.1879272-0.1879578h0.1879883l0.3759155-0.1879272v-0.1879578h0.5639038v0.1879578l0.1879272,0.1879272h0.1879883v0.1879578
        h0.5638428l0.1879883,0.1879578h0.1879883v0.1879578l0.1879272,0.1879578l0.1879883,0.1879578v0.375946l0.1879272,0.1879578
        l0.1879272,0.1879578h0.1880493v0.1879578l0.1879272,0.5639038v0.1879578l0.1879883,0.7518616l0.1879272,0.3759155h0.7518921
        v0.1879578l0.1879272,0.3759155v0.375946l0.1879883,0.3759155v0.3759155l-0.1879883,0.375946v0.1879578l-0.5639038,0.7518005
        v0.7518616l-0.3759155,0.3759155h-0.1879272l-0.9398193,0.5639038l-0.1879883,0.1879578h-0.1879272v0.1879578l-0.1879883,0.1879578
        l-0.1879883,0.1879578l-0.3759155,0.1879883l-0.1879272,0.1879578l-0.1879883,0.3759155v0.1879578l-0.1879272,0.1879578v0.375946
        l0.1879272,0.1879578l0.1879883,0.1879578v0.1879578l0.3759155,0.1879578v0.375946l-0.1879883,0.1879578l-0.1879272,0.1879578
        h0.1879272v0.3759155h0.1879883v0.1879883h0.7518311l0.7518921,0.1879578h0.9397583l0.3759766,0.1879578l0.1879272,0.1879578
        v0.1879578l0.1879883,0.1879578v0.375946h-0.1879883v0.3759155l0.1879883,0.1879578v0.375885h-0.1879883v0.1880188
        l0.1879883,0.1879578l0.3758545-0.1879578l0.1879883-0.3759766h0.3759766v0.1879578h0.1879272v0.1880188h-0.1879272
        l-0.1879883,0.1879578h0.9397583l0.1880493,0.1879578v0.375885h0.75177v0.1879578h0.1879883l0.3759155,0.3759766
        l0.3759155,0.375885v0.1879578l0.1879883,0.1879578v0.5639038l-0.3759155,0.1879578v0.7518616l-0.1879883,0.1879578
        l-0.1879272,0.1879578h-0.7518921v0.1879578h-0.1879272v0.1879578l-0.1879272,0.1879578l-0.1880493,0.1879883l-0.1879272,0.1879578
        h-0.9398193v0.1879578h-0.1879883v0.1879578l0.1879883,0.1879578v0.1879578l0.1879883,0.9398193l0.1879883,0.1879578h0.1879272
        v0.1879883h0.1879272l0.1879883,0.1879578v0.1879578l-0.1879883,0.1879578v0.3759155h-0.1879272v0.1879272h0.1879272v0.1880188
        h0.5639648l0.5638428-0.375946l1.3157349-0.3759155l0.1879272-0.1879578h0.9398193l0.1879883-0.1879578v-0.375946
        l0.1879272-0.1879578l0.1879883-0.1879578h0.9398193l0.1879883,0.1879578h0.5638428l0.7518921,0.1879578h0.9398193
        l0.1879272,0.1879883h0.1879883l0.1879272,0.1879578l0.1879272,0.1879578v0.1879578h0.1879883l0.1879272,0.1879578
        l0.1880493,0.1879578h0.3758545v0.1879272l0.1879883-0.1879272h0.3759155l0.1879883-0.1879578v-0.1879578l0.1879272-0.3759155
        v-0.375946h0.9398193l0.1879883-0.1879578h0.9398193v-0.1879578h0.5638428v0.1879578h1.8796387l0.7518311,0.375946
        l0.5639038,0.3759155l0.9398193,0.1879578h0.3759155v-0.3759155l0.1879883-0.1879578v-0.1879883h0.1879272v-0.7518311h0.3759155
        v-0.375946h-0.1879883v-0.1879578l0.1879883-0.1879578v-0.1879578h-0.7518311l0.1879883-0.1879578h-0.1879883v-0.1879578
        h-0.1879272v-0.1879883h0.1879272v-0.1879578h0.1879883v0.1879578h0.3758545v-0.1879578h0.3759766h0.3759155v0.1879578
        l0.1879272,0.1879883h0.3759155v-0.1879883h0.1880493v0.1879883h0.1879272v0.1879578h-0.1879272v-0.1879578h-0.1880493v0.1879578
        v0.1879578h0.9398193v-0.5639038h0.1879883l0.1879883-0.1879578h0.1879272v0.1879578h0.1879883v-0.1879578h0.1879272v0.1879578
        h0.7518921v-0.1879578h0.1879272v-0.1879578h0.3759155l0.1879883-0.1879578h0.1879272v-0.1879578h0.1879883v-0.375946h-0.1879883
        v-0.1879578h0.1879883l0.1879883-0.1879578v-0.3759155h0.1879272v-0.1879272l-0.1879272-0.1879578h-0.3759766v-0.1880188
        h-0.3759155v-0.3759155h-0.3759155v-0.1879272h-0.1879272l-0.1879883-0.1879578h-0.3759155v0.1879578h-0.1879883v-0.3759155
        l0.1879883-0.1880188h0.1879272v-0.375885l0.1879883-0.1879578h-0.1879883v-0.1879578l-0.1879272-0.1879578v-0.3759766
        l-0.1879883-0.1879272v-0.1879578h-0.1879272v0.1879578h-0.1879883l0.1879883-0.1879578l0.9398193-0.5639343l0.5638428-0.7518005
        l0.1879883,0.1879578v0.3759155l1.3157349,1.5037231h0.1879883l0.1879272,0.3759155h0.1879883l0.1879272,0.1879578
        l0.3759155,0.1879272h0.1879883v1.1277771l1.5037231,0.1879578h1.1277466v0.1880188h0.3759155l0.3759155,0.5638428h1.3157349
        l0.1879883-0.1879578v-0.5639038l0.1879272-0.1879578v-0.1879578l-0.1879272-0.1879272v-0.7518921l-0.3759766-0.375885v-0.1879578
        l0.1879883-0.1879578l0.1879883-0.1880188h0.5638428v-0.1879578h-0.1879272v-0.5638428l-0.1879883,0.1879578h-0.1879272
        l-0.1879883-0.3759155l-0.3759155,0.1879578l-0.7518311,0.1879578h-0.1879883v-1.1277771l-0.1879883-0.5638733
        l-0.1879272-0.5639038l-0.1879883-0.1879578l-0.3758545-0.1879578h-0.7518921l-0.3759155-0.375946l-0.1879883,0.1879883h-0.3759155
        v-0.7518616l-0.7518311-0.3759155v-0.375946l0.3759155,0.1879578l1.5036621,0.5639038l0.7518921-0.3759155h0.3759155
        l0.7518311-0.5639038l-0.5639038-0.3759155v-0.7518616l0.5639038-0.3759155v-0.1879272h0.1879883l0.5638428,1.3157043v0.9398193
        l0.3759766-0.1879883l2.819397-1.1277771h0.3759155l3.3833008-1.3157349l4.8870239-1.8795776l0.7518921-0.3759766
        l0.5639038-0.375885l0.5638428-1.5036926l0.1879883-0.3759155l-0.1879883-0.375946l0.1879883-0.3759155l0.7518311-0.3759155
        h1.3157349l0.1879272,0.1879578l0.1879883,0.1879578h0.1879272v-0.3759155h0.1880493l0.1879272-0.1879883h0.1879272
        l0.3759155,0.1879883v-0.9398193l1.5037231-0.1879578l0.3759155,0.1879578v-0.375946h0.1879883l0.1879272-0.1879578h0.1879883
        l0.9398193-0.1879578l0.1879272,0.1879578l0.3759766,0.1879578h0.1879272l0.1879883-0.1879578h0.3759155V400.04953
        l0.3759155-0.1879578h0.1879883l0.1879272-0.1879578v-0.1879578l-0.1879272-0.1879883l-0.1879883-0.1879578v-0.1879578
        l0.1879883-0.1879578v-0.375885h-0.3759766v-0.5639343h0.1879883v-0.1879578h0.1879883v-0.1879272h0.3759155v-0.1879578h2.0675659
        l0.5638428-0.1879578h0.1879883l0.3759766,0.1879578h0.5638428v0.1879578l0.1879272,0.1879272h0.1880493v0.1879578h0.1879272
        l0.1879272-0.1879578v-0.375885h0.1879883v-0.1879578h-0.3759155v-0.1880188h0.1879272v-0.1879578h0.3759155v-0.1879272h0.1879883
        v0.375885l-0.1879883,0.9398193h0.1879883l0.1879883-0.1879578v-0.1879272h0.3759155v-0.5639343l-0.1879883-0.1879578h0.7518311
        v0.1879578h0.1879883v0.1880188h0.1879883v-0.1880188h0.1879272v0.1880188v0.5638428h0.1879883v0.1879578h-0.3759155v-0.1879578
        h-0.1879883l-0.1879883,0.3759155h-0.1879272v0.1880188h0.1879272v0.1879578h0.1879883l0.1879883,0.375885l0.1879272,0.1879578
        h0.7518311l0.5639038,0.1879578l0.1879883,0.1879578h0.1879272l0.1879883,0.1879883v0.1879578h0.1879883v0.1879578h0.1879272
        v0.1879578h0.1879883v0.1879578h0.1879272v0.1879578h0.1879883l0.1879272-0.1879578h0.9398193V400.04953h0.1879272v0.1879578
        h0.1879883v0.1879578h0.1879883v-0.1879578h0.1879883v-0.3759155h0.1879272l0.1879272,0.1879578h0.3759155l0.3759766-0.1879578
        l0.1879272-0.1879578l0.1879883-0.1879578l-0.1879883-0.375946v-0.1879578h0.5639038l0.3759155-0.1879578l0.3759155-0.1879578
        h0.1879883l0.1879272-0.1879272l0.3759766,0.1879272l0.1879272,0.1879578h0.1879883l0.3759155-0.375885h0.1879272
        l0.3759766-0.3759766l0.1879272-0.3759155v-0.1879272h-0.1879272l0.1879272-0.1879578h-0.1879272l0.1879272-0.1879578v-0.1880188
        l0.1879883-0.1879578h0.1879272l0.1879883-0.1879272h0.1879883l0.1879272-0.1879578h-0.1879272v-0.5639343l0.1879272-0.5638428
        v-0.1879578h0.1879883v-0.5639038l-0.1879883-1.6916504h0.1879883v-0.3759155l-0.1879883-0.1879883v-0.1879578h0.1879883
        l-0.1879883-0.1879578l-0.3759155-0.5638733l0.1879883-0.1879883l-0.5639038-0.3759155l-0.7518921,0.1879578l0.1879883-0.3759155
        l-0.1879883-0.3759155l0.5639038,0.1879578v-0.5639038h0.1879883v-0.1879578l0.3759155,0.5639038l0.5639038,0.1879578
        l0.5638428,0.3759155l0.1879883,0.1879578l0.3759155,0.375946l0.3759155,0.9397888v0.1879883h0.5639038v-0.1879883h0.1879883
        l0.3759155-0.3759155v-0.7518616l-0.5639038-0.3759155l-0.1879272-0.1879578l0.1879272-0.1879578v-0.1879578l-0.5639038-0.9398193
        h-0.1879272l-0.7518921-1.1277466l0.5639038,0.375885l0.3759155,0.1879578l0.9398193,1.5037231l0.5638428,0.5638733
        l0.3759766-0.3759155h0.3759155l0.3759155,0.5638733l0.1879883,0.5639038l-0.1879883,0.1879578l0.1879883,0.1879578
        l0.5638428,0.1879578l0.5639648,0.1879578l0.3758545,0.9398193l-0.3758545,0.1879578h0.1879272l0.1879272,0.5639038
        l0.5639038-0.3759155v0.7518311l0.7518311,0.7518616l0.7518921,0.5638733l0.3759155,0.375946l0.3759155,0.1879578
        l0.1879272,0.3759155l0.5639038,0.375885h-0.1879272v0.1880188l0.3759155,0.1879578l0.1879272,0.1879578v0.5638428
        l-0.3759155,0.1880188l-0.1879272,0.375885l-1.3157349,0.9398193l-0.1879883-0.375946l-1.3157349,0.9398193l-1.3157349,0.3759155
        l-0.1879883,0.375946h-0.7518311v-0.375946h-0.7518311l-0.1879272,0.1879883h-0.3759766v0.1879578l0.1879272,0.1879578
        l-0.5638428,0.1879578h-0.1879272l-0.3759155,0.5639038l-0.3759766,0.3759155l-1.1277466,0.7518616l-0.1879883,0.1879578
        l-0.1879272,0.3759155l-0.1879883,0.1879578h-0.5639038v-0.1879578h-0.1879272l-0.3759155,0.1879578h-0.1879883
        l-0.3759155,0.1879578h-0.7518311l-0.1879272,0.1879578h-0.1880493l-1.3157349,0.7518616v0.375885l-0.1879272,0.3759766
        l-0.1879883,0.1879578l-0.5639038,0.1879578l-1.5036621,1.5036621l0.3759155,0.5639038v0.7518311l-0.7518311,0.7518616
        l-0.1879272,0.1879578l0.1879272,0.1879578v0.375946l-0.1879272,0.7518311l0.3759155,0.1879883v0.9397888l-0.1879883,0.1879883
        v0.1879578l-0.1879272,0.1879578l-0.1879883-0.1879578h-0.1879883v0.3759155l-0.1879272,0.1879578l0.1879272,0.1879578h0.1879883
        v0.375946l-0.1879883,0.5638733l0.5639038,0.5639038h0.1879883l0.1879272,0.1879578v0.3759155h0.1879272l0.5639648,0.5639038
        l0.3758545,0.7518005l0.3759155,0.3759766l0.1880493,0.5638428v0.7518616l-0.1880493,0.7518311l-0.75177,0.7518616
        l-1.1278076,1.1277771h-0.1879272l-0.1879883,0.1879578h-0.3759155l0.1879272,0.3759155v0.9398193l-0.1879272,0.375885h-0.3759155
        l-0.1879883,0.1880188h-0.1879272l0.1879272,0.3759155v0.1879578l-0.3759155,0.5639038l-0.3759155,0.3759155h-0.1879883v0.1879272
        l0.3759155,0.5639343l0.5639038,0.1879578l1.5037231,1.3157043l0.3758545,0.3759155l0.3759766,0.3759155l0.3759155,0.375946
        l-0.1879272,0.5638733l-0.5639648,1.6916809l0.1879883,1.3157349l0.3759766,0.3759155l0.1879272,0.1879578l0.1879272,0.5639038
        l-0.1879272,0.3759155v0.1879272l-0.3759155,0.3759766l-0.7518311-0.5639038l-0.9398193,0.9398193l-0.1879883,0.1879272v0.1879578
        l0.1879883,0.1879578v0.1880188l0.5639038,0.375885l0.75177,0.7518921l0.3759766-0.3759766v0.1879578h0.1879883l0.75177,0.375946
        v0.1879578h0.1880493v0.7518311l0.1879272,0.1879883l0.1879272,0.1879578l-0.1879272,0.3759155l-1.5037231,0.7518616
        l0.1879883,0.1879578h0.3759155l0.5638428-0.1879578h0.5639038l0.5639038-0.1879578l0.3759155-0.1879883l0.5639038-0.3759155
        v-0.3759155h-0.1879883v-0.1879578l-0.1879272-0.1879883l0.1879272-0.1879578h0.3759155v0.375946h0.1880493v0.3759155h0.1879272
        v-0.1879578h0.3759155l0.1879272-0.1879578h-0.1879272v-0.5639038h0.3759155v-0.1879578h0.3759155l0.1879883,0.1879578v0.1879578
        h0.1879272v0.1879578h0.1879883h0.1879272l0.1879883-0.1879578h0.5639038v-0.1879578h0.3759155v0.1879578h0.1879272v-0.3759155
        l0.5639038-0.0000305v-0.1879272l0.7518921-0.0000305l0.1879272-0.1879578h0.7518311v0.1879578h0.1879272v0.1879578h0.5639648
        l0.1879272-0.1879578h0.1879272l0.3759766,0.1879578h0.1879883l0.1879272,0.5639038h0.1879272l0.1879883,0.3759155v0.1879578
        l0.3759766,0.1879883l0.1879272-0.1879883h0.3759155l0.3759155-0.3759155l0.3759155,0.1879578l0.1879883-0.1879578
        l0.1879272,0.1879578v0.1879578h0.3759155l0.1879883-0.3759155h-0.1879883v-0.1879578h0.3759766v-0.1879578l0.1879272-0.1879578
        l0.1879883,0.1879578h1.3157349v-0.7518616h-0.1879883v-0.1879578l0.1879883,0.1879578h0.3759155l0.1879883-0.1879578v-0.1879578
        h0.5638428l0.1879883-0.1879578h0.7518311l0.1879883,0.1879578h0.5638428l0.1879883-0.1879578h1.6916504l2.6314697,0.1879578
        l1.3157349,0.3759155l1.3157349,0.5639038l1.8796387,0.9397888l0.7518311,0.5639038l0.3759155,0.5638733l0.1879272,0.375946
        l0.5639648,1.5036926h-0.3759766v0.375885l0.3759766,0.1879578l0.1879272,0.3759766l0.1879272-0.1879578v-0.5639343
        l-0.1879272-0.5638428l-0.1879272-0.5639038l-0.1879883-1.3157349l-0.3759766-0.7518616l-1.3156738-0.9397888l-0.7518311-0.375946
        l-0.7518921-0.3759155l-0.3759155-0.3759155v-0.5639038h0.1879883l0.5638428-0.5638733l0.1879883-0.375946v-0.9397583
        l0.5639038,0.1879272l-0.5639038,1.5036926h-0.1879883v0.3759155l0.1879883,0.1879883l0.1879883,0.1879578h0.1879272
        l0.1879883,0.1879578l0.1879272,0.1879578h0.7518921v0.1879578l0.1879272-0.1879578v0.3759155l0.1879883,0.1879578h0.1879272
        v0.1879883h0.1879272l0.1879883,0.1879578v0.1879578h0.1879883v0.1879578l0.1879883,0.1879578h0.1879272v0.1879578h-0.1879272
        v0.1879883l0.1879272,0.1879578v0.1879578h0.1879272v0.7518616l0.1879883,0.3759155v0.9398193l0.1879272,0.7518005
        l-0.1879272,0.5639343l-0.3759155,0.1879272h-0.1879272l-0.1879883-0.1879272h-0.3759766l-0.1879272,0.1879272h-0.3759155
        l-0.1879272,0.3759155l0.5638428,0.5639038l0.1879272,0.3759155v0.1879578l-0.1879272,0.1879578v0.1879883h0.3759155v0.5638733
        l-0.1879883,0.1879578l-0.1879272,0.1879578h-0.3759155v0.375946h-0.9398193v0.1879578l-0.1879272,0.3759155h0.1879272
        l0.1879883,0.1879578l-0.1879883,0.1879578v0.375946l-0.1879272,0.5638733l0.1879272-0.1879578l0.1879883,0.1879578v0.375946
        h0.1879272l0.3759766-0.1879883l0.1879272-0.1879578v-0.1879578l0.1879883-0.5638733l0.3758545-0.375946l0.7518921-0.3759155
        l0.1879272-0.1879578l0.1879883,0.3759155l0.3759766-0.1879578v0.1879578h0.75177v-0.1879578l0.5639038,0.1879578
        l0.1879883,0.1879578h0.7518311v-0.1879578h0.1879883v-0.1879578h0.1879272v-0.1879578h0.1879883v-0.1879578h0.5638428
        l0.3759766-0.375946v-0.3759155l0.5638428-0.3759155v-0.7518616l-0.5638428-0.5638428l-0.1879883-0.1880188v-0.5638733h-0.1879883
        v0.1879578l-0.1879272-0.1879578l-0.1879883-0.1879272h0.1879883v-0.1879578h-0.1879883v-0.1880188h-0.1879272
        l-0.1879883-0.1879578h-0.3759155l-0.1879883-0.1879578h-0.3759155l-0.3759155,0.1879578l-0.1879272-0.1879578l-0.3759766-0.375885
        v-1.3157349l0.1879883-1.3157349v-1.1277771l0.5639038-1.3157349l0.1879272-0.9398193l0.5639038-0.7518616l0.1879883-0.7518616
        l1.1277466-1.6916199l0.1879883-0.1879578l0.1879272-0.1879578l0.1879883,0.1879578h0.5639038l-0.1879883-0.1879578v-0.5639038
        l0.1879883-0.1879578l0.1879272-0.1879578h0.5639038l-0.3759155-0.1879578l-0.1879883-0.1879578v-0.5639038h0.1879883
        l0.1879272-0.5638733l0.7518921-0.9398193l1.1277466-0.7518616l-0.1879883-0.3759155l0.1879883-0.1879578l0.7518311-0.7518616
        l0.1879272-0.3759155h0.3759766v-0.375946l0.3759155-0.1879578h0.5639038v-0.3759155l0.1879883-0.1879578l0.1879272,0.1879578
        v-0.375885h0.3759155l0.1879272,0.1879272l0.1879883-0.1879272l-0.1879883-0.1880188h0.1879883l0.1879883-0.3759155
        l0.9397583-0.7518005l0.1879883-0.1880188l0.5639038-0.1879578v-0.1879272l0.9398193-0.7518921l0.3759155-0.375885
        l0.7518311-0.3759155l0.3759155,0.1879578v-0.3759155h-0.1879272l-0.3759155-0.7518616v-0.9398193h-0.5639038v-0.7518311
        l0.1879272-0.1879883v-0.3759155h-1.3157349l-0.1879272-0.1879578l-0.7518921-0.1879578l-0.1879272-0.1879578l-0.1879883-0.375946
        l-0.1879272-0.1879578v-0.3759155l-0.1879883-0.1879272h-0.1879272l-0.1879883-0.1880188h-0.3759155l-0.5638428-0.3759155
        l-0.7518921-0.5638428l-0.5638428-0.1880188l-0.9398193-0.5638733l-0.1879883-0.1879272l-0.9398193-0.1879578l-0.3759155-0.1879578
        v-0.3759766l-0.3759155-0.1879272h-0.1879883l0.1879883-0.1879578h-0.1879883l-0.1879272-0.1879578v-0.3759766l-0.3759155-0.375885
        l-0.7518921-0.3759155v0.1879578h-0.3759155l-0.9398193,0.7518616h-0.3759155v-0.1879578h-0.1879883l-0.1879272,0.1879578
        l-0.3759155,0.1879578l-0.3759155,0.1879578l-0.1879883-0.1879578l-0.1879883,0.1879578l-0.1879272-0.1879578l-0.3759155,0.1879578
        l-0.3759766,0.1879883l-0.1879272-0.5639038h-0.1879883l-0.1879272-0.7518616l-0.3759155-0.1879578h-0.7518311v-0.1879578
        h-0.3759155v-0.5638733l-1.3157349,0.1879578l-0.1879883-0.9398193h0.5639038v-0.1879578h0.1879272l0.3759155-0.1879578v-0.375946
        l0.3759766-0.1879578v-1.3157349l-0.1880493-0.1879272h-0.3758545l-0.1879883-0.1879578v-0.1879578l-0.5638428-0.3759766
        l-0.5639038-0.375885h-0.5639038l-0.5639038,0.1879578l-0.1879272,0.1879272l-0.1879883-0.375885l0.3759155-0.3759155v-0.1880188
        l-0.7518311-0.7518005l0.1879883-0.1879578v-0.1879883l-0.3759766-0.1879578h-0.3759155l0.5639038-0.1879578l0.1879883,0.1879578
        l0.3759155,0.1879578h0.1879272l0.1879883-0.3759155l-0.1879883-0.1879578l0.1879883-0.3759155l0.3759155-0.1879578
        l0.1879883,0.3759155l0.1879272,0.1879578h0.1879883l0.1879272-0.3759155l0.1879883-0.1879578l0.1879883-0.1879883
        l0.1879272,0.1879883v-0.1879883l0.1879883-0.1879578l-0.1879883-0.1879578v-0.1879578l0.5639038,0.3759155v-0.1879578
        l0.3758545,0.5639038l0.3759766,0.1879578l0.3759155-0.1879578h0.1879272l-0.1879272,0.5638733l0.5639038,0.5638733
        l0.1879883,0.5639038l0.3758545-0.1879578v0.1879578l0.1879883,0.1879578l0.3759766,0.375885l0.5638428-0.1879272
        l-0.1879883,0.375946l1.1278076,0.7518005l0.1879272-0.1879272h1.6916504l0.1879883-0.3759155l0.1879883,0.1879578h1.1277466
        l0.1879883-0.1879578l0.1879272-0.1879578l0.3759155-0.1880188h0.1879883V405.3125h0.3759155v0.3759155h0.7518311
        l-0.1879883-0.3759155h0.1879883v-0.375946l0.1879883-0.1879578h0.1879883l0.1879272-0.1879578l0.1879272-0.3759155
        l0.1879883-0.1879883l0.3759766,0.1879883h-0.3759766v0.1879578l-0.1879883,0.1879578v0.3759155h0.1879883l0.1879272-0.1879578
        v-0.1879578l0.1880493-0.1879578v0.1879578h0.1879272l-0.1879272,0.1879578l-0.1880493,0.5639038v0.3759155l0.1880493,0.1879578
        l0.75177,0.5639038l1.1278076,0.7518005l0.7518311,0.5638733h0.1879883v0.375946h0.3759155l-0.5639038,0.1879578v0.1879578
        l0.3759155,0.9398193v0.7518311l-0.5638428,1.6916809v2.0675354l0.1879272,0.5639038l0.3759155,0.3759155v0.1879578
        l0.1879883,0.1880188l0.3759155,0.5638428l0.3759155,0.5639038h0.7518921l0.9397583-0.1879883l0.1879883-0.1879578
        l0.1879883,0.1879578l0.3759155,0.1879883l0.1879272-0.1879883l0.1879883,0.1879883l0.1879272,0.1879578l0.3759766,0.3759155
        v0.1879578l-0.1879883,0.1879578l-0.1879883-0.1879578v0.1879578l0.3759766,0.375946h0.1879272l-0.1879272,0.1879578v0.1879578
        l0.1879272,0.1879578h0.5638428l0.9398193-0.5638733h0.1879883L903.25,416.5901794l0.9397583-0.375946l0.1879272-0.1879578
        v-0.3759155l0.3759766-0.1879578l0.1879272-0.1879272h0.1879883l0.7518311-0.3759766l0.1879883-0.1879578l0.1879272,0.1879578
        l0.1879883,0.1879578h1.5037231v-0.5638733h0.1879272l0.1879883-0.1879578h0.3759155l0.1879272,0.1879578h0.1879272
        l0.1880493,0.1879578h0.75177v-0.1879578h0.7518921v-0.375885l-0.3759155-0.3759766l-0.3759766-0.1879578l-2.4434814-1.5036621
        l-0.9398193-0.5639343l-1.5036621-1.5036621l-0.5639038-1.5036926l-0.1879883-0.7518616l-0.1879272-1.3157349l-0.3759155-1.8796387
        l-0.1879883-1.8795776l-0.5639038-0.7518616l-0.75177-0.9398193l-0.9398193-0.5638733l-1.1278076-0.9397888l-4.3231201-2.6315002
        l-1.3157349-1.3157043l-0.7518311-1.3157349l-0.5639038-1.5036926v-4.8869934l-0.9398193-0.9398193h-1.3157349
        l-1.6916504-0.3759155l-0.7518311-1.1277771l0.1879272-1.6916809l0.5639038-1.6916504l0.7518311-0.7518616l0.7518921-1.6916199
        l-0.3759155-1.3157349l-0.5639038-0.1880188l-0.7518311-1.1277771l-1.1278076-2.6314392l0.1879883-1.3157349l1.3157349-1.6916199
        l2.4434814-1.6917114l2.6314697-1.1277771l2.2555542-0.375885l2.4434814-0.3759155l1.5036621-0.375946l1.8796387-0.7518311
        l0.9398193-0.7518616l2.067627-1.3157349l2.4434814-1.1277771l3.0073853-1.1277771l1.3157349-0.375885l1.6916504-0.7518921
        l0.9398193-1.6916199l0.7518311-1.8796387l1.1278076-1.3157349l1.8796387-1.3157349l1.8795776-0.5639038h1.3157959
        l2.2554932,0.1879578l1.3157349-0.7518311l1.5037231-0.5639038l1.1277466,0.375946l1.5037231,2.0675659l1.1277466,0.5639038
        l1.3157349,0.3759155l2.4434814-0.9398193l1.5037231-1.6916504l0.9397583-1.8796387l1.1278076-1.3157349l0.3759155-0.5638428
        l3.9472046-3.0074158l1.8796387-0.9398193l5.638855-1.5036926l2.4434814-1.1277771l1.3157959-1.6916199l0.9397583-1.1277771
        l0.9397583-1.1277771l1.1278687-0.5639038l1.6916504-0.9397888l1.1277466-0.7518616l1.3157349-1.1277771l0.9398193-1.3157349
        v-1.3157043l-1.3157959-4.6990662l0.1879272-1.5037231l0.1880493-1.5036926l0.3759155-1.3157349l0.5639038-0.5638428
        l2.0675659-0.7518616l3.1953125-0.1879578l1.8796387-0.3759766l1.1277466-0.375885l0.9398804-1.3157349l0.3759155-1.1277771
        l0.75177-1.1277771l1.1278687-1.3157349l1.5036011-0.9398193l1.6917114-0.7518616l2.2555542-0.9397583l2.2555542-1.1277771
        l1.3157349-1.1277771l1.3156738-1.3157349l2.2555542-1.8796387l1.6917114-2.0675964L986.8930664,307.1962891z
        M854.7557373,436.7021179h-0.1879883v0.375946h0.5639648v-0.1879883h-0.3759766V436.7021179z M838.7789917,400.9893494v0.1879578
        h0.3759155v-0.1879578h0.1879272l-0.1879272-0.1879578h-0.1879883v0.1879578H838.7789917z"

            filter="url(#dropshadow)" // Apply the drop shadow filter here

          />
          <text x="790" y="320" className="centered-text">惠州</text>

        </a>






        <a xlinkHref="../about/foshan.html" id="dmap_foshan" className="focus">
          <title>佛山</title>

          <path id="Foshan_5_" className="gba1"
            d="M497.0640564,262.2734985 497.2520142,263.0253296 496.6881409,264.5290222 
        495.9363098,265.8447876 496.3121948,266.7845764 497.4399719,267.1604919 498.7557068,267.1604919 499.6955261,267.9123535 
        500.2593994,268.8521729 500.8233032,270.5438232 501.3871765,272.4234314 501.9510803,273.7391663 502.1390381,275.0549011 
        501.763092,276.370636 501.011261,277.4983826 500.2594299,279.1900635 500.0714722,280.5057983 500.2594299,282.1974487 
        500.8233032,282.761322 501.3871765,283.1372681 502.7029114,283.8890991 504.3945923,284.2650452 504.9584656,284.4530029 
        505.7102966,284.8289185 507.0260315,285.3928223 508.5297546,285.0168762 509.469574,284.6409607 510.4093628,284.4530029 
        510.9732361,285.2048645 510.4093628,286.5205688 509.2815857,287.6483459 509.2815857,288.2122498 510.4093628,289.9039001 
        510.9732361,291.0316467 511.7250977,292.7233276 511.7250977,294.2270203 511.5371399,295.3547974 511.5371399,296.4825745 
        511.3491821,297.4223633 511.3491821,298.5501404 511.9130554,300.4297485 512.1010132,301.5575256 511.7250977,302.873291 
        511.5371399,304.0010681 512.1010132,305.1288147 514.3565674,304.9408569 516.8000488,305.3167725 518.303772,305.3167725 
        519.4315796,304.5649109 519.6195068,303.249176 519.4315796,302.1213989 519.2435913,301.7455139 518.4917603,299.4899597 
        519.055603,297.6103516 520.1834106,296.6705322 521.4991455,296.1066284 522.6268921,296.2945862 523.5667114,296.2945862 
        524.694519,295.3547974 524.8824463,294.7908936 525.8222656,294.0390625 526.762085,293.4751892 528.0777588,292.9112854 
        529.2055664,292.9112854 530.5213013,293.0992432 531.2731323,293.4751892 532.4009399,293.8510742 532.7768555,294.7908936 
        533.152771,295.7307129 534.2805176,296.8584595 535.0324097,297.4223633 535.7841797,297.6103516 536.5360718,297.9862366 
        538.6036377,299.1140137 538.6036377,300.2417908 539.1675415,301.7455139 539.7314453,302.3093872 539.9193726,303.249176 
        540.1073608,304.1889954 540.2952881,305.1287842 540.1073608,305.8806763 539.9193726,306.6325073 539.543457,307.5723267 
        539.543457,308.1362 539.7314453,309.0760193 540.1073608,310.0158386 540.6712646,310.7676697 541.0471802,311.331543 
        541.4230957,312.2713623 541.9869995,313.0232239 542.5508423,313.5870972 543.3027344,314.151001 543.8665771,314.7148743 
        544.2425537,315.0907898 544.8063965,315.6546631 544.9943848,316.4065247 544.9943848,318.8500366 544.8063965,319.4139099 
        544.430481,319.6018677 544.2425537,319.6018677 542.7388306,321.1055908 541.6110229,322.2333679 540.8591919,322.7972107 
        540.2952881,324.1129761 540.2952881,325.6166687 541.4230957,326.556488 543.6786499,326.556488 545.3703003,326.7444153 
        545.5582886,326.7444153 545.5582886,327.6842346 545.3703003,329.1879272 545.182312,329.9397888 545.182312,330.5036926 
        544.9943848,330.8795776 543.6786499,330.8795776 543.6786499,331.2555237 544.0545654,332.5712585 544.0545654,333.5110779 
        545.7462158,335.390686 546.1221313,336.1425171 545.7462158,337.2702942 545.3703003,337.834198 544.2425537,338.0221558 
        543.1147461,337.834198 542.1749268,338.0221558 538.9795532,338.9619751 538.791626,339.3378906 539.1675415,340.6536255 
        541.2351074,343.473053 541.9869995,344.2249146 545.182312,345.5406189 546.8740234,346.4804382 547.8138428,347.044342 
        548.1896973,347.044342 548.5656128,347.2322998 551.9489746,347.2322998 552.5128784,347.044342 553.2647095,346.8563538 
        554.0165405,346.668396 554.2045288,349.2998657 554.9563599,349.8637695 554.9563599,350.6156006 554.7684326,351.1795044 
        554.2045288,351.9313354 553.640625,352.4952087 553.2647095,353.6229858 553.0767212,354.9387207 553.4526367,356.0664978 
        553.8286133,356.6303711 554.2045288,357.3822327 554.7684326,358.322052 555.1443481,358.1340942 555.5202637,359.4498291 
        556.0841675,360.7655334 557.2119141,361.7053528 558.3397217,362.6451721 560.2192993,363.3970337 561.7230225,363.960907 
        562.850769,364.7127686 563.6026001,366.7803345 563.2266846,367.9081116 562.098938,368.8479309 560.9711304,369.2238159 
        559.8433838,369.7877197 559.4674683,370.5395813 559.27948,371.2914124 559.27948,372.7951355 559.0914917,374.4867554 
        559.0914917,375.9904785 559.4674683,376.9302979 560.4072266,377.6821289 561.5350342,378.6219177 562.2868652,378.9978638 
        562.6627808,379.3737793 563.4146729,379.7496948 563.7905884,380.3135986 564.3544922,381.253418 564.5424194,382.005249 
        564.7304077,382.9450684 564.918335,383.8848572 565.1063232,385.0126343 565.2943115,386.3283997 565.4822388,387.4561462 
        565.8581543,388.5839233 566.9859619,389.7116699 567.9257813,390.2755737 568.8656006,390.839447 569.8053589,391.0274353 
        570.5571899,391.0274353 570.9331055,391.2153931 572.0609131,391.0274353 573.0007324,390.6514893 573.9405518,390.839447 
        575.0682983,390.6514893 576.5720215,389.8996582 577.6997681,389.3357849 579.5794067,389.5237122 581.2710571,390.2755737 
        581.6469727,391.9672241 581.0830688,394.7866516 579.5794067,395.9144287 578.0756836,395.7264709 576.5720215,395.1625977 
        575.4442139,395.5385132 575.0682983,395.5385132 574.8803711,395.7264709 574.6923828,395.9144287 574.6923828,397.2301636 
        575.0682983,397.9819946 575.4442139,399.29776 575.8201904,400.0495911 575.8201904,400.2375488 576.0081177,400.4255371 
        576.196106,400.6134949 576.3840332,400.6134949 575.6322021,401.1773682 575.6322021,401.3653259 575.4442139,401.5532837 
        575.2562866,401.741272 575.2562866,402.117157 575.0682983,402.3051453 575.0682983,402.6810608 574.8803711,403.0569763 
        574.6923828,403.8088379 574.3164673,404.1847534 573.9405518,404.5606995 573.3766479,405.3125305 572.8127441,405.6884766 
        572.4368286,405.8764343 571.6849976,406.2523193 571.1211548,406.6282654 569.6173706,406.8162231 568.3016968,407.1921387 
        566.9859619,407.3800964 566.4220581,407.5680847 565.8581543,407.7560425 565.4822388,407.7560425 564.918335,407.9440002 
        562.6627808,407.9440002 562.098938,407.7560425 561.7230225,407.5680847 560.4072266,407.5680847 560.031311,407.7560425 
        559.6554565,407.7560425 559.27948,408.1319885 558.9035645,408.5078735 558.5276489,408.8838196 557.7758179,409.8236389 
        557.5878296,410.0115967 556.6480103,410.0115967 556.460083,409.6356506 556.0841675,409.2597351 555.7081909,409.0717773 
        555.5202637,408.8838196 555.3322754,408.5078735 554.9563599,408.1319885 554.5804443,407.7560425 554.2045288,407.3800964 
        553.4526367,407.0042114 553.0767212,406.6282654 552.3249512,406.4403076 551.1971436,406.4403076 550.821167,407.0042114 
        550.4453125,407.7560425 550.069397,408.8838196 549.5054932,410.0115967 549.3175049,410.3875122 548.1897583,412.2671509 
        547.0619507,413.5828552 546.3101196,414.3347168 544.8063965,415.8384094 543.8665771,417.7180176 543.6786499,418.4698792 
        542.7388306,419.785614 540.1073608,420.7254333 538.2277832,421.4772644 537.0999756,422.4170837 535.7842407,421.1013489 
        535.2203369,420.3494873 534.8444824,419.9735718 534.2805176,419.5976563 533.9046021,419.2217407 533.3407593,418.6578674 
        532.7768555,418.0939636 531.4611206,416.7782288 530.8972168,416.4023132 529.9573975,415.8384094 529.3935547,415.4624939 
        529.0176392,415.2745361 528.4537354,415.0865784 527.513916,414.8985901 527.1380005,414.7106323 526.762085,414.8985901 
        526.1981812,414.8985901 525.6342773,415.0865784 525.0704346,415.0865784 524.8824463,415.2745361 524.694519,415.4624939 
        524.5065308,415.4624939 524.1306152,415.6504517 523.942627,415.6504517 523.5667114,415.4624939 523.3787842,415.4624939 
        523.0028687,415.0865784 522.6268921,414.8985901 522.4389648,414.5227051 522.2509766,414.146759 522.0630493,413.5828857 
        521.6870728,413.2069397 521.4991455,412.6430664 521.12323,412.0791626 520.7473145,411.5152893 520.3713379,410.7634277 
        520.3713379,410.1995544 520.1834106,409.4477234 519.9954834,408.8838196 519.6195068,407.9440002 519.4315796,407.1921692 
        519.2435913,406.0643921 518.8676758,404.5606995 518.6796875,403.6208801 518.6796875,402.493103 517.7399292,401.5532837 
        517.7399292,400.4255371 517.5519409,399.6736755 517.1760254,398.7338867 516.6121216,397.4181213 516.0482178,396.102417 
        514.9204712,394.7866821 514.1686401,394.2227783 513.7927246,394.0348206 513.6047363,394.0348206 513.416748,393.8468628 
        510.0334778,393.8468628 509.6575317,394.0348511 508.5297546,394.2227783 507.4019775,394.4107361 507.026062,394.5987244 
        505.8983154,394.7866821 505.5223694,394.9746399 505.1464233,394.9746399 504.0186768,395.1625977 499.6955566,395.1625977 
        499.3196106,394.9746399 498.9436951,394.7866821 498.5677795,394.4107361 497.815918,393.4709473 497.2520447,392.907074 
        496.6881714,391.9672546 496.3122253,391.2153931 495.7483215,390.463562 494.4326172,389.1477966 492.7409363,387.8320923 
        491.4252319,386.7043152 489.9215088,385.5765381 488.6057739,384.824707 487.1021118,384.824707 485.4104309,385.2006226 
        482.2150574,385.7645264 480.3354492,386.1404114 478.079895,386.7043152 476.0123291,387.2681885 475.0725098,388.02005 
        474.5086365,388.7719116 474.1326904,389.1478271 473.0049438,391.0274658 471.689209,392.3431702 470.7493896,392.907074 
        468.493866,393.658905 466.6142273,395.1626282 466.2383118,397.2301941 466.4262695,399.4857483 466.2383118,401.3653564 
        465.4864807,403.4329529 465.4864807,405.1246033 465.2984924,406.0643921 464.9225769,408.1319885 463.4188843,409.6356812 
        461.5392456,410.5755005 459.8475952,411.5152893 458.1559448,412.6430664 456.4642639,414.5227051 455.3364868,415.2745361 
        454.396698,415.8384399 453.4568787,416.4023132 452.7050476,416.966217 451.5772705,417.5300903 449.6976624,418.0939941 
        448.1939392,417.7180481 447.4421082,416.7782288 446.3143311,416.2143555 445.3745422,414.8986206 445.5625,412.6430664 
        445.3745422,410.7634583 445.186554,409.0718079 444.4347229,407.0042114 442.9309998,404.9366455 442.3671265,404.3727417 
        441.0513916,403.2449646 439.7356567,403.2449646 438.9838257,404.1847839 438.6078796,406.2523499 438.9838257,408.5079041 
        438.6078796,410.951416 437.4801331,412.0791931 436.352356,411.8912354 435.6004944,410.951416 434.8486633,409.6356812 
        433.908844,408.8838501 432.4051208,408.8838501 430.713501,409.8236694 429.5857239,411.3273315 429.2097778,413.0190125 
        428.8338623,414.5227051 427.1422119,416.0263977 426.0144348,416.0263977 424.8866882,415.0866089 422.8190918,414.1467896 
        421.3153992,414.1467896 420.7514954,415.4624939 421.3153992,417.3421326 420.9394531,418.8458252 419.9996338,420.3495178 
        420.3755798,422.0411682 420.7515259,423.7328491 420.1876221,425.0485535 418.4959717,426.1763306 416.4283752,426.3643188 
        414.5487671,425.9883728 412.4811707,424.6726685 411.5413513,422.9809875 410.977478,420.9133911 410.4136047,419.5976868 
        410.4136047,417.1541748 410.225647,415.2745667 410.0376587,413.5828857 409.0978699,411.7032776 408.5339661,410.3875427 
        407.782135,408.6958923 406.0904846,407.9440308 404.3988037,407.756073 402.8951111,408.5079041 401.0155029,410.5755005 
        399.8877258,411.7032776 398.7599487,412.6430664 397.8201294,413.3949585 395.7525635,413.2069702 393.8729248,412.0791931 
        392.5572205,412.4551392 391.6174011,413.5828857 391.4294434,416.0263977 390.8655396,418.4699097 389.7377625,420.1615601 
        388.7979431,420.7254639 386.918335,421.1013794 384.850769,420.7254639 383.5350037,419.409729 383.3470459,417.5301208 
        383.3470459,416.5903015 383.9109497,414.7106628 384.0989075,412.6430969 383.5350037,410.951416 382.4072266,409.0718079 
        380.7156067,406.8162537 380.1517029,404.56073 380.5276184,402.6810913 380.9035645,401.7413025 381.4674377,401.1773987 
        382.9711304,400.2375793 385.9785156,399.8616943 388.0461121,399.4857483 391.4294434,399.1098328 393.8729248,398.1700134 
        396.8803101,396.8542786 398.7599487,395.9144592 400.8275452,394.5987549 401.9552917,392.7191162 402.3312073,390.2756042 
        403.2710266,389.3358154 404.7747192,389.1478271 406.2784119,389.5237732 408.5339661,390.6515503 410.4135742,391.0274658 
        411.9172974,391.2154236 414.172821,390.6515198 415.8644714,389.3358154 417.1802368,387.268219 416.9922485,385.3886108 
        416.4283752,384.4487915 415.6765137,382.7571411 415.4885559,381.8173218 416.2404175,380.6895447 417.9320679,380.6895447 
        420.1876221,381.629364 422.4431763,381.0654907 424.1347961,380.3135986 425.8264771,379.3738098 426.9542236,378.6219482 
        429.0218201,378.2460327 431.0894165,378.058075 432.4051208,377.8701172 433.908844,377.8701172 435.2245789,377.6821594 
        437.104187,376.9303284 437.8560486,375.8025513 438.4199219,375.4266052 439.7356567,374.8627319 440.6754761,374.2988281 
        440.8634338,373.5469971 440.8634338,372.6071777 440.6754761,371.4794006 440.4874878,370.1636658 440.1116028,369.2238464 
        439.547699,368.2840576 439.1717834,367.5322266 438.9838257,366.4044495 439.3597412,365.8405457 439.7356567,365.4646301 
        440.4874878,365.2766724 441.6152649,365.0886841 442.3671265,365.0886841 443.3069458,365.2766724 444.0587769,366.0285034 
        444.9985962,366.5924072 446.1263733,367.5322266 447.2541504,368.0960999 448.0059814,368.4720154 448.5698853,368.2840576 
        449.1337585,368.0960999 450.2615356,366.9683228 450.6374817,365.8405457 450.8254089,364.1488953 450.6374817,362.8331604 
        449.8855896,360.765564 449.6976318,358.322052 450.2615356,356.8183594 451.5772705,356.4424438 452.7050476,357.0063477 
        453.2689209,357.9461365 454.020752,358.697998 454.772644,358.8859558 455.9003906,357.9461365 456.4642639,357.1943054 
        457.2161255,357.0063477 457.7800293,357.0063477 459.2836914,357.3822327 460.2235107,357.5702209 461.3512878,357.0063477 
        462.2910767,355.6905823 462.8549805,355.126709 463.4188538,354.5628357 464.7345886,353.9989624 466.426239,353.8109741 
        467.7420044,353.0591125 468.4938354,352.8711853 469.4336548,352.3072815 469.245697,350.4276428 468.4938354,348.7360229 
        467.5540161,347.2322998 466.9901428,346.2924805 466.8021851,344.6008606 466.6142273,342.3453064 466.2383118,340.6536255 
        465.8623657,339.7138062 465.2984924,338.5860596 464.7345886,338.0221558 464.1707153,337.4582825 463.9827576,336.8943787 
        463.606842,336.1425476 463.230896,334.8268127 462.8549805,334.0749817 462.8549805,333.3231201 462.6670227,332.9472046 
        462.4790649,332.195343 462.4790649,328.2481384 462.1031189,326.9324341 461.5392456,325.6166687 460.035553,323.5491028 
        459.8475952,323.3611145 459.2836914,322.4213257 458.7198181,321.4815063 457.592041,320.3537292 457.4040833,319.9778137 
        456.6522522,319.0379944 456.0883484,317.346344 455.7124329,315.2787781 456.0883484,313.5871277 456.8401794,311.8954468 
        457.2161255,311.1436157 457.592041,310.9556274 458.1559143,310.5797424 458.7198181,311.3315735 458.3439026,312.6473083 
        458.7198181,313.3991394 459.6596375,313.3991394 460.7874146,312.2713928 461.5392456,311.5195313 462.4790649,311.3315735 
        463.230896,311.707489 463.606842,313.5871277 463.7947998,314.5269165 463.9827576,315.2787781 465.2984619,314.7149048 
        465.4864502,314.151001 467.1781006,312.2713928 467.9299622,310.2037964 468.6817932,309.0760193 469.0577393,307.5723267 
        468.3058777,306.2565918 467.3660583,305.5047302 468.3058777,304.3769836 469.4336243,303.8130798 470.1854858,302.6853027 
        470.9373474,301.1816406 470.9373474,299.8658752 470.3734436,298.3621826 470.7493896,295.7307129 471.3132629,294.6029663 
        472.0651245,293.2872009 472.6289978,291.219635 471.6891785,288.0242615 470.1854858,286.1446533 469.0577393,285.2048645 
        461.3512878,280.3178406 460.2235107,278.6261902 460.2235107,274.8669434 460.787384,273.3632507 460.9753418,272.2354736 
        462.1031189,268.288269 463.4188538,265.6567993 464.9225464,263.4012451 466.6142273,262.0855408 468.869751,260.3938599 
        470.9373474,258.3262939 471.8771667,256.2586975 472.8169556,252.8753967 472.44104,249.8679962 471.5012207,247.6124725 
        470.3734436,246.2967224 469.2456665,245.5448914 468.4938354,244.9810028 469.6216125,244.0411835 470.5614014,243.2893372 
        471.3132629,241.9736023 472.44104,240.8458405 474.1326904,240.0939789 475.6363831,239.9060211 476.9521179,239.9060211 
        477.8919373,240.2819519 478.8317261,241.033783 479.5835876,241.5976715 480.5233765,242.1615601 482.5909729,242.1615601 
        484.0946655,241.5976715 485.2224426,241.4097137 485.9743042,241.5976715 486.7261353,242.1615601 486.5381775,243.2893372 
        485.9743042,244.4171143 484.8465271,246.2967224 484.0946655,247.2365265 483.1548767,248.1763458 482.4030151,248.9281921 
        482.0270996,250.243927 481.8391418,252.4994659 482.2150574,254.1911316 482.5909729,255.1309204 483.5307922,256.8225708 
        484.4706116,257.5744629 486.3502197,257.7623901 488.0418701,257.1985168 488.9816895,258.1383057 489.1696472,259.6420288 
        489.357605,260.7698059 489.733551,261.7096252 489.9214783,262.0855103 490.6733704,262.4614563 491.9890747,263.0253296 
        493.4927673,263.2132874 494.4325867,263.0253296 494.9964905,263.0253296 495.9363098,262.6494141 497.0640564,262.2734985 "
            filter="url(#dropshadow)" // Apply the drop shadow filter here
          />
        </a>
        <text x="505" y="350" fontSize={16} color="000000" className="centered-text">佛山</text>


        <a xlinkHref="../about/jiangmen.html" id="dmap_jiangmen" className="focus">
          <title>江門</title>
          <path id="Jiangmen_3_" className="gba2" d="M509.4696045,600.7929688v0.3759766l0.3759155,0.1879272l0.1879883-0.1879272v-0.1879883
        l-0.1879883-0.1879883H509.4696045z M508.5297852,600.229126l-0.1879578,0.1879272v0.1879883l0.1879578,0.3759155h0.1879578
        l0.1879883-0.1879272l0.1879578-0.3759766l-0.1879578-0.1879272H508.5297852z M508.7177429,596.6577759l-0.1879578-0.1879272
        h-0.3759155l-0.1879883,0.1879272v0.3759766h0.7518616V596.6577759z M509.6575623,589.3273315l0.1879578,0.1879272v0.1879883
        h0.1879883v-0.3759155l-0.375946-0.3759766V589.3273315z M506.0862732,588.3874512l-0.1879578,0.1879883l0.1879578,0.1879883
        l0.1879578,0.1879272l0.1880188-0.1879272l0.1879272-0.1879883l-0.375946-0.1879883H506.0862732z M510.9732971,579.1773682
        h-0.1879578v0.1879272h0.1879578V579.1773682z M512.8529053,581.2449341h-0.1879883l-0.1879272-0.1879272v-0.1879883
        l-0.1879883-0.1879883v-0.1879272v-0.1879883v-0.3758545l-0.1879272-0.1880493v-0.1879272h-0.1879578v-0.3759155h-0.1879578
        l-0.1880188,0.1879272h-0.1879578v0.1879883l-0.1879272,0.1879272l-0.1879578,0.1880493v-0.1880493h-0.1879578v-0.1879272
        v-0.1879883h-0.7518616v0.1879883v0.1879272h-1.1277771l-0.3759155,0.1880493h-0.3759155l-0.1879883-0.1880493h-0.1879578
        l0.1879578,0.1880493v0.3758545l-0.1879578,0.1879883h-0.1879578l-0.1879578,0.1879272h-0.5639038v0.3759766h-0.3759155v-0.1879883
        h-0.1879578l-0.1879578-0.1879883v0.1879883v0.1879883h-0.1879578l-0.1879883-0.1879883v0.1879883l0.1879883,0.1879272h-0.1879883
        v0.1879883l-0.1879578-0.1879883l-0.1879578,0.1879883v0.1879272h-0.3759155v0.1879883l-0.1879578,0.1879272v0.1879883h-0.1879272
        l0.1879272,0.1879883h0.1879578v-0.1879883h0.3759155v0.1879883h0.3759155v0.3759155l0.1879883,0.1879272l0.1879578,0.1879883
        l0.1879578,0.1879272v0.1879883h0.1879578v0.5639038l0.1879578,0.1879272v0.1879883l0.1879578,0.1879883l0.1879883,0.1879272
        l0.1879578,0.3759155v0.1879883l0.1879578,0.1879272v0.3759766l-0.1879578,0.1879272v0.7518311h0.1879578v-0.1879883h0.1879578
        v-0.3758545l0.1879578-0.1879883v-0.1879272h0.1879578v-0.5639038l0.1879883-0.1879883l0.1879578-0.1879272l0.1879578-0.1879883
        l0.1879578-0.1879272h0.3759155l0.375946-0.1879883h0.7518311v0.5639038l0.1879272,0.1879272l0.1880188-0.1879272
        l0.1879578-0.1879883h0.3759155l0.1879272-0.1879272h0.5639343l0.1879578-0.1879883h0.1879272v-0.1879883l0.1879883-0.1879272
        h0.1879272v-0.1879883l0.1879883-0.1879272v-0.9398193l0.1879883-0.1879883v-0.3759155v-0.3759155v-0.3759155h-0.1879883
        V581.2449341z M465.674469,639.8890991l-0.1879578,0.1879883l-0.3759766,0.1879272h-0.5638428v0.1879883l-0.3759766,0.1879272
        v0.1879883l-0.1879272,0.1879272l-0.3759155,0.1879272h-0.3759155v0.1880493l-0.1879883,0.1879272h-0.1879578l-0.1879578,0.3759155
        l-0.5638733,0.1879272l-0.375946,0.3759766h0.1879578l0.1879883,0.1879883l0.5638733,0.1879272h0.5639038l0.5638733-0.1879272
        l0.1879578-0.1879883h0.1879272l0.1880188-0.1879883v-0.1879883h0.1879578l0.1879578-0.1879272l0.1879578,0.1879272h0.1879272
        l0.1879578-0.1879272h0.5639343v-0.1879272l-0.1879578-0.1879883h0.1879578l0.375885-0.1879272v-0.3759766h-0.1879272v-0.1879272
        l0.1879272-0.1879883l0.1879578-0.1879272v-0.3759155h-0.5638428L465.674469,639.8890991z M451.3893127,635.565979h0.3759155
        v-0.1879883h-0.3759155V635.565979z M450.8254395,635.565979v0.1879272v0.1879883v0.1879272h0.1879578v-0.1879272h0.1879578
        v-0.1879883h0.1879578v-0.1879272h-0.1879578H450.8254395z M483.718811,633.8742676l-0.3759766-0.1879272h-0.3759155
        l-0.1879272,0.1879272h-0.1879578l-0.1879578-0.3759155h-0.7518616l-0.1879578-0.1879272l-0.5639038,0.1879272h-0.1879578
        l-0.1879578-0.1879272h-0.1879578v-0.1879272l-0.1879578,0.1879272l-0.1879883-0.1879272l-0.1879578-0.1879883h-0.1879578
        l-0.1879578-0.1879883l-0.1879578,0.1879883l-0.1879578,0.1879883h-0.375946v0.1879272l-0.1879578,0.1879272h-0.3759155
        l-0.3759155,0.1879883h-0.7518616l-0.1879578,0.1879272h-0.375946l-0.1879578,0.1880493l-0.1879578,0.1879272v0.1879272
        l-0.1879578,0.1879883h-0.1879578l-0.1879272,0.1879272l-0.1879578,0.3759766l0.375885-0.1879883l0.3759155-0.3759155
        l0.9398193-0.1879883l-0.1879578,0.3759155v0.5639038h0.1879578v0.5639038h-0.1879578l-0.1879578,0.1879272v0.3759766
        l0.1879578,0.1879272h0.5638733v-0.1879272h0.1879578l0.375946-0.1879883h0.1879578l0.3759155-0.1879883v-0.1879272
        l0.1879578-0.1879883l0.1879883,0.1879883l0.1879578,0.1879272h0.1879578v-0.1879272l0.1879578,0.1879272h0.1879578v0.1879883
        h0.3759155v0.1879883l-0.1879578,0.1879272h-0.1879578l-0.1879578,0.1879883h0.3759155l0.1879578-0.1879883h0.375946
        l0.5638733-0.1879272h0.375946l0.1879578,0.1879272l0.1879578-0.1879272h0.375885l0.1879578-0.1879883l-0.1879578-0.1879883
        l-0.1879272-0.1879272h0.1879272l0.1879578-0.1879883l0.1880188-0.1879272h0.3759155l0.1879272-0.1879883l0.3759155-0.1879272
        h0.1880188l0.1879578-0.1879883h0.1879578l0.1879272-0.1879883v-0.75177l-0.1879272-0.1880493H483.718811z
        M477.8919678,632.3706055H477.70401v0.3759155l0.3759155-0.1879883h0.1879578v-0.3758545h-0.3759155V632.3706055z
        M467.7420044,621.468811v0.1879883l0.1879578,0.1879272l0.1879883,0.1879883v-0.1879883v-0.1879272v-0.3759155h-0.1879883
        L467.7420044,621.468811z M467.7420654,622.9725342h0.1879578v-0.1879883h-0.1879578V622.9725342z M467.1781311,622.9725342
        l0.1879578,0.1879272v0.1879272v0.1879883h0.1879578v-0.1879883h0.1880188v-0.1879272v-0.1879272h-0.1880188H467.1781311z
        M467.5540466,623.7243652l-0.1879578,0.1879883l0.1879578,0.1879272h0.3759766v-0.1879272l-0.1879578-0.1879883H467.5540466z
        M395.1886597,619.9651489l-0.1879578-0.1879883v-0.1879272l0.1879578-0.1879272l0.1879883-0.1880493h0.1879578v-0.3759155
        h0.1879578v-0.1879272l0.1879578-0.1879272v-0.1879883h-0.1879578l-0.1879578,0.1879883h-0.5639038l-0.1879578-0.1879883
        l-0.1879578-0.1879272v-0.1880493l-0.1879578,0.1880493h-0.1879578v0.75177h0.1879578l0.1879578,0.1879883v0.1879272
        l-0.1879578,0.1880493v0.1879272h-0.3759155l-0.375946,0.5638428l-0.3759155,0.1879883l-0.3759155-0.1879883v0.1879883
        l0.1879578,0.3759155l-0.1879578,0.3759155h0.3759155l-0.1879578,0.1879883v0.1879272h-0.1879578l-0.1879883,0.1879883h-0.1878967
        l-0.1879578,0.1879883h-0.1880188l-0.1879578-0.1879883l-0.1879578,0.1879883l-0.375885,0.3759155l-0.1879578,0.7518311
        l-0.3759766-0.1879883h-0.375885l-0.1879578,0.1879883v0.5639038h0.3759155v0.1879272h-0.1879578l-0.1879578,0.1879883v0.1879883
        v0.1879272l0.1879578,0.1879883h0.1879578l0.1879272,0.1879272h0.1879578v-0.1879272h0.1880188l0.1879578,0.1879272
        l0.1879578,0.1879883v0.1879272l0.1879272,0.1879883v0.3759155h0.1879578l0.1879578,0.1879883l0.1880188-0.1879883
        l0.1879578,0.1879883l0.1879272-0.1879883h0.1879578l0.1879578-0.1879272v0.1879272h0.5639038l0.1879578,0.1879883h0.3759155
        v-0.3759155l0.1879578-0.1879883l0.1880188-0.1879883v-0.1879272h0.1879272l0.1879578-0.1879883l-0.1879578-0.1879272h0.1879578
        v-0.3759155h-0.375885v-0.9398193l0.1878967-0.1879883h0.1879578l0.1879578-0.1879883l0.1879883-0.1879272h0.1879578
        l0.1879578-0.1879883h0.1879578l0.1879578-0.1879272v-0.1879883v-0.1879272h-0.3759155v-0.1879883l-0.1879578-0.1879883v-0.1879272
        v-0.1879883l0.1879578-0.1879272h0.1879578v-0.3759155l0.1879578-0.1879883v-0.1879883l-0.1879578-0.1879272H395.1886597z
        M419.6237183,619.4013062v-0.1879883h-0.5638733v0.1879883v0.1879272h0.3759155L419.6237183,619.4013062z
        M423.1950073,636.1298828h-0.3759155l-0.1879578-0.1879883h-0.5639038v0.1879883h-0.3759155l-0.1879578,0.1879272v-0.1879272
        l-0.375946-0.1879883h-0.3759155v0.1879883h-0.3759155l-0.1879578,0.1879272l-0.1879578-0.1879272h-0.1879883l-0.1879578-0.1879883
        v-0.1879883h-0.1879578l-0.1879578,0.1879883l0.1879578,0.1879883h0.1879578v0.1879272v0.3759155l-0.1879578,0.1879883
        l-0.1879578-0.1879883v0.1879883l-0.1879578,0.1879272v0.5639038h-0.1879272l0.1879272,0.1879883l0.1879578-0.1879883h0.1879578
        l0.1879578-0.1879272h0.375946l0.1879578,0.1879272h0.1879578v0.1879883v0.5638428h0.1879578l0.1879578-0.1879272h0.9398193
        v-0.1879883l0.1879578-0.1879272l0.1879578-0.1879883v-0.1879272v-0.1879883h0.1879578l0.1879883,0.1879883h0.1879578
        l0.1879578-0.1879883h0.1879578l0.1879578-0.1879883v-0.3759155h0.375946v-0.3759155h-0.1879883L423.1950073,636.1298828z
        M418.1200256,638.0094604l0.1879578,0.1879883h0.1879578l0.1879578-0.1879883l0.1879578-0.1879272h-0.5638733
        L418.1200256,638.0094604z M431.6532898,636.6937256h-0.1879578l-0.1879578,0.1879883l-0.1879578,0.1879272v0.1880493h0.7518616
        v-0.1880493h0.1879578v-0.1879272h-0.1879578L431.6532898,636.6937256z M433.7208862,629.175293h0.1879578l0.1879272,0.1879272
        h0.3759766l0.1879578-0.1879272h0.5638428v-0.1879883l0.1880188-0.1879883v-0.1879272l0.1879578-0.1879883v-0.3759155
        l0.1879578-0.1879272l0.1879272-0.1879883v-0.1879883h-0.7518616v-0.1879272l-0.1879578,0.1879272l-0.5638428,0.1879883H434.28479
        l-0.375946,0.1879883l-0.1879578-0.1879883v-0.3759155h0.1879578v-0.1879883h0.1879272v-0.1879272h-0.1879272l-0.1879578,0.1879272
        h-0.5638733l-0.1879578,0.3759155l-0.375946-0.1879272l-0.1879578-0.1879883h-0.1879578l-0.1879578-0.1879272l-0.1879578-0.1879272
        l0.1879578-0.1880493v-0.5638428l-0.375946-0.3759155h-1.1277771l-0.1879578-0.1879883v-0.3759155l-0.1879578-0.3759155h0.1879578
        l0.1879578-0.1879272l0.1879883-0.1879883l-0.1879883-0.1879883l0.1879883-0.1879272v-0.1879883h-0.1879883v-0.5638428
        l0.1879883-0.3759766l-0.1879883-0.1879272v-0.7518921l0.1879883-0.5638428v-0.3759155l0.3759155-0.1879883l0.3759155-0.1879883
        h0.375946l0.3759155-0.1879272l0.3759155-0.1879883l0.375946-0.1879272h0.9397888v-0.1879883l0.1879272-0.1879272h0.1880188
        l0.1879578-0.3759766v-0.1879272h0.1879578l0.1879578-0.1879883h0.1879272l0.3759766,0.1879883h0.3759155v-0.1879883h0.5638428
        v-0.9398193l0.1880188-0.1879272v-0.3759155l0.1879578-0.1879883v-0.1879883h0.1879272v-0.5638428l-0.1879272-0.1879272v-0.1879883
        l-0.1879578-0.1879883v-0.1879272l-0.1880188-0.1879883v-0.3759155h-0.1879578l-0.1879578-0.1879272l0.1879578-0.1880493h0.1879578
        v-0.1879272h-0.1879578v-0.1879272l-0.1879578-0.1879883v-0.3759155h-0.1879272v-0.1879883l-0.1879578-0.1879272v-0.1879883
        l0.1879578-0.1879272h-0.3759155l-0.1880188,0.1879272l0.1880188,0.1879883v0.1879272h-0.1880188l-0.1879578,0.1879883v0.5639038
        h-0.375885v-0.1879883l-0.1879578,0.1879883H434.28479v-0.1879883l-0.375946-0.1879272l-0.1879578-0.1879883v-0.7518311
        l-0.1879578-0.1879883h-0.5638733l-0.375946,0.1879883h-0.1879578l-0.3759155,0.3759155h-0.1879578l-0.1879883,0.1879272
        h-0.1879578l-0.1879578,0.1879883h-0.3759155l-0.1879578,0.1879883h-0.5639038l-0.1879578,0.1879272l-0.3759155,0.1879883
        v0.3758545l-0.1879578,0.1880493v1.1277466l-0.1879883,0.1879883h-0.3759155l-0.1879578-0.1879883h-0.375885v-0.1879883h-0.1880188
        v-0.1879272h-0.1879578v-0.5639038l-0.9398193,0.000061l-0.3759155,0.1879272l-0.375885,0.1879883h-0.3759766l-0.1879578-0.1879883
        l-0.375885,0.1879883h-0.1879578l-0.3759155,0.1879272l-0.375946,0.1879883l-0.1879578,0.1879883l-0.1879578,0.1879272v0.1879883
        l-0.1879578-0.1879883l-0.1879578,0.1879883l-0.1879883,0.1879272h-0.5638733l-0.1879578,0.1879883l-0.1879578,0.1879272
        h-0.1879883v0.1879883l-0.3759155,0.1879883h-0.3759155l-0.1879578,0.1879272v0.1879883l-0.1879883,0.1879272l-0.1879578,0.1879883
        l0.1879578,0.1879272l0.375946,0.1879883l0.1879578,0.1879883v0.9398193l-0.7518616,1.5036621h-0.7518311l-0.1879578,0.1879883
        l-0.1879883-0.1879883l-0.3759155,0.1879883l0.1879578,0.1879272h-0.1879578v0.1879272h-0.1879578l-0.7518616,0.1879883h-0.1879578
        l-0.1879578-0.1879883l-0.375885-0.1879272h-0.3759766v0.1879272l-0.1879578,0.3759766h-0.1879272v0.3759155l0.1879272,0.1879272
        l-0.1879272,0.1879883l-0.1879578-0.1879883h-0.3759766l-0.1879578,0.1879883v-0.1879883l-0.1879272-0.1879272h-0.3759155
        l-0.1879578,0.1879272h-0.1879578v1.1278076l0.1879578,0.1879272l0.5638733,0.1879883l0.5639038,0.3759155l0.1879578,0.1879883
        l0.375885-0.000061l0.3759155,0.1879883l0.1880188,1.6915894v0.3759766h-0.3759766l-0.1879578,0.1879272h-0.1879272
        l-0.1879578,0.1879883h-0.1879578l-0.1880188,0.1879272h-0.1879578l-0.375885,0.3759766l-0.1879578,0.1879272v0.1879883h-0.3759155
        v0.1879272l-0.1879883,0.1879883l0.1879883,0.1879272h-0.1879883v0.1879883h-0.1879578l-0.1879578,0.1879883h-0.3759155
        l-0.1879578-0.1879883h-0.375946l-0.1879578,0.1879883l-0.1879578,0.1879272h-0.1879578v0.3759155h-0.7518616l-0.5638733-0.5637817
        l-0.1879883-0.1879883l0.1879883-0.1879883l0.1879578-0.1879272v-0.1879883h-0.1879578v-0.1879272h-0.1879883v0.1879272h-0.1879578
        v0.1879883h0.1879578v0.1879272h-0.1879578l-0.1879578,0.1879883h-0.1879578v0.1879883h-0.1879578v0.5638428h-0.1879578
        l-0.1879578,0.1879272l-0.1879883,0.1879883v0.1879883h0.1879883v0.1879272h0.1879578v0.1879272h-0.375946v0.3759155
        l-0.1879578,0.1879883h-0.1879578v0.7518311h-0.1879578l-0.1879578,0.1879883v0.3759155h-0.1879272l-0.1880188,0.1879883v0.1879272
        h0.5639038v0.1879883l0.1879578,0.1879272h0.3759155l0.1879883,0.1879272v0.5639648l-0.1879883,0.1879272h-0.3758545
        l-0.3759766,0.1879272h-0.375885v-0.3758545l-0.1879578,0.1879272h-0.3759766v0.3759155h0.3759766v0.1879272l-0.1879578,0.1880493
        v0.1879272h0.1879578v-0.1879272h0.3759155l0.1879272,0.1879272h0.1879578l0.1880188,0.1879272v0.5639038l-0.1880188,0.1879883
        v0.3759155l-0.1879578,0.1879272l-0.1879272,0.1879883h-1.3157349l-0.1879578,0.1879272v0.3759766l0.1879578,0.1879272v0.1879883
        h-0.1879578v0.1879272l0.1879578,0.1879883h0.3759155v0.1879883l0.1879272,0.1879272h0.1880188l0.1879578,0.1879883
        l0.1879578-0.1879883l0.375885-0.3759155v-0.1879883l0.3759766-0.3759155l0.1879578-0.3758545h0.375885l0.5638733-0.1880493
        h0.1880188l0.1879272-0.1879272h0.1879578l0.3759155,0.3759766v0.5638428h0.3759766v0.1879272l-0.1880188,0.1879883
        l0.375946,0.1879883l1.1277771-0.7518921h0.1879578l0.1879578,0.1879883h0.1879578v0.1879272h0.1879578l0.1879883-0.1879272
        l0.1879578-0.1879883h0.3759155v-0.3758545h-0.375946v-0.3759766h-0.3759155l-0.3759155-0.1879883l0.1879578,0.1879883v0.1879272
        h-0.7518311l-0.1879883-0.1879272l-0.7518311-0.5639038h-0.7518616l-0.1879578-0.1879272l-0.1879578-0.3759766
        l-0.0000305-1.5036621h0.375946v-0.1879272h0.1879578l0.1879578-0.1879883h0.9398193l0.1879578-0.1879272l0.5638733-0.1880493
        l0.1879883-0.1879272l0.1879578-0.1879272l0.5638733-0.1879883v-0.1879272h0.1879578l0.1879883-0.1879883h0.5638733v-0.1879883
        h-0.1879578v-0.1879272l0.1879578-0.1879883l0.1879578-0.1879272h0.375885l0.1880188-0.1879883l0.1879578-0.1879272h0.5638428
        l0.3759766,0.1879272h0.375885l0.3759155,0.1879883l0.3759766,0.1879272l0.375885,0.1879883l0.1879578,0.1879272v0.1879883
        h-0.1879578v0.1879883h0.1879578v0.1879272h0.9398193l0.1879578-0.1879272h0.3759155v-0.1879883h0.5639038l0.1879578,0.1879883
        h0.7518616l0.3759155,0.3759155v0.1879272h-0.1879578v0.7518921l0.1879578,0.1879272l0.1879578,0.1879272h1.1277771v-0.1879272
        l-0.1879578-0.1879272v-0.1879883h-0.1879578v-0.1879272h0.1879578l0.1879578-0.1880493v-0.1879272l0.1879578-0.1879272h0.5639038
        v0.1879272h0.1879578l0.1879578-0.1879272v-0.1879883h0.7518616v0.1879883h0.1879578v0.3758545h0.1879578v0.3759766
        l0.1879272,0.1879883l0.1879578,0.1879272h0.3759766v-0.1879272l-0.1880188-0.3759155v-0.5639038h0.5639038v-0.3759155
        l-0.1879272-0.1879883h-0.3759766v-0.1879883l0.1880188-0.1879272h0.1879578l0.1879272-0.1879883h0.7518921l0.1879272-0.1879272
        h0.1879578l-0.1879578-0.1879883h0.1879578v-0.3759155h0.1879578v0.1879883h0.3759155v-0.1879883h0.375946l0.1879578-0.1879883
        v-0.3759155l-0.3759155-0.1879272v0.1879272h-0.1879883v-0.7518311l0.1879883-0.3759155l0.1879578-0.1879883l0.1879578-0.1879272
        h0.1879578v-0.1879883h0.7518616l0.1879578-0.1879883h0.7518311l0.1879883,0.3759766h0.3759155l0.1879578-0.1879883h1.1277771
        L433.7208862,629.175293z M419.6237183,633.4983521v0.1879883v0.3759155h0.375946l0.1879578-0.1879883l-0.1879578-0.1879272
        h-0.1879272v-0.1879883H419.6237183z M436.5403442,634.6261597l-0.1880188-0.1879883h-1.1277771v0.3759155H434.28479
        l-0.1879578-0.1879272h-0.1879883v0.1879272v0.1879883l-0.1879578,0.1879272l0.1879578,0.1879883v0.1879272h-0.1879578v0.3759766
        h-0.1879578l0.1879578,0.1879272l0.1879578,0.1879883h0.375946l0.1879578,0.1879272l0.1879578,0.1879883h0.375885v-0.1879883
        h0.1879578v-0.3759155h-0.1879578v-0.3759155l-0.1879272-0.1879883h0.375885v-0.1879272h0.1880188v-0.3759155l0.1879578-0.1879883
        h0.1879578h0.1879272v-0.1879272l0.1879578,0.1879272h0.1879578l0.1880188,0.1879883h0.1879578l0.1879272,0.1879272h0.1879578
        v-0.1879272v-0.1879883h-0.5638428V634.6261597z M437.4801636,633.3103638l-0.1880188-0.1879272v-0.1879883h-0.1879578
        l0.1879578-0.1879272v-0.1879883h0.1880188v-0.1879272h-0.3759766l-0.375885-0.1879272l-0.3759155,0.3758545l-0.3759766,0.1879883
        v0.1879272l0.1880188,0.1879883v0.3759155h0.3759155v-0.1879883h0.7518005v0.1879883h0.3759766l0.1879272,0.1879883v-0.1879883
        l-0.1879272-0.1879883H437.4801636z M438.6078796,626.7317505v-0.3759155l-0.1879578-0.1879883h-0.1879578l-0.1879578,0.1879883
        h-0.375885v0.1879272h0.1879272v0.1879883H438.6078796z M439.547699,628.0474854l-0.1879578-0.1879883l-0.1879578-0.1879272
        h-0.1879578h-0.1879578v0.7518311v0.1879272h0.1879578v0.3759766v0.1879272h0.1879578v0.1879883h0.1879578v-0.3759155h0.1879578
        v-0.3759766l0.1880188-0.1879272l-0.1880188-0.1879883V628.0474854z M439.1717834,629.7391357h-0.1879578v0.1879883v0.1879272
        h0.1879578l0.1879578-0.1879272h-0.1879578V629.7391357z M438.2319641,629.927124h0.3759155l0.1879883-0.1879883h-0.5639038
        V629.927124z M436.5403442,612.6345825h-0.5639343l-0.1879272,0.1879883h0.7518616V612.6345825z M440.8634338,614.5142212
        h-0.1879578l-0.1879578-0.1879272l-0.1879578-0.1880493v-0.1879272l0.1879578-0.1879883v-0.1879272h0.1879578l-0.1879578-0.1879272
        v-0.1879883h0.1879578v-0.1879272h-0.1879578l-0.1879578,0.1879272v0.3759155l-0.3759155,0.1879272v0.1879883l-0.1879883-0.1879883
        l-0.1879578,0.1879883v0.1879272v0.1880493h-0.9398193v0.1879272l0.1879883,0.1879272v0.1879883h0.1879578v0.1879272
        l-0.1879578,0.1879883h-0.1879883v0.1879883v0.1879883l0.1879883,0.1879272l0.1879578,0.1879272h0.5638733v-0.1879272h0.1879578
        l0.1879883-0.1879272h0.5638733v0.1879272l0.1879578-0.1879272h0.1879578v0.1879272h0.1879578l0.1879883-0.1879272v-0.1879883
        h-0.1879883v-0.1879883v-0.1879883h-0.1879578v-0.1879272v-0.1879883l0.1879578-0.1879272h0.1879883v-0.1879272h-0.375946
        V614.5142212z M475.6364136,607.3716431h0.1879578l0.3759766-0.1879883l0.1879578-0.3759155l0.1879272-0.3759155
        l0.1879578-0.1879272l0.3759155-0.3759766v-0.3759155l0.375946-0.1879272h0.1879578v0.1879272l0.1879578,0.1879883
        l0.1879578-0.1879883l-0.1879578-0.3758545v-0.1879883l0.1879578-0.1879883l0.1879578-0.1879883l-0.1879578-0.1879272
        l0.1879578-0.1879272l0.1879883-0.1879883l-0.1879883-0.1879272l-0.1879578-0.1880493l-0.1879578-0.1879272l0.1879578-0.1879272
        l-0.1879578-0.3759155l-0.1879578-0.1879883h-0.1879578v-0.3759155l0.1879578-0.1879883h0.3759155v-0.1879272l0.1879578-0.1879883
        h0.1879883l-0.1879883-0.3759155h-0.1879578v0.1879883h-0.5638733l-0.5639038-0.1879883h-0.5638428v-0.1879883
        l-0.1879578,0.1879883h-0.1880188v0.1879883l-0.1879578,0.1879272l0.1879578,0.3759155l0.1880188,0.1879883l-0.1880188,0.1879272
        h-0.1879578l-0.5638428-0.5638428h-0.3759766l-0.1879578-0.1879883h-0.5638428l-0.3759155-0.1879272l-0.1880188,0.1879272
        h-0.5638428l-0.3759155,0.1879883h-0.375946v-0.3759155l0.1879578-0.1879883h0.1879883l0.1879578-0.1879883l-0.1879578-0.1879272
        l-0.375946-0.1879883l-0.3759155-0.1879272h-1.6916809l-0.3759155,0.1879272l-0.1879578,0.1879883v0.1879272l-0.1879578,0.1879883
        h-0.5639038l-0.1879578,0.3759155l-0.1879578,0.3759155v0.1879883h-0.3759155l-0.1879272-0.1879883h-0.3759766
        l-0.3759155,0.1879883l0.1879578,0.1879272l-0.1879578,0.1879883l-0.375885-0.1879883h-0.3759766v0.1879883l-0.375885-0.1879883
        h-0.1879578l-0.1879578,0.3759766h-0.5639343l-0.1879272-0.1879883h-0.1879578l0.1879578-0.1879883l-0.3759155-0.1879272
        l-0.1879578-0.1879883l-0.1880188,0.3759155l-0.1879272,0.1879883l-0.1879578,0.1879883l-0.3759155,0.1879272v0.3759155
        l0.1879578,0.5639038l0.1879578,0.1879883l-0.1879578,0.1879272h-0.3759155v0.3759155h-1.6916809l-0.1879578-0.3759155h-0.7518616
        l-0.1879578-0.1879272h-0.3759155l-0.1879578-0.1879883l-0.1879578-0.1879272v-0.9398193l0.1879578-0.1879883h0.3759155v-0.3759155
        l-0.1879578-0.1879883h-0.1879578l-0.1879578,0.1879883v0.3759155l-0.1879883,0.1879883l-0.1879578,0.1879272v0.5638428h-0.1879578
        l-0.1879578,0.1880493l0.1879578,0.3759155v0.5638428l0.1879578,0.1879883v0.3759155l-0.1879578,0.1879272h-0.3759155
        l-0.1879272,0.1879883v0.1879272l0.1879272,0.3759766v0.1879272l0.1879578,0.1879883l0.1879578,0.1879272h0.3759155
        l0.1879883,0.1879883l0.3759155,0.1879272l0.1879578,0.5639038l-0.1879578,0.3759155l-0.1879578,0.3759766l-0.5639038,0.3759155
        l-0.5638733,0.1879272h-0.1879272l-0.1880188,0.1879883h-0.1879578l-0.1879578,0.3759155v0.1879883h-0.9398193v0.1879272v0.1879883
        h0.5639343l0.1879272,0.1879272h0.1879578v0.1879883l-0.5638428,0.7518311l-0.3759766,0.1879883h-0.5638428v0.7518311
        l-0.1879578,0.3759155h-0.9398193l-0.1879578-0.1879272v-0.9398193l0.1879578-0.3759155l0.1879578-0.3759155v-0.1879883h-0.1879578
        l-0.1879578-0.1879883l-0.1879578-0.1879272v-0.1879883l-0.1880188-0.1879272h-0.1879272l-0.1879578,0.1879272h-0.1879578
        l-0.1879578,0.1879883h-0.1879578l-0.1879883-0.1879883h-0.3759155v0.3759155l0.1879578,0.5639038l-0.3759155,0.1879883
        l-0.1879578-0.1879883l-0.1879578-0.1879272h-0.1879883l-0.1879578-0.1879883v0.1879883h-0.5638733l-0.1879578-0.1879883
        h-0.1879883l-0.1879578,0.1879883h-0.7518311l-0.1879578,0.1879272l0.1879578,0.1879883l-0.375946,0.1879272h-0.1879578
        l-0.1879578,0.1879883h-0.5638428v0.7518311h-0.3759766l-0.1879578-0.1879272v-0.1880493l-0.1879578,0.1880493v0.1879272
        h-0.1879272l0.1879272,0.1879883v0.1879272h-0.1879272v0.3759155h0.5638428v0.5639038l-0.1879578,0.1879272l-0.1879578,0.3759155
        h-1.3157043l-0.1879578,0.1880493l-0.1880188,0.1879272l-0.1879578,0.1879272l-0.1879578,0.3759155h-0.1879272v-0.3759155
        h-0.1879578l-0.1879578,0.1879883v0.5639038h-0.1879578l-0.1880188,0.1879883v0.1879272h-0.1879272l-0.1879578-0.1879272v0.3758545
        l-0.1879578,0.1879883h0.3759155v0.1879272h0.375946v0.1879883l0.1879578-0.3759155h0.1879578l0.1879578,0.1879272h0.375885
        l0.1879578,0.1879883v0.1879883l0.1880188-0.1879883v-0.1879883h0.5638428v0.1879883h-0.1879578l-0.1879272,0.1879883h-0.3759766
        v0.1879272l-0.1879578,0.1879883l0.3759766,0.1879272h0.375885v0.3759155l-0.1879272,0.1879883h-0.7518921v0.1879883h-0.1879272
        v0.1879272h-0.1879578l-0.3759155,0.5639038l-0.1880188,0.1879883h-0.1879272l-0.1879578,0.1879272h-0.7518616v0.1879883h0.1879883
        v0.1879272h0.1879578l0.1879578-0.1879272v0.1879272l0.1879578-0.1879272h0.1879578l-0.1879578,0.1879272l0.1879578,0.1879883
        h-0.1879578v0.1879272l0.1879578,0.1879883h0.1879272l0.1880188-0.1879883h0.7518005v0.1879883h-0.1879272v0.1879883h0.1879272
        v0.3759155h-0.1879272l0.1879272,0.1879272h0.3759155l0.1880188-0.1879272h0.1879578l0.1879272-0.3759155h0.1879578v-0.3759766
        h0.1879578l0.7518616,0.1879883l0.3759155,0.1879883l0.1879578,0.1879272v0.1879883h0.1880188v0.3759155h0.1879272
        l0.1879578,0.1879272h0.1879578v0.3759766l0.1879578,0.3759155v0.1879272h0.1879578l0.1879883,0.1879272l0.1879578-0.1879272
        h0.1879578l0.3759155-0.1879272l0.5639038-0.1879883h0.1879578l0.1879578-0.1879272h0.3759155l0.1879578-0.1879883
        l0.1879883-0.1879883v-0.7518311h0.1879578v-0.1879272l0.1879578-0.3759766h0.1879578v-0.5638428l0.3759155-0.5639038
        l0.375946,0.1879883v0.1879272h0.7518005l0.1880188-0.1879272h0.3759155l0.375885-0.3759766l0.3759155,0.1879883
        l0.7518616,0.5639038l0.3759155-0.3759155h0.1880188v0.1879272h0.1879578v0.5639038h0.1879272l0.9398193,0.7518311
        l0.7518311-0.7518311h0.375946v0.1879272h-0.1879578v0.1879883h0.3759155v-0.1879883h0.1879578v0.1879883h0.1879578
        l-0.5638733,0.5639038l0.3759155,0.7518311h0.3759155v0.1879883h-0.3759155v0.1879272l0.1879578,0.1879883l0.1879578,0.1879272
        v0.3759766l-0.1879578,0.3759155l-0.1879578,0.1879272l-0.1879578,0.1879272h-0.9398193v0.7518921h0.3759155l0.1879883,0.1879272
        v0.5639038h0.1879578v0.5639038h-0.375946v0.3759155h0.1879883l-0.1879883,0.1879272v0.1879883l-0.1879578,0.1879883h-0.1879578
        v0.1879272h-0.3759155v0.1879883l-0.1879272-0.1879883h-0.1880188v-0.1879272l-0.1879578-0.1879883h-0.1879578
        l-0.1879578,0.1879883h-0.1879272v0.1879272l-0.1879578,0.1879883l-0.1880188,0.3759155h-0.7518005l-0.1879578-0.1879883
        v-0.1879272h0.1879578v-0.1879883h-0.1879578l0.1879578-0.1879272h-0.1879578v-0.5639038l-0.1880188,0.1879272v-0.1879272
        l-0.1879578,0.1879272h-0.375885l-0.1879578,0.1879883v0.1879883h-0.1879578l-0.1879578-0.1879883l-0.1880188-0.1879883h-0.375885
        l-0.1879578-0.1879272l-0.3759155,0.1879272l-0.375946,0.1879883v0.9398193h-0.1879578v0.1879883v0.5638428h-0.1879578v-0.1879272
        l-0.1879578,0.1879272v0.3759155h-0.1879578v0.3759766l-0.1879883,0.1879272h0.375946v0.5639038h-0.375946l-0.1879578,0.1879272
        h-0.5638733l-0.1879578,0.1879883h-0.375946v0.7518311h-0.1879578v0.1879272h0.3759155v0.1880493l0.1879883,0.1879272
        l-0.1879883,0.1879883v0.1879272h-0.1879578l-0.1879578,0.1879272h-0.1879578v0.3759766h-0.3759155l-0.1879578,0.1879883
        l-0.1879883,0.1879272v0.1879272l-0.1879578,0.1879883v0.1879272l-0.1879578,0.1880493v0.1879272l-0.1879578,0.1879272
        l0.1879578,0.1879883h0.3759155v0.3759155l-0.1879578,0.1879272v0.7518921h1.1277771l0.1879578-0.1879883v0.1879883
        l0.1879578-0.1879883h0.7518616l0.3759155-0.3759155l0.375946-0.1879883h0.1879578l0.1879578-0.1879272v-0.5639038
        l0.1879578-0.1879272v-0.1879272l0.375946-0.3759766l0.1879578-0.3759155h0.1879578v-0.1879272h0.1879578v-0.1879883
        l0.1879578,0.1879883h0.1879272v0.1879272l0.1880188,0.1879272h-0.1880188l-0.1879272,0.1879883l0.1879272,0.1879272v0.1880493
        h-0.1879272v0.1879272h0.375946l0.1879578,0.1879272h0.3759155l0.1879272-0.1879272h0.3759155l0.7518616-0.1879272
        l-0.5638428,0.1879272v0.7518311h-0.1880188l-0.7518005,0.1879272h-0.3759155l-0.1880188,0.1879883l-0.1879272,0.1879883v0.3759155
        h-0.1879578l0.1879578,0.1879272v0.1879883l-0.1879578,0.1879883l-0.3759155,0.1879272h-0.1879578v0.1879883h0.3759155
        l0.1879578,0.1879272l0.1879578,0.1879883v0.1879272l0.1879272,0.1879883v0.1879883h-0.1879272l-0.1879578,0.1879272h-0.1879578
        l-0.3759155,0.1879883v0.1879272l-0.1879883,0.1879883l0.1879883,0.1879883v0.3759155l-0.1879883,0.1879272v0.3759155
        l0.375946,0.1879883l0.1879578,0.1879883h0.1879578l0.1879578-0.1879883l0.1879272-0.1879883l0.1880188-0.1879272
        l0.1879578-0.1879883l0.3759155-0.1879272l0.1879272-0.1879883l0.1879578-0.1879272l0.1879578-0.1879883h0.1880188
        l0.1879578-0.1879883h0.1879578l0.375885-0.1879272h0.3759766l0.1879578,0.3759155l0.1879272,0.1879883l0.1879578,0.1879272
        l0.1879578,0.1879883v0.1879272h-0.1879578l-0.1879578,0.3759155v0.3759766l-0.1879272,0.1879272v0.1879883h-0.1879578
        l-0.1880188,0.3759155l-0.1879578,0.3759155H456.84021l-0.375885,0.1879883v0.1879272l-0.3759766,0.1879883l-0.1879578,0.1879272
        l-0.1879578,0.1879272l-0.1879272,0.3759766l0.1879272-0.1879272l0.3759155-0.1880493h0.3759766l0.1879578,0.1880493h0.1879272
        l0.1879578,0.3759155h0.5639343l0.1879272,0.1879272l-0.1879272,0.3759155l-0.1879578,0.1879883h0.1879578l0.375885-0.1879883
        h0.5639343l0.375885-0.1879883l0.1879578-0.1879272l0.3759155-0.1879272h0.375946l0.1879578-0.1879883l0.1879578-0.1879272
        l0.1879578-0.1880493l0.1879578-0.1879272v-0.1879272h1.1277771l0.375946-0.1879883v-0.1879272h0.7518311l0.1879578-0.1879883
        l0.375946-0.1879883v-0.1879272l0.3759155,0.1879272l0.1879578-0.1879272h0.375946v-0.3759155l0.1879578-0.3759155
        l0.1879578-0.1879883h0.1879578l0.1879272-0.1879883l0.3759155-0.1879272l0.1880188-0.1879883l0.1879578-0.1879272
        l0.1879578-0.1879883h-0.1879578l-0.5639343-0.1879272l-0.1879578-0.3759766h0.1879578l0.1879578-0.1879272l-0.3759155-0.3759155
        l0.1879578-0.1879883v-0.3759155h-0.5638428l-0.1879578,0.1879272l-0.3759766,0.1879883h-0.9397583v-0.9398193
        l-0.1879883-0.1879272l-0.1879578-0.1879883v-0.3759155h0.1879578l0.375946-0.1879883l0.1879578-0.1879272h-0.3759155
        l-0.5639038-0.3759766l-0.3759155-0.1879272v-0.1879883l-0.1879578-0.3759155l-0.1879883-0.1879272v-0.3759766l0.1879883-0.3759155
        l0.1879578-0.1879272l-0.375946-0.1879272v-0.7518921h0.375946l0.3759155-0.1879272l-0.3759155-0.1879883v-0.3759766
        l0.1879578-0.3758545l0.1879578-0.3759155l0.3759155-0.1879883l0.1879883-0.3759155v-0.3759155l0.1879578-0.1879883v-0.3759155
        l0.1879578-0.3759155l0.1879578-0.1879883l0.1879578-0.3759155v-0.1879272l0.375946-0.1879883h0.3759155v-0.3759155
        l0.1879578-0.1879883l0.375885-0.1879272l0.1879578-0.3759766l0.1880188-0.1879272h0.1879578l0.375885-0.1879883
        l0.3759155-0.1879272v-0.3759155l0.1880188-0.1879883l0.1879578-0.1879883v-0.3759155l-0.1879578-0.3758545l-0.1880188-0.3759766
        l-0.3759155-0.1879883l-0.375885-0.1879272l-0.1879578-0.3759155l-0.1880188-0.3759766v-0.75177l0.1880188-0.3759766
        l-0.1880188-0.3759155l-0.3759155,0.1879883l-0.1879272-0.3759155l-0.3759155-0.3759155h-0.3759766l-0.1879272-0.1879883
        l-0.1879578-0.5639038v-0.3759155l0.1879578-0.5639038v-1.1277466l0.1879272-0.5639038l0.1880188-0.5639038l0.3759155-0.75177
        l0.375885-0.7518921l0.1879578-0.5638428l0.1879578-0.1880493l0.3759766-0.1879272l0.1879578-0.1879883v-0.3758545
        l0.375885,0.1879272l0.1879578-0.3759155l-0.3759155-0.1879883l0.1879578-0.1879883l0.3759766-0.9398193l0.375885-0.75177
        l0.5638733-0.9398193l0.375946-0.5639038l0.5638733-0.7518311l0.375946-0.3759155l0.1879578-0.3759766l0.1879578-0.1879272
        l0.3759155-0.1879883l0.1879578,0.1879883h0.9398193l0.3759155,0.1879272l0.1879883-0.1879272l0.1879578,0.1879272h0.3759155
        l0.3759155-0.1879272l0.1879883-0.1879883h0.1879578l0.1879578,0.1879883h0.3759155l0.1879272-0.3759155v-0.5639038
        l0.1880188,0.1879272l0.1879578-0.1879272h0.3759155l0.1879578,0.1879272l0.1879272,0.1879883l0.1879578,0.1879883v-0.3759766
        h0.7518616V607.3716431z M455.9003906,609.2512817l-0.1879578,0.1879272l0.1879578,0.1879883l0.1879578,0.1879883v-0.1879883
        h0.1880188v-0.3759155H455.9003906z M456.6522827,609.6271973h-0.3759155l0.5638428,0.1879883L456.6522827,609.6271973z
        M454.5846863,606.8077393h0.1879272v-0.1879272v-0.1879883h-0.1879272h-0.1879578v0.1879883v0.1879272l0.1879578,0.1879883
        V606.8077393z M455.1485901,606.9957886v0.1879272h0.1879578l0.1879578,0.1879272v-0.1879272h0.1879272l-0.1879272-0.1879272
        H455.1485901z M479.7715759,579.3652954v0.5639038h0.375946l-0.1879578-0.1879883h0.1879578v-0.1879272h-0.1879578v-0.1879883
        H479.7715759z M378.084137,554.5543213v0.3759766l0.1879578-0.1879272L378.084137,554.5543213z M401.5793762,567.3357544
        l1.5036926,0.1879883h0.7518616l0.5638733-0.1879883l0.375946-0.5638428v-0.1879883l-0.1879578-0.1879272l-0.1879883-0.1879883
        v-0.1879883h-0.1879578v-0.1879272l-0.1879578-0.1879883l-0.1879578-0.3759155l-0.3759155-0.3759155l-0.1879578-0.1879883
        h-0.1879883v0.5639038l-0.1879578,0.5639038l0.375946,0.1879272l-0.5639038,0.7518921L401.5793762,567.3357544z
        M397.4441833,569.0274658l0.3759766-0.1879883l1.5036621-1.1277466l0.5638733-0.3759155h0.5639038l0.1879578-0.1879883h0.1879578
        v-0.1879272h-0.1879578l-0.5638428-0.1879883l-0.5639343-0.1879883l-0.375885-0.1879272l-0.5639343-0.1879883h-1.5036621
        l-0.5639343,0.1879883l-0.375885,0.1879272l-0.1879578,0.1879883v0.1879883l-0.1879578,0.1879272l-0.1879578,0.1879883
        l-0.1879578,0.1879272v0.5639038l-0.1879883,0.1879883l-0.5638733,0.5638428h0.9398193l0.3759155,0.1879883H397.4441833z
        M555.5202637,476.1742249h-2.4435425l-0.5638428-0.1879578h-0.7518921l-0.3758545,0.1879578h-0.7518921l-1.5036621-0.1879578
        l-0.5639038-0.1880188l-1.1278076-0.1879578h-0.1879272l-0.1879883-0.375885v-0.1879578l-0.1879272-0.1879578h-0.1879883
        l-0.7518311,0.1879578h-0.1879883l-0.1879272,0.1879578v0.1879272l0.1879272,0.9398193l-0.3759155,0.5638733l-0.9398193,1.1277771
        l-0.1879272,0.1879578h-0.1879883l-0.3759155,0.1879883h-0.3759155l-1.6917114,0.5638733l-0.1879272-0.1879578
        l-0.1879272-0.1879578l-0.1879883-0.5639038l-0.1879272-0.3759155l-0.1879883-0.5638428v-0.3759766h-0.1879883
        l-0.1879883-0.1879578v-0.1879578h-0.1879272l-0.7518921-0.7518616l-0.75177-0.7518005l-0.3759766-0.3759766l-1.1277466-0.7518005
        l-0.3759155-0.1880188l-0.3759155-0.1879272l-1.6917114-0.1879578v0.1879578l0.7518921,1.6916809l0.1879272,0.375885
        l0.1879883,0.7518921l0.1879883,0.375885v0.3759155l0.1879272,0.375946v0.3759155l0.1879883,0.3759155l0.1879272,0.1879578
        l0.3759155,0.375946l0.5639038,0.3759155l1.3157349,0.9398193l0.3759155,0.1879578l0.1879272,0.3759155l0.9398193,1.3157654
        l0.1879883,0.3759155l0.1879883,0.1879578l0.1879883,0.3759155l0.1879272,0.5639038v0.5638428l0.1879883,1.5037537
        l0.1879272,0.5638428v0.5639343l-0.1879272,0.375885l-0.1879883,1.5036926l-0.1879272,1.5036926l-0.1879883,1.3157654v1.5036926
        l-0.1879883,1.5036621l-0.1879883,1.3157349l-0.3758545,1.3157349l-0.3759155,0.5639038l-0.1880493,0.5638733l-0.1879272,0.375946
        l-0.5638428,0.5638733l-0.1879883,0.3759155l-0.1879883,0.375946l-0.1879272,0.7518311l-0.1879883,0.7518616v0.5638428
        l0.1879883,1.1277771v0.5639038l-0.5639038,2.2555542l-0.5639038,1.6916504l-0.1879272,0.375946l-0.1879883,0.1879578
        l-0.1879272,0.3759155l-0.3759155,0.3759155l-0.1879883,0.1879883l-0.5639038,0.3759155l-0.3759155,0.5638733l-0.3759155,0.1879883
        l-0.5639038,0.1879578l-1.3156738,0.5638733l-2.067627,0.5639038l-0.9397583,0.5638428h-0.3759766l-0.1879272-0.1879272
        l-1.1278076,0.5638428l-0.5638428,0.3759766l-0.1879272,0.3759155l-1.5037842,0.9398193v0.1879272l-1.3156738,0.9398193
        l-0.5639038-0.5639038l-0.1879883-0.3759155l-0.1879272-0.7518311l-1.1278076-1.5037231l-0.1879272-0.1879272l-0.1879883-0.1879578
        l-0.1879272-0.1879578v-0.3759766l-0.1879883-0.1879272l-1.3157349,0.375885l0.3759155,0.5639343l0.3759155,0.9398193v0.1879272
        l0.1879883,0.7518921l-0.1879883,0.3759155v0.3759155h-0.1879272v0.3759155l0.5639038,0.1879883v0.5638428l-0.3759766,0.3759155
        l0.3759766,0.3759766l-0.5639038,2.4434814l0.1879272,0.3759155l0.1879883,3.3833008l-0.5639038,0.5639038l0.1879883,0.1879883
        v3.7592163l-4.8870239,5.638855l-4.5111084-3.3833008l-0.375885-0.1879883l-0.3759766-0.1879272l-0.375885-0.1879883v-0.1879883
        l-3.0073853,4.1351929l0.9397888,0.9398193v0.3759155l1.1277466,1.1278076v0.1879272l-0.7518005,1.3157349h-0.1879578
        l-1.5037231-0.5638428v0.1879272l0.5639038,0.1879272l0.1879578,0.1879883l-0.3759155,0.9398193v0.1879272h-0.1879883
        l-0.3759155-0.1879272v0.1879272l0.3759155,0.1879883l-0.1879578,1.1277466l1.5037231,0.7518921l-0.1879883,0.3759155
        l-0.3759155,0.3759155l-1.5036926-0.3759155l-0.1879578,1.5036621h2.0675659l-0.1879578,0.7518921h-0.7518311v0.9398193
        l0.3759155-0.000061v1.1277466h-0.5639038l0.0000305,0.5639038l0.1879578,0.1879272v0.1879883l-0.1879578,0.5639038
        l0.1879578,3.0073853h0.1879578v0.3759155h0.1879578v0.3759155l0.1879883,0.1879272h0.3759155v0.9398193l-0.1879578,0.7518921
        l0.1879578,0.3759155v0.3759155l0.3759155,0.1879883v0.3759155h0.375946l-0.1879883,0.5638428v1.5037231l0.7518616-0.1879272
        h0.1879578v0.1879272l0.1879578,0.1879883v0.3759155l0.1879578,0.1879272v0.1879883l0.1879883,0.1879883l0.1879578,0.1879272
        l0.1879578,0.1879883l0.1879578,0.1879272h0.3759155v0.1879272l0.1879883,0.1879883v0.3759766h-0.1879883l-0.1879578,0.1879272
        l-0.1879578-0.1879272h-0.1879578l-0.1879578-0.1879883l-0.1879578-0.1879883h-0.1879883v-0.1879883h-1.3157349v1.8796387h0.375946
        l0.1879578,0.5639038v0.7518311h-0.9398193v1.1278076l0.1879578,1.1277466h0.375946l0.3759155,0.7518921h0.1879578
        l0.1879578,0.1879272l0.1879578,0.1879883v0.1879272l0.1879578,0.3759155l0.1879883,0.5639038v0.3759155l-0.1879883,0.3759766
        l-0.1879578,0.1879272l0.1879578,0.5638428l-0.1879578,0.1879883l-0.1879578,0.1879272l-0.7518311,0.3759766l-0.1879883,0.3759155
        l-0.9397888,0.7518311l-0.1879883,0.1879883v0.3759155l-0.9397888,0.1879272h-1.1277466l-0.1880188,0.1879883l-3.1953125,3.0073853
        l-0.1879578,0.3759155l-0.1879883,2.067627l-0.5638733,1.5036621l-0.3759155,0.5639038v1.6916504h-0.7518616l-0.5638733,0.1879883
        l-0.375946,0.1879272v0.3759155l-0.1879578-0.1879272h-0.1879578l-0.3759155-0.1879883l-0.5639038,0.3759155h-0.3759155
        l-0.1879578,0.3759155l-0.375946,0.1879883h-0.3759155l-0.375885,0.1879883v0.7518311l0.1879272,0.1879272h-0.375946v0.3759766
        h-0.1879578v0.1879272h-0.1879578v0.3759155h-0.9398193l-0.375885,0.1879883l-0.1879578,0.1879883h-0.1879578v0.1879272h-0.3759766
        l-0.1879272,0.1879883h-0.5638733l-0.5639038-0.1879883l-0.1879578,0.1879883l-1.1277771-0.1879883l-0.3759155,0.1879883
        l-0.1879578,0.3759155l-0.5639038,0.1879272l-0.1879578,2.2555542h-2.0675964l-0.9398193,0.7518311l-0.7518005-0.1879272
        h-0.1879578l-0.1879578,0.1879272l-0.5639038-0.3758545v-0.1880493l-0.5638733-0.1879272l-0.375946,0.1879272l-0.3759155-0.1879272
        v-0.3759155h-0.1879578v-0.1879272h-0.3759766v-0.1879883h-0.1879272v-0.1879272h-0.1879578v-0.3759766l-0.1879578-0.1879272
        v-0.7518921l-0.3759155-0.1879272l-0.1879883-0.1879883l-0.1879578-0.1879272v-0.1879883l-1.1277771-0.1879272v-0.3759766
        l1.6916809,0.1879883v-0.5639038h-0.1879578v-0.1879272h0.1879578v-0.7518921l-0.1879578-0.3759155v-0.3759155h-0.1879883
        v-0.1879883h-0.1879578l-0.3759155-0.1879272v-0.1879883l-0.1879578-0.1879272l-0.3759155-0.1879883h-0.5639038v0.1879883
        l-0.1879578-0.1879883h-1.3157349l-0.1879578-0.3759155h-0.1879883l-0.3759155-0.1879883v-0.3759155h-0.3759155
        l-0.1879272-0.1879272v-0.3759766l-0.1880188-0.5638428l-0.7518005-2.6314697l-3.0074158,0.000061v-0.1879272l1.5037231-0.000061
        l0.1879578-0.1879883h-0.1879578v-0.3759155h0.1879578v-0.1879883v-0.1879272h0.5638428l-0.1879272-0.1879883l0.1879272-0.1879883
        v0.1879883h0.1879578v-0.1879883h0.1880188l-0.1880188-0.75177h-1.3157043v-0.3759766h0.1879272l-0.1879272-0.3758545v-0.3759155
        l0.1879272-0.1879883l0.3759766-0.3759155h0.5638428v-0.1879883l0.1879578-0.1879272h0.1880188v-0.7518921h-0.3759766
        l-0.5638428-0.5638428l-0.3759766,0.3759155l-1.8795776-1.3157349v-0.1879883h-0.375946l-0.9397888-0.7518311h-0.1879578
        l-0.1879883-0.5639038v-0.1879272h-0.3759155v0.1879272l-0.3759155-0.3759155v-0.1879883h-0.1879578l-0.1879883,0.1879883
        l-0.1879578-0.3759155v-0.1879883l-0.1879578-0.1879272l-0.5638733-0.7518921l-0.5639038-0.3759155l-0.3759155,0.3759155
        l0.9398193,0.9398193l0.3759155,0.3759155v1.5037231l-0.3759155,0.1879883l-0.5639038-0.1879883l-2.4434814,0.1879883
        l-0.1879578,0.1879272h-1.5036926v0.3759155h-1.8796387v-0.7518311l-0.3759155-0.1879883l-0.1879578,0.7518921v0.1879272h-0.375946
        v-0.1879272l-0.3759155-0.1879883v0.1879883l-0.9398193,0.3759155h-0.3759155l-1.1277771,0.5639038l-0.5639038,0.3759155
        l-2.0675354,1.3157349l-1.8796387,1.5037231h-0.1879578l-0.1879578,0.3759155l0.1879578,0.1879272v0.1879272h0.1879578v0.1879883
        l-0.1879578,0.1879883l-0.375946,0.1879883l-0.7518311,0.75177v0.1880493h-0.1879578l0.1879578,0.1879272l0.1879578,0.1879272
        v0.7518921h0.1879578v0.5638428l-0.1879578,0.3759155l-0.1879578,0.1879883l0.1879578,0.1879883v0.1879272h0.3759155v0.1879883
        l0.1879578,0.3759155l-0.1879578,0.1879272l0.1879578,0.1879883h0.375946v0.1879883l-0.375946,0.1879272l-0.1879578-0.1879272
        h-0.1879578l-0.1879578,0.1879272l0.1879578,0.3759155h-0.5638733l-0.1879578,0.1879883v0.3759155l-0.5639038,0.1879883
        l-0.3759155-0.1879883h-0.1879578v-0.1879272h-0.1879883l-0.1879578-0.1879883h-0.3759155v-0.1879883l-0.1879578-0.5638428
        l-0.7518616,0.3759155l-2.0675964,1.1277466l-0.5638428,0.1879883l-0.7518921-1.1278076v0.1879883l-0.375885,0.1879883
        l-0.3759155,0.1879272l-0.1880188,0.1879883l-0.375885,0.5638428l-0.5638733,0.5639038l-0.375946,0.5638428l-0.1879578,0.1879883
        l-0.1879578,0.1879883l-0.1879578,0.3759155l-0.5639038,0.9398193l-0.3759155,0.5638428l-0.3759155,0.5639038l-0.375946,0.9398193
        l0.1879883,0.1879883l0.1879578,0.1879272v0.5639038l-0.1879578,0.1879272v0.1879883l-0.1879883,0.5639038v0.3759155h-0.1879578
        v-0.1879883h-1.3157349v-0.1879272h-0.1879578v-0.1879883h-0.1879578v0.1879883h-0.1879272l-0.1879578-0.1879883h-0.1880188
        l-0.1879578,0.1879883l-0.3759155,0.1879272l-0.375885,0.5639038l-1.8796387,1.6916504h-0.7518616l-0.7518311-0.1879272
        l-0.1879578,0.3758545l0.1879578,0.1880493l0.1879578,0.1879272v0.1879883h0.1879578v0.5638428l0.1879578,0.1879272h0.1879578
        v0.5639038h0.1879272v0.1879883l-0.375885,0.1879272h-0.5638733v0.1879883h-0.1879578l-0.375946,0.1879883H434.28479
        l-0.1879578,0.1879883l-0.1879578,0.5638428v0.1879272l-0.1879578,0.1880493v0.1879272l-0.1879883,0.7518311l-0.1879578,0.9398193
        v1.8796387h0.375946l-0.1879883,0.1879272h-0.1879578v0.1879883l-0.1879578,0.1879272v0.1879883l0.1879578,0.1879883v0.5638428
        l0.1879578,0.1879883h-0.1879578l-0.1879578,0.1879272l-0.1879578,0.1879883v0.3759155l0.1879578,0.1879883l-0.1879578,0.1879272
        v0.1879272h-0.3759155l-0.1879883-0.1879272h-0.1879578v-0.3759155h-1.3157349l-0.1879578,0.7518311l-0.9398193,0.1879883
        h-0.7518005l-0.7518616-0.9398193h-0.1879578l0.7518005,0.9398193v0.5638428l0.1880188,0.1879883l0.1879578,0.1879272h-0.1879578
        v0.7518921h0.1879578l-0.1879578,0.3759155v0.1879883h-0.5639343l-0.1879272-0.1879883h-0.1879578v0.5639038l-0.9398193,0.5638428
        l-0.3759155,0.3759766h-0.5639038l-0.1879578-0.1879883l-0.1879578-0.1879883h-1.1277771l-0.1879578,0.1879883h-0.1879578
        l-0.375946,0.1879883h-0.1879578l-0.1879578,0.1879272l-0.5639038,0.3759155l-0.3759155,0.3759155v0.1879883h-0.1879578
        l-0.1879578-0.1879883h-4.1351929l-0.375885,0.9398193l-0.7518616,0.3759766l-0.5638733-0.1879883l-0.1879578-0.3759155
        l-2.2555542-0.7518921l-1.1277771-0.7518311l-0.1879578-0.1879272l-0.1879883-0.1879883l0.1879883-0.1879883v-0.1879272h0.1879578
        l-0.7518616-0.3759155v-0.5639038l0.1879578-0.3759155h-0.7518311l-1.1277466-0.1879883l-0.1879578-0.1879272l-0.1880188,0.1879272
        l-0.1879578,0.1879883h-0.1879578v0.1879272h-0.5638428l-0.1880188-0.1879272l-0.5638428-0.1879883v-0.4385376l0.1879578-0.1253662
        h-0.1879578v0.1253662l-0.3759155,0.2506104l-0.375946-0.1879272l-0.5638733-0.3759766l-0.3759155-0.1879883v-0.1879272
        l0.1879578-0.1879272l-0.7518616-0.1879883l-0.1879578,0.5638428l-0.3759155-0.1879272l-0.7518616-0.3759155v-0.3759766
        l-0.1879578,0.1879883h-0.1879578v0.1879883l-0.1879883-0.1879883l-0.5638733-0.1879883l-0.5639038-0.1879272l-0.3759155-0.3759155
        v-0.1879272l0.1879578-0.1879883l0.1879578-0.1879883v-0.1879272h-0.1879578l-0.3759155,0.1879272h-0.1879578l-0.1879578-0.1879272
        l-0.375946-0.1879883l-0.5638733-0.3759155l-0.1879272-0.1879272v-0.3759766l-0.1879578,0.1879883h-0.1880188l-0.9397583-0.5639038
        v-0.1879272h-0.1880188l-0.1879578-0.1879883l-0.375885,0.1879883l-1.3157349-0.7518921l-0.7518616-0.5638428l-0.1879578-0.1879883
        h-0.1879578l-0.1879578,0.1879883h-0.375946v-0.1879883l-0.1879578-0.1879883v0.3759766l-0.1879578,0.1879272l-1.1277771-0.5639038
        v-0.1879272h-0.1879578l-0.1879578-0.1879883v0.3759155l-0.375946,0.1879883l-0.1879578-0.1879883h-0.3759155l-0.5638428-0.1879272
        v-0.5639038l-0.3759766,0.3759155l-0.7518005-0.3759155l-0.7518616-0.3759155h-0.3759155l-0.1880188-0.1879883
        l-0.1879578-0.1879272l-0.1879272-0.1879883v-0.1879272l-0.1879578-0.1879272l0.1879578-0.1880493l-0.1879578-0.1879272v-0.5638428
        h-0.1879578l-0.1879578-0.1879883v-0.1879883l-0.1880188-0.3759155l-0.1879272,0.1879272l-0.5638733-1.1277466
        l-0.3759155-1.1278076v-0.3758545l-0.375946-1.3157349l-0.1879578-0.9398193v-1.1278076l0.1879578-2.0675659v-0.1879883
        l0.1879578-0.1879272l0.7518616,0.3759155l-0.3759155-0.3759155l-0.1879578-0.1879883h-0.375946v-1.1277466h-0.3759155
        l0.1879578-0.1879272h0.1879578v-0.3759766h-0.1879578v-0.3759155h0.7518616v-0.3759155h-0.5639038l-0.1879578-1.1277466
        l-0.1879578-0.1879883l-0.1879578-0.9398193v-0.9398193l0.3759155-1.5036621v-0.7518921l0.3759155-0.5638428l0.375946-0.7518921
        l0.1879578-0.3758545l0.5638733-0.3759766l0.1879272-0.3759155l0.5639343-0.1879272l0.375885-0.1879883l0.7518921-0.3759766
        l1.1277466-0.5638428l0.1879578-0.1879272l1.8796387-0.9398193h0.1879578l0.1879578-0.1879883h1.5036926l0.5639038,0.1879883
        h0.7518311l0.1879883,0.1879272h0.5638733l0.3759155-0.1879272l0.375946-0.1879883l0.1879578,0.1879883h0.1879578v-0.1879883
        l0.375885-0.1879272l0.1879578-0.1879883v-0.1879883h0.1880188v-0.5638428l1.6916199-0.3759155l0.1879578,0.1879272h0.5639038
        l0.7518311,0.1879883h1.1277771l0.1879578-0.1879883v0.1879883l0.7518616-0.5639038l0.1879578-0.3759155v-0.1879883
        l-0.1879578-0.1879272h0.1879578v-0.1879883l0.1879578-0.1879883v-0.5638428l-0.1879578-0.3759155l-0.1879578-0.1879883
        l-0.1879578,0.1879883l-0.3759155-0.3759766l-0.375946-0.3759155l-0.9398193-0.3759155v0.1879883l-0.9397888-0.3759155
        l-0.375946-0.3759766l0.375946-2.819397l-0.375946,1.1278076l-0.1879578,0.1879272l-0.1879578,0.3759155l-0.1879578,0.1879272
        v0.3759766h-0.1879272l-0.1879578-0.1879272l-0.7518616-0.7518921l-0.9398193-0.9398193l0.375885,0.9398193l1.3157959,1.1277466
        l0.5638428,0.5639038h0.1879578l0.5639038,0.3759155l0.1879578,0.5639038l0.5638733,0.1879272l-0.1879578,1.1278076v0.3759155
        l-0.3759155,0.1879883l-0.375885,0.1879272h-0.3759766l-0.3759155-0.1879272l-1.6916809-0.7518921l-1.6916199-0.5638428h-1.5036926
        l-0.5639038,0.1879272l-0.5638733,0.5639038l-0.1879578,1.5037231l-0.375946,0.5638428l-0.9398193,0.5639038l-0.1879578,0.1879883
        l-0.7518311,0.3759155l-1.3157043,0.5639038l-1.3157959,0.3759155l-1.8795776,1.1277466l-0.5639038,0.7518311l-0.3759155,0.3759766
        l-0.5638733,1.3156738v0.7518921l-0.1879883,0.1879272l-0.7518311,0.7518311h-0.1879578l-0.1879883-0.7518311l-0.3759155-1.3156738
        l-0.1879578-0.7518921v-0.5639038l-0.7518616-1.5036621l-0.1879578-0.1879883v-0.7518311l-0.1879578,0.1879883
        l-0.1879578-0.5639038l-0.1879578-0.7518921l-0.1879272-0.3759155l-0.3759766-0.7518311l-0.3759155-0.5639038l-0.375885-0.9398193
        l-0.1880188-0.3759155l-0.1879578-0.3759155l-0.1879578-0.1879883l-0.1879272-0.7518311l-0.5639343-0.7518311l-0.375885-0.3759155
        v-1.6917114l0.1879272-0.5638428l-0.1879272-0.5639038l-0.1879578-0.3759155l-0.1879578-0.5638428l-0.3759766-0.3759766
        l-0.1879272-0.1879272l-0.5638733-0.3759155l-0.1879578-0.1879883l-0.1879578-0.3759155l-0.375946-0.1879883v-0.9398193
        l0.1879578-0.7518311l0.5639038-0.3759155l0.1252747-0.2506104l-0.1252747,0.0626221l-0.375946,0.1879883l-0.1879578,0.1879272
        l-0.1879578,0.1879883v-0.7518311l0.1879578-0.3759155h0.375946v-0.1879272l0.3759155-0.1880493h0.3759155l0.375885-0.1879272
        v0.3759766l-0.1879578,0.1879272l-0.1879272,0.1879272l-0.1879578,0.1879883h0.1879578l0.1879272-0.1879883h0.1880188v-0.1879272
        l0.5638733-0.5639038h0.1879272v-0.1879883l-0.1879272-0.1879272v-0.1879272l0.1879272-0.1879883l0.3759766-0.1879883h0.1879578
        l0.1879578-0.3759155l-0.3759155-0.3759155l-0.3759766,0.3759155h-0.1879272l-0.1879578-0.1879272l0.1879578-0.7518921
        l0.5639038-0.5638428l-0.1880188-0.1879883h0.1880188l0.1879578-0.1879272h0.1879578l1.3157043-0.1879883l0.5639343-0.3759155
        l0.1879272-1.1278076l0.5638733-2.0675659v-1.1278076l0.1879578-0.1879272l-0.1879578-0.3759155V545.15625l-0.5638733-0.3759155
        l-0.1879272-0.3759155v-0.3759766l0.5638428-0.75177v-0.5639648l-0.7518616,1.3157349v0.5639038l0.375946,1.1278076
        l-0.375946,0.3759155l0.1880188,0.1879272l-0.1880188,0.1880493h-0.1879578l0.3759766,0.9397583l-0.3759766,0.7518311
        l-0.1879578-0.1879272l-0.1879578-0.5639038l-0.1879272,0.5639038l0.1879272,0.1879272l-0.1879272,0.1879883l-0.1879578,0.1879272
        l0.1879578,0.3759766l-0.7518921,0.5638428l-0.5638428-0.3759155l-0.5639038,0.1879883l-0.3759155,0.5638428h-0.1879578v-0.5638428
        l-0.375946,0.1879272v0.7518921l-0.3759155,0.3759155h-0.3759155l-0.1879578,0.3759155l0.1879578,0.1879883v1.5036621
        l0.1879578,0.3759155l-0.1879578,0.5639038l-0.375946,0.1879883l-0.3759155,0.1879272l-0.1879578,0.3759155l-0.1879578,0.5639038
        l-0.1879578,0.3759155v1.6916504l0.1879578,0.1879883v0.3759155l0.1879578,0.5638428v0.1879883l0.3759155,0.5639038v0.1879272
        h0.1879578l0.375946,0.3759766l0.3759155,0.3759155l0.3759155,0.3759155l0.1879272,0.3759155v0.3759155l0.1880188,0.9398193
        v1.3157349l0.3759155,0.7518311l0.375885,0.9398193l0.1879578,1.3157349l0.1880188,0.5639038l0.1879578,0.7518311
        l0.9398193,1.6916504v0.5639038l-0.3759766,0.3759155l0.3759766,0.1879883l0.1879578,0.5638428l0.1879272,0.7518921
        l0.5638733,1.3156738l0.1880188,0.9398193l0.1879272,0.3759155l-0.1879272,0.3759766h0.1879272l0.1879578,0.1879272
        l0.1879578,1.3157349v0.7518311l0.1879578,0.1879883l0.1879578,0.9398193v0.5639038l0.1879883-0.1879272h0.1879578
        l0.1879578,0.1879272l0.1879578,0.1879883v0.1879272v0.5639038v0.1879883l-0.1879578,0.1879272v-0.1879272h-0.1878967
        l0.1878967,0.3758545l0.1879578,0.1879272l-0.1879578,0.5639648v1.6915894l-0.1879578,1.5037231l0.3759155,0.9398193v0.9398193
        l0.5638733,1.8796387l0.375946,1.5036621l0.1879578,0.5638428l0.1879578,0.3759766l0.1879578,0.3759155l0.375946,0.9398193
        l-0.1879883,0.1879272l0.1879883,0.7518921h-0.1879883l0.1879883,0.1879272l-0.1879883,1.3157349h-0.1879578v0.1879883h0.1879578
        l0.1879883,0.1879272v1.1278076l-0.1879883,0.3759155h-0.5638733l-0.1879578-0.1879883v0.1879883l0.1879578,0.1879883v0.1879272
        l0.1879578,0.1879883l0.1879578,0.1879272l-0.1879578,0.1879883v0.1879883h0.1879578l0.1879578,0.1879272v0.5639038h-0.3759155
        l-0.1879578,0.1879272l0.1879578,0.5639038v1.1278076h0.3759155v0.3758545h0.1879883l0.3759155,0.7518921v0.5638428h-0.1879578
        l-0.1879578,0.3759766l-0.7518616,0.5638428l-0.1879578,0.3759155l-0.1879578,0.3759766v0.3759155l0.3759155,0.3759155
        l0.1879578,0.3759155h-0.1879578l-0.3759155,0.3759155l-0.375946,0.3759155v0.3759766l-0.3759155,0.1879272l0.3759155,0.1879883
        h0.7518616l0.1879578,0.1879272v0.1879883l-0.1879578,0.5639038v0.5638428l-0.1879578,0.1879272l-0.1879578,0.1880493
        l-0.1879883,0.1879272l-0.3759155,0.5638428l-0.3759155,0.3759155v0.1880493l-0.5639038,0.1879272l-0.9397583,0.3759155
        l-1.1277771,0.5639038v0.1879272h-1.5037537l-0.5638428,0.1879883v0.1879272l0.1879578,0.1879883l-0.5639343,0.1879272
        l-0.375885-0.3759155l0.1879578-0.1879272h-0.9397888l-2.6314697,0.3759155l-0.7518616,0.5639038l-1.1277771,0.3759155
        l-0.1879272,0.1879272l-0.1879578,0.1879883l0.1879578,0.1879883v0.3759155h-0.3759155v0.3759155h0.1879578l0.1879578,0.1879272
        v0.1879883h-0.5638733v0.7518311l-0.1880188,0.1879883l-0.375885-0.1879883l-0.1879578-0.3759155h0.1879578v-0.3759155h-0.3759155
        l0.1879578-0.7518311l-0.1879578-0.1879883h-1.1277771v0.3759155h-0.1879578l-0.5639038-0.1879272l-0.1879578-0.3759155h-0.5638733
        l-0.5639038,0.3759155l-0.3759155,0.3759155l0.3759155,0.3759155v0.5639038l-0.1879578,0.1879272h-0.1879578l-0.375946-0.1879272
        h-0.1879578l-0.1879578-0.1879883l-0.5638733,0.1879883h-0.1879883l-0.1879578,0.1879272l-0.1879578,0.1879883
        l-0.1879578,0.5639038h-0.375885v0.1879272h-0.3759766v0.1879883l-0.1879578,0.1879272h-0.1879578l-0.375885-0.7518311
        l-0.7518616,0.1879883h-0.1879578l-0.1879578,0.1879272l-0.1879578,0.1879883l-0.7518616,0.1879272h-0.1879578v-0.5638428
        l0.1879578-1.8796387v-0.9398193l0.3759155-1.6916504l0.5639038-1.1278076l1.8795776-2.2554932l0.5639038-0.7518921
        l0.9398193-1.1277466l1.5036621-1.3157349l1.5037231-1.5037231l0.9397583-1.6915894l0.5639343-1.6917114l-0.1879578-1.5036621
        l-0.3759766-1.5037231l-0.375885-1.5037231l-0.3759155-1.3157349l0.1879578-1.1277466l0.7518616-1.3157349l0.7518005-0.7518921
        l0.9398193-0.9397583l0.3759155-0.5639038l1.3157349-0.9398193l0.7518616-0.9397583l0.9398193-1.3157959l0.3759155-1.1277466
        l-0.3759155-1.5037231l-0.7518616-0.3759155l-1.3157349-0.3758545l-0.7518005-0.5639648l-0.9398193-0.9398193l-0.3759766-0.5638428
        l-0.3759155-2.0675659v-1.3157349l0.1879578-0.3759155l0.3759766-1.6917114v-2.819397l-0.1880188-1.8796387l-0.5638428-2.0675659
        l-0.7518921-1.8796387l-1.3157043-2.0675659l-1.5036926-1.6916504l-1.5036926-1.3157349l-1.6916809-0.9398193l-1.8795776-0.7518311
        l-1.8796387-0.7518921l-1.5036926-0.1879272l-2.443512-0.1879883l-2.4434814-0.9398193l-1.3157959-0.7518311l-1.3157349-0.9398193
        l-0.5638428-0.9398193l-0.7518616-1.6915894l-1.3157349-0.9398193l-1.3157349-0.5639038l-0.7518616-1.3157349l-0.1879578-1.8796387
        l0.5639038-1.5037231l0.5638733-1.3157349l0.7518616-2.0675049l-0.9398193-1.1278076l-1.6916504-0.1879883l-2.0675659,0.5639038
        l-2.0675964,0.3759155h-1.3157349l-1.5036926-0.9398193l-1.5037231-1.1277466l-1.3157349-1.1278076l-1.8795776-1.3157349
        l-1.8796387-0.1879272l-1.6916504-0.3759155l-1.3157654-0.1879883l-1.3157349-1.5037231l-1.1277771-1.1277466l-1.3157349-1.5036621
        l-0.9397583-1.5037231l-1.8796387-2.0675659l-1.1277771-0.9398193l-0.7518311-0.1879883l-1.8796387,0.7518921l-1.6916199,0.1879272
        l-0.9398193,0.7518921l-2.2555542,0.9397583l-3.1953735,1.5037231l-1.6916504,0.5639038h-0.5639038v-1.1278076
        l-0.3759155-0.5638428l-0.7518616-0.7518921l-0.375885-0.3759155l1.5036621-0.9398193l1.1277771-0.7518311l1.3157349-1.1278076
        l0.5639038-1.6915894l-0.3759155-2.6314697l-0.5639038-1.5037231l0.1879578-1.5037231l0.7518616-1.6916504l0.5638733-1.8796387
        l-0.5638733-2.0675659l-1.3157349-1.3157349l-0.7518616-1.6916199l-0.9398193-1.5036926l-1.3157349-1.6916504l-1.6916199-1.5036621
        l-2.0675964-1.3157349l-0.5638733-0.375946l0.1879578-0.7518311v-0.5639038l0.5638733-0.3759155l1.6916809-0.7518616
        l1.5036926-0.1879578l1.1277771-0.1879578h1.6916199l1.3157349-0.5639038l1.5037231-0.9397888l0.9398193-1.3157043
        l0.5638733-0.7518616l1.1277771-1.1277771l0.5638428-1.3157349v-1.3157349l-0.375885-0.9398193l-0.3759155-0.7518616v-3.7592468
        l0.7518005-2.0675659l0.3759155-1.1277771l1.3157349-0.5638733h2.443512l2.4435425-0.1879578l0.7518005-0.375946
        l1.3157349-0.3759155l1.3157349,0.1879578l0.5639038,1.1277771l0.1879578,0.1879578l0.1879578,0.7518616l0.5639038,0.1879578
        l0.5638733-0.7518311l0.9398193,0.1879578l0.9398193,0.9397583h1.6916504l0.9398193-1.1277161l0.375885-1.5037231v-1.5036926
        l-0.1879578-1.1277771l0.3759155-1.8796387l0.3759766-1.1277771l0.7518005-1.5036621l0.5639038-0.9398193v-0.3759155
        l0.5638733,1.3157349l0.9398193,0.7518616h1.6916809l0.7518311-0.5639038l0.9398193-1.3157349l0.9398193-1.5036926
        l1.5036926-1.3157349l2.819458-0.5639038h3.9471741l1.3157349-0.375946l0.5639038-0.5638733l-0.1880188-0.9397583
        l-0.1879272-0.3759766l-0.3759155-0.9397583v-1.5037231l0.3759155-1.6916504l0.1879272-1.1277771l0.5639343-0.9398193
        l0.9398193-0.9398193l0.7518005-0.5638733l1.5037537-1.5036621l0.1879272-1.6916809l-0.375946-1.6916504l-0.7518005-1.3157349
        l-0.1879578-1.6916809l1.3157043-1.3157349l1.3157349-0.5638428h3.3833313l1.8795776-0.1880188l1.5037537-0.7518005
        l1.1277771-2.0675964v-1.5036926l-0.3759766-1.3157349l-0.7518005-1.5037231l0.5638428-1.6916504l2.0675964-0.375885
        l2.2554932,0.5638428l1.6917114,0.9398193h1.5036621l1.8796387-0.375885l1.5036926-1.1277771l1.1277771-1.1277771
        l1.3157349-1.5037231l0.9398193-1.5036926l0.5638428-1.8796387l0.5639343-1.3157349l1.5036621-1.3157349l1.8796387-1.1277771
        l0.7518311-2.4434814l0.375946-1.3157349l1.1277466-1.8796387l1.5037231-2.6314697l1.5036926-1.3157043l1.1277466,0.375885
        l0.7518921,1.3157959l0.5638428,2.4434814l-0.1879578,1.3157349l0.1879578,2.2555542l0.7518616,1.5036926l2.6314697,0.375885
        l1.5036926,0.5638733l1.5037231,1.6916809l1.3157349,0.5638733l2.0675354-0.1879578l1.8796387-0.3759155l1.3157349-0.375946
        l1.6916809-0.5638733l0.7518311-0.5639038l1.5037231-1.1277771l0.1879578-0.5638733l0.1879578-1.6916809l-0.9398193-1.6916199
        l-1.3157349-1.5036926l-1.3157349-1.3157349l-0.375946-1.8796387l0.375946-0.9397583l-1.5036926-1.5037537l-0.5639038-1.1277161
        l-0.3759155-1.6916809v-1.3157349l-0.9398193-2.6314392l-0.9398193-1.1277771l-1.3157349-0.9398193l-0.5638428-0.7518616
        l0.9397583-0.7518311l1.1277771-0.9398193l1.1277771-1.1277771l1.8796387-2.0675964l1.5036926-0.7518005l1.6916809,0.1879578
        l1.6916199,0.7518005l0.7518921,1.6916809l0.5638428,1.3157349l0.9398193,1.8796082l0.1879578,1.6916809l0.1879578,1.8796387
        v2.4434509l0.5639038,1.3157349l0.5638733,2.0675659l0.9398193,1.6916504l2.0675964,1.3157654l1.8795776,0.3759155
        l2.0675964-0.1879578l1.6916809-1.1277771l0.5638733-1.3157043l-0.3759155-1.6917114l-0.375946-1.6916199l0.9398193-1.5037231
        l0.3759155-1.5036926l-0.5638733-1.8795776l0.5638733-1.3157959h1.5037231l2.0675964,0.9398193l1.1277161,0.9398193h1.1277771
        l1.6916809-1.5036621l0.3759155-1.5036926l0.375946-1.6916809l1.1277466-1.5036926l1.6916809-0.9398193h1.5036926
        l0.9398193,0.7518616l0.7518616,1.3157349l0.7518005,0.9398193l1.1277771,0.1879578l1.1277771-1.1277771l0.375946-2.443512
        l-0.375946-2.2555542l0.375946-2.0675659l0.7518311-0.9398193h1.3157349l1.3157349,1.1277466l0.5639038,0.5639343
        l1.5036621,2.0675354l0.7518921,2.0675964l0.1879272,1.6916809l0.1879578,1.8795776l-0.1879578,2.2555542l0.9398193,1.3157349
        l1.1277771,0.5639038l0.7518311,0.9398193l1.5037231,0.3759155l1.8796082-0.5639038l1.1277771-0.5638733l0.7518616-0.5639038
        l0.9398193-0.5638733l0.9397583-0.5639038l1.1277771-0.7518005l1.6916809-1.8796692l1.6916504-1.1277466l1.6916809-0.9398193
        l1.8795776-0.9397888l1.5037537-1.5037231l0.375885-2.0675354l0.1879578-0.9398193v-1.6917114l0.7518616-2.0675354
        l0.1879578-1.8796387l-0.1879578-2.2555542l0.3759155-2.0675964l1.8796387-1.5036621l2.2555542-0.7518616l0.9397583-0.5638733
        l1.3157349-1.3157349l1.1277771-1.8796387l0.3759766-0.3759155l0.5638428-0.7518616l0.9398193-0.7518005l2.0675964-0.5639343
        l2.2555542-0.5638428l1.8795776-0.3759766l3.1953735-0.5638428l1.6916504-0.3759155h1.5037231l1.3157349,0.7518005
        l1.5036621,1.1277771l1.3157349,1.1277771l1.6916809,1.3157349l1.3157349,1.3157349l0.5638733,0.7518616l0.375946,0.7518616
        l0.5638733,0.9397888l0.5639038,0.5639038l0.7518311,0.9398193l0.375946,0.3759155l0.3759155,0.1879272l0.3759155,0.1879578
        h4.3231201l1.1277771-0.1879578h0.3759155l0.3759155-0.1879272l1.1277771-0.1879578l0.375946-0.1879578l1.1277771-0.1879578
        l1.1277771-0.1880188l0.3759155-0.1879272h3.3833313l0.1879272,0.1879272h0.1879883l0.3758545,0.1880188l0.7518921,0.5638733
        l1.1277466,1.3157043l0.5639648,1.3157349l0.5638428,1.3157349l0.3759155,0.9398193l0.1879883,0.7518616v1.1277771
        l0.9397583,0.9398193v1.1277771l0.1879883,0.9397583l0.3759155,1.5037537l0.1879883,1.1277466l0.1879272,0.7518311
        l0.3759155,0.9398193l0.1879883,0.5638733l0.1879883,0.7518616v0.5639038l0.3759155,0.7518311l0.3759155,0.5639038
        l0.3759155,0.5638428l0.1879883,0.5639343l0.3759155,0.3759155l0.1879272,0.5638428l0.1879883,0.3759766l0.1879883,0.375885
        l0.3759155,0.1879578l0.3759155,0.3759766h0.1879272l0.3759766,0.1879272h0.1879272l0.3759155-0.1879272h0.1879272
        l0.1880493-0.1880188l0.1879272-0.1879578h0.5638428l0.5639648-0.1879578h0.5638428l0.3759766-0.1879578l0.3758545,0.1879578
        l0.9398193,0.1879578l0.5639038,0.1879578l0.3759155,0.1880188l0.5639038,0.375885l0.9398193,0.5638733l0.5638428,0.3759155
        l1.3157349,1.3157349l0.5639038,0.5639038l0.5638428,0.5638428l0.3759155,0.3759766l0.5639038,0.3759155l0.3759155,0.375885
        l0.5639038,0.7518616l1.3157349,1.3157349l0.1879883,2.2555542l0.1879272,0.3759155v0.5639038l0.3759155,0.7518311v1.3157043
        l-0.1879272,0.7518921l-0.1879883,0.5639038l-0.1879272,0.3759155v0.5638428l-0.1879883,0.5639038v1.5036926l-0.1879883,1.3157349
        v0.9398193l0.1879883,0.5639038l0.1879883,0.3759155l0.5638428,0.5638733l0.3759155,0.5639038l0.5639038,0.7518005
        l0.1879883,0.7518921l0.3758545,1.1277466l0.3759766,1.3157349l0.3759155,1.1277771l0.1879272,1.3157349l0.1879883,1.5037231
        v1.5036926l0.1879883,0.5638428l0.1879883,0.7518921l0.3759155,0.5638428l0.5638428,0.7518616l0.3759766,0.5639343
        l0.5638428,0.5638428l0.5639038,0.5639343l0.5638428,0.5638428l1.8796387,1.6916504l0.7518311,0.5639038l0.3759766,1.1277771
        l0.5638428,0.9398193l1.1278076,1.1277771l0.5639038,0.3759155l0.3759155,0.1879272l0.3758545,0.1879578l0.5639648,0.1879578
        l1.1277466,1.5037231l0.7518311,1.8796082l0.3759155,0.7518616l1.1278076,2.6314697l0.3759155,0.9398193l0.3759155,0.9398193
        l0.1879883,1.3157043l0.1879272,0.9398193l-0.3759155,1.1277771v0.7518311l-0.1879883,1.3157349l0.1879883,1.8796387
        l0.1879883,0.7518616l0.1879272,1.3157349L555.5202637,476.1742249z M384.0989075,579.1773682l0.1879578,0.3759766
        l0.1879883,0.1879272v-0.3759155h-0.1879578v-0.3759155h-0.1879883V579.1773682z"
            filter="url(#dropshadow)" // Apply the drop shadow filter here
          />
        </a>
        <text x="450" y="500" fontSize={16} color="000000" className="centered-text">江門</text>


        <a xlinkHref="../about/zhongshan.html" id="dmap_zhongshan" className="focus">
          <title>中山</title>
          <path id="Zhongshan_3_" className="gba3" d="M629.5772705,454.9344482l-2.4435425,0.1879578h-0.3759155l-1.5036621,0.3759766
        l-2.2555542,0.1879272l-1.3157349-5.4508972l-0.5639038-4.3230896l0.3759766-0.3759155l-2.2555542-1.6916809l-0.3759766-0.5638733
        l-0.1879272-0.1879578v-0.1879578l-0.3759155-0.5639038l-0.5639038-0.5638733l-1.6916504-1.1277466l-0.1879883-0.3759155
        v-0.1880188l0.5639038-0.375885h0.3759155l0.9398193-0.1879578l2.0675659-0.1879578h0.1879883l0.1879883,0.1879578h0.1879272
        l0.1879883,0.1879578v0.1879272h0.1879272l0.1879883,0.1879578v0.1880188h0.1879272v0.1879578l0.1879883-0.1879578v-0.1880188
        l-0.1879883-0.1879578l-0.1879272-0.1879272v-0.1879578v-0.1879578h0.1879272l2.4434814-0.1880188l1.5037231,0.9398193
        l0.3759155,0.3759155l1.3157349,1.1277771l0.5639038,0.5639038l0.3759155,0.5638733l0.3759155,0.7518616l0.1879883,0.5638733
        l0.3759155,1.1277771l0.1879883,2.0675659v3.195343L629.5772705,454.9344482z M614.7283325,431.6271973l0.1879272,0.3759155
        l0.1879883,0.375946l0.1879272,0.1879578l0.1879883,0.3759155l0.1879883,0.3759155l0.1879272,0.1879883l0.1879883,0.3759155
        l0.5638428,0.5638733l0.7518921,0.9398193l0.3758545,0.3759155l0.1880493,0.375946l0.3759155,0.1879578l0.5638428,0.1879578
        h0.5639038v-0.1879578h0.1879272l0.1879883,0.1879578h1.1277466l1.5037231-0.1879578l-1.6916504-0.3759155l-0.5639038-0.1879883
        l-0.1879883-0.1879578l-0.3759155-0.1879578l-0.1879272-0.3759155l-0.3759766-0.3759155v-0.375946l-0.3759155-0.1879578
        l-0.3758545-0.3759155l-0.5639648-0.5639038l-0.75177-0.7518311h-0.1879883l-0.3759155-0.1879883l-0.1879883-0.1879578
        l-0.3759155-0.1879578l-0.3759155-0.3759155h-0.3759155l-0.1879883-0.1879272l-0.1879272-0.1879578h-0.3759155l0.1879272,0.1879578
        v0.375885H614.7283325z M611.1570435,423.1689148h0.1879272l0.1879883,0.1879578v0.1879578h1.1277466v-0.1879578
        l-0.1879272-0.1879578l-0.1879883-0.1879883h-0.1879883l-0.1879272-0.1879578l-0.1879883-0.1879578h-0.1879272
        l-0.1879883-0.1879578h-0.3758545l-0.1880493,0.1879578v0.1879578v0.1879578h0.1880493L611.1570435,423.1689148z
        M621.4949341,455.6863098l-1.1278076-5.2629395v-2.6314697l-0.5638428-1.3157349l-0.3759155-1.1277771l-0.5639038-1.3157043
        l-0.5638428-0.7518311l-0.3759766-0.3759155l-0.7518311-0.7518616l-0.9398193-0.5638428l-0.5639038-0.1879578l-0.3759155-0.1880188
        l-1.1277466-0.1879578l-2.067627-0.1879578l0.7518921-1.3157043l2.4434814-0.1879578l2.819458-0.5639038l0.5638428-0.3759155
        l1.3157349-0.5639038v-0.1879578l-2.4434814-0.9397888l-0.9398193-0.9398193l-0.3759155-1.1277771l-0.3759155-0.7518616
        l-2.0675659-3.0073547l-1.1278076-0.9398193l-0.7518311-0.5639038l0.1879272-0.1879578l0.9398193,0.7518616l-2.4434814-3.7592468
        l-0.5639038-1.3157654l-0.5639038-0.7518311l0.3759155-0.375946l0.1879883,0.1879883h0.1879272l-0.1879272,0.1879578v0.1879578
        v0.1879578h0.1879272v0.1879578h0.1879883v0.1879578l0.1879883,0.1879578v0.1879883l0.1879272,0.1879578l0.1879883,0.5638733
        l0.3759155,0.5639038l0.3759155,0.5638733l0.3759155,0.7518616l0.5639038,0.7518005l0.1879883,0.1880188l0.1879272,0.1879578
        l0.3759155,0.5638428l0.3759155,0.5639343l0.7518921,0.5638428l0.1879272,0.1879578l0.5639038,0.1880188l0.3759155,0.1879578
        l0.3759155,0.1879272l0.1879272,0.1879578l0.3759766,0.1879578l0.1879272,0.1879578l0.1879883,0.1880188l0.1879272,0.1879272
        l0.3759766,0.1879578l0.3759155,0.1879578l0.1879272,0.3759155l0.3759155,0.1879578l0.3759766,0.375946l0.1879272,0.3759155
        l0.3759155,0.3759155l0.3759155,0.1879883h-0.1879272v0.1879578h0.1879272v0.1879578l0.1879883,0.1879578h0.1879883v0.1879578
        h0.3759155v0.1879578l0.1879272-0.1879578v0.1879578h0.1879883l0.1879883,0.1879883h0.7518311l0.3759155,0.1879578
        l0.1879883,0.1879578v-0.1879578l-0.1879883-0.375946l-0.3759155-0.9397888l-1.1278076-1.6916809l0.1879883-0.1879578
        l-0.3759155-0.9398193l-0.7518921-1.1277771l-0.3759155-0.1879578h0.1879883l-0.3759155-0.5638428l-0.5639038-0.3759766
        l-0.9398193-0.9398193l-1.3156738-1.3157043l-1.1278076-1.1277771l-0.1879883-0.1879578l0.1879883-0.1879578h-0.1879883v-0.1879578
        l-0.1879883-0.1879578h-0.1879272l-0.1879272-0.1879578l-0.3759155-0.1879883v-0.1879578l-0.1879883-0.1879578
        l-0.1879883-0.1879578l-0.1879272-0.1879578l-0.1879883-0.1879578l-0.1879272-0.1879578h-0.3759155l-0.3759766-0.1879883
        h-0.3759155l-0.5639038,0.1879883v-0.1879883l-1.1277466,0.1879883h-0.1879272l0.7518311-0.1879883v-0.1879578
        l-0.3759155-0.1879578l-3.3833618-3.5712585l-1.5036621-1.3157349l-0.9398193-0.7518616l-1.1277466-0.3759155l-4.1351318-3.1953735
        l-1.8796387-1.3157349l-1.3157349-0.5638428l-0.9398193-0.1879578l-2.6314697-2.2555542l-0.1879883-0.375946l-2.4434814-0.5638733
        v-0.3759155h-0.3759766l-1.1277466-0.5639038l-0.9397583-0.3759155l-1.6917114-0.7518616l-2.0675659-1.3157349
        l-1.3157349-0.5638428l-0.7518311-0.5639343l-1.3157349-0.7518616l-1.1277466-0.7518005l-0.7518921-0.5639038l-0.9398193-0.7518311
        l-1.3157349-0.9398193l-0.7518311,0.5638733v0.1879272l-0.1879883,0.1880188l-0.1879272,0.1879578v0.3759155l-0.1879883,0.1879578
        v0.375946l-0.1879272,0.3759155l-0.1879883,0.7518616l-0.3759155,0.3759155l-0.3759155,0.375885l-0.5639038,0.7518616
        l-0.5639038,0.3759155l-0.3759155,0.1879578l-0.7518311,0.375946l-0.5638428,0.3759155l-1.5037842,0.1879578l-1.3156738,0.3759155
        l-1.3157349,0.1879883l-0.5639038,0.1879578l-0.5639038,0.1879578h-0.3759155l-0.5639038,0.1879578h-2.2555542
        l-0.5638428-0.1879578l-0.3759155-0.1879578h-1.3157959l-0.3758545,0.1879578h-0.3759155l-0.3759766,0.3759155l-0.3759155,0.375946
        l-0.3759155,0.3759155l-0.7518311,0.9398193l-0.1879883,0.1879578h-0.9398193l-0.1879272-0.375946l-0.3759155-0.3759155
        l-0.3759766-0.1879578l-0.1879272-0.1879578l-0.1879883-0.3759155l-0.3759155-0.375946l-0.3759155-0.3759155l-0.3759155-0.3759155
        l-0.7518311-0.375946l-0.3759766-0.3759155l-0.75177-0.1879578h-1.1278076l-0.3759766,0.5638733l-0.3758545,0.7518616
        l-0.3759155,1.1277771l-0.5639038,1.1277771l-0.1879883,0.3759155l-1.1277466,1.8796387l-1.1278076,1.3157349l-0.7518311,0.7518005
        l-1.5037231,1.5037231l-0.9398193,1.8796082l-0.1879272,0.7518616l-0.9398193,1.3157349l-2.6314697,0.9398193l-1.6916504,0.7518616
        h-0.1879272l-1.1278076,0.9397583l0.1879883,2.2555542l0.1879272,0.375946v0.5638733l0.3759155,0.7518616v1.3157349
        l-0.1879272,0.7518311l-0.1879883,0.5639038l-0.1879272,0.3759155v0.5639038l-0.1879883,0.5638428v1.5037537l-0.1879883,1.3157043
        v0.9397888l0.1879883,0.5639038l0.1879883,0.3759155l0.5638428,0.5639038l0.3759766,0.5638733l0.5638428,0.7518616
        l0.1879883,0.7518311l0.3759155,1.1277466l0.3759155,1.3157959l0.3759155,1.1277771l0.1879272,1.3157043l0.1879883,1.5036926
        v1.5036926l0.1879883,0.5639038l0.1879883,0.7518311l0.3759155,0.5639038l0.5639038,0.7518005l0.3759155,0.5639343
        l0.5638428,0.5638428l0.5639038,0.5639343l0.5638428,0.5638428l1.8796387,1.6916809l0.7518921,0.5638733l0.3759155,1.1277771
        l0.5638428,0.9398193l1.1278076,1.1277771l0.5639038,0.3759155l0.3759155,0.1879272l0.3758545,0.1880188l0.5639648,0.1879578
        l1.1277466,1.5036621l0.7518921,1.8796692l0.3758545,0.7518005l1.1278076,2.6315002l0.3759155,0.9397888l0.3759155,0.9398193
        l0.1879883,1.3157043l0.1879272,0.9398193l-0.3759155,1.1277771v0.7518616l-0.1879883,1.3157349l0.1879883,1.8796082
        l0.1879883,0.7518616l0.1879272,1.3157349l0.5639038,1.1277771l0.9398193,0.375885l0.1879272,0.1880188l0.1879883,0.1879578
        l0.5639038,0.9398193l0.5638428,0.5638428l0.7518921,0.7518616l0.5638428,0.3759155l2.0675659,2.6314697l1.1278076,1.3157349
        l0.7518311,1.3157043l0.7518311,1.1277771l1.1278076,1.1277771l0.3759155,0.5639038l1.5037231,2.8193665l0.3759155,0.7518616
        l0.1879883,1.8796387l0.3759155,0.1879578l0.75177,1.8796082l0.9398193,1.5037231l2.6314697,2.8194275l0.9398193,0.9398193
        l1.6917114,1.5036621l0.5638428,0.5638733l0.5639038,0.5639038l0.1879883,0.1879578h0.1879272l0.3759155,0.1879578
        l-0.1879272,0.1879578l1.5036621,1.3157654l0.5639038,0.3759155l0.1879883,0.1879578l0.1879272-0.1879578h0.1879272v-0.1879578
        h0.1880493v0.1879578l0.1879272,0.1879578h0.1879883l-0.1879883,0.1879578h-0.3759766l0.9398193,0.9398193l0.5639648,0.5638428
        l0.5638428,0.3759766l0.3759155,0.5638428l0.1879883,0.1879578l0.7518311,0.9398193l0.7518311,1.3157349h0.1879883
        l0.1879272-0.1879578v0.1879578v0.1880188v0.1879272l0.5639038,0.7518311l1.1278076,1.6917114l0.3759155,0.3758545h0.3759155
        l0.1879272,0.1879883l0.1879883-0.3759155v-0.1879272h0.1879883v-0.1879883l0.3759155-0.1879883h2.0675659l0.3759155,1.6916504
        h0.1879272l0.1880493-0.1879272h0.9397583l0.1879272-0.1879272h0.3759766l0.3759155-0.1880493l0.9398193-0.3759155
        l1.1277466-0.3758545l0.5639038-0.3759766h0.3759155l0.3759155-0.5638428h0.1879883v-0.1879883l0.3759155-0.1879272v-0.1880188
        l0.1879883-0.1879578v-0.1879578h0.7518311l0.1879272-0.1879578h0.3759766l0.1879272-0.1879578l0.5638428-0.1879578
        l0.1880493-0.1879272h0.1879272l0.3759155-0.1880188h0.5639038l0.3759155-0.1879578h0.5638428l0.1879883-0.1879578
        l0.1879883-0.1879578v-0.1879272h-0.1879883v-0.1879578l-0.5639038-0.1880188v-0.3759155h-0.1879272v-0.1879272
        l0.1879272-0.1879578v-0.1879578l0.3759155-0.9398193v-0.1879578l0.1879883-0.1880188v-0.1879272l0.5639038-0.5638733
        l0.1879272-0.1879578h0.1879883l0.1879272-0.1879578l1.1278076-0.1879883l2.0675659-0.3759155v-0.1879578h0.1879883v-0.1879578
        h0.1879272l0.9398193-1.5037231l0.1879272-0.1879578l-0.1879272-0.1879578l-0.9398193-1.6916504l-0.5638428-0.7518616
        l-1.6916504-1.8796387l-0.1879883-0.1879578l-0.1879883-0.1879272l-0.3759155-0.1879578l-1.8796387-0.9398193l-0.1879272-0.1879578
        l-0.1879883-0.1879578l-0.3758545-0.1879578l-0.7518921-0.9398193l-0.1879272-0.1879578l-0.1879883-0.375946l-0.1879272-0.1879578
        v-1.8796082l0.1879272-0.1879578l0.5638428-0.375946l0.5639648-0.5638733l0.3758545-0.1879272l0.7518921-0.5639343
        l0.1879272-0.1879578l0.1879883-0.375885l0.1879272-0.1880188l0.7518921-1.3157349l0.1879272-0.5638428l0.3759155-1.6916809
        v-0.3759155l0.1879883-1.6916504v-0.5639038l-0.1879883-0.1879578l-0.5638428-1.8796387l-0.1879883-0.1879578v-0.1879578
        l-0.1879883-0.1879578l-0.3759155-0.7518616l-0.1879272-0.1879578v-0.1879272l-0.3759155-1.8796387v-0.7518311l0.3759155-2.2555542
        l0.1879272-0.375946l0.1879883-0.3759155l0.3759155-1.1277771l0.1879883-0.3759155l0.1879272-0.375885l0.7518921-0.9398193
        l0.1879272-0.1879578l0.1879883-0.1880188h0.1879272l0.3759155-0.1879578h0.5639038l0.9398193,0.3759766h2.2555542
        l0.1879883-0.1880188l0.3758545-0.3759155l0.1879883-0.1879272h0.3759766l1.3156738-0.1879578h2.2555542l0.3759155-0.1879578
        h5.8268433l0.7518311-0.1880188l0.9398193-0.1879578l0.3759155-0.1879272l0.1879883-0.9398193v-2.8194275l-2.4434814-0.5639038
        l0.1879272-0.3759155l2.0675659,0.5639038v-3.5712585l-2.0675659-0.1880188l1.5036621-3.5712585l0.3759155,0.5639038
        l0.3759766,0.1879578l0.1879272-0.9398193l0.7518921-1.3157349v-0.3759155v-0.375946h-0.1879883V455.6863098z"
            filter="url(#dropshadow)" // Apply the drop shadow filter here
          />
        </a>
        <text x="585" y="460" fontSize={16} color="000000" className="centered-text">中山</text>


        <a xlinkHref="../about/dongguan.html" id="dmap_dongguan" className="focus">
          <title>東莞</title>

          <path id="Dongguan_3_" className="gba4"
            d="M637.0957642,398.5458984l0.1879883,0.1879578v0.1879578l0.1879272,0.1879578v0.1879883
        l0.1879883,0.1879272v0.1879578l-0.1879883,0.375946l-0.1879272,0.1879578h-0.3759766v-1.5036926L637.0957642,398.5458984
        M673.9363403,328.999939l-0.7518921,0.3759155h-1.8796387l-0.5638428,0.1879578l-0.9398193,0.1879883l-2.6314697,0.3759155
        l-1.6917114,0.1879578h-1.5036621l-2.0675659-0.3759155l-1.5037231-0.1879578l-1.1277466,0.1879578l-1.1278076,0.1879578
        h-1.8796387l-1.6916504-0.7518616l-0.7518311-0.5638733l-1.1278076-0.7518311l-1.3156738-0.7518616l-0.1879883,0.3759155
        l-1.5036621,0.5638733l-1.5037231,0.9398193l-2.0675659,1.1277771l-1.5036621,1.3157349l-1.5037231,0.7518311h-2.0675659
        l-2.067627,0.375946h-2.0675659l-1.5036621,0.7518311l-2.6315308,0.7518616h-1.1277466l-2.6314697,0.1879578h-2.4435425
        l-1.5036621-0.5638733l-0.3759155-0.7518311l-1.3157349,0.3759155l-1.1277466,0.5638733l-0.5639038,0.5638733l-0.9398193,0.9398193
        l-1.3157349,0.3759155l-1.1278076,0.1879883l-0.9398193,0.1879578l-0.5638428,0.3759155l-0.5639038,0.3759155l-0.9397583,0.9398193
        l-0.5639038,0.7518311l-0.9398193,0.7518616l-0.5639038,0.9398193l-0.5639038,1.1277771l-0.3759155,1.3157043l-0.7518311,0.9398193
        l-1.1278076,1.1277771l-1.1277466,1.3157349l-0.9397583,0.5639038l-0.5639038,0.1879578l-0.1879883,0.7518311l0.3759155,0.5638733
        l2.4434814,5.4509277h1.3157959l0.5638428,1.3157349h-0.1879272v0.1879578l0.1879272,0.1879578h0.1879883v0.1879578
        l-0.9398193,0.5639038l0.3759155,1.1277466l0.9398193-0.3759155l0.1879883,0.5638733l-0.7518921,0.1879578l0.1879883,0.375946
        l0.7518311-0.1879578l0.3759155,0.9397888l-0.9398193,0.375946l0.5639038,0.9398193l0.5639038,1.5036621l0.5639038,1.8796387
        v0.5638733l0.3759155,0.7518616l-0.1879883,0.1879578l0.1879883,0.375946l0.3759155,0.9397888l0.1879883,0.1879578
        l0.1879272-0.1879578l0.1879883,0.5638733l1.6916504,2.6315002l0.7518311,0.9397888l-0.3759155,0.1879578v0.3759155h0.1879883
        l0.3759155,1.5037231l0.1879272,0.1879578l0.1879272,0.9398193l0.1880493,1.1277466l0.1879272,0.1879578h-0.1879272
        l0.1879272,0.1879578v0.1879578l0.1879883,0.375946l0.7518311,1.6916504l-0.1879883,0.1879578l-0.1879883,0.3759155
        l0.1879883,0.1879578l-0.5638428,0.9398193l-0.1879883,1.8796387l-0.1879272,1.3157043l0.1879272,1.1277771h0.1879883
        l0.1879272,0.1879883v0.3759155l0.1879272,0.1879578v0.3759155l0.3759766,0.375946l0.5638428,0.5638733l0.5639648,0.3759155
        h-0.1880493l1.3157959,1.1277771h0.1879272l1.5037231,1.1277771l0.3759155,0.3759155l1.1277466-0.1879578v0.9398193
        l0.3759766,0.5638733l0.1879272,0.5638733l0.5639038,1.5037231l-0.1879883,0.1879578h0.1879883l0.1879272,0.3759155h0.1879883
        l0.1879883-0.1879578h0.1879272v-0.3759155h0.1879883l0.5638428-0.5639038l0.3759766,0.1879578l-0.3759766,0.375946
        l-0.5638428,0.7518311l-0.1879883,0.5639038l-0.1879272,0.5638733l0.1879272,0.9397888l0.1879883,0.5639038l0.3759155,0.1879578
        l0.3759155,0.3759155l0.3759155,0.375946v-0.1879578h0.3759155l0.1879272,0.1879578l0.3759766,0.1879578h0.3759155
        l3.5712891,1.5036926l-0.3759155,0.9397888l1.1277466,1.5037231l0.3759155,0.7518311v0.1879883l0.3759155,0.1879578h0.3759766
        l0.1879272-0.1879578l0.1879883-0.1879883v-0.1879578l0.5639038-2.2555542v-0.375885l0.3759155-1.5037231l0.5638428,0.1879578
        v0.7518616l0.3759766,0.9397888l0.1879272,0.7518616v0.1879578l-0.1879272,1.1277771l-1.3157349,1.8796082l0.1879272,0.375946
        l-0.5639038,0.5638733l-0.1879272,0.1879578l0.1879272,0.3759155l0.1879883,0.375946v0.1879578l0.3759155,0.1879578v0.1879578
        l0.5639038,1.1277771l0.3758545,0.1879578l0.1880493,0.1879883l0.1879272,0.1879272l0.5638428,0.1879578l0.1879883-0.1879578
        h1.3157959l0.3759155,0.1879578h1.5036621l0.1879272,0.1879883h1.6917114v-0.9398193h1.3157349l0.1879272-0.1879578v1.1277771
        h1.5037231l0.1879272-0.1879883l0.1879883-0.375885v-0.375946l-0.1879883-1.3157349v-0.1879578l0.1879883-0.3759155
        l1.3157349,1.5036621l0.5638428-0.5638733l1.3157349,0.9398193l0.3759766-0.1879578l0.5638428,0.3759155l-0.9398193,0.5638733
        v0.1879883l1.3157349,0.3759155l0.3759766-0.1879578h0.1879272l0.1879883,0.1879578l0.3759155,0.1879578l1.5037231,1.1277466
        l0.7518311-0.5638733l0.3759155-0.5638733h0.1879883l1.5036621-0.9398193l0.1879272,0.1879578l0.5639648-0.3759155
        l0.1879272-0.1879578l0.7518921-0.1879578l1.3156738-0.5639038h0.3759155l0.1879883-0.1879272h0.1879272l0.1879883-0.1879883
        l0.7518311-0.9397888l0.1879883-0.375946l0.1879272-0.3759155l0.1879883-0.1879578v-1.6916809l-0.1879883-0.375885
        l-0.1879272-0.1879883v-0.1879578l0.3759155-0.375946l0.1879883-0.375885l0.3759155-0.1879883l0.3759155-0.1879578h0.1879883
        l0.3759155-0.1879578l0.1879272-0.3759155v-0.1879578l-0.1879272-0.1879883l-0.3759155-0.7518311v-0.1879578l0.7518311-0.375946
        h0.1879272l0.3759766,0.5639038l0.1879272,0.1879578h0.9398193l0.3759155-0.3759155l0.1879272-0.1879578v-0.1879883
        l-0.1879272-0.1879578l-0.1879272-0.3759155v-0.1879578l0.1879272-0.1879578l0.1879272-0.375946h0.7518921l0.9398193-0.1879272
        h0.3759155v-0.375946l0.1879272-0.1879578h0.1879883l0.3759155-0.3759155h0.5639038l0.7518311-0.375946l0.3759155-0.1879578
        l0.1879883-0.1879578l0.5639038-1.1277771v-0.3759155l0.1879272-0.3759155v-0.375946h-0.1879272v-0.1879272l-0.1879883-0.1879883
        l-0.1879883-0.1879578v-0.9398193h-0.1879272l-0.1879883-0.1879578h-0.1879272l-0.1879883-0.1879578v-0.1879578h0.1879883
        v-0.1879578h0.1879272v-0.7518616l0.1879883-0.1879578h0.1879272l0.1879883-0.1879578l1.5037231-0.5639038h1.8795776
        l0.3759766,0.1879883h0.1879272l0.9398193-0.1879883l1.1278076-1.1277771l0.1879272-0.1879578l0.5638428-1.1277466v-0.1879578
        h0.1879883l1.1277466-0.375946h0.3759766l0.9397583,0.1879578h0.1879883l1.8796387,1.5036926v0.1879578h0.1879272
        l1.6917114,0.5639038h0.1879272l2.2555542-1.3157349l1.1277466-0.7518311h0.1879883l1.5036621-0.1879883h0.1879883
        l1.3157349,0.7518616l0.1879883,0.1879578l0.3759155,1.1277771v0.1879578l-0.5639038,0.5638733l-0.7518311,0.9398193h-0.1879883
        v0.1879578l0.1879883,0.1879578h0.5638428l0.3759766,0.375946h0.3759155l0.3758545,0.5638733h0.5639648l0.3758545,0.1879578
        v0.1879883l0.3759155,0.375885h0.5639038l0.1879883,0.1879883l0.3759155,0.1879578l1.8796387,0.7518311h0.3759155
        l0.1879272-0.1879578h0.3759766l0.5638428,0.375946h0.1879883l0.1879883,0.1879578l0.3759155,0.5638733l0.1879272,0.1879578
        v0.5638733h0.1879883v0.1879578h0.3759155l0.3759155-0.1879578h0.5639038l0.1879883,0.1879578v0.1879883h0.3759155
        l0.1879272,0.3759155h0.1879883v0.3759155l0.1879272,0.1879578l-0.1879272,0.7518616l0.1879272,0.1879578v1.1277771
        l0.1879272,0.375946v0.5638733l0.1880493,0.1879578v0.1879578l0.5638428,0.3759155v0.3759155l0.1879272,0.1879883v0.1879578
        l0.1879883,0.1879578l-0.1879883,0.1879578v0.1879578h-0.1879272l-0.1879272,0.375946v0.1879578h0.1879272v0.1879578h0.1879272
        v0.1879578h0.1879883l0.1879883,0.1879578h0.1879883v0.1879578h0.1879272l0.1879272,0.375946h0.1879883v0.1879272
        l0.5639038-0.1879272l0.1879272,0.1879272l0.1879883,0.1879883l0.1879272,0.1879578l0.5639038-0.1879578v0.1879578
        l0.3759155,0.1879578h0.3759155v-0.1879578l0.1879883,0.1879578h0.1879883l0.5638428,0.1879883h1.1278076l0.1879272-0.1879883
        h0.7518921v-0.3759155l0.1879272-0.1879883l0.1879883-0.375885l0.1879272-0.1879883h0.1879883l0.1879883,0.1879883v0.1879578
        l0.1879272,0.1879272h0.7518311v0.1879883h0.1879883v-0.1879883h0.1879883v-0.1879272h0.1879272v0.7518311l0.7518921,0.3759155
        v0.1879578h0.1879272v0.1879578h0.7518311l0.3759155,0.1879883l0.3759155-0.1879883h0.1879883l1.3157349-0.3759155h0.9398193
        v0.1879578h0.1879272v0.1879578h0.1879883l-0.1879883,0.375946l0.1879883,0.1879578l0.1879883,0.1879578l0.1879272,0.5639038
        l-0.1879272,0.1879578v0.5638733h-0.1879883v0.1879578h0.1879883v0.1879883h0.3759155l0.1879272,0.5638733l0.1879883,0.1879578
        h0.3759155l0.3759155-0.1879578l0.5639038-0.7518616h0.1879883v-0.3759155l0.1879272-0.3759155l0.1879883-0.1879578h0.3759155
        l0.3758545,0.375885h0.3759766l0.1879883,0.1879883l0.5638428,0.3759155h0.1879883l0.1879883-0.1879578l0.1879272,0.375946
        l0.3759155,0.375885v0.5639038h-0.1879883v0.5638733l0.9398193,0.9398193l0.1879883,0.1879578h0.5639038l0.5638428,1.6916504
        v0.375946h0.1879883v0.1879578h0.9398193l0.1879272-0.1879578h0.3759155v-0.1879883h0.3759766v0.1879883l0.3759155,0.5638733
        l0.3759155,0.5639038l0.1879883,0.1879578h0.1879272v0.1879578h0.1879883v-0.1879578l0.3759155-0.1879578h0.7518311
        l0.1879883,0.1879578v0.1879578l0.1879272,0.1879578h0.1879272l0.7518921,0.1879578l0.1879272-0.1879578v-0.3759155h0.3759155
        l0.1880493,0.1879578l0.5638428,0.3759155h0.3759155l0.1879883,0.1879578l0.1879272,0.1879578v0.1879883h-0.1879272v0.1879578
        l0.1879272,0.1879272l0.000061,0.5639038l-0.3759766,0.3759155v0.1879578l0.1879883,0.1879883v0.1879578h0.1879883
        l0.1879272,0.1879578h0.1879272l0.1879883,0.1879578l0.1879272,0.1879578l0.3759766,0.375946v0.1879578l0.3759155,0.7518311
        l-0.1879883,0.1879578v0.1879883l0.5639038,0.375885l0.5639038,0.375946h0.1879272l0.5639038,0.7518311l0.7518311,0.7518616
        l0.1879883,0.1879578l0.1879272,0.7518616l-0.1879272,0.1879578l-0.5639038,0.1879578l-0.1879272,0.1879578l-0.1879883,0.1879883
        v0.1879578l-0.1879883-0.1879578h-0.3759155l-0.1879272,0.1879578h-0.7518921v0.375885l-0.1879272,0.1879883v0.5639038
        l-0.1879883,0.375885h0.1879883l0.1879272,0.1879883l0.7518921,0.1879578l0.1879272,0.1879578l0.3759155,0.1879578v0.1879578
        l0.3759766-0.3759155h0.3759155l0.1879272,0.1879578h0.1879883l0.1879272-0.1879578l0.1879883-0.1879578h0.1879883v0.3759155
        h0.1879272l0.1879883-0.1879578v0.5638733h0.3759155l0.1879883,0.1879883h0.5638428l0.1879883-0.1879883h0.1879272v-0.3759155
        l0.1879272-0.5638733h0.1880493l0.1879272-0.5639038l0.1879883-0.5638733v-0.1879883l0.5638428-0.375885l0.1879883-0.1879578
        v-0.9398193l0.1879883-0.1879578l0.1879272-0.1879883l0.1879272-0.1879578h0.1879883l0.1879272-0.1879578l0.1880493-0.1879578
        l0.1879272-0.1879578v-0.1879578l0.1879272-0.1879883h0.1879883l0.1879272-0.1879578l0.1879883-0.1879578l-0.1879883-0.1879578
        l-0.1879272-0.1879578h-0.3759155l-0.3759766-0.375946v-0.375885l0.1880493-0.1879883h0.1879272l0.3759155,0.1879883h0.3759155
        v-0.375946l-0.1879883-0.1879578v-0.3759155h0.5639038v-0.3759155l0.1879883-0.1879883l-0.1879883-0.1879578h-0.1879272
        l-0.1879883-0.1879578l0.1879883-0.1879578v-0.1879578l0.1879272-0.1879578v-1.1277771l-0.1879272-0.1879883h-0.1879883v-0.1879578
        l-0.1879883-0.1879272v-0.375946l0.1879883-0.1879578l0.1879883-0.1879578h0.9397583v-0.3759155h0.9398193l0.1879883-0.1879578
        l0.3759155-0.1879883v-0.3759155l-0.1879883-0.1879578l-0.1879272-0.3759155v-1.5037231l0.1879272-0.3759155l0.1879883-0.1879578
        l0.1879883-0.1879578l0.3759155-0.1879883l0.1879272-0.1879272l0.1879883-0.1879578v-0.5639038h-0.1879883v-0.1879578h-0.1879272
        v-0.1879578l-0.1879883-0.3759155l-0.1879272-0.1879883h-0.3759766l-0.1879272-0.1879578v-0.7518311l0.1879272-0.1879883v-0.375885
        h-0.1879272l-0.3759155-0.1879883l-0.3759155-0.1879578v-0.1879578l-0.1879883-0.1879883v-0.375885l-0.1879883-0.1879883
        h-0.1879272l-0.1879883-0.1879578l-0.1879272-0.1879578l-0.1879883-0.1879578v-0.3759155l-0.1879272-0.1879578
        l-0.1879883-0.1879883h-0.3759155l-0.1879883,0.375946h-0.1879272l-0.1879272-0.375946h-0.3759766l-0.3759155,0.375946h-0.7518921
        l-0.3758545-0.1879578l0.3758545-0.5639038l0.5639648-0.3759155v-0.375946l-0.1879883-0.1879578v-0.5638733l0.1879883-0.1879578
        l-0.1879883-0.1879883l-0.3759766-0.375885l-0.1879272-0.1879883v-0.7518311l0.1879272-0.1879578l0.1879883-0.1879578
        l0.1879883-0.1879883h0.1879272l0.3759155-0.1879578h1.1278076l0.3759155-0.1879578l0.1879883-0.1879578h0.5638428
        l0.1879883-0.1879578l0.1879272-0.1879578h1.3157349l0.1879883-0.1879883l0.5639038-0.5638733h0.1879272l0.3759766-0.1879578
        l0.1879272-0.1879578l1.1278076-0.375946l0.1879272-0.1879272v-0.375946l0.1879883-0.1879578l0.1879272-0.1879883
        l0.1879272-0.1879272l0.1879883-0.1879578l-0.1879883-0.375946l-0.1879272-0.1879578l0.3759155-0.5638733l0.1879883-0.375946
        l0.1879883-0.7518311l0.3758545-0.5639038l0.3759155-0.7518311v-0.7518616l0.5639038-2.819397l-0.5639038-2.4435425
        l0.1880493-2.2555237l0.5638428-1.3157349l0.5639038-1.3157349l0.3759155-0.9397888l0.1879272-1.1277771l0.3759155-1.1277771
        v-1.6916809l-0.5638428-2.0675659l-0.5639038-0.5639038l-1.6916504-0.7518311l-2.2555542-0.375946l-1.5036621-0.7518311
        l-0.9398193-1.5036926l-0.1879883-1.6916504l0.5639038-2.2555542l1.6916504-1.8796082l0.5638428-2.443512l-0.75177-1.8796387
        l-1.1278076-1.1277771l-1.6916504-0.375885l-1.1278076,0.1879272l-1.6916504,0.375946l-0.3759155-0.5638733l-1.1278076,0.7518311
        h-0.5638428l-0.7518921,0.5638733l-0.5638428,0.375946l-0.3759155,0.3759155l-0.5639038,0.1879578l-1.3157349,0.3759155
        l-0.9398193,0.1879883h-0.9398193l-0.9397583-0.1879883l-0.5639038-0.1879578l-1.1278076-0.9398193h-1.3157349
        l-0.3758545-0.7518311l-0.1879883-0.5638733l-0.1879883-0.375946l-0.5638428-0.1879578l-0.3759155-0.5638733l0.1879272-0.3759155
        l0.3759155-0.5639038l0.9398193-0.7518616l0.9398193-0.5638733l0.7518311-0.3759155l2.0675659-1.8796082v-0.5639038
        l-0.1879272-1.3157349l-1.6917114-1.6916809l-1.1277466-0.9397888l-1.3157349-1.6916504l-0.7518311,0.375946h-0.9398193
        l-0.7518311-0.1879883l-1.3157349-0.9397888l-0.5639038-0.7518616l-0.5639038-1.3157349l-0.5638428-1.5036926l-0.7518921-1.1277771
        l-0.3759155-1.3157349l-0.5639038-0.9398193l-0.7518311-1.3157043l-1.3156738-0.7518616l-1.1278076-0.5639038h-3.1953735
        l-1.1277466,0.1879883l-1.3157349,0.1879578l-1.3157349,0.9398193l-1.5037231,1.1277771l-0.5638428,0.5638428l-0.3759155,0.375946
        l-0.3759766,0.1879578h-0.5638428l-0.5639038-0.1879578l-0.3759155-0.1879578l-0.9398193-0.1879883l-1.6916504-0.375885
        l-2.2555542-2.6315002l-3.0074463-2.0675659l-1.3156738-0.1879578h-1.8796387l-1.5037231,0.1879578l-1.8795776,0.1879578
        l-2.2555542,0.5639038l-1.5037231-0.375946l-2.819397-1.1277466l-3.1953735-0.1879883h-0.9397583L673.9363403,328.999939"
            filter="url(#dropshadow)" // Apply the drop shadow filter here
          />
        </a>

        <text x="680" y="370" fontSize={16} color="000000" className="centered-text">東莞</text>


        <a xlinkHref="../about/macao.html" id="dmap_macao" className="focus">
          <title>澳門</title>

          <path id="Macao_3_" className="gba5"
            d="M619.9912109,524.8563232l-0.1879272,0.1879272l-0.3759155,0.3759766l-0.3759155,0.1879272
        h0.1879883l0.1879272-0.1879272h0.1879272v-0.1879883l0.1879883,0.1879883l0.3759766,0.1879272l0.5638428,0.1879883
        l0.5638428,0.3759155l0.3759766,0.3759155l0.1879272,0.1879883h0.1879883v0.1879272h0.1879272v0.3759155h-0.1879272v0.3759766
        h-0.1879883v-0.1879883l-0.1879272,0.1879883h-0.3759766v-0.1879883h-0.1879272v-0.1879883h-0.1879883v-0.1879272h-0.3759155
        l-0.1879272,0.1879272h-0.3759766l-0.1879272,0.1879883l0.1879272,0.1879883v0.3759155l-0.1879272,0.1879272l-0.1879883,0.1879883
        l0.1879883,0.1879272l0.1879272-0.1879272l0.1880493-0.1879883h-0.1880493l0.1880493-0.1879272l0.3758545-0.1879883
        l0.3759155-0.1879272l0.1879883-0.1879883v0.1879883l-0.1879883,0.1879272l-0.3759155,0.3759155l-0.1879272,0.1879883
        l0.1879272,0.1879272v0.1879883v0.1879883v0.1879272h0.1879883l0.1879272-0.1879272v0.1879272h0.3759155l0.1879883,0.1879883
        l0.1879883-0.1879883h0.1879272l0.1879883,0.1879883l0.1879272-0.1879883h0.1879883l0.1879883,0.1879883l0.1879272,0.5639038
        h0.1879883v0.1879272v0.1879883l-0.1879883,0.1879272h-0.1879272v0.1879272h-0.1879883l-0.1879883,0.1879883v-0.1879883h-0.7518311
        l-0.1879883,0.1879883l-0.1879272,0.1879883v0.1879883h-0.1879883l-0.3759155,0.1879272v0.1879272l-0.3758545-0.1879272h-0.1880493
        l-0.3759155-0.1879272l-0.1879272-0.1879883l-0.1879272,0.1879883l-0.3759766,0.3758545l-0.1879883,0.1879883v0.1879272
        l-0.1879272,0.3759766v0.5638428h0.5639038l0.1879883,0.1879883v0.1879272v0.1879883h-0.1879883v0.1879883h-0.3759766
        l-0.1879272,0.1879272h-0.1879272l-0.3759155-0.1879272v0.1879272h-0.1880493l-0.1879272-0.1879272h-0.3759155v0.1879272
        h-0.3759155v-0.1879272l-0.1879883-0.1879883h-0.1879272l-0.1879883,0.1879883v0.1879272h-0.1879272l-0.1879883,0.1879883
        l-0.1879272,0.1879272h-0.3759766l-0.1879272-0.1879272l-0.3759155-0.1879883h-0.1879883l-0.1879272-0.1879272v-0.1879883
        h-0.1879883l0.1879883-0.1879883l0.1879272-0.1879272v-0.5639038l-0.1879272-0.1879272v-0.3759766h0.3759155v-0.3759155v-4.3231201
        l-0.5639038-0.7518311l-0.3759155-0.5639038l-0.9398193-0.7518311h-0.1879883v-0.1879883l-0.1879272-0.9397583
        l-0.1879883-0.5639038l-0.1879272-0.3759155l0.1879272-0.1879883l0.1879883,0.1879883v0.1879272l0.1879272,0.1879883
        l0.3759155-0.1879883h1.1278076v-0.1879272h0.1879883l0.3759155-0.1879883h0.3759155l0.5639038,0.1879883v-0.1879883h0.5638428
        l0.1879883,0.1879883h0.9398193l1.3156738,0.1879272v-0.3759155l0.1879883-0.1879272v-0.5639038h0.5639038v-0.1879883h0.1879272
        v0.1879883l-0.1879272,0.1879272l-0.1879272,0.1879883v0.1879883v0.1879272h0.1879272l-0.1879272,0.1879883h0.3758545
        l0.1879883-0.5639038h0.3759766l-0.1880493,0.7518311h0.1880493v0.1879883h0.1879272v-0.1879883v-0.1879272l0.1879272-0.1879883
        v-0.1879272l0.1879883,0.1879272v0.1879883l0.1879272-0.1879883h0.1879883v0.1879883v0.1879272v0.1879883l-0.9397583,0.3759766
        v0.5638428l-0.1880493,0.1879272V524.8563232z M613.2246094,520.1572266v-0.5638428h0.3759155v0.3759155l0.5639038-0.1879272
        l0.3759155-0.1879883l0.1879883-0.5639038l0.7518311-0.3759155l0.1879883,0.3759155h0.3759155v-0.3759155l-0.3759155-1.6916504
        l0.1879272-0.1879883l0.3759155,0.1879883l0.3759155-0.3759155l-0.1879272-0.1879883h0.1879272l0.1879883-0.1879272
        l-0.1879883-0.9398193v-1.1278076l-0.1879272-0.3759155l-0.1879883-0.1879272l-0.7518311-0.1879272l-0.9398193-0.3759766
        h-0.3759155l-0.3759155-0.1879883h-0.7518921l-0.3759155,0.7518921H612.28479l-0.7518311-0.1879272l-0.3759155,0.1879272
        l-0.1879272,0.1879272l0.1879272,0.3759155l0.1879272,0.5639038l1.1278076-0.3759155l-0.1879883-0.1879883l0.3759155-0.1879272
        l0.1879883,0.5639038l-1.1278076,0.3759155l0.1879883,0.1879272h0.1879883l0.3759155-0.1879272v0.1879272l-0.3759155,0.1879883
        h-0.1879883l-0.1879883,0.5639038l-0.1879272,0.9397583v0.3759766l-0.1879883,0.3759155l-0.9398193,1.1277466l0.1879883,0.1879883
        h-0.1879883l-0.1879272,0.1879272v0.7518921l0.1879272,0.1879272h0.1879883v0.1879272h0.1879883l0.3759155,0.7518921v-0.1879272
        v-0.3759766h0.5638428l0.3759766-0.1879883H613.2246094z M623.7504883,528.0516968l-0.7518311-1.6916504l-1.1278076-3.1953735
        v-0.1879883h-0.1879272v0.1879883h-0.3759766v0.1879272l0.1879883,0.1879883l-1.3156738,0.3759766l0.9397583-0.1880493h0.3759155
        l1.1278076,2.6315308l-0.1879883,0.1879272l-2.6314697-1.5037231l1.3157349,0.9398193l1.3157349,0.7518921h-0.1879883
        l0.1879883,0.1879272l0.3759155,1.1278076l0.5639038,1.1277466v0.1879883H623.5625l0.3759766-0.1879883l0.1879272-0.1879272
        L623.7504883,528.0516968z M618.6754761,514.5184326h0.1879883v0.1879272l-0.1879883,1.1278076v0.3759155v0.3759155
        l0.1879883,0.1879883h1.5036621v-0.1879883h0.1879883v-1.5037231h0.1879272v-0.1879272l0.1879883-0.1879883l0.1879883-0.3758545
        l0.1879272-0.3759155l0.1879883-0.1880493v-0.1879272l-0.3759155-0.1879272h-0.3759766l-0.3759155-0.1879883h-1.5036621
        l-0.1879883,0.1879883l-0.1879272,0.3758545v0.1880493v0.1879272v0.1879883h0.1879272V514.5184326z M616.9838867,515.0822754
        v0.1879883v0.1879272l0.1879272,0.1879883v0.1879272h0.1879883v1.8796387v0.1879883v0.1879272h0.3758545l0.1879883,0.1879883
        h0.1879883h0.3759155v-0.1879883h0.1879272l-0.1879272-0.1879272v-2.2555542l-0.1879272-0.1879883v-0.5638428l-0.1879883-0.3759766
        h-0.1879883v-0.1879272h-0.1879883l-0.5638428-0.3759155h-0.3759766v0.1879272v0.1879883l0.1880493,0.5639038V515.0822754z"
            filter="url(#dropshadow)" // Apply the drop shadow filter here
          />
        </a>
        <text x="640" y="525" fontSize={16} color="000000" className="centered-text">澳門</text>


        <a xlinkHref="../about/greaterbayarea.html" id="dmap_greaterbayarea" className="focus">
          <title>香港</title>
          <path id="HongKong_3_" className="gba6" d="M693.8602905,491.9630127h-0.3758545l0.1879272,0.1879272l-0.1879272,0.1879578v0.1879578
        l-0.1879883,0.3759766l-0.1879272,0.375885h0.75177l0.1879883-0.375885l0.1879883-0.7518921l0.1879883-0.375885v-0.1879578
        h0.9398193l1.1276855,0.3759155h0.1879883v-0.5639343h0.3759155l0.1879883,0.1880188v0.1879578l-0.1879883,0.1879578v0.375885
        l-0.1879272,0.3759155v0.1880188l-0.1879883,0.375885v0.5638733h-0.1879883v0.1880188h-0.1879272l-0.1879883,0.1879272
        l-0.1879272-0.1879272h-0.1879883l-0.3758545-0.1880188l-0.5639648-0.1879578l-0.1879272-0.1879578h-0.5639038
        l-0.3759155,0.1879578h-0.5638428l-0.1880493,0.1879578l-0.1879272,0.1880188l-0.1879272,0.1879272v0.5638733l0.1879272,0.1879578
        v0.1879883l0.1879272,0.1879578v0.1879578l-0.1879272,0.1879578v0.1879578l-0.3759155,0.1879578v0.1879578l-0.1879272,0.375946
        v0.1879578l0.1879272,0.3759155l-0.9398193,0.7518616h-0.1879272l-3.3833618-0.9398193h-2.2555542l-0.3758545,0.1879578
        l-0.7518921,0.1879578h-0.9398193v-0.1879578h-0.1879272v-0.3759155h0.1879272l0.5639038-0.9398193l0.1879883-0.1879578v-0.1879578
        l-0.1879883-0.1879578l-0.1879272-0.1879578l-0.1879883-0.5639038l-0.5639038-0.1879578v-0.1879578v-0.1879272l0.1879883-0.1880188
        l7.8944092-2.443512h0.3759155l0.1879272,0.5639343l1.5037231-0.3759155l0.1879883-0.1880188h0.1879272l0.1879272,0.1880188
        V491.9630127z M685.026062,513.7665405v-0.1879272l-0.1879272,0.1879272H685.026062z M675.4400024,516.7739868v-0.1879883
        h-0.1879272v0.1879883H675.4400024z M709.6491089,489.3314819h-0.1879272v0.1880188h0.1879272V489.3314819z
        M709.2731934,488.9555969v0.1879578l0.1879883,0.1879272v-0.375885H709.2731934z M709.4611816,489.5195007v0.1879578h0.1879272
        L709.4611816,489.5195007z M708.1454468,511.1351013h-0.3759766v0.1879578h-0.7518311v0.1879578h-0.1879272v0.1879578
        l-0.1879883,0.1879883v0.1879883h0.1879883l-0.1879883,0.1879272h-0.1879883v0.1879883h-0.1879272v-0.1879883h-0.1879883v0.1879883
        h-0.3759155v0.3758545h-0.1879883v0.3759766h-0.7518311v-0.3759766h-0.1879272v-0.1879272h-0.9398193v0.3759766h-0.1879883
        v0.1879272h-0.3759155v-0.1879272h-0.1879883l-0.3759155,0.3759155v0.1879272h0.1879883v0.1879272h-0.7518921v-0.5638428
        h-0.1879272v-0.1879272h-0.7518311v-0.1880493h-0.3759766v-0.1879272l0.1879883-0.1879272h0.1879883l0.1879883-0.1879883
        v-0.3759155h0.1879272v-0.375946l-0.1879272-0.1879578v-0.1879578l-0.3759766-0.1879578l-0.1879883-0.1879272l-0.1879272-0.1879578
        h-0.3759155l0.1879883-0.1880188v-0.1879578h0.1879272v-0.7518005h0.1879272l0.1879883-0.1880188l0.1879883-0.1879578v-0.1879272
        l-0.1879883-0.1879578l-0.3759155-0.1879578l-0.5638428-0.1879578l-0.5639038-0.1880188H699.12323l-0.1879272,0.1880188h-0.3759155
        v0.1879578l-0.1880493,0.1879578h-0.1879272l-0.1879272,0.1879578h-0.5639038v0.1879272h-0.1879883l-0.1879272,0.5639343
        v-0.1879578h-0.1879883v0.1879578l-0.1879272-0.1879578v0.1879578h-0.3759155v0.1879578h-0.1879883v-0.3759155h-1.6916504
        l-0.3759155,0.1879578l-0.5639038,0.1879578l-0.3759155,0.1879272h-0.1879883l-0.1879272,0.1879578h-0.1879883v0.1879578
        h-0.5639038l-0.1879272,0.1880188h-0.1879272l-0.1880493,0.1879578v0.1879272h-0.1879272v0.1879578h-0.5638428
        l-0.1879883,0.1879578h-0.5639038v-0.1879578l-0.1879272-0.1879578h-0.3759155l-0.1879883,0.1879578v0.1879578
        l-0.1879883,0.1879578v0.1879578l-0.1879272,0.1879883h-0.3759155v0.1879883l-0.1879883,0.1879272h0.1879883v0.3759155h0.5638428
        v0.1879272h0.3759766l0.1879272-0.1879272l0.1879883-0.1879272h0.3758545l0.1880493,0.1879272h0.1879272l0.1879883,0.1879272
        v0.1880493h0.1879272v0.3759155h-0.1879272v0.5638428l0.1879272,0.1879883h-0.1879272v0.1879883h-1.1278076l-0.1879272,0.1879272
        h-0.1879883v0.1879272h-0.5639038v-0.3758545h-0.3759155v-0.1879883l-0.1879272-0.1879883h-0.5639038v0.3759766
        l-0.1879883,0.1879272v0.1879272h-0.1879272v0.1879883h-0.3759766l-0.1879272-0.1879883h-0.3759155v-0.1879272h-0.1879883
        v-0.1879272l-0.1879272-0.1879883v-0.1879883h-0.3759766v0.1879883h-0.1879272v-0.3759766l-0.1879883-0.1879272h-0.1879272
        v-0.1879272h-0.5639038v-0.5639648h-0.1879883v-0.1879272h-0.1879272v-0.1879272l0.1879272-0.1879883h0.1879883v-0.5639038
        h-0.9397583v-0.1879578h-0.5639648v0.375946h0.1879883l-0.1879883,0.1879883h-0.1879272v0.1879272h-0.3759155v-0.1879272
        l-0.1879272-0.1879883h-0.1880493l-0.1879272,0.1879883h-0.1879272v0.1879272h-0.3759155v0.1879883h-0.1879883v0.1879272
        l-0.1879883,0.1879272v0.1880493h-0.1879883l-0.1879272,0.1879272h-0.1879272v0.3759155l0.1879272,0.1879272h0.1879272v0.1879883
        h-0.5638428v-0.1879883h-0.1879272v0.1879883h-0.3759766l-0.1879272,0.1879883l-0.3759155,0.1879883v0.1879272h-0.1879883
        v0.1879272h-0.1879883v0.1879883l-0.1879272,0.1879272v0.1880493l-0.1879883,0.1879272v0.1879272h-0.5638428v0.1879883h-0.1879883
        v-0.3759155h-0.1879883v-0.1879272h-0.5638428v0.1879272h-0.7518921v0.1879272h-0.3759155l-0.1879272,0.1879883v0.1879272
        h-0.1879883v0.3759155h0.1879883v0.1879883h-0.1879883v0.1879883h-0.5639038l-0.1879272-0.1879883v0.1879883h-0.3759155
        l0.1879272,0.1879272v0.1879883l-0.1879272-0.1879883l-0.1879883,0.1879883h-0.1879883v-0.3759155l0.1879883-0.1879883
        l0.1879883-0.1879883v-0.5638428h0.1879272l0.1879883-0.1879883v-0.3758545h-0.1879883v-0.1880493h-0.1879272v0.1880493h-0.1879883
        v-0.1880493h-0.3759155v-0.1879272h-0.1879883v-0.1879883h-0.1879272v-0.1879272h-0.1879272v-0.7518921h0.3758545v-0.5638428
        h0.1879883v-0.1879272h-0.1879883v-0.1880493l-0.3758545-0.1879272l-0.1879883-0.1879272h-0.1879883v-0.9398499
        l0.1879883-0.7518005h-0.1879883v-0.3759766h0.1879883l0.1879883-0.5638428v-0.5639343l0.3758545-0.1879578h0.5639038
        l0.1879883,0.1879578l0.1879272,0.1880188v0.1879578h0.1879883v0.375885h0.3759155v-0.1879272h0.1879272v-0.1879578h0.1879883
        v-1.1277771l-0.1879883-0.1879578l-0.1879272-0.1880188v-0.5638428l0.1879272-0.1879578h0.1879883l0.1879883-0.1879578h1.1277466
        l0.1879883-0.1879883l0.1879272-0.1879578h0.1879883v-0.1879578h-0.1879883l-0.1879272-0.9398193h0.1879272l0.1879883-0.1879578
        v-0.1879578h-0.1879883v-0.1879578l-0.1879272,0.1879578h-0.1879883l-0.1879272-0.1879578h-0.3759766v0.1879578
        l-0.1879272,0.1879578h-0.3759155v0.1879578h-0.1879883v-0.3759155l-0.1879883-0.1879578v-0.3759155h0.1879883l0.1879883-0.1879883
        h0.1879272v-0.1879578h0.1879883v-0.1879578h0.1879272l0.1879883-0.1879578h0.3759155v-0.1879578h0.1879883l0.1879272,0.1879578
        h0.1879883v0.1879578h0.1879272v0.1879578h0.3759766l0.1879272-0.1879578v-0.1879578l0.1879883-0.1879578l0.1879272-0.1879578
        l0.1879883-0.375946l0.1879272-0.1879578v-0.3759155l0.1879883-0.1879578l0.1879883-0.1879272h0.3759155l0.1879272,0.1879272
        l0.3759766-0.1879272h0.3759155v-0.1880188h0.3758545l0.1879883-0.1879578l0.3759766-0.1879578v-0.1879578h0.1879272
        l0.1879883,0.1879578v0.1879578h-0.1879883v0.1879578h0.1879883l0.1879272,0.1880188h0.1879272v0.1879272h0.1880493v-0.1879272
        v-0.3759766h0.1879272v-0.1879578l-0.1879272-0.1879578h-0.1880493v-0.1879272l-0.1879272-0.1879578v-0.3759766h0.1879272
        v-0.1879578l0.3759766-0.1879272l0.3759155-0.1879578l0.1879272-0.1879578h0.1879883l0.3759766-0.3759766v-0.5638428
        l0.1879272-0.1879578v-0.1880188l-0.1879272-0.1879272h0.1879272v-0.1879578h0.1879272v-0.1879578v-0.1879578h0.1879883v-0.1879578
        l0.1879272,0.1879578h0.1879883v-0.1879578h0.1879883l0.1879272,0.3759155l0.1879883,0.1879578h0.1879272v-0.3759155h0.1879883
        l0.1879272-0.1879578v-0.375946h0.1879883v0.1879883h0.3759155l0.1879883,0.1879578l0.1879272-0.1879578v0.3759155
        l0.1879883,0.1879578l0.1879272-0.1879578h0.1879883v-0.1879578h0.3759155v-0.1879578h0.7518921l0.1879272,0.1879578h0.1879883
        v0.1879578h0.3759155v-0.1879578h0.1879272l0.1879883,0.1879578h0.1879883l0.1879272,0.3759155v0.5639038l0.1879883,0.1879578
        l0.3758545,0.3759155v0.5639038h0.1880493l0.1879272,0.1879578l0.1879883-0.1879578l0.3758545-0.1880188l0.1879883-0.1879578
        v-0.1879272h0.1879272v-0.1879578h0.1880493v-0.1879578h0.1879272v-0.1879578l-0.1879272,0.1879578l-0.1880493-0.1879578h0.1880493
        v-0.375946l0.3758545-0.1879578v-0.3759155l0.5639038-0.5639038l0.7518311-0.1879578l0.7518921-0.5638733h1.6916504v0.3759155
        l0.1879272-0.1879578l0.7518921-0.3759155l1.1277466-0.1879883h0.3759155h0.1879272l0.3759766-0.3759155l0.7518311-0.1879578
        l0.5639038-0.3759155l0.5638428-0.7518616l1.3157349-0.7518616h0.9398193l0.1879883-0.1879578l0.5639038-0.5638733
        l0.5638428-0.375946l-0.1879272-0.1879578l-0.1879883-0.1879578l0.1879883-0.1879578h0.3759155v-0.1879272h0.7518311
        l0.1879883-0.1879578h0.1879272l0.7518921-0.5639343l0.1879272-0.1879272h0.5639038v-0.3759155h-0.1879272l0.1879272-0.1880188
        h0.1879883v-0.1879578h0.9398193l0.1879272-0.1879272v-0.3759155h0.1879883v-0.1879578l0.1879272-0.1880188v0.1880188
        l0.1879272,0.1879578h0.5639648v0.1879578h-0.1879883v0.375885l-0.1879272,0.3759766h-0.1880493l-0.1879272,0.1879578h0.1879272
        v0.1879578l0.1880493-0.1879578l0.3759155-0.1879578l0.1879272-0.1880188l0.3759155-0.1879578l0.1879272-0.375885v-0.5639343
        h0.1880493l0.1879272-0.1879272v-0.1879578h0.1879272v-0.1879578h0.3759155v0.1879578h0.1879883l0.1879883-0.1879578
        l0.9397583-0.3759155v-0.1879578v-0.1879883h0.1879883v-0.1879578h0.1879883l0.1879272,0.1879578v-0.3759155h0.1879883
        l0.1879272-0.1879578h0.1879883v-0.7518616h0.1879883v-0.1879578l0.1879272,0.1879578l0.3759155,0.1879578h0.3759155
        l0.1879883,0.1879883h0.1879883v0.1879578l0.1879272,0.1879578h0.1879883v0.1879578l0.3759155,0.3759155v0.1879883
        l0.3759155,0.3759155v0.3759155h0.1879883v0.1879578h-0.5639038l-0.1879883,0.1879272h0.1879883v0.1880188v0.5638733h0.1879272
        l0.1879883,0.1879272h-0.1879883l-0.1879272,0.1879578v0.1880188l-0.1879883,0.1879578v0.375885h-0.1879272v0.1879578
        l0.1879272,0.1879578h0.1879883v0.1880188l0.1879272,0.1879578v0.1879272h-0.1879272v-0.1879272h-0.1879883v-0.1879578h-0.1879272
        v0.1879578l-0.1879883,0.1879272l0.1879883,0.1879578l0.1879272,0.3759155v0.375946l0.1879883,0.5638733v0.1879578
        l-0.1879883,0.1879578l-1.3157349,0.7518616h-0.5638428l-0.1879883-0.1879578l-0.1879883,0.1879578l-0.1879272,0.1879578
        h-0.9398193l-0.1879883-0.1879578l-0.5638428-0.1879578l-0.1879883-0.1879578v-0.1879578l-0.1879883-0.375946l-0.5638428-0.1879578
        h-0.1879272l-0.3759766,0.3759155l-0.1879883,0.1879883h-0.3758545v-0.1879883h-1.1278076l-0.1879272,0.1879883v0.5638733
        l-0.1879883,0.1879578h-0.1879883v0.375946h0.1879883v0.1879578h0.7518311v0.1879578v0.1879578h-0.1879272l-0.1879883,0.1879578
        h-0.5639038v0.375946l0.1879883,0.3759155h0.1879883v-0.1879578h0.1879272v0.1879578h0.1879883l0.1879272,0.1879578
        l0.1879272-0.1879578l0.1880493-0.1879578h0.1879272v-0.1879578h0.1879883v-0.1879883h0.1879272v0.375946h0.1879272v0.5638733
        h0.1879883v0.1879578h-0.3759155v-0.1879578h-0.1879272v0.1879578l0.1879272,0.1879883v0.1879578h-0.1879272l-0.3759155-0.1879578
        l-0.1880493-0.1879883h-0.3758545v0.375946l0.1879272,0.1879578l-0.1879272,0.1879578v0.7518616l0.1879272,0.1879578
        l0.1879272,0.1879578l0.1880493-0.1879578h0.1879272v0.3759155l-0.1879272,0.1879272h0.1879272l-0.1879272,0.3759766h0.1879272
        l0.1879883,0.3759155h-0.1879883l-0.1879272,0.1879272v0.3759155l0.1879272,0.1880188l-0.1879272,0.7518005v0.3759766h0.3759155
        v0.1879272h-0.3759155l-0.1880493,0.1879578h0.1880493v0.1879578h0.3759155v0.1879578h-0.5639648v-0.1879578h-0.3758545v0.1879578
        h-1.3157349l-0.1879883-0.1879578h-0.1879272v-0.3759155h-0.1879883v-0.1879272h-0.7518311l-0.1879883-0.1880188v-0.1879578
        h-0.1879883v-0.1879578h-0.7518311l-0.1879272,0.1879578v0.1879578l-0.1879883,0.1880188v0.5638428h0.3759155l0.1879883,0.1879578
        h0.1879272v0.5639038h0.1879883l0.1879272,0.1879578h0.1879883l0.1879883,0.1879578v0.1879578h0.1879272v0.1879578
        l0.1879883,0.1879578v0.375946h0.1879272v0.1879578h0.1879883l0.3759155,0.1879578v0.3759155h-0.3759155v0.1879883h-0.1879883
        v0.1879578h-0.5638428v0.3759155h-0.3759766v-0.1879578l-0.1879272,0.1879578h-0.5639038v0.3759155h-0.1879272v0.375946h-0.3759766
        l-0.1879272,0.1879578h-0.1879883l-0.1879272,0.1879578h-0.1879883v0.1879578l-0.1879272,0.1879578h0.1879272v0.1879272h0.3759155
        l0.1879883,0.1880188h0.1879272v-0.1880188h0.3759766v0.3759766h0.3759155l0.3759155,0.3759155v0.375885l0.1879272,0.1880188
        l0.1879883,0.1879578l0.1879883-0.1879578h0.5638428v-0.1880188l0.3759766-0.1879578v0.5639343h0.1879272v0.1879578h0.5639038
        l0.1879272,0.1879272h0.1879883l0.1879883-0.1879272h0.1879272l0.1879883,0.1879272h0.1879272v0.1879578h-0.1879272
        l-0.1879883,0.1879578h-0.1879272v0.1880188h0.1879272l-0.1879272,0.375885V511.1351013z M700.6268921,495.722229
        l-0.3758545,0.1879578h-0.5639038l-0.1879883,0.1879578h-0.1879272l-0.3759155,0.1879578h-0.1879883l-0.1879272,0.1879883
        h-0.3759766v0.1879578h0.3759766l0.1879272-0.1879578h0.3759155l0.1879883-0.1879883h0.7518311v0.1879883h-0.1879272v0.1879578
        h0.1879272v0.1879578h0.1879883v0.3759155l0.1879272-0.1879578v0.3759155l0.1879272-0.1879578V495.722229z
        M726.0018311,490.6472778h0.7518311v-0.1880188l0.5639038-0.1879578v0.3759766h0.1879883l0.7518311-0.7518616
        l-1.1278076-3.5712891l-0.3759155-0.5639038l-0.1879272-0.5638733v-1.1277771l-0.3759155-0.5638428l-0.7518921-0.1880188
        h-0.7518311l-0.1879883,0.1880188l-0.3759155,0.1879578h-1.1277466l-0.5639038-0.1879578l-0.3759155,0.1879578l-1.3157349,0.375885
        l-0.1879272,0.3759155v0.1879578l0.1879272,0.1879883v0.7518311l0.1879883,0.375946l0.5639038-0.1879883v0.5639038
        l0.1879272,0.1879578l-0.1879272,0.7518616h1.1276855l0.1880493,0.1879578l0.1879272,0.1879578l-0.1879272,0.1879578
        l0.1879272,0.3759155l0.3759155,0.1879883l-0.3759155,0.5638733l-0.3759766,0.9398193l0.5639038,0.9398193h0.3759155v0.1879578
        l0.1879883-0.3759155l1.6916504-0.1880188L726.0018311,490.6472778z M771.1127319,460.0094299l-0.1879883-0.1879578v0.7518616
        l0.1879883-0.1879578V460.0094299z M759.4081421,449.5905762v0.1879578h0.1879883v-0.1879578H759.4081421z M702.2677002,482.295929
        v0.1879883h-0.1879883v0.1879578h0.5639038v-0.1879578l-0.1879883-0.1879883H702.2677002z M766.1747437,458.2368164v0.1879578
        h0.1879883L766.1747437,458.2368164z M759.2201538,452.2220154v0.1879578h0.1879883L759.2201538,452.2220154z
        M741.1758423,496.2051086v-0.1879578l-0.1879883,0.1879578H741.1758423z M761.8516235,450.3423767h-0.3759155v0.1879578
        l0.1879883,0.1880188h0.1879272V450.3423767z M760.3479614,450.906311v-0.1879578h-0.1879883v0.1879578H760.3479614z
        M762.6035156,491.506073l-0.1879883-0.1879578v0.3759155h0.1879883V491.506073z M785.5349121,473.0858154h0.1879272v-0.1879883
        h-0.1879272V473.0858154z M774.6330566,485.115387h0.1879272v-0.1879578h-0.1879272V485.115387z M756.7766724,484.3635254
        h-0.1879883v0.1879578h0.1879883V484.3635254z M737.416626,496.769043l0.75177-0.1879578h-0.75177V496.769043z
        M747.37854,464.4395447v0.1879578l-0.1879272,0.1879883l-0.1879272,0.1879578v0.1879578l-0.1879883,0.1879578h-0.1879883
        v0.1879578l0.1879883,0.1879578h0.9397583l0.1880493,0.1879883h-0.1880493v0.1879578h0.3759766v-0.1879578l-0.1879272-0.1879883
        v-0.1879578h0.1879272l0.1879272,0.1879578h0.5639038l0.1879883-0.1879578h-0.1879883l-0.1879883-0.1879578l-0.1879272-0.1879578
        h-0.5638428l-0.1880493-0.1879578l-0.1879272-0.1879578l0.1879272-0.1879883l0.1880493-0.1879578h-0.1880493v-0.1879578h-0.1879272
        v-0.1879578h-0.5638428l0.1879272,0.1879578L747.37854,464.4395447z M749.6340942,465.7552795h0.1879883v-0.1879578h0.1879272
        v-0.1879578h0.5639038v-0.1879578h0.1879883v-0.1879578h0.1879272l0.1879883-0.1879578l0.1879272-0.1879883v-0.3759155h0.1879883
        v-0.1879578h-0.1879883l-0.1879272-0.1879578h-0.5639038v0.1879578l-0.1879272,0.1879578h-0.1879883v0.1879578h-0.1879883
        v0.5639038l-0.1879272,0.1879578h-0.1879883l-0.1879272,0.1879578h-0.1879883l-0.1879272,0.1879578h0.3759155
        L749.6340942,465.7552795z M746.626709,465.7552795v-0.1879578h-0.1879883L746.626709,465.7552795z M770.8738403,461.6201172
        v-0.1879578h-0.1879883v0.1879578H770.8738403z M755.836853,445.2674255h-0.1879883v0.1880188h0.1879883V445.2674255z
        M785.5349121,472.7098694v0.1879578h-0.1880493v0.1879883H784.78302v0.1879578h-0.1879272l-0.1879883-0.1879578h-0.1879883
        v-0.375946h-0.3759155l-0.3759155-0.1879578v-0.5638733h-0.1879272l-0.1880493-0.375946h-1.1276855v-0.1879578h-0.3759766
        v0.1879578h-0.1879272l-0.1879883,0.1879578v0.1879883h-0.1879272v0.1879578h-0.3759766v0.1879578l-0.1879272,0.3759155
        l-0.1879883,0.1879578l-0.1879272,0.1879883l0.1879272,0.1879578v0.3759155l-0.3759155-0.1879578v-0.1879578l-0.1879272,0.1879578
        v0.1879578l-0.3759766,0.1879578l0.1879883,0.1879578v0.9398193l-0.1879883-0.1879578v0.3759155l-0.1879272,0.1879883h-0.1879883
        v0.1879578h-0.5639038l0.1879883,0.3759155h0.1879883v0.1879578l0.1879272,0.1879272h0.1879883l0.1879272-0.1879272
        l0.1879883-0.1879578h0.3759155v0.1879578h0.1879883v0.1879272h0.3759155v0.1879578l0.3759155,0.3759766v0.375885h0.3759155
        l0.1879883,0.1879578v0.1879578h0.3758545v-0.1879578h0.1880493v0.1879578l0.1879272,0.1880188h0.3759155v0.1879578h0.1879883
        v0.375885l-0.1879883,0.1879578l0.1879883,0.1879578v0.1880188h0.1879272l-0.1879272,0.1879578v0.1879272h-0.1879883
        l0.3759155,0.1879578v0.7518616h-0.9397583l0.5638428,1.1277771h0.1879883l0.1879272,0.1879578v0.5638733h-0.3759155
        l0.1879883,0.1879578v0.1879883l-0.1879883,0.1879578h-0.1879272l-0.1879883-0.1879578h-0.1879272l-0.1880493-0.1879883
        l-0.1879272-0.1879578v-0.3759155l-0.1879272-0.3759155h-0.1879883v-0.1879883l-0.1879272-0.1879578v-0.5638733h-0.1879883
        v0.3759155h-0.5639038v-0.1879578h-0.3759155l-0.1879272,0.1879578l-0.1879883,0.1879578v0.1879578l0.1879883,0.1879883v0.3759155
        h0.3759155l0.1879272,0.1879578h0.1879883v0.3759155h0.1879272l0.1879883,0.1879883l-0.1879883,0.3759155l-0.1879272,0.1879578
        h-0.3759155l-0.1879883-0.1879578l-0.3759155,0.5638733l-0.1879883,0.1879883h0.3759766v0.1879578h0.5638428l0.1879883,0.1879578
        v0.1879578h-0.1879883l-0.1879272-0.1879578l-0.1879883,0.1879578h-0.3759155v0.1879578h0.1879883l-0.1879883,0.1879578h-0.3759155
        l0.1879272,0.1879272v0.1880188h-0.5638428v0.3759155h-0.1879883v-0.1879578h-0.1879883v-0.1879578l-0.1879272-0.1880188
        l-0.3759155,0.1880188v-0.1880188h-0.1879883v-0.1879272l-0.1879272,0.1879272v0.1880188v0.1879578h0.1879272l0.1879883,0.1879578
        l0.1879272,0.1879578l0.1879883,0.1879272h-0.3759155v-0.1879272l-0.1879883,0.1879272h0.1879883l-0.1879883,0.1879578h-0.1879272
        v-0.1879578l-0.1879883-0.1879272l-0.3759155-0.1879578h-0.3759155v1.1277771h-0.1879883v0.1879272h0.1879883v0.1879578
        l0.1879272,0.1879578l0.1879883,0.1880188v0.1879578h-0.1879883l-0.3759155,0.375885h0.1879883l-0.1879883,0.3759155v0.1880188
        h-0.1879272v0.1879272h-0.3759766l-0.1879272-0.1879272l-0.1879883-0.9398193l-0.1879272-0.3759766l-0.1879272-0.7518005
        h-0.1880493v-0.1879578h0.7518921l-0.1879883-0.1880188l-0.1879272-0.1879578v-0.1879272l-0.1879272-0.5638733h-0.1880493
        l-0.1879272-0.1880188v0.1880188l-0.1879883-0.1880188v0.3759766l-0.1879272,0.3759155v0.1879272l-0.1879272-0.1879272h-0.3759766
        v0.1879272l-0.1879883,0.1879578v0.1880188h-0.1879272v0.1879578h-0.1879272l-0.1879883-0.1879578v-0.1880188h-0.1879272
        v-0.1879578l-0.1879883-0.1879272l-0.1879883-0.1879578v-0.3759155l-0.1879272-0.1880188h0.7518311v-0.1879272
        l-0.1879272-0.1879578l-0.3759766-0.1879578l-0.1879272-0.1879578v-0.1879578h-0.1879883l-0.1879272-0.1879578v0.1879578
        h-0.3759155l-0.5639038-0.5639038l-0.1879883-0.3759155l0.1879883-0.1879578l0.1879272-0.1879578v-0.375946l0.1879883-0.1879578
        v0.1879578h0.3759155l0.1879883-0.1879578h0.1879272l-0.1879272-0.1879578h-0.3759155v-0.1879578h-0.1879883l0.1879883-0.1879578
        v-0.7518616l0.1879272-0.1879578v-0.5639038h-0.1879272v0.1880188l-0.3759766,0.1879272l-0.3759155-0.1879272h-0.1879272
        l-0.3759766-0.1880188h-0.3759155l-0.1879272-0.1879578h-0.1879883l-0.3759155-0.3759155v-0.1879272l0.3759155-0.1879578
        l-0.1879272-0.1880188v0.1880188h-0.1879883v0.1879578h-0.5639038v0.1879272h-0.1879272l-0.1879883-0.1879272v-0.0560913
        l-0.1370239,0.1370544h-0.1879272v-0.3759155v-0.1879883h0.1879272v-0.1879578h0.3250122v-0.2689209h0.1879272v-0.1879578
        l0.1879883-0.1879578h-0.1879883v0.1879578h-0.3759155v-0.3759766l0.1879883-0.1879578h-0.3759155v-0.1879578l0.3759155-0.1879272
        h0.3759155v0.1879272l0.1879272-0.1879272v-0.1879578h-0.9397583v-0.1879578l-0.3759766,0.1879578l-0.1879272,0.1879578h-0.7518921
        l-0.1879272,0.1879272v0.1879578l0.1879272,0.1879578h-0.1879272v0.1880188l-0.1879883-0.1880188v0.5639343h0.1879883
        l0.1879272,0.1879272v0.1879578h-0.75177v0.1879578h-0.5639648l0.1879883-0.1879578l-0.1879883-0.1879578h-0.1879272
        l-0.1879272-0.1879272v0.375885h-0.1879883l-0.1879272,0.1879578h-0.1880493l-0.1879272,0.1880188v-0.1880188l0.1879272-0.1879578
        l0.1880493-0.1879578h-0.1880493v-0.1879272l-0.1879272-0.1879578h-0.3759155v-0.3759766h0.1879272l-0.1879272-0.1879578
        l-0.1879272,0.1879578v0.3759766h-0.3759155l-0.3759766-0.3759766l-0.1879272-0.3759155l-0.1879883-0.375885l-0.1879272-0.1879578
        h-0.1879883l-0.1879883-0.1880188h-0.5638428l-0.3759155,0.3759766v0.1879578h-0.1879883v-0.1879578l-0.1879883-0.1879578
        h-0.1879272l-0.1879883,0.1879578l-0.1879272,0.5638428l-0.1879883,0.3759766v0.3759155l0.1879883,0.375885l0.1879272,0.1879578
        v0.1880188h-0.3759155l-0.1879272,0.375885l-0.1879883,0.1879578l-0.1879883,0.1879578l-0.1879272,0.1879578h-0.1879883
        l-0.1879272,0.1880188h0.1879272l0.1879883,0.1879272l0.1879272-0.1879272h0.1879883h0.1879883l-0.1879883,0.375885
        l0.1879883,0.1879578l-0.1879883,0.3759155l-0.5639038,0.5639038l0.1879883,0.5638733l0.1879272,0.375946v0.3759155h0.1879883
        l0.1879883,0.1879578v0.3759155h0.3759155l0.1879272,0.1879883l0.1879883,0.1879578v0.1879578h-0.1879883v0.7518005h-0.3759155
        v0.7518921h-0.1879272l-0.1879883-0.1879578l-0.3759155-0.7518616l-0.1879883-0.1879578l0.1879883-0.1879578v-0.3759155
        l-0.1879883,0.1879578l-0.1879272,0.1879578h-0.7518921v0.1879578h-0.1879272v-0.7518311h0.3759155l0.1879272-0.1879883
        l0.3759766-0.5638733v-0.3759155l-0.5639038-0.9398193h-0.3759155l-0.1879883-0.1879578v-0.1879883h-0.3759155l0.1879883,0.1879883
        v0.1879578l0.1879272,0.1879578h-0.3759155v0.3759155h-0.1879883l-0.1879272-0.1879578h-0.1879883l0.1879883,0.1879578v0.375946
        l-0.1879883,0.1879578l-0.3758545,0.3759155l-0.1880493,0.3759155l0.3759766,0.5639038l-0.1879272,0.1879578h-0.3759766
        l-0.3759155,0.1879578l-0.1879272,0.1879578h-0.1879883v0.1879272l-0.1879272,0.1880188v0.1879578h0.3759155v-0.1879578h0.5638428
        v-0.1880188h0.3759766v0.1880188h0.1879272l0.1879272-0.1880188v-0.1879272l0.3759155,0.1879272h0.1879883l0.3759155,0.3759766
        v0.5638428l0.3759155-0.1879272h0.3759155v0.1879272h0.1879883l-0.3759155,0.3759766l-0.1879883,0.3759155l-0.1879272,0.375885
        l0.1879272,0.7518616l0.1879883,0.3759155l0.3759155,0.1879578v0.1880188h0.1879883v0.375885l0.1879272,0.1879578
        l-0.1879272,0.1879578v0.1879578l0.1879272,0.1879578l0.1879883,0.1879883v1.5036926l0.7518311,0.1879578h0.1879883
        l0.1879272,0.3759155l0.1879883,0.1879883l0.5639038-0.375946h0.3759155l0.1879272-0.1879578h0.1879883v0.1879578
        l0.1879272,0.1879578h0.5639038v0.1879883l0.1879883,0.1879578v0.1879578l0.1879272,0.1879578h-0.1879272v0.3759155
        l0.1879272,0.1879883h0.3759766v0.1879578l-0.1880493,0.1879578h0.1880493v0.3759155l-0.1880493,0.1879578l-0.1879272,0.1879272
        v0.1880188h0.5639038l-0.1879272,0.3759155v0.1879578h0.5638428v-0.3759155l-0.1879272-0.1879578v-0.1880188l0.75177-0.5638428
        l0.1880493,0.1879578h0.3759155l0.1879272-0.1879578h0.1879272v0.1879578h0.1879883v0.1879578l-0.1879883,0.1879272
        l0.1879883,0.1880188l0.3759766,0.1879578l0.3758545,0.3759155l0.1879883,0.1879272h0.1879272v0.1879578l0.1880493,0.1880188
        h-0.1880493l0.1880493,0.1879578l0.1879272,0.1879578v0.1879272l-0.1879272,0.1879578h-0.3759766v0.1879578h0.1879272v0.5639038
        l-0.1879272,0.1879578h-0.5638428l-0.1879883,0.1879578l-0.3759766,0.3759766l-0.1879272,0.1879272l-0.1879272,0.1879578v0.1879578
        h-0.5639648l-0.1879272-0.1879578h-0.5638428l-0.1879883-0.1879578l0.1879883-0.1879272v-0.1880188h-0.7518921v0.1880188
        h-0.1879272l-0.1879883,0.1879272l-0.1879272,0.1879578v0.1879578l0.1879272,0.1879578h0.3759155v0.1879578l-0.1879272,0.1879578
        h-0.1879883l-0.1879272,0.1879883v0.5638733l0.1879272,0.1879578h0.5638428l0.1880493,0.1879578h0.1879272v-0.1879578h0.3759155
        v0.375946l-0.1879272,0.1879578h-0.1879883l-0.1879272,0.1879578v0.3759155l0.1879272,0.1879578h0.1879883v-0.7518311h0.1879272
        l0.1879272-0.1879578l0.1879883-0.1879883l0.1879272-0.1879578v-0.1879578h0.5639648l0.1879272,0.1879578h-0.1879272
        l-0.3759155,0.1879578v0.375946l0.1879272,0.3759155l0.1879883,0.1879578v0.375946l0.5638428,0.3759155h0.3759766v0.9398193
        h-0.1879883v0.1879578h-0.3759766l-0.1879272-0.1879578l-0.3759155-0.1879578l-0.1879272,0.1879578l0.1879272,0.5638733v0.1879578
        l-0.1879272,0.1879272h-0.5639648l-0.1879272-0.1879272h0.1879272v-0.1879578l-0.3758545-0.5638733l-0.7518921-0.375946
        l-0.1879272-0.1879578v-0.3759155l-0.1879883-0.1879578l-0.1879272,0.1879578l-0.1879883-0.1879578h-0.7518311
        l-0.1879883-0.1879578v0.1879578h-0.1879272l0.1879272,0.1879578v0.3759155h-0.1879272l-0.1879883,0.1879578l-0.1879883,0.1879883
        l-0.1879272,0.3759155h-0.1879883v0.3759155h-0.7518311v-0.1879578l-0.3759155-0.3759155l-0.5639038-0.9398193h-0.1879883
        l-0.1879272-0.3759155l0.5639038-0.1879883l-0.1879883-0.1879578v-0.1879578h-0.1879883l-0.1879272,0.1879578h-0.3759155
        v-0.1879578l-0.1879883-0.1879578v-0.5639038h0.1879883l0.3759155-0.3759155v-0.1879578l-0.1879883-0.1879578l0.9398193-1.3157349
        v-0.5639038h-0.7518311v-0.7518005l-0.3759155-0.1880188h-0.1879883v-0.3759155l-0.1879272-0.1879272l0.1879272-0.3759155
        l0.5639038-0.9398193h-0.1879883v-0.3759766l-0.1879272-0.1879272l-0.1879883-0.1879578v0.375885l0.1879883,0.3759766v0.1879578
        l-0.3759155,0.5638428v0.1880188h-0.5639038v-0.1880188h-0.1879883v0.1880188h-0.7518311v-0.1880188l-0.1879883,0.1880188
        l0.1879883,0.3759155h-0.1879883v0.375885h-0.3758545v0.1879578l-0.1879883,0.1880188h-0.1879272v0.1879578h-0.1880493v0.1879272
        l-0.1879272,0.1879578h-0.1879272v0.1879578l-0.1879883,0.1879578l-0.3759155,0.375946l-0.1879883,0.1879578l-0.3759155,0.3759155
        h-0.7518311l-0.5639038-0.3759155l0.1879883-0.375885l0.1879272-0.3759766v-0.1879578l-0.3759155,0.1879578l-0.5639038-0.3759155
        l-0.1879272-0.375885l0.7518311-0.3759766l-0.1879272-0.375885l-0.3759766,0.1879272h-0.5638428l-0.3759155-0.1879272
        l-0.3759766-0.3759155l-0.3759155-0.3759766l-1.1277466-1.3157043l-1.3157349-1.3157349l-0.3759155,0.5639038l-0.7518921-0.5639038
        l-0.1879272,0.1879578l1.1278076,0.9398193l0.1879272,0.1879578v-0.1879578l0.3759155,0.3759155v0.375946l0.5639038,0.3759155
        l-0.3759766,0.375885l-3.9471436-3.5712585h-0.1879883l-0.5639038,1.1277771l-0.1879272,0.1879578v0.7518616l0.3759155,0.3759155
        l-0.1879883,0.9398193l0.1879883,0.1879272l-0.3759155,0.1879578l-1.5037231,0.5639343h-0.1879272v0.1879272l-0.1879883-0.1879272
        h-0.1879272l-0.7518921,0.5638428l-0.3759155,0.3759766v0.1879272h-0.5639038l-0.5638428,0.1879578l-0.1879883-0.1879578
        h-0.1879883v-0.375885v-0.1880188l-0.1879272-0.5638428v-0.3759155h-0.7518921l-0.75177,0.1879578h-0.1879883v0.1879578
        l-0.1879272,0.1879272h-0.5639038l-0.1879883-0.1879272v-0.5639343l0.1879883-0.1879578h0.3758545l0.3759766-0.375885
        l0.1879883-0.5639343v-1.8795776h-0.9398193v-0.7518616l-0.1879883-0.3759155l-0.3759155,0.1879578l0.1879883-0.3759155
        l-0.9398193-0.5639038l-0.7518921-0.5638733l-0.3759155,0.3759155l-0.3759155-0.1879578v0.3759155l0.5639038,0.1879578
        l-0.5639038,0.375946l0.3759155,0.3759155l0.5639038-0.5638733l0.1879883,0.1879578l0.1879272,0.3759155l-0.3759155,0.1879578
        l-0.1879883,0.375946v0.3759155l-0.3759155,0.3759155l-0.5639038-0.5638733h-0.5638428v-0.1879578l-0.7518921,0.1879578
        l0.1879883,0.7518311l-0.1879883-0.5638733l-0.5638428-0.1879578h-0.1879883l-1.6916504-0.7518616v-0.1879578l0.1879272-0.1879578
        l2.6315308-0.7518616l-0.1879883-0.5638733v-0.1879883l-1.5037231,0.5639038l-0.5638428-1.5036926l1.1277466-0.375946
        l-0.1879272-0.3759155l-1.1277466,0.1879578l-0.7518921-2.0675964l-0.7518921,0.1880188l-0.3759155-0.9398193l0.1879883-0.1879578
        v0.3759155h0.1879272l0.1879883-0.5639343l-0.1879883-0.1879272v-0.1879578l-0.5638428-0.7518311l-0.1879272,0.1879578
        l-0.1879883-0.5639038l0.1879883-0.5638733l-0.1879883-0.375946h-0.1879883l-0.1879883-0.1879578l-0.1879272-0.1879578v-0.1879578
        l-0.5638428-0.5639038h-0.1880493l-1.1277466,0.7518616h-0.7518311l-0.1879272,0.1879578h-1.8796387l-0.5639038-0.3759155
        h-0.5639038v0.3759155l-0.1879272,0.1879578l-0.1879883-0.1879578h-0.7518311v0.1879578l-0.1879883,0.1879883h-0.1879272
        l-0.1879272,0.1879578l0.1879272,0.1879578h-0.5639038l-0.1879883,0.1879578v-0.1879578h-0.75177v-0.1879578l-0.1880493-0.1879578
        h-0.5638428l-0.1879272-0.1879883l-0.3759766,0.1879883l-0.5638428,0.3759155l-0.3759155,0.3759155h-0.1879883
        l-0.3759155,0.1879578h-0.3759155l-0.1879883,0.1879883v-0.1879883h-0.5639038l-0.5638428,0.1879883l-0.3759766,0.1879578
        v0.1879578h-0.5638428l-0.5639038,0.3759155h-0.3759155l-0.3759155,0.1879578h-1.1278076l-0.5639038-0.1879578
        l-0.5638428-0.1879578l-0.3759155-0.3759155h0.1879883l-0.1879883-0.375946l-0.5639038-0.3759155h-0.3759155l0.1879883,0.1879578
        h-0.5639648v-0.5638733h-0.1879272l-0.1879272-0.1879578l-0.3759155,0.1879578l-0.3759766,0.1879578l-0.1879272,0.1879578
        l-0.5639038,0.1879578h-0.5639038l-0.1879272-0.5638733l-0.3759155-0.1879578l-0.1879883,0.1879578h-0.1879272
        l-0.1879883-0.1879578h-0.3759155l0.1879272-0.1879883v-0.3759155l0.3759766-0.1879578l-0.3759766-0.5639038l-0.5638428,0.1879883
        v0.1879578l-0.1879883-0.1879578l-0.3759155-0.375946v-0.1879578l-0.3759155-0.1879578l-0.3759155-0.1879578h-0.1879883v-0.1879578
        h-0.3759155l0.1879272-0.1879272v-0.1880188l-0.1879272-0.1879578h-0.3759155l-0.1879272-0.3759155l-1.5037842,0.5638733
        h-0.3758545l0.7518311,1.3157349l0.1879883,0.1879883h-0.3759155l-1.1278076-0.1879883l-0.1879883-0.1879578h-0.5638428
        l-0.1879883,0.1879578v-0.1879578h-0.9398193l-0.1879272,0.1879578v0.1879883h-0.1879883l-0.1879883,0.1879578h-0.1879272
        v0.1879578h-0.1879883l0.1879883,0.1879578l-0.5639038,0.3759155l-0.5639038,0.375946l0.3759766,0.5638733l-0.1879883,0.375946
        h-0.1879883v-0.5639038h-0.1879272l-0.1879883,0.3759155l-0.5638428-0.1879578l0.1879272-0.3759155l-0.1879272-0.1879578
        l-0.1879883,0.3759155l-0.5639038-0.1879578l0.1879883-0.3759155l-0.3759155-0.1879883l-0.3759766,0.9398193l0.5639038,0.375946
        l-0.1879272,0.1879578l-0.3759766-0.375946l-0.3759155-0.1879578l-0.9398193-0.5638733l-1.6915894-1.3157349l-0.9398193-0.7518616
        v-0.3759155l0.3758545-0.375946v-0.1879578l0.1880493,0.1879578l0.1879272-0.1879578l0.1879883-0.3759155l0.1879272-0.7518616
        v-0.5638428l-0.1879272-0.1879578l-0.1879883-0.3759766h-0.1879272l-0.1880493-0.1879578h-0.1879272l-0.1879272-0.1879272
        l-0.1879883-0.5639343h-0.1879272v-0.1879578l0.3759155-0.1879272v-0.5638733l-0.1879883-0.1879578l-0.3759155-0.375946
        l-0.1879883,0.1879578l-0.3759155-0.5638733l-0.3759155,0.1879578l-1.3157349-0.3759155l0.1879883-0.1879578l0.1879272-0.375946
        l0.1879883-0.3759155h0.1879272l1.1278076-0.9398193l0.1879272-0.1879578l0.3759766-0.5639038h0.1879272l-0.1879272-0.3759155
        h0.1879272l0.3759155-0.1879578h0.3759766l0.75177-0.3759155h0.9398193l0.1879883,0.1879578h0.1879272v0.3759155
        l0.1879883-0.3759155v-0.1879578l1.5037231-0.5639038l0.3759155-0.1879578l0.3759155-0.1879578l0.1879883-0.1879272
        l0.3759155-0.1879578l0.5638428-0.3759766l0.1879883-0.5638428v-0.9398193h0.1879883l0.1879272-0.1879578l0.3759155-0.1879578
        l0.3759155-0.375946l0.1879883-0.1879578v-0.5638733l0.1879883-0.1879578h0.1879272v-0.1879883l0.3759155-0.1879578h0.3759766
        l0.1879272-0.1879578h0.1879883l0.1879272-0.1879578l0.1879883-0.1879578v0.1879578h0.1879272v-0.1879578l0.1879883,0.1879578
        v-0.1879578l0.3759155-0.1879578h0.5638428l0.1880493-0.1879883h0.3759155l0.1879272-0.1879578l0.5638428-0.3759155
        l0.1880493-0.1879578l0.1879272-0.1879578l0.1879272-0.1879883v-0.5638733l0.1879883-0.1879578v-0.1879578l0.3759155-0.5639038
        l-0.1879883-0.1879578h0.1879883l0.1879883-0.1879578h0.1879272l0.1879883-0.1879578v-0.375946l0.3759155-0.1879578h0.1879272
        l0.5639038-0.3759155v-0.1879272l0.1879883-0.1879578h0.3759155v-0.5639343l0.1879883-0.1879272l0.1879272-0.3759155v-0.3759766
        l0.1879883-0.1879272v-0.1879578h0.1879272v-0.1879578l0.1879883-0.1879578v-0.1880188h0.9398193l0.3759155,0.1880188
        l0.1879272,0.1879578h0.7518921v0.1879578h0.5639038v0.5638428l-0.1880493,1.1277771l-0.1879272,0.3759766l0.3759766,0.375885
        l0.1879272,0.3759155l0.3759155-0.3759155l0.5639038-0.5638428l0.3759155-0.1880188l0.9398193-0.3759155l0.1879272,0.1879578
        v0.3759766l0.1879883-0.1880188v-0.1879578h0.1879272v-0.1879578h0.1879883l0.1879272,0.1879578v0.1879578h0.3759766v-0.5638428
        h0.3759155l0.1879272,0.1879272h0.1879883v-0.1879272h-0.1879883l-0.1879272-0.1879578v-0.1879578l-0.1879883-0.9398193
        l0.3759155-0.9398193l0.3759766-0.7518616l-0.1879883-0.1879578v-0.3759155l0.5639038-0.3759155l0.1879272-0.1879883h0.1879883
        l0.3759155-0.1879578h0.3759155l0.1879883-0.1879578h0.3759155l0.3759155,0.1879578l0.1879883,0.1879578h0.9398193
        l0.1879272-0.1879578l0.3759155-0.1879578l0.1879883,0.1879578v-0.1879578l0.3759155-0.3759155v-1.1277771h0.1879883
        l0.1879272-0.375946l0.1879883-0.1879578l0.5639038-0.3759155h0.1879272l0.1879883-0.1879578h0.75177l0.3759766-0.1879578
        h0.3759155v-0.1879272h0.1879272l0.1879883-0.1880188v-0.1879578l0.5639038-1.6916199l0.1879272-0.1879578L720.5,449.4025574
        l0.9398193-0.1879578h0.1879272v-0.1879272h0.1879883v-0.1879578l0.3759155-0.3759155l0.1879883-0.1879578h0.9398193v0.1879578
        l0.1879272,0.1879578h0.1879883l0.1879272,0.1879578h0.1879883l0.3759155-0.1879578h1.1278076l0.3759155,0.1879578h0.1879272
        v0.375885h0.1879272v0.1879578l0.1879883,0.1880188h0.5639038l0.3759155-0.1880188l0.1879272-0.1879578h0.1879883v-0.375885
        l0.1879883-0.1879578h0.5638428v-0.1879578h0.1879883v-0.1879578l0.1879272-0.1879578l0.1880493-0.1879578l0.3758545-0.1879883
        l0.1879883-0.1879578h0.1879272v0.1879578h0.1879883v-0.1879578h0.3759155v-0.1879578l0.1879883-0.1879578h0.9398193
        l0.1879272-0.1879578h0.1879883v-0.1879578h0.3759155v0.1879578h0.5639038v0.1879578l0.1879272,0.1879578v-0.1879578h0.3759155
        v0.1879578h0.1879883l0.1879883-0.1879578h0.3759155v-0.5638733h0.1879272l0.1879883-0.1879883v-0.1879578h0.3759155v-0.1879578
        l-0.1879883-0.1879578h-0.1879272l0.1879272,0.1879578h-0.3759155v-0.3759155h0.1879883l-0.1879883-0.1879272l0.1879883-0.1880188
        h0.1879272l0.1879883-0.1879578l-0.1879883-0.1879578l0.1879883-0.375885h0.7518311l0.1879272-0.1879578v-0.1880188
        l0.1880493-0.1879578l0.1879272-0.1879578v-0.1879272h0.1879883v-0.1879578l0.1879272-0.1879578h0.3759155v0.3759155h0.7518311
        v-0.1879578h0.1879883v-0.1879578h0.3759155v0.1879578h0.1879883v0.1879578h0.9397583l0.1879883-0.1879578v0.1879578h0.3759155
        v0.7518616l0.1879883,0.1879578h0.1879272l0.3759766,0.1879272v-0.1879272l0.1879272-0.1879578l0.1879883,0.1879578h0.7518311
        l0.1879883-0.1879578l0.1879272,0.1879578v0.1879272h0.1879883l0.1879272-0.1879272l0.1879883-0.1879578h0.9398193v-0.1880188
        h0.7518311v-0.1879578h0.1879883v0.1879578h0.1879272v0.1880188h0.1879272l0.3759766-0.1880188h0.9397583l0.1879883,0.1880188
        l0.1879883-0.1880188l0.1879883,0.1880188l0.1879272,0.1879578h0.3759155l0.1879272-0.1879578v0.1879578h0.1880493v-0.1879578
        h0.3758545l0.1879883,0.1879578v0.1879272h0.1879272v0.3759155h0.1879883l0.1879883,0.1879578h0.3759155v0.1880188
        l0.1879272,0.1879272l0.1879883,0.1879578h0.1879272v0.1879578l-0.1879272,0.1879578l-0.1879883,0.1879578l0.1879883,0.375946
        l-0.1879883,0.1879578l-0.1879272-0.1879578h0.1879272l-0.1879272-0.1879578h-0.5639038l-0.1879883,0.1879578h-0.1879272
        v-0.1879578h-0.1879883v0.1879578v0.1879578h-0.1879272v0.1879578h-0.1879272l-0.1880493,0.1879578v0.1879578h-0.1879272
        l0.1879272,0.1879578h-0.1879272v0.5639038H747.37854l-0.1879272,0.1879578l-0.1879272,0.1879578h-0.1879883v-0.1879578h-0.1879883
        v-0.1879578l-0.1879883,0.1879578v0.1879578h-0.1879272v0.3759155l0.1879272,0.1879272v0.1879578h-0.1879272v1.1277771
        l0.3759155,0.1880188v-0.1880188h-0.1879883v-0.1879578h0.5079346l0.1069336-0.1069641v0.1069641h0.5129395v-0.1879578h0.1879272
        v-0.1879272h0.1880493v0.1879272l-0.1880493,0.1879578v0.3759766l0.7518921-0.3759766v-0.375885h0.1879272v-0.1879578h0.1879883
        v-0.3759766h0.1879883l0.1879272,0.1880188l0.1879883,0.1879578h0.1879272l0.1879883-0.1879578l0.1879272-0.1880188h0.3759766
        v-0.7518005h0.1879272v0.1879578h0.1879883l0.1879272,0.1879578l0.1879883,0.1879272l0.5639038-0.5638428h0.1879272v-0.1879578
        h0.1879883v-0.3759155l0.1879272-0.1879578v-0.5639038l0.3759766-0.3759155l0.1879272-0.1879578v-0.375946h-0.1879272
        l0.3759155-0.5638733v-0.375885h0.1879272v0.5638428h0.1879883v0.3759155l0.1879272-0.1879578v-0.1879578l-0.1879272-0.1879578
        v-0.1879578h0.5639038v0.5638733l0.3759155,0.375946l0.1879272-0.1879883h0.1879272v-0.3759155h0.1880493v-0.1879578
        l0.1879272,0.1879578l0.1879883-0.1879578v0.3759155l-0.1879883,0.1879578v0.1879883h0.1879883v-0.1879883h0.1879272v-0.1879578
        l0.1879272-0.1879578v-0.3759155h0.3759155v0.1879578h0.3759766l0.1879272-0.1879578h0.1879883v0.1879578l-0.1879883,0.1879578
        h-0.1879272v0.1879578h-0.3759766v0.1879578l-0.1879272,0.1879883h0.5639038v0.1879578h-0.1879272v0.5638733l-0.1880493,0.1879578
        h-0.1879272v0.1879578l0.1879272,0.1879883h0.1880493l0.3758545-0.375946l0.1879883-0.1879578h0.1879272l0.1879883,0.1879578
        l0.1879883,0.1879578v0.1879883h-0.1879883v0.3759155h-0.1879883v0.3759155h-0.1879272l0.1879272,0.1879578l0.1879883-0.1879578
        v-0.1879578h0.1879883v-0.1879578l0.3759155-0.1879578h0.1879272l0.1879883,0.1879578v0.5638733h-0.7518311v0.375885
        l-0.1879883,0.1879578v0.5639343h-0.1879883v0.375885l0.1879883,0.1879578l0.1879883-0.1879578h0.1879272l0.3759155-0.1879578
        h0.3759155l0.1879883,0.1879578h0.3759155v0.1879578h0.1879883v-0.1879578h0.1879272v0.1879578h0.1879883v-0.3759155h0.3759155
        v-0.1879272l-0.1879272-0.1879578h1.1277466v-0.1879578l0.1879883,0.1879578l-0.1879883,0.1879578h-0.1879883v0.375885h-0.1879272
        v0.1879578h-0.1879883v0.1880188l-0.1879272,0.1879578h0.1879272v0.1879272h0.1879883v-0.1879272h0.1879272v0.1879272h0.1879883
        v-0.1879272l0.3759155-0.1879578v-0.1880188h0.1879883v0.1880188h0.3759155l-0.1879883,0.1879578v0.1879272l-0.1879272,0.1879578
        h-0.1879883l-0.1879272,0.1879578v0.1879578l0.1879272,0.1880188h-0.5639038v0.5638428h-0.1879272v0.3759155h-0.1879883
        l-0.1879272-0.1879578v-0.1879578h-0.1879883l0.1879883-0.1879578h-0.5639038v-0.1879578h-0.3759155v0.1879578l0.1879272,0.1879578
        h-0.1879272v0.5638733h0.1879272v-0.1879578h0.3759766v0.1879578h-0.1879883v0.1879883h0.1879883v0.1879578h-0.3759766
        l0.1879883,0.1879578l0.1879883,0.1879578h-0.3759766v-0.1879578h-0.1879272v0.3759155h0.1879272h0.5639038v-0.3759155h0.1879883
        l0.1879272,0.1879578v-0.1879578l0.1879883-0.1879578l0.1879272,0.1879578v-0.1879578h0.7518921v-0.1879578h0.1879272v0.1879578
        h0.1879883v0.1879578h-0.1879883v0.1879578l-0.1879272-0.1879578h-0.3759155l0.1879272,0.1879578h0.1879883v0.1879578h0.1879272
        l0.1879883-0.1879578h0.5639038v0.1879578h-0.1879883l-0.1879883,0.1879578v0.375946l0.1879883-0.1879578h0.1879883h0.1879272
        v0.1879578h0.3759155v0.1879578h0.1879272v-0.3759155h-0.3758545v-0.1879883h0.1879272l-0.1879272-0.1879578l0.1879272-0.1879578
        h0.1879272v0.1879578h0.1880493v-0.1879578h0.1879272v0.5639038l-0.1879272,0.1879578v0.1879578h0.1879272v-0.1879578h0.3759155
        l0.1879272,0.1879578v0.3759155h-0.1879272v0.1879578l0.1879272,0.1879883l0.1879883-0.1879883v-0.1879578h0.2388306v-0.1069946
        h-0.1879272l0.1879272-0.375946l-0.1879272,0.1879883h-0.1879272v-0.1879883l0.1879272-0.1879578l0.1879272-0.1879578
        l-0.1879272-0.1879578l-0.3759155-0.1879578v-0.1879272h0.1879883v-0.1880188h0.1879272h0.1879272v0.1880188h0.1879883
        l0.1879883,0.1879272v-0.1879272l-0.1879883-0.1880188h0.1879883v-0.1879578h0.3759155l-0.1879272,0.1879578v0.1880188h0.1879272
        v0.1879272h0.3759155v0.1879578l0.1879272-0.1879578h0.1880493l-0.1880493-0.1879272h-0.1879272v-0.1880188v-0.1879578
        l0.3759766,0.3759766l0.1879272-0.1880188l0.1879272-0.1879578h-0.1879272h-0.1879272v-0.1879578h0.3758545v-0.1879578v-0.1879272
        h0.3759155v-0.1879578v-0.1879578h0.1879883v-0.1880188l-0.1879883-0.1879578v-0.1879272v-0.1879578v-0.3759155v-0.1880188
        h-0.1879272v-0.1879272v-0.1879578h0.1879272v0.1879578h0.3759766v0.1879272h0.3759155l0.1879272,0.1880188h0.1879883v0.1879578
        l0.1879272,0.1879578v0.1879578l0.1879883,0.1879272h-0.1879883v0.1879578h0.1879883v0.1880188h-0.1879883v0.1879578h0.1879883
        v-0.1879578h0.1879883l0.1879272-0.1880188v0.1880188h0.3759155v0.1879578l0.1879883,0.1879578h-0.3759155l-0.1879883,0.1879272
        v0.3759155h-0.3759155v0.1879578l-0.1879883,0.1880188h-0.3759155l-0.1879272,0.1879272h-0.3759155l-0.1879883,0.5638733
        h-0.3759155v-0.1879578h-0.1879883v-0.1879578h-0.5639038v0.1879578h-0.1879272v0.1879578h0.1879272l-0.1879272,0.1879578
        h-0.1879883v0.1879883l-0.3758545,0.1879578h-0.1879883l-0.1879883,0.1879578h-0.0508423v0.1069946h0.3759155l0.1879272,0.375946
        v0.1879578h0.1879272v0.1879578h0.1879883v-0.1879578h-0.1879883v-0.1879578h0.1879883v-0.1879578h0.3759766v-0.1879883h0.5638428
        v-0.1879578h0.7518311v-0.1879578l0.1879883-0.1879578h0.3759155v0.1879578l0.1879883-0.1879578h0.1879272v0.1879578h0.3759155
        v-0.1879578l0.3759155-0.1879578h0.1879883v-0.1879578h0.9398193l0.1879272-0.375946l0.3759766-0.1879578v-0.1879578h0.3759155
        l0.1879272-0.1879578l0.1879883-0.1879578v0.1879578l0.1879883,0.1879578h0.1879272l0.1879883,0.1879578v0.1879578
        l-0.1879883,0.1879578h-0.1879272v0.1879883h-0.1879883v0.1879578l-0.5639038,0.3759155h-0.3759155l-0.1879883,0.1879578v0.1879578
        l-0.1879272,0.1879883h-0.3759155l-0.1879883,0.1879578v0.1879578l-0.1879272,0.1879578v0.1879578h-0.3759766v0.375946h-0.3759155
        v0.1879578l-0.1879272,0.1879578v0.1879578h-0.3759155v0.1879578h-0.1879883v0.1879578l-0.1879883,0.1879272h-0.1879272v0.1880188
        h-0.1879883l-0.1879272,0.1879578v-0.1879578h-0.1879272v-0.1880188h0.1879272v-0.1879272h-0.1879272l-0.1880493,0.1879272
        v0.1880188h-0.3759155v0.1879578h-0.3758545v0.1879578h-0.1879883v0.1879578h0.5638428l-0.1879272,0.1879272v0.1879578h-0.1879272
        l-0.3759766,0.3759766l-0.3759155,0.1879578h-0.3759155v0.1879272h-0.1879272l-0.1880493,0.1879578l-0.1879272-0.1879578
        l-0.1879883,0.1879578h-0.3758545v0.1879578l-0.1879883,0.1880188l-0.1879272,0.1879578l-0.1880493,0.1879272h-0.3758545v0.1879578
        h-0.1879883v-0.1879578h-0.1879272v0.1879578h-0.1879883l-0.3759155,0.1879578l-0.3759155,0.1879578l-0.3759155,0.1880188
        h-0.1879883v0.1879272h-0.5639038v-0.1879272h-0.9398193v0.1879272l-0.1879272,0.1879578l-0.1879883,0.1879578h-0.1879272
        v0.1879578l-0.1879883-0.1879578v-0.1879578h-0.1879883l-0.1879272,0.1879578h0.1879272v0.1879578h-0.1879272v0.3759155h0.1879272
        v0.1879883h-0.1879272l-0.1879883,0.1879578l-0.1879272,0.1879578h-0.1879883l-0.1879272,0.1879578h-0.1879883v0.1879578
        h-0.1879883v0.1879578h-0.1879272v0.375946h-0.1879883l-0.1879272,0.1879578l0.1879272,0.1879578h-0.1879272v0.1879578h-0.1879272
        h-0.1880493v0.375946h-0.3759155v0.1879578h-0.1879272l-0.1879272-0.1879578h-0.5639648v0.1879578h-0.1879272l-0.1879272,0.1879578
        l-0.1879883,0.1879578v0.1879578h-0.1879272v-0.9398193l0.1879272-0.1879578l-2.6314697-2.443512h-0.3759155v-0.1879578
        l0.1879272-0.1879578h-0.1879272v-0.375885h-0.1879883v-0.1880188h-0.3759155l-0.1879272,0.1880188h-0.3759766
        l-0.1879272,0.1879272v-0.1879272l-0.1879883,0.1879272v0.1879578l-0.1879272,0.1879578h-0.5639038v0.1879578l-0.1879883-0.1879578
        l-0.1879272,0.1879578h-0.1879883l-0.1879272-0.1879578v-0.3759155l-0.1879272-0.1879272l-0.1880493-0.1880188
        l-0.3759155-0.1879578h-0.1879272v0.3759766l-0.1879272,0.1879272l-0.1879883,0.1879578h-0.1879883v0.3759155l-0.1879883,0.1879578
        v0.7518616l-0.1879272,0.1879578v0.5639038h0.1879272v0.1879578h0.3759766l0.1879883,0.1879578h-0.1879883l-0.3759766,0.1879578
        h-0.3759155l-0.1879272-0.1879578h-0.3759155v-0.3759155h-0.1879883v0.1879578h-0.1879883v-0.1879578h-0.3758545
        l-0.1879883,0.1879578h-0.1879272v0.1879578h-0.1880493v0.9398193h-0.3758545l-0.1879883,0.1879578h-0.3759155v0.1879578
        h-0.1879883l-1.3157349-0.3759155h-0.7518311l-0.1879272,0.1879578v0.3759155h-0.1879883v0.1879578h-0.5639038l0.1879883,0.1879883
        v0.1879578h-0.1879883l0.1879883,0.1879578h0.1879272v0.1879578h-0.1879272l0.5639038,0.3759155l0.3759155,0.1879272
        l0.1879272,0.1880188l0.3759155,0.1879578l0.5639038,0.1879578l0.5639038-0.1879578h0.3759155v0.1879578h0.5639038v0.1879578
        h0.1879272l0.1879272,0.1879272l0.3759766,0.3759766h0.1879883l0.1879272,0.1879578l0.3759155,0.1879578l0.3759766,0.1879272
        l0.1879272,0.3759155l1.5036621,0.9398193v0.1879578h0.1879883v1.5037231l-0.1879883,0.1879578v0.1879578h0.1879883v0.1879578
        l-0.1879883,0.3759155v0.375946l0.7518921,0.7518311l0.1879272,0.1879883l0.3759155-0.1879883v-0.5638733l0.1879883-0.1879578
        l0.1879883-0.5639038l0.1879272-1.3157349l0.1879883-0.3759155l0.1879272-0.1879578l0.3759155-0.5639038l0.1879883-0.1879578
        h0.1879883l0.3759155-0.1879578l0.1879272,0.1879578h0.3759155l0.7518921-0.5638428l0.1879272-0.1880188h0.1879883v-0.5638428
        h-0.1879883v-0.1879578h-0.1879272v-0.1879578l0.1879272-0.1880188h0.9398193l0.1879883,0.1880188l0.1879272,0.1879578
        l0.1879883-0.1879578h0.3759155v0.1879578h-0.1879272l-0.1879883,0.1879578h-0.1879883l-0.1879272,0.1879272v0.1879578
        l0.1879272,0.1879578h0.3759766v-0.1879578h0.3759155v-0.1879578h0.5639038l0.1879272-0.1879272l0.1879883-0.1879578h0.1879272
        v0.375885h0.1879272l0.1879883,0.1879578v0.1879578h0.1879272v-0.1879578h0.7518921v-0.1879578h0.3759155l0.1879883-0.1879272
        h0.5638428v0.1879272h0.1879883v-0.1879272h0.1879272v0.1879272l0.1879883,0.1879578l0.1879883,0.1879578l0.1879272,0.1880188
        l-0.1879272,0.375885h0.1879272v0.7518921h-0.1879272v0.1879272h0.1879272v0.1879578l-0.1879272,0.1879578h-0.1879883
        l-0.1879883,0.1879578v-0.1879578h-0.1879272v0.1879578l0.1879272,0.3759155h0.1879883v0.1879883l0.1879883,0.1879578v0.1879578
        l0.1879272,0.1879578h0.1879883v0.1879578h0.1879272v0.1879578h0.1879883v0.375946l0.3759155,0.1879578l0.1879883,0.3759155
        v-0.5638733l-0.1879883-0.1879578V471.77005l1.3157349-0.5638733l0.1879883-0.1879578v-0.1879578h0.1879272l0.1879883-0.1879272
        l0.3759155-0.1880188l0.1879883-0.1879578l0.1879272-0.1879578l0.1879883-0.1879578l-0.1879883-0.1879272h-0.5639038
        l-0.1879272-0.1879578v-0.1880188l-0.1879883-0.1879578l-0.1879272-0.1879578v-0.5638428h-0.1879883v-0.3759766
        l-0.1879272-0.1879272v-0.3759155l0.5638428-0.3759766l-0.1879272-0.1879272h-0.5639038l-0.1879883-0.1879578h-0.1879272v0.375885
        l-0.1879883-0.1879272v-0.1879578h-0.1879272l0.1879272-0.1879578h0.1879883v-0.1879578h0.1879272l0.1879883-0.1879578v-0.1879578
        l0.1879883-0.1879883l0.1879272-0.1879578v-0.1879578l0.5639038-0.1879578l0.1879272-0.1879578v-0.1879578h0.1879883
        l0.3759155,0.1879578v0.3759155h0.1879883v0.3759155l0.3758545-0.1879578v-1.3157349h0.1880493l-0.1880493-0.1879578v-0.1879578
        h0.1880493l0.3759155,0.3759155l0.1879272,0.1879578l0.1879272-0.1879578h0.5639648v0.1879578l-0.1880493,0.1879578h0.3759766
        l0.3759155-0.1879578l0.1879272-0.1879578v-0.5638733l-0.1879272-0.1879883v-0.3759155l-0.1879272-0.1879578h0.5638428v-0.3759155
        l0.1879883-0.1879883h-0.1879883v-0.1879578h0.1879883v-0.1879578l0.1879883-0.1879578l0.1879272-0.1879578v-0.1879578h0.1879272
        l0.1879883-0.1879272h0.5639038l0.3759155-0.3759766v-0.3759155l0.1879272-0.1879272l0.1879883-0.1879578h0.9398193v-0.5639343
        l0.1879272-0.1879272v0.1879272l0.1879883,0.1879578v0.1879578l0.1879883,0.1880188v0.1879578l-0.1879883,0.1879272v0.3759155
        h0.1879883v0.1879578l0.1879272,0.1880188h0.7518311v-0.1880188l0.1879883-0.1879578v-0.1879578h0.1879883l0.1879272,0.1879578
        v0.5639038h-0.3759155v0.3759155h-0.1879883v0.5638733l-0.3759155,0.5639038l0.1879883,0.1879578l-0.1879883,0.1879578h0.5639038
        l0.1879883-0.1879578l0.1879272-0.1879578v-0.1879578h0.3759155v0.3759155h0.1879883l0.1879883-0.1879578h0.1879272v0.1879578
        l0.1879883-0.1879578l-0.1879883-0.5639038v-0.1879578h0.3759155l0.1879883-0.1879578v-0.3759155h0.1879272v-0.5639038
        l-0.1879272-0.1879578v-0.1879578h-0.1879883v-0.1879578h0.3759155v-0.1879272l0.1879883-0.3759766l0.1879883-0.1879578h0.1879272
        v0.1879578l0.3759155,1.1277771v0.7518616h0.1879272v0.1879578h0.1879883v0.5639038h0.1879883v0.3759155l-0.1879883,0.1879578
        l-0.3759155,0.1879578h-0.5638428v-0.1879578h-0.1879883l-0.1879883-0.1879578h-0.3759155l0.1879883,0.7518616v0.3759155
        l-0.1879883,0.3759155h-0.1879272v0.375946h0.1879272v0.1879578l0.1879883,0.1879578v0.5638733h-0.1879883v0.1879883
        l0.5639038,0.1879578h0.1879883v0.5638733l-0.1879883,0.1879578v0.375946h-0.1879883v0.1879578h-0.5638428v-0.1879578h-0.1879883
        l-0.1879272,0.1879578v-0.1879578h-0.1879883v0.1879578l-0.1879883,0.1879578l0.1879883,0.1879578v0.1879272h0.1879883
        l0.1879272,0.1879578l0.1879883,0.1880188h0.1879272v0.5638428h-0.1879272v0.3759155l-0.1879883,0.1880188v0.1879578h-0.5639038
        v0.1879272l0.3759766,0.1879578l0.3759155-0.1879578h0.3759155l0.1879272,0.1879578l0.3759766-0.1879578l-0.1879883-0.1879272
        h-0.3759155v-0.1879578l-0.1879883-0.1880188h0.7518921l0.1879272-0.3759155v-0.375885l0.1879883-0.5639343l0.3758545-0.1879272
        l0.1879883-0.1879578l0.1879883,0.1879578v0.1879272h0.1879883l-0.1879883,0.1879578v0.3759766l0.1879883,0.375885
        l0.1879272,0.1879578v0.1879578l0.1879272,0.1880188v0.5638428l0.1879883,0.1879578l-0.3759155,0.1879578h-0.1879272
        l-0.1879883,0.1880188v0.375885h0.3759155v0.1879578h0.3759155v-0.1879578h0.5639038l0.1879272,0.1879578h0.1879883v-0.1879578
        l-0.1879883-0.1879578l-0.1879272-0.1879272h0.5638428v-0.1880188h-0.1879272v-0.1879578h-0.1879883l0.1879883-0.1879578
        h-0.3759155v-0.1879578h0.1879272v-0.9398193v-0.1879272h0.1879883v-0.1879578h-0.1879883v-0.3759766l0.3759155-0.5638428
        l-0.3759155-0.1879578l0.1879883-0.7518616l0.1879272-0.1879578l0.1879883-0.1879578h-0.3759155v0.1879578h-0.3759155v-0.3759155
        h0.1879272v-0.1879578h-0.1879272l-0.1879272-0.1879883v-0.1879578l0.1879272-0.1879578h0.1879272v0.1879578h0.1879883h0.3759155
        v-0.1879578l0.1879883-0.3759155l0.3759155,0.1879578h0.1879272l0.1879883-0.1879578v-0.1879578h-0.1879883v-0.1879883
        l-0.1879272-0.3759155v-0.1879578l0.1879272-0.5639038v-0.1879578l0.1879883,0.1879578l0.5639038,0.1879883h0.3759155
        l0.1879272,0.1879578v0.3759155l0.3759155,0.1879578v0.1879578h0.1879883l-0.1879883,0.9398193v0.1879578h-0.1879272v0.1879883
        h-0.1879883l-0.1879272,0.1879578v0.3759155l0.1879272,0.1879578l0.1879883,0.375885l0.1879272,0.5639343v0.375885h0.1879883
        v0.1879578h0.1879883l0.1879272-0.1879578h0.3759155l0.1879883-0.1879272h0.1879883l0.1879272-0.1879578v0.375885h0.1879883
        l0.3759155,0.1879578h0.5639038l0.1879272-0.1879578h0.1879883v-0.1879272h0.7518311v-0.1879578l0.1879883,0.1879578h0.1879272
        l0.1879883-0.1879578v-0.1879578l0.1879272-0.1879578h0.1879272v0.3759155h-0.1879272v0.375885h0.1879272h0.1880493
        l0.1879272-0.1879272v0.1879272l0.1879883,0.1879578v0.1880188h0.1879272l0.3759155-0.1880188h0.3759766l0.1879272,0.1880188
        l-0.1879272,0.1879578l-0.1879883,0.1879578h-0.1879883v0.5638428l-0.1879883,0.1880188h-0.3758545l-0.1879883,0.1879578h0.1879883
        l0.1879272,0.1879272v0.1879578h0.1879272v0.9398193l0.1879883,0.1879578h0.1879883v0.1879578h0.3759155v0.1879578h0.3759155
        v0.1879578h0.1879272v0.1879883h-0.1879272l0.1879272,0.1879578h0.1880493v0.1879578h-0.1880493v0.1879578L785.5349121,472.7098694
        z M746.0628052,492.2579346l-1.3156738-1.1277771l-0.1879883,0.3759155l1.1278076,1.1277771L746.0628052,492.2579346z
        M758.8442383,462.1839905l0.7518921-0.3759155h-0.1879883l-0.7518311,0.3759155h-0.1879883l-0.1879883,0.1879578h0.3759766
        L758.8442383,462.1839905z M748.5063477,464.2515869h-0.3759155v-0.1879578h-0.3759766l0.1880493,0.1879578v0.1879578h0.5638428
        V464.2515869z M689.1612549,525.0443115h-0.1879883v0.1879272v0.1879883l0.1879883,0.1879272v-0.1879272h0.1879883
        l0.1879272-0.1879883l-0.1879272-0.1879272H689.1612549z M685.7779541,521.4730225l-0.1879883-0.1879272h-0.1879272v0.1879272
        v0.1879883h0.1879272L685.7779541,521.4730225z M686.1539307,521.4730225h-0.1879883h-0.1879883l0.1879883,0.1879883
        L686.1539307,521.4730225z M686.5297852,520.5332031v0.1879883h0.1879883v-0.1879883H686.5297852z M689.5371704,520.1572876
        h-0.1879272h-0.1880493v0.1879883h0.1880493v0.1879272l0.1879272,0.1879883h-0.1879272l0.1879883,0.1879272v-0.1879272h0.1879272
        l0.1879272-0.1879883v-0.1879272h-0.3759155V520.1572876z M689.7251587,521.0970459v-0.1879272h-0.1879272v0.1879272H689.7251587z
        M680.3270264,480.1213989l0.1879272,0.1879578h0.3759766v0.1879578l0.1879883,0.1879578v0.375885h0.1879272v-0.1879578h0.1879883
        v-0.1879272v-0.1879578h0.1879272v-0.1879578v-0.1879578l-0.1879272-0.1880188h-0.7518921v-0.1879272h-0.1879883
        l-0.1879272-0.1879578h-0.1879272v0.1879578l-0.1879883,0.1879272l0.1879883,0.1880188H680.3270264z M680.8909302,481.249176
        v0.1879578v0.1879578h-0.1879883v0.1879272v0.1879578h0.5639038v-0.1879578v-0.1879272v-0.1879578h0.1879883v-0.1879578h-0.1879883
        l-0.1879272-0.1880188v0.1880188H680.8909302z M682.206665,485.5722656h0.1879883v-0.1879578h-0.3759155l-0.1879883,0.1879578
        v0.1879578v0.1879578l0.1879883-0.1879578L682.206665,485.5722656z M687.84552,523.7285767v0.1879883h0.1879883v-0.1879883
        v-0.1879272H687.84552V523.7285767z M717.7315063,486.5120239v-0.1879272h-0.5639038v-0.3759155v-0.1879578h0.1879272v-0.1879578
        h0.1879883v0.1879578h0.1879883v-0.1879578v-0.1879883h-0.1879883v-0.1879578h0.1879883v-0.1879578v-0.1879578h-0.1879883
        v0.1879578h-0.7518311v-0.1879578h-0.3759155v0.1879578h-0.3759766v0.1879578v0.1879578h0.1879883v0.1879883v0.1879578
        l-0.1879883,0.1879578h-0.1879272v0.1879578h-0.1879883l-0.1879272,0.1879578h0.1879272v0.1879272h0.3759155v0.1879578v0.1880188
        v0.1879578h0.1879883v0.1879578h0.1879883v0.1879272l0.1879272,0.1879578h0.3759155v-0.1879578h0.3759155v-0.1879272h0.1879883
        l0.1879883-0.1879578h0.1879272v-0.1879578v-0.1880188h-0.1879272V486.5120239z M717.3555908,487.8278198h-0.3759155v0.1879578
        h0.1879272v0.1879578h0.3759155v-0.1879578h-0.1879272V487.8278198z M749.1210938,463.3927307v0.1879578h-0.1879272v0.1879578
        v0.1879578h0.3759155v-0.1879578h0.1879883l-0.1879883-0.1879578v-0.1879578H749.1210938z M682.3946533,486.8880005v0.3759155
        v0.1879272h0.1879272v0.1880188h0.1879272v-0.1880188v-0.1879272v-0.1879578h0.1880493l0.1879272-0.1879578h-0.1879272
        l-0.1880493-0.1879578h-0.5638428v0.1879578H682.3946533z M681.8307495,486.7000427h0.3759155l-0.1879272-0.1879578v-0.1879578
        h-0.3759155v0.1879578h0.1879272V486.7000427z M682.5825806,488.0157776l0.1879883-0.1879578v-0.1879578l-0.1879883,0.1879578
        V488.0157776z M716.0397949,498.3536987h0.1879883v-0.1880188L716.0397949,498.3536987z M716.6036987,498.1656799h-0.1879272
        l-0.1879883,0.1880188v0.1879578l0.1879883,0.1879272h0.1879272v-0.1879272h0.1879883l0.1879272-0.1879578h-0.1879272
        L716.6036987,498.1656799z M720.1749878,498.9176025h-0.3759155l-0.1879883,0.1879578v0.1879578v0.1879578v0.1879272h1.1277466
        v-0.1879272v-0.1879578h-0.1879272v-0.1879578h0.1879272v-0.1879578v-0.1880188h-0.3759155L720.1749878,498.9176025z
        M728.069397,499.1055603h0.1879883v-0.1879578h-0.1879883h-0.1879883L728.069397,499.1055603z M736.7156372,514.1425171
        h-0.7518311v0.1879883h-0.7518311v-0.5639648h-0.3759155l-0.1879883,0.1880493h-0.3759155v0.1879272h-0.1879883v0.1879883
        h-0.1879272v0.1879272l-0.1879883,0.1879272h-0.1879272v-0.1879272h-0.3759766v-0.1879272h-0.5638428v0.1879272h-0.7518921
        l-0.1879272,0.1879272h-0.1879883l-0.1879272,0.1879883l-0.1879272,0.1879883l-0.1880493,0.1879883v0.3758545h-0.1879272
        v-0.1879272h-0.1879883v-0.1879272h-0.1879272v-0.1879883h-0.1879272v-0.7518311h0.1879272h0.3759155v-0.1879883
        l0.9398193-0.5639038l0.1879272-0.1879272h0.1879883v-0.1879883l0.1879883-0.1879272v-0.5639038l-0.1879883-0.1879883v-0.1879272
        h-0.3759155v-0.1879883h-0.1879883v0.1879883h-0.1879272l-0.1879272,0.1879272v0.1879883l-0.1880493,0.1879272h-0.1879272
        v-0.1879272h-0.1879883l-0.1879272-0.1879883h-0.1879272v-0.3759155h-0.1879883l-0.1879272-0.1879272v-0.1879578h0.1879272
        v-0.5639343h-0.1879272v-0.1879272h-0.3759766l0.1879272-0.1879578h-0.3758545v-0.1879578h-0.3759155v-0.7518616h0.1879272
        v-0.1879578h0.1879883v-0.1879578l0.1879272-0.1880188v-0.5638428h-0.1879272l-0.1879883-0.1879578h-0.3759155v-0.1879578
        h-0.1879883v-0.1879883h-0.1879272v0.1879883h-0.1879883v0.1879578h0.1879883v0.1879578h-0.5639038v0.1879578l-0.1879272-0.1879578
        l-0.1879883,0.1879578l-0.3759155,0.1879578h-0.1879883l-0.1879272,0.1879272h-0.3759155l-0.1879883,0.1880188h-0.1879883
        v0.1879578l0.1879883,0.1879578l0.1879883,0.1879578h0.1879272l0.1879883,0.1879272h0.1879272v0.3759766h-0.1879272v0.1879578
        h-0.1879883v0.1879578h0.1879883l-0.1879883,0.1879272h0.1879883l0.1879272-0.1879272h0.1879883v0.1879272h-0.1879883v0.1879578
        l-0.1879272,0.1879578v-0.1879578l-0.1879883,0.1879578h-0.1879272v0.1880188h-0.1879883l-0.1879883,0.1879578v0.1879272
        h-0.1879272v0.3759155h-0.1879883v0.1879883H725.25l0.1879272,0.1879272v1.3157959h0.9398193v-0.5639038h-0.1879272v-0.1879883
        h-0.3759766v-0.1879272h0.9398193v0.3759155l0.1879883,0.1879272h0.1879272l0.1879883-0.1879272l0.1879883-0.1879883v-0.9398193
        l0.1879272-0.1879272h0.1879883v0.1879272h0.3759155v0.1879883h0.3759155l0.1879883,0.1879272v0.3759766l0.1879272,0.1879272
        v0.3759155h-0.1879272l0.1879272,0.1879272v0.1880493h0.1879883v0.1879272h-0.3759155l0.1879272,0.1879883v0.3758545h-0.3759155
        l0.1879883,0.1879883v0.1879883h0.3759155v0.1879883l0.1879272,0.1879272h-0.1879272v0.3759155l-0.1879883,0.1879272v1.1278076
        l-0.1879272,0.1879272v0.7518921h-0.1879883v0.1879272h-0.1879883v0.1879883h-0.1879272l-0.1879883,0.1879883h-0.3759155v0.1879272
        l-0.1879272,0.1879883v0.5638428h0.1879272v0.1879883h0.1879883v0.1879883h0.3759155v0.1879272h0.3759155l0.1879883,0.1879883
        h0.3759155v-0.3759155h0.1879272l0.1879272,0.1879272l0.1880493,0.1879883h0.1879272v0.1879272h0.3759155v-0.1879272
        l0.1879272-0.1879883h0.1879883v-0.1879272l0.1879272-0.1879883h0.1880493v-0.1879883h0.1879272l0.1879272,0.1879883v0.1879883
        l0.1879883,0.1879272v0.1879883h0.1879272v0.1879272h0.1879883l0.1879883,0.1879883v-0.9398193l-0.1879883-0.1879883v-0.3759155
        l-0.1879883-0.1879272l-0.1879272-0.1879883v-0.7518311l0.1879272-0.1879883h0.3759766v0.3759155h0.1879272v0.1879883h0.1879883
        v0.1879883h0.3759155v0.1879272l0.1879272,0.1879883h0.1880493l0.1879272,0.1879272v0.1879883h0.1879272v0.1879272h0.1879883
        v-0.1879272h0.1879272v0.1879272h0.3759766l-0.1879883-0.1879272v-0.3759155h-0.1879883l0.1879883-0.1879883h-0.1879883v-0.3759155
        h-0.1879272v-0.1879883h-0.3759155l-0.1879272-0.1879272l0.1879272-0.1879883v-0.3759155h-0.1879272v-0.3759155h-0.5639648
        v-0.7518311h0.5639648v0.1879272h0.3758545v-0.1879272v-0.1879883h0.1879883v0.1879883h0.1879272v-0.1879883h0.1879883v-0.1879883
        v-0.3759155h0.1879883v-0.1879272h0.3759155v-0.3759155h0.1879272v-0.1879883h0.1879883v0.1879883h0.1879272v0.1879883h0.1879883
        v0.1879272h0.7518311v-0.3759155h0.1879883v-0.1879883h0.3759155v-0.5638428h-0.1879883V514.1425171z M734.6480713,519.9693604
        v-0.3759766h-0.1879272v0.3759766H734.6480713z M732.0166016,511.3230591v-0.1879578v-0.1879578h-0.3759155v0.1879578h-0.1879883
        v0.1879578v0.1879578h0.5639038V511.3230591z M732.9564209,507.375824h-0.3759155l-0.1879883,0.1880188h0.5639038V507.375824z
        M756.4516602,510.3832397h0.3759155l0.1879272-0.1879578v-0.1879578l-0.1879272-0.1879883l-0.1879272,0.1879883h-0.5639038
        v0.1879578h0.1879883L756.4516602,510.3832397z M765.0979614,481.6250916h-0.1879883v0.1879272h0.1879883V481.6250916z
        M764.7220459,481.249115v0.1879578h0.1879272v0.1879578h0.1879883v-0.1879578h0.1879272l-0.1879272-0.1879578v-0.1879578
        h-0.3759155V481.249115z M792.7283325,447.6039124l-0.1879272-0.1879578l-0.1879883,0.1879578h-0.3759155l-0.1879883-0.1879578
        l-0.1879272-0.1879578h-0.1879883v-0.1879578h0.1879883v-0.1879578h-0.1879883l-0.1879272,0.1879578l-0.1879883-0.1879578
        l-0.1879272-0.1879578v-1.3157349h-0.3759766l-0.3759155,0.1879578h-0.1879272v0.1879578l-0.1879272,0.1879578v0.3759155h0.1879272
        v1.1277771h0.1879272l0.1879883,0.375946h0.1879272l0.3759766,0.1879578h0.1879272l0.1879883,0.1879578l0.1879272,0.1879578
        l0.1879883,0.1879578h0.7518311l0.1879883-0.1879578h0.1879272v-0.1879578h0.1879883v-0.1879578l0.1879272-0.1879578v-0.1879883
        H792.7283325z M757.3914795,445.5363464h-0.3759155l-0.1879883,0.1879578h0.5639038V445.5363464z M771.6765747,460.0094604
        h-0.1879272v0.1879578L771.6765747,460.0094604z M771.3006592,460.0094604h0.1879883v-0.1879578L771.3006592,460.0094604z
        M770.9247437,461.137207h0.1879883v-0.1879578v-0.1879578l-0.1879883,0.1879578V461.137207z M770.9248047,447.7919006v-0.375946
        h-0.3759155v0.1879883l0.3759155,0.3759155V447.7919006z M749.309082,447.4159851v-0.1879578h-0.1879272v0.1879578H749.309082z
        M748.557251,447.7919006h0.1879272v-0.1879578h-0.1879272V447.7919006z M748.9332275,447.6039429v-0.1879578l-0.1880493,0.1879578
        H748.9332275z M758.5192871,445.1604614h-0.1879883l0.1879883,0.1879272V445.1604614z M758.8951416,445.7243042v0.1879578
        l0.1879883-0.1879578l-0.5638428-0.3759155v0.3759155H758.8951416z M757.9553833,445.3483887h-0.1879272v0.1879578h0.1879272
        V445.3483887z M756.6396484,447.0400391h0.1879883v-0.1879578h0.3759155l0.1879272-0.1879578h-0.5638428l-0.1879883,0.1879578
        V447.0400391z M758.1433716,447.4159851h-0.3759155v0.1879578h0.3759155V447.4159851z M760.2108765,449.2955933h-0.3759155
        v0.1879578h0.3759155V449.2955933z M759.4591064,449.2956238h-0.1880493v0.1879578h0.1880493V449.2956238z
        M760.9627686,449.2956238h-0.1879883v0.1879578h0.1879883V449.2956238z M763.40625,449.1076355l0.1879883-0.1879578v0.1879578
        h0.1879883v-0.1879578h0.3759155l0.1879272-0.1879883v-0.1879578h0.3759155v0.1879578v0.1879883h0.3759766v0.1879578h-0.3759766
        l-0.1879272,0.1879578v0.1879578h1.1277466l-0.1879272-0.1879578h0.1879272v-0.1879578l0.1879883-0.1879578l-0.1879883-0.1879883
        v-0.1879578l-0.1879272,0.1879578h-0.3759155v-0.1879578h-0.1879883l-0.1879883-0.1879578h-0.1879272v-0.1879578h-0.1879883
        v-0.1879578h-0.1879272v-0.1879578v-0.1879883v-0.3759155h-0.3759155l-0.1879883-0.1879578v-0.3759155h0.1879883v-0.1879578
        v-0.1879883h0.3759155l0.1879272-0.1879578l0.1879883,0.1879578v0.1879883h0.1879272l0.1879883,0.1879578h0.1879883v0.1879578
        l0.1879272,0.1879578h0.1879883v-0.1879578h0.1879272v-0.1879578h0.1879883v-0.1879578h0.3759155v-0.1879883l0.1879883-0.1879578
        v0.1879578h0.3759155v0.1879883h0.1879272v-0.1879883h0.3759766l0.1879272-0.1879578h0.1879883v-0.1879578h-0.5639648v-0.1879578
        h-0.5638428v0.1879578h-0.1879272l-0.1879883-0.1879578h-0.3759155l-0.1879883-0.1879578v0.1879578l-0.1879272,0.1879578
        h-0.1879883l-0.1879272-0.1879578v0.1879578h-0.1879883l-0.1879883-0.1879578h-1.1277466l-0.1879883-0.1879578v-0.1879578
        l0.1879883-0.1879883l-0.1879883-0.1879578h-0.5638428v0.1879578h-0.1879883l-0.1879883,0.1879883v0.1879578h0.1879883v0.1879578
        h0.1879883v0.1879578h0.1879272l0.1879883,0.1879578l-0.1879883,0.1879578v0.375946l-0.1879272,0.1879578h-0.1879883v-0.1879578
        l0.1879883-0.1879578v-0.1879883h-0.1879883v0.1879883l-0.1879883-0.1879883v0.1879883h-0.1879272v0.1879578h0.1879272v0.1879578
        l0.1879883,0.1879578h0.1879883v0.1879578h0.1879272l0.1879883,0.1879578h0.1879272v0.1879578l0.1879883,0.1879883v0.1879578
        h0.1879883v0.1879578h-0.5639038v-0.1879578h-0.1879883v0.1879578h-0.5639038v0.1879578v0.1879578v0.1879578h-0.1879272v0.1879883
        h-0.1879883v0.1879578v0.1879578l0.3759155-0.1879578H763.40625z M767.9173584,445.7243042h-0.1879272v0.1879578h0.1879272
        V445.7243042z M765.8497925,448.3557739h0.1879883l0.1879272-0.1879578l0.1879883-0.1879578h0.1879272v-0.3759155h-0.5638428
        h-0.1879883v0.1879578h-0.1879883v0.3759155v0.1879578v0.1879578h0.1879883V448.3557739z M767.7294312,450.2354126h-0.1879883
        v-0.3759155h-0.1879272v-0.1879578l-0.1880493-0.1879578l-0.1879272-0.1879883h0.1879272l-0.1879272-0.1879578h-0.1879272
        v-0.1879578h-0.1879883l-0.1879272,0.1879578l-0.1879883,0.1879578v0.7518616l0.1879883,0.1879578h0.1879272l0.1879883,0.1879578
        h0.3758545l0.1880493,0.1879578l0.3759155,0.1879883v0.1879578h0.5638428v0.1879578l0.1879883,0.1879578h0.1879883v-0.1879578
        l0.1879272-0.1879578v-0.375946h-0.1879272v-0.3759155v-0.1879578v-0.1879578v-0.1879578l0.1879272-0.1879578l0.1879883-0.1879883
        v-0.1879578l-0.1879883-0.1879578h-0.1879272V448.73172v-0.1879578v-0.1879578h-0.1879883l-0.1879883,0.1879578v0.3759155
        v0.1879578v0.1879578h0.1879883v0.1879883v0.1879578l-0.1879883,0.1879578l-0.1879883,0.1879578h-0.1879272
        L767.7294312,450.2354126z M763.9701538,454.3705444h0.1879272l-0.1879272-0.1879578V454.3705444z M765.8497925,453.4307251
        h0.1879272v-0.1879578L765.8497925,453.4307251z M762.6544189,451.9270935l-0.1879883-0.1879578v0.1879578h-0.3759155v-0.1879578
        h-0.1879272v0.1879578v0.1879578h0.7518311V451.9270935z M749.1210938,468.0917969v0.3759155l0.1879883-0.1879578h0.1879883
        l-0.1879883-0.1879578v-0.1879578L749.1210938,468.0917969z M759.4591064,470.9112549v0.1879578h0.1879272v-0.1879578
        l-0.1879272-0.1879578V470.9112549z M776.1876831,456.4381409v0.1879578l0.1879883,0.1879578v-0.1879578h0.3759155v-0.1879578
        h0.1879883v-0.1879578v-0.375946h0.1879272v-0.1879578h0.1879883v-0.3759155h0.1879272l0.1879883-0.1879578v-0.1879578
        l-0.1879883,0.1879578h-0.3759155h-0.1879272l-0.1879883-0.1879578h-0.1879272l-0.1879883,0.1879578h-0.1879883v0.1879578
        h-0.1879272v0.1879578l0.1879272,0.1879578l-0.1879272,0.1879578h-0.1879883v0.1879578l0.1879883,0.1879883
        L776.1876831,456.4381409z M775.8117065,460.0094299v0.1879272h0.1879883v0.1880188v0.1879578v0.1879578h0.1879883v0.7518616
        v0.1879578h0.1879883l0.1879272-0.1879578v-0.1880188h0.3759155l-0.1879883,0.1880188l-0.1879272,0.1879578v0.1879578v0.1879578
        l-0.1879272,0.1879272v0.1879578v0.1880188v0.1879578h0.1879272l0.1879272,0.1879578h0.1879883l0.1879272-0.1879578h0.1879883
        v-0.1879578h0.1879883v-0.5639038v-0.1879578v-0.1879578l0.1879272-0.1879578h0.3759155v-0.1880188v-0.7518005
        l-0.1879272-0.1879578h-0.1879883l-0.1879272-0.1880188l-0.1879883-0.1879272v-0.1879578l0.1879883-0.1879578l0.1879272-0.1879578
        v-0.1879578l-0.1879272-0.1879578v-0.1879883h-0.5639038v-0.1879578h-0.3759155l-0.1879272,0.1879578h-0.1879883v0.1879883
        v0.1879578h-0.1879883v0.1879578h-0.3759155v0.1879578h-0.1879272v0.1879578l0.1879272,0.1879578H775.8117065z
        M779.0071411,459.4455566h0.1879883l-0.1879883-0.1879578l-0.1879272-0.1879578l-0.1880493,0.1879578h0.1880493
        L779.0071411,459.4455566z M783.894165,466.4001465l-0.1879883-0.1879578h-0.1879883l0.1879883,0.1879578H783.894165z
        M783.894165,466.2121887v0.1879578h0.1879272L783.894165,466.2121887z M782.0145264,475.7982483h0.1879272v-0.1879578h-0.1879272
        h-0.1879883L782.0145264,475.7982483z M781.8265381,475.2344055v0.1879272l0.1879883,0.1879578v-0.375885H781.8265381z
        M782.9543457,483.3167114l0.1879272,0.1879578v-0.3759155h-0.1879272V483.3167114z M778.8191528,487.2639465v-0.375946h-0.1879883
        v0.1879883h-0.1879272l0.1879272,0.1879578H778.8191528z M779.9468994,488.2037659h-0.1879272h-0.1879883v0.1879578h0.3759155
        V488.2037659z M778.0672607,490.459259v-0.1879578h-0.1879272v0.1879578H778.0672607z M776.7515259,489.8954163h0.1880493
        v0.1879578h0.1879272v-0.1879578h0.1879883v0.1879578l0.1879272,0.1879272h0.3759155v-0.1879272h0.5639038v-0.1879578h-0.3759766
        v-0.1880188v-0.1879578l-0.1879272-0.1879578h0.1879272v-0.1879272h0.1880493v-0.1879578l0.1879272-0.1879578h-0.1879272
        v-0.1879578h0.1879272v-0.1880188h-0.1879272v-0.1879272h-0.3759766v0.1879272v0.1880188h-0.1879883l-0.1879272-0.1880188
        h-0.5638428v0.1880188v0.1879578v0.1879578v0.1879578v0.1879272v0.1879578h0.1879272l-0.1879272,0.1879578L776.7515259,489.8954163
        z M779.9468994,490.083374h-0.5639038v-0.1879578v-0.1879578h-0.1879272v-0.1879578v-0.1879578h-0.1879883v-0.1879883h-0.1879272
        h-0.1879883v0.1879883h0.1879883v0.1879578v0.1879578h0.1879272v0.1879578v0.1879578v0.1879272h0.1879883v0.1879578h0.9398193
        l0.1879272,0.1880188v-0.1880188v-0.1879578h-0.3759155V490.083374z M778.2553101,490.8352356h-0.1880493v0.1879578h0.5639038
        v-0.1879578v-0.1880188h-0.3758545V490.8352356z M777.8793945,491.7750549h0.1879272l-0.1879272-0.1880188V491.7750549z
        M778.6311646,493.4666748h-0.1879272v-0.1879578v-0.1879578h0.1879272v-0.1879272h-0.1879272v-0.3759766l-0.1879272-0.1879578
        l-0.1880493-0.1879578h-0.1879272v-0.1879272h-0.7518311l-0.1879883,0.1879272v0.1879578v0.1879578v0.1879578v0.1880188
        l0.1879883,0.1879272v0.3759155l0.1879883,0.1879578h0.1879272l0.1879272,0.1879578l0.1879883,0.1880188v0.1879272
        l-0.1879883,0.1879578v0.1879578h0.1879883l0.1879272,0.1879578h0.1880493l0.1879272-0.1879578h-0.1879272v-0.1879578v-0.1879578
        v-0.1879272h0.5638428l0.1879272-0.1880188h0.1879883v-0.1879578h-0.3759155L778.6311646,493.4666748z M777.8793335,491.7750549
        v0.1879578h0.1879272L777.8793335,491.7750549z M774.3080444,493.6546326h0.1879883v-0.1879578v-0.1879883h-0.1879883v0.1879883
        V493.6546326z M775.8117676,491.0231934h-0.1879272v0.1879272l-0.1879883,0.1879578h-0.1879883v-0.1879578h-0.1879272v-0.1879272
        h-0.1879883l-0.1879272,0.1879272l-0.1879883,0.1879578l-0.1879883,0.1879578v0.1879578v0.1879578v0.1879578v0.1879883h-0.1879272
        v0.1879578h-0.1879883v0.3759155h0.1879883v0.3759155h0.1879272v-0.1879578h0.1879883v-0.1879578h0.1879883v-0.1879578h0.1879272
        v0.1879578h0.3759155v-0.1879578h0.1879883v-0.1879578v-0.1879578l0.1879883-0.1879883h-0.1879883v-0.1879578v-0.3759155h0.3759155
        v-0.1879578h0.1879883l0.1879272-0.1879578v-0.1879272h0.1879883v-0.1880188h-0.5639038V491.0231934z M768.8571777,480.6852722
        v0.1879272v0.1880188v0.1879578v0.1879578v0.1879578h0.1879883v0.1879272h0.1879272v0.1879578h0.1879272v-0.1879578
        l0.1879883-0.1879272v-0.3759155l0.3759155,0.1879578v0.1879578v0.1879272l-0.1879883,0.1879578l0.1879883,0.1879578v0.1880188
        l0.1879883,0.1879578v0.1879578v0.1879272h0.3759155v-0.1879272v-0.1879578v-0.1879578v-0.1880188h-0.1879883v-0.1879578h0.1879883
        l0.1879272-0.1879578v-0.1879272l-0.1879272-0.1879578v-0.1879578h-0.1879883l-0.1879272-0.1879578l-0.3759766-0.1880188
        h-0.3759155v-0.1879272h-0.1879272l-0.1879272,0.1879272v-0.1879272H768.8571777z M769.6090698,482.5649109h-0.1880493v0.1879578
        l0.3759766,0.1879272v-0.1879272v-0.1879578v-0.1880188h-0.1879272V482.5649109z M765.8497925,480.6852112h0.1879272v-0.1879578
        v-0.1879578h-0.1879272v-0.1879578h-0.5639038v-0.1879578h-0.3759155v0.1879578v0.1879578v0.1879578v0.1879578v0.1879883h0.1879883
        v0.1879578h0.1879272v0.1879578h0.3759155v-0.1879578h0.1879883v-0.1879578V480.6852112z M766.225647,479.9333801v-0.1879272
        l-0.1879272,0.1879272l0.3759155,0.1879578h0.1879272v-0.1879578H766.225647z M767.1654663,487.4519043l0.1879883-0.1879883
        v-0.1879578v-0.1879578h0.1879272l0.1879883,0.1879578l0.1879272,0.1879578v0.1879883h0.7518921v0.1879578h0.7518311
        l0.1879883-0.1879578v-0.1879883v-0.1879578v-0.1879578h-0.1879883v-0.1879578v-0.1879578h0.5639038v-0.1879578
        l-0.1879883-0.1879883v-0.1879578h0.3759155v-0.1879578v-0.1879578l-0.1879272-0.1879578v-0.1879578h0.1879272v-0.1879578
        h0.1879883v-0.1879883h-0.1879883v-0.1879578h-0.5638428v-0.1879578v-0.1879578h-0.1879883l-0.1879272-0.1879578h-0.3759766
        v0.1879578h-0.1879272v-0.1879578h-0.1879883v-0.1879578l-0.1879272-0.1879883h0.1879272l0.1879883,0.1879883v-0.375946v-0.1879578
        l-0.1879883-0.1879578h0.3759155v-0.375885l-0.1879272-0.1880188v-0.1879578v-0.1879578l-0.1879883-0.1879578l-0.1879272,0.1879578
        v-0.1879578l-0.1879883-0.1879578v-0.1879272v-0.3759766v-0.3759155l0.1879883,0.1879578v-0.1879578l-0.1879883-0.1879272
        l0.1879883-0.1879578h-0.1879883l-0.1879883-0.1879578h-0.1879272v0.1879578h-0.1879883v0.375885h-0.3759155v-0.1879272v-0.1879578
        h0.1879883v-0.1879578h0.1879272v-0.1880188l-0.1879272-0.1879578h-0.3759155l-0.1879883,0.1879578h-0.1879883v0.1880188
        l-0.1879272,0.3759155l-0.1879883,0.1879272v0.1879578v0.1879578h-0.1879272v-0.1879578h-0.1879272v0.1879578v0.1880188h-0.1879883
        v0.1879578v0.1879272h-0.1879883l0.1879883,0.1879578h-0.5639038v0.1879578v0.3759155h0.1879272v0.1880188h0.1879883v-0.1880188
        v-0.1879578h0.1879883v0.1879578h0.1879883v0.1880188l-0.1879883,0.1879272h-0.1879883l0.3759766,0.1879578v0.1879578v0.1879578
        l0.1879272,0.1879578l0.1879272,0.1879883h0.1879883v0.1879578h-0.3759155v-0.1879578h-0.1879272v0.1879578h-0.1879883v0.1879578
        h0.1879883v0.1879578h0.1879272v0.1879578l-0.1879272,0.1879578l-0.1879883,0.375946l-0.1879883-0.1879578v0.1879578v0.1879578
        v0.1879578h-0.1879883v0.1879578h0.1879883v0.1879578l0.1879883,0.1879578v0.1879883l0.1879883,0.1879578v0.1879578h0.1879272
        v0.1879578l0.1879272,0.1879578h0.3759155v0.1879578h0.1879883v0.1879883H767.1654663z M771.6765747,488.5796204h-0.1879272
        v0.1879578v0.1880188h-0.1879883v0.1879578l0.1879883,0.1879272h0.1879272v-0.1879272h0.1879883v-0.1879578h-0.1879883
        l0.1879883-0.1880188L771.6765747,488.5796204z M770.7367554,489.1435547h-0.3759155v-0.1880188h-0.1879883v-0.1879578
        l0.1879883-0.1879578h-0.1879883l-0.1879272-0.1879578v-0.1879272v-0.1879578h-0.1879883l-0.1879272-0.1879578h-0.5638428
        h-0.1880493l-0.1879272,0.1879578h-0.5639038v0.1879578h-0.1879272v0.1879272v0.1879578v0.1879578h0.1879272v0.1879578
        l0.1879883,0.1880188v0.1879272v0.1879578v0.1879578h0.1879272v0.1879578v0.1879578v0.1879883v0.1879578h0.1879883v0.1879578
        v0.1879578h0.3759766l0.1879272-0.1879578v0.1879578h0.1879272l0.1879883-0.1879578h0.1879272v0.1879578h0.1879883v0.1879578
        h0.1879272v-0.1879578h0.1879883v-0.1879578h0.1879883v-0.1879578h0.3759155v-0.375946v-0.1879578v-0.1879578v-0.1879578
        v-0.1879578h-0.1879883V489.1435547z M770.1729126,491.5870972l0.1879272-0.1880188h-0.1879272V491.5870972z
        M768.2932739,493.8425903h0.1879883v0.1879578l0.1879883-0.1879578v-0.1879578h-0.1879883v-0.1879578L768.2932739,493.8425903z
        M768.1052856,495.5342712h0.1879883v-0.1879578h-0.1879883V495.5342712z M768.293335,496.2861023h0.1879272v-0.1879578h-0.1879272
        V496.2861023z M768.8571777,495.7221985h-0.1879272v0.1879272h-0.1879883v0.1880188h0.5639038v-0.1880188h-0.1879883V495.7221985z
        M765.0979614,491.0231323l-0.1879883-0.1879578v-0.1879578h-0.3759155v-0.1879578l-0.1879883-0.1879578h-0.1879272v-0.1879272
        h-0.1879883l-0.1879272-0.1879578v-0.1880188h-0.1879883v0.1880188v0.1879578v0.1879272v0.1879578H763.40625v0.1879578h0.1879883
        v0.1879578v0.1879578h0.1879883v0.375946l0.1879272,0.1879578h0.5639038v0.1879578h0.1879272l0.1879883-0.1879578v0.1879578
        h0.1879883v-0.1879578v-0.1879578h-0.1879883l0.1879883-0.1879578V491.0231323z M760.7747803,479.7454529v0.1879272v0.1880188
        h0.1879883l0.1879272,0.1879578h0.1879883l0.1879272-0.1879578v-0.1880188h-0.3759155v-0.1879272H760.7747803z
        M762.8424072,480.3093567l-0.1879883-0.1879578h-0.1879272v0.1879578h0.1879272v0.1879578L762.8424072,480.3093567z
        M761.7145996,481.0612183v0.1879578h0.1879883v0.1879578h0.1879272v-0.1879578h0.1879883v-0.1879578h-0.1879883h-0.1879272
        H761.7145996z M762.0905151,480.8731995v0.1880188l0.1879883-0.1880188H762.0905151z M762.4664917,480.8731995h0.1879272
        v-0.1879272h-0.1879272V480.8731995z M763.0303345,481.0612183v-0.1880188h-0.3759155v0.1880188H763.0303345z
        M763.40625,483.5046692v0.5639038v0.1879578h0.1879883v0.1879578H763.40625v0.1879578h0.3759766l0.1879272,0.1879883
        l-0.1879272,0.1879578v0.1879578v0.1879578h0.1879272h0.3759155v-0.1879578h-0.1879272l0.1879272-0.1879578h-0.1879272v-0.1879578
        v-0.1879883l-0.1879883-0.1879578v-0.5638733v-0.1879272v-0.1880188v-0.1879578h0.3759155l-0.1879272-0.5638428h-0.3759155
        v-0.1879578h0.1879272l-0.1879272-0.1880188l-0.1879883-0.3759155h0.1879883h0.1879272v-0.1879578h-0.1879272h-0.1879883
        v-0.1879272v-0.1879578h0.1879272h0.000061h0.1879272v-0.1879578h-0.1879272v-0.1880188l-0.1879883-0.1879578v0.1879578H763.40625
        v0.1880188h-0.1879272l-0.1879883,0.1879578v0.1879578v0.1879272v0.1879578l-0.1879272,0.1879578l-0.1879883,0.1879578
        l-0.1879883,0.1880188h-0.1879272v0.1879578h0.1879272v0.375885l0.1879883-0.1879578l0.1879883,0.1879578h0.1879272v0.1879578
        h0.1879883v0.1879578H763.40625z M762.0905151,483.3167114v0.1879578h-0.1879272l0.1879272,0.1880188h0.1879883v-0.1880188
        v-0.1879578h0.1879272v-0.1879578h-0.1879272L762.0905151,483.3167114z M778.0673218,502.8647766h0.1879883v-0.1879883h-0.1879883
        V502.8647766z M778.8191528,502.8647461h-0.1879883v0.1879883l0.1879883,0.1879578h0.1879272v-0.1879578h-0.1879272V502.8647461z
        M773.9321289,503.0527039v0.1879883v0.1879578v0.0000305h-0.1879272v0.1879578h0.1879272v-0.0000305h0.3759155
        l0.1879883-0.1879578l0.1879272-0.1879578v-0.1879883v-0.1879578v-0.1879578h0.1879883v-0.1879578v-0.1879578h0.1879883v-0.1879578
        h-0.1879883v-0.1879578h-0.3759155v0.1879578v0.1879578l-0.1879883,0.1879578h-0.1879272l-0.1879883,0.1879578h0.1879883v0.3759155
        H773.9321289z M775.0599365,501.736969h-0.1879883v0.1879883h0.1879883V501.736969z M775.0599365,503.9925537h-0.1880493
        l0.1880493,0.1879578v0.3759155h-0.1880493h-0.5638428v0.1879578v0.1879578l0.1879883,0.1879272v0.3759766h0.1879272v-0.1879578
        h0.3759766v0.1879578h0.1879272v-0.1879578h0.1879883l-0.1879883-0.1880188v-0.1879272h0.1879883v-0.1879578v-0.1879578h-0.1879883
        v-0.1879578v-0.1879578v-0.1879578H775.0599365z M775.4358521,504.3684692v-0.1879578h-0.1879883L775.4358521,504.3684692z
        M773.7441406,505.3082886h0.1879883v0.1879578h0.3759155v-0.3759155h-0.3759155L773.7441406,505.3082886z
        M767.5414429,500.6092224l-0.3759766,0.3759155l0.1879883,0.1879578l0.1879883,0.1879578h0.3759155v-0.5638733
        l-0.1879272-0.1879578H767.5414429z M763.9701538,505.8721924h0.1879883v-0.1879883l-0.1879883-0.1879578v-0.1879578h0.3759155
        v-0.1879578h-0.1879272v-0.1879578h-0.1879883v-0.1879578v-0.1879578v-0.1879883h-0.1879272v0.1879883h-0.1879883H763.40625
        v0.1879578h-0.1879272l-0.5639038,0.3759155v0.1879578h-0.5639038v-0.1879578h-0.1879272l-0.1879883,0.1879578h-0.1879272
        l-0.1879272,0.1879578v0.1879578l-0.1880493,0.1879883l-0.1879272,0.1879578v0.1879578v0.7518616v0.1879578l0.3759766,0.3759155
        h0.3758545l0.1879883,0.1879578h0.5639038v0.1879578v0.1879883h-0.1879883v0.1879578v0.1879578l0.1879883,0.1879578h0.3759155
        v-0.1879578v-0.1879578v-0.1879578v-0.1879883h0.1879272v-0.1879578v-0.1879578h0.1879883v0.1879578h0.3759155l0.1879883,0.1879578
        h0.1879272v-0.3759155v-0.1879578l0.1879883-0.1879578l-0.1879883-0.1879578v-0.375946h0.3759155v-0.3759155h0.1879883v-0.1879578
        h-0.3759155L763.9701538,505.8721924z M763.2183228,507.9397583h-0.1879883l0.1879883,0.1879883V507.9397583z
        M765.8497925,518.6536255v0.1879272h-0.1879883v0.1879883v0.1879272v0.1879883h-0.1879272v0.1879272h-0.1879883
        l-0.1879272,0.1879883v0.1879883h0.3759155l0.1879272-0.1879883v-0.1879883h0.1879883v-0.1879272v-0.1879883l0.1879883-0.1879272
        v-0.1879883v-0.1879272v-0.1879883h-0.1879883V518.6536255z M762.8424072,518.4656372h-0.1879883v-0.1880493l-0.1879883-0.1879272
        l-0.1879272,0.1879272l-0.1879883,0.1880493v0.1879272v0.1879883h-0.5639038v0.1879272v0.1879272v0.1879883h0.1879883v0.1879272
        v0.3759766h0.3759155l0.1879883,0.1879272h0.3759155v-0.1879272h0.1879883v-0.1879272v-0.1880493v-0.1879272l-0.1879883-0.1879883
        h0.1879883V519.02948v-0.1879272v-0.1879883h0.1879272v-0.1879272v-0.1880493h-0.1879272V518.4656372z M754.947998,520.3452759
        h0.3759155l0.3759155-0.1879883l0.1879272-0.1879272l0.1879883-0.1879883l0.1879883-0.1879883v-0.9397583l-0.1879883-0.1879883
        v-0.1879883h-0.9398193l-0.3759155,0.1879883h-0.9398193l-0.1879272,0.1879883v0.1879272h0.1879272v0.7518311h-0.1879272v0.3759766
        h0.1879272v0.5638428l0.1879883-0.1879272v-0.1879883v-0.1879272h0.3759155l0.1879272,0.1879272h0.3759766V520.3452759z
        M759.6470337,522.9766846h-0.1879272v-0.1879883h0.1879272l-0.1879272-0.1879272l-0.1879883-0.1879883v-0.3759155
        l0.1879883-0.1879883h0.1879272v-0.1879272h-0.1879272v-0.1879883h-0.1879883v-0.3759155v-0.1879272h-0.1879272
        l-0.1879883-0.1879272l-0.1879883-0.1880493h-0.3759155v-0.1879272v-0.1879883l-0.1879272-0.1879272l-0.1879883,0.1879272
        h-0.1879272v0.3759155l-0.1879883,0.1880493h-0.3759155l-0.1879883,0.1879272h-0.3759155l-0.1879272,0.1879272h-0.1879883
        v0.1879883l-0.1879883-0.1879883v0.1879883h-0.1879272v0.3759155h-0.1879883v0.1879272h-0.1879272l-0.1879883,0.1879883v0.1879883
        v0.1879272l-0.1879883,0.1879883l-0.1879272,0.1879272v0.1879883v0.1879883v0.1879272l0.1879272,0.1879883l0.1879883-0.1879883
        h0.1879883v-0.1879272h0.1879272v0.1879272v0.1879883h-0.1879272v0.1879272h-0.1879883v0.1879883h0.1879883l0.1879272,0.1879272
        v-0.1879272l0.1879883,0.1879272h0.5639038v0.1879883v0.1879883l-0.1879883,0.1879272h-0.1879883v0.1879883v0.1879272
        l-0.1879272,0.1879883h0.3759155v-0.1879883h0.3759155v-0.1879272h0.1879883l0.1879272-0.1879883l0.1879883-0.1879272v-0.1879883
        h-0.1879883v-0.1879883h0.1879883l0.3759155-0.3759155l0.1879883-0.1879272v-0.1879883h0.3759155v0.1879883v0.1879272h0.5638428
        v0.1879883h0.1879883v-0.1879883h0.9397583v-0.1879272l-0.1879272-0.1879883V522.9766846z M754.7600098,524.1044922v0.1879272
        h0.3759155v-0.1879272v-0.1879883l-0.1879272,0.1879883H754.7600098z M737.0916138,510.0073242v-0.375885h-0.1879883
        l-0.1879272-0.1880188v-0.1879578h0.1879272v-0.1879578v-0.1879272v-0.3759155v-0.1880188v-0.1879578v-0.1879272
        l-0.1879272-0.1879578v-0.3759155v-0.1879578H736.52771v-0.1879883h-0.3759155l-0.1879883,0.1879883h-0.9398193
        l-0.1879272-0.1879883h-0.3759155l-0.1879883,0.1879883h-0.5639038v0.1879578h-0.1879272l0.1879272,0.1879578h0.1879883
        l0.1879272,0.1879578h0.1879883v0.375885h0.1879883v0.1879578l0.5638428,0.1880188l0.3759155,0.3759155h0.1879883
        l0.1879883,0.1879272l0.3759155,0.1879578h0.1879272l0.1879883,0.1879578v0.1880188l0.1879883,0.1879578H736.52771v0.1879272
        h0.1879883l0.1879272,0.1879578l0.1879883,0.1879578h0.1879272v-0.1879578h-0.1879272V510.0073242z M741.6027222,508.8795776
        h-0.1879883l-0.1879272,0.1879272v0.1880188v0.1879578h0.1879272v0.1879578h0.1879883v0.1879578h0.1879272l0.1879272-0.1879578
        l0.1879883-0.1879578h0.1879272v0.1879578l0.1880493-0.1879578h0.1879272v-0.1879578v-0.1880188h-0.9398193
        L741.6027222,508.8795776z M711.5287476,498.3536987v-0.1879578h-0.1879272h-0.1879883l-0.1879883,0.1879578h0.3759766v0.1879578
        L711.5287476,498.3536987z M713.220459,500.2332764v-0.1879578l0.1879272,0.1879578h0.1879272v-0.1879578h0.3759155v-0.1879578
        v-0.1879578h0.1879883v-0.1879272v-0.1880188v-0.1879578h-0.1879883v-0.1879578v-0.1879578h-0.75177v0.1879578h-0.1880493
        v0.1879578h-0.3759155v-0.1879578v-0.1879578h0.1879883v-0.1879272h0.1879272v-0.1879578h0.5639038v-0.1879578h0.1879883
        v-0.1880188l-0.1879883-0.1879578h-0.9398193v0.1879578h-0.75177v0.1880188l-0.1880493,0.1879578v0.1879578l0.3759766,0.5638428
        v0.1879578l0.1879883-0.1879578v0.1879578v0.1880188v0.1879272h-0.1879883v0.1879578h0.1879883v0.1879578h0.5638428v0.1879578
        H713.220459z M715.8518677,503.4286804v-0.1879272h-0.5638428h-0.1879883l-0.1879883,0.1879272v-0.1879272h-0.1879272v0.1879272
        h-0.1879883v0.1879578l-0.3758545,0.1879578v0.1879578v0.1879578h-0.1880493l0.1880493,0.1879578v0.1879883h0.1879272
        l0.1879272,0.1879578h0.3759155l0.1879883-0.1879578l0.1879883-0.1879883h0.1879272l0.1879883-0.1879578v-0.1879578h0.1879272
        v-0.1879578l0.1879883-0.1879578h-0.1879883V503.4286804z M713.972229,506.6240234l-0.1879272-0.1879578l-0.1879883-0.1879578
        l-0.1879883-0.1879578h0.1879883v-0.1879578v-0.1879883l0.1879883-0.1879578v-0.5638733l-0.1879883-0.1879578h-0.1879883
        l-0.1879272-0.1879578h-1.5037231l-0.3759155-0.1879883h-0.1879883v0.1879883h-0.3759155l-0.1879272-0.1879883h-0.1879272
        l-0.1879883,0.1879883h0.1879883v0.1879578h-0.1879883v0.1879578h-0.1879883v0.1879578h0.1879883v0.1879578h0.9397583
        l0.1879883,0.1879578l0.1879883,0.1879578v0.1879883l0.1879272,0.1879578v0.1879578h0.1879883v-0.1879578h0.1879272v0.1879578
        h0.3759155v0.1879578l-0.1879272,0.1879578l0.1879272,0.1879578l0.1879883,0.1879883v0.1879578v0.1879578l0.1879883,0.1879578
        v0.1879578h0.3759155v0.1879578h0.5639038v-0.1879578v-0.1879578h0.1879272v-0.3759155l0.1879883-0.1879578v-0.1879883h-0.1879883
        V506.6240234z M712.2805786,507.375885l0.1879272,0.1879578v-0.1879578H712.2805786z M727.1295776,498.9175415
        l-0.1879272,0.1879578v0.3759766v0.1879272h0.1879272v-0.1879272h0.5638428v-0.1880188h0.1879883l-0.1879883-0.1879578
        l-0.1879272-0.1879578H727.1295776z M712.6564941,514.7063599l-0.1879272-0.1879272h-0.3759766l-0.1879272,0.1879272
        l-0.1879883,0.1879883l-0.1879272-0.1879883h-0.1879272v-0.1879272l-0.1880493,0.1879272v-0.1879272h-0.1879272
        l-0.1879883-0.1879272l-0.1879272-0.1880493v-0.5638428v-0.3759155h0.1879272v-0.1879883h0.5639648v-0.1879883h0.5638428
        l-0.1879883-0.1879272h-0.5639038v-0.1879272h0.1880493l0.1879272-0.1879883h0.1879272v-0.1879272l0.1879883-0.1879883h-0.1879883
        v-0.375946h-0.1879272l-0.1879272,0.1879578h-0.1880493v0.1879883h-0.3759155v-0.1879883l-0.1879272,0.1879883h-0.5638428
        l-0.1880493,0.3759155l-0.1879272,0.1879883h0.1879272v0.1879272h0.1880493v0.1879272h-0.1880493v0.1879883h-0.1879272
        l0.1879272,0.1879883h-0.1879272l-0.1879272,0.1879883h-0.1879883l-0.1879272,0.1879272l0.1879272,0.1879272l0.1879883,0.1879883
        h0.7518311v0.1879272l0.1879883,0.1880493v0.1879272v0.1879272l-0.1879883,0.1879883v0.1879272h-0.7518311l-0.1879883,0.1879883
        l-0.1879272,0.1879883v0.3759155h-0.1879883v0.3759155h-0.1879883v-0.1879883v-0.1879272h-0.3759155v0.1879272h-0.1879272
        v0.1879883h0.1879272v0.1879272h0.1879883l-0.1879883,0.1879883l0.1879883,0.1879883h0.5639038v0.1879272h0.3759155
        l0.1879272-0.1879272h0.1879272v-0.1879883h0.1880493v-0.1879883h0.3759155v-0.1879272h-0.1879883l0.1879883-0.1879883h0.1879272
        v-0.1879272h0.5638428v-0.1879883h0.3759766v0.1879883h0.1879272v0.3759155h0.1879883l0.1879272-0.1879883v-0.1879272v-0.1879883
        h0.3759766l0.1879272-0.1879272h-0.1879272l0.1879272-0.1879883v-0.1879883h0.1879883v-0.1879272h-0.1879883V514.7063599z
        M709.0852661,517.1499023v-0.1879883h-0.1879883L709.0852661,517.1499023z M703.2584229,517.1498413l-0.1879883,0.1879883
        l-0.1879883-0.1879883v-0.1879272h0.1879883v-0.1879883v-0.1879272v-0.1879883h-0.1879883v-0.1879272v-0.1879883h-0.1879272
        v-0.1879883h-0.7518311v0.1879883h-0.1879883l-0.1879883,0.1879883v0.1879272h-0.1879272v0.1879883h-0.1879272v0.1879272v0.1879883
        v0.3759155v0.1879883l0.1879272,0.1879272h0.1879272l0.1879883,0.1879883h0.3759766v0.1879272l0.1879272,0.1879272
        l0.1879272,0.1880493h0.5639038v-0.1880493h0.3759155v-0.1879272l0.1879883-0.1879272v-0.1879883v-0.1879272v-0.1879883
        l-0.1879883-0.1879883H703.2584229z M687.6575317,522.4127808l-0.1879272-0.1879272v0.1879272h-0.1879883l-0.1879272-0.1879272
        h-0.5639038l-0.1879883,0.1879272v0.1879883h-0.1879272v0.1879272h0.1879272l0.3759766,0.1879883v0.1879883h-0.1879883v0.1879272
        h-0.1879883v0.1879883h-0.3759155v0.1879272h-0.3759155v-0.1879272h-0.7518311l-0.1879883,0.1879272l-0.1879272,0.1879883
        v0.1879272v0.1879883v0.1879883h0.1879272v0.1879272h0.1879883l0.1879272,0.1879883l0.1879883,0.1879272h0.1879272v-0.1879272
        h0.1879883v-0.1879883v-0.1879272h0.1879883v-0.1879883l0.1879272,0.1879883h0.9398193l0.1879883-0.1879883v-0.1879883
        l0.1879272,0.1879883h0.3759155v-0.1879883v-0.1879272h-0.1879272v-0.1879883v-0.1879272h-0.1879883v0.1879272h-0.1879272
        v-0.1879272v-0.5639038h0.5638428l0.1879883-0.1879883h0.1879883v-0.1879272l-0.1879883-0.1879883H687.6575317z
        M688.4094238,519.9692993v-0.1879272h-0.3759766l-0.1879272-0.1879883l-0.1879883-0.1879272h-0.3759155v0.1879272h-0.1879272
        l-0.1879883-0.1879272l-0.1879883-0.1879883V519.02948h-0.1879272v-0.1879272h-0.1879883l-0.1879272-0.1879883h-0.3759155
        v0.5639038h-0.1879883l0.1879883,0.1879883v0.1879272v0.1879883v0.1879272h-0.1879883v0.1879272v0.1879883l0.1879883,0.1879883
        v-0.1879883h0.5638428v-0.3759155h0.1879883v-0.1879272h0.5639038v0.1879272h0.1879272v0.1879272l-0.1879272,0.1879883
        l0.1879272,0.1879883h0.1879883v-0.1879883h0.1879272v0.1879883v0.1879883h0.3759155v-0.1879883H687.84552v-0.1879883v-0.1879883
        h0.5639038h0.1879272v-0.1879272H688.4094238z M688.4094238,520.3452148h0.3759155v-0.1879883h-0.1879883L688.4094238,520.3452148z
        M684.6501465,521.848938h0.1879883h0.1879272v-0.1879883v-0.1879883h-0.1879272v0.1879883h-0.3759155l-0.1879272,0.1879883
        h-0.1879883l0.1879883,0.1879272h0.1879272L684.6501465,521.848938z M693.6723633,513.390686h0.1879272v-0.1880493h-0.1879272
        V513.390686z M697.6195679,489.5194397v0.1879578v0.3759766v0.1879272h0.5638428v-0.1879272h0.1879272v-0.1880188h-0.1879272
        v-0.1879578h-0.1879272l-0.1879883-0.1879578v-0.1879578L697.6195679,489.5194397z M700.2510376,490.6472778l0.1879883,0.1879578
        v-0.1879578H700.2510376z M671.4928589,512.8267822l0.1879272,0.1879883l0.1879883,0.1879272h0.1879272v-0.1879272
        l0.1879272-0.1879883v-0.1879272h-0.75177V512.8267822z M737.279541,508.6915588l0.3759155-0.1879578h-0.3759155V508.6915588z
        M729.3851318,499.4814148v-0.1879578l-0.3759155,0.1879578l0.1879883,0.1879883v-0.1879883H729.3851318z M731.0768433,498.3536377
        h0.5638428l-0.5638428-0.1879578V498.3536377z M751.1887817,498.9175415h-0.3759766v0.1879578h0.3759766l0.1879272,0.1879578
        h0.1879272l-0.1879272-0.1879578L751.1887817,498.9175415z M754.0081787,514.7063599l0.1879272,0.1879883h0.3759766v-0.1879883
        l0.1879272,0.1879883l0.1879883-0.1879883h0.3759155v-0.1879883l0.1879272,0.1879883v0.1879883h0.3759766v-0.1879883
        l0.1879272-0.1879883l0.1879883,0.1879883h0.7518311v0.1879883l0.1879883-0.1879883l-0.1879883-0.1879883v-0.1879272h-0.3759155
        v-0.3759155h-0.3759155l-0.1879883,0.1879272h-0.1879272l-0.1879883-0.1879272l0.1879883-0.1879883h0.1879272l-0.1879272-0.1879883
        v-1.5036621h-0.1879883v-0.3759155l-0.1879883-0.1879578l-0.1879272-0.1879883l-0.1879883-0.1879578v-0.3759155h-0.1879272
        v-0.1879578h0.1879272v-0.1879578h0.5639038l0.1879883,0.1879578h0.3759155l-0.1879883-0.1879578v-0.1879578h-0.1879272
        l-0.1879883-0.1879883h-0.3759155v-0.3759155l0.1879272-0.1879578v-0.1879578h0.1879883v-0.1879578l-0.1879883-0.1879883
        l-0.1879272-0.1879578l-0.1879883-0.1879578h-0.1879272v-0.1879578l0.1879272-0.1879578l-0.1879272-0.1879272v-0.1880188
        h-0.3759155l-0.1879883-0.1879578v-0.3759155h-0.1879883v-0.1879272l0.1879883-0.1879578h0.1879883v0.1879578h0.1879272v-0.1879578
        h0.1879883v-0.3759766h0.1879272v-0.1879578h0.1879883l0.1879272,0.1879578l0.1879883,0.1879578v-0.1879578h0.1879883v-0.3759155
        h-0.1879883v-0.1879272l0.1879883-0.1879578l0.1879272-0.1879578v-0.5639038h0.1879883l-0.1879883-0.1879578v-0.1879578
        l-0.1879272-0.1879578v-0.1880188l0.1879272-0.1879272h0.1879883l0.1879272-0.1879578l-0.1879272-0.1879578l-0.3759155-0.1879578
        l-0.1879883-0.1879578h-0.1879883l-0.9397583-0.9398193l-0.1879883,0.1879578l-0.1879883-0.1879578v-0.1879578l-0.1879272-0.375946
        l-0.1879883,0.1879578l0.1879883,0.375946l-0.3759155,0.1879578h-0.1879883l-0.1879272-0.3759155l0.5638428-0.5639038h-0.1879272
        l-0.1879883-0.3759155l-0.1879272-0.1879578l-0.1879883-0.375946v-0.1879578l-0.1879883-0.1879578l-0.1879272-0.1879578
        l-0.5638428-0.3759155l-0.3759766-0.375946h-0.1879883v0.1879578h-1.5036621l-0.1879272-0.1879578l0.1879272-0.1879578v-0.1879578
        l-0.1879272,0.1879578v-0.1879578h-0.1880493l-0.3759155-0.375885l-0.5638428-0.1880188l-0.7518311-0.1879578l-0.9398193-0.3759155
        l-0.1879883-0.1879272h-0.3759155v-0.1879578l-0.1879272,0.1879578h-0.1879883l0.1879883-0.1879578h-1.3157349h-0.1879883
        h-0.3759155l-0.3759155,0.1879578l-0.3759766,0.1879272l-0.1879272,0.1879578l-0.1879883-0.1879578l-0.7518311,0.7518921
        h-0.1879883l0.1879883,0.1879272v0.1879578h0.1879883v0.1879578l-0.5639038,0.1879578h-0.1879883l-0.3758545,0.375946h-0.1880493
        v-0.375946h0.1880493v-0.1879578h-0.3759766v0.3759155h-0.1879883h-0.1879272h-0.9398193v0.1879883h-0.1879272v-0.375946
        h-0.1879883v-0.1879578h-0.1879272v0.1879578h-0.1879883v0.375946h-0.7518311v-0.1879883h-0.5639038l-0.3759155-0.1879578
        l-0.1879883-0.1879578v-0.1879578v-0.1879578l-0.1879272,0.1879578v-0.1879578h-0.1879883v-0.1879272l-0.1879883,0.1879272
        v-0.1879272l-0.1879272,0.1879272v-0.1879272h-0.1879883v-0.1880188l-0.1879272,0.1880188v-0.1880188l-0.1879883,0.1880188
        v-0.1880188l-0.3759155,0.375946v-0.1879272h-0.7518311v-0.1880188l-0.1879883-0.1879578l-0.7518311-0.1879578h-1.1278076
        l-0.1879272,0.1879578v-0.1879578h-0.1879883v0.1879578h-0.5639038v0.1879578h-0.1879272v0.1880188h-0.3759155l-0.3759155,0.375885
        l-0.3759766,0.1879578l-0.1879272,0.1879578l-0.1879272,0.1879578h-0.1879883v-0.1879578h-0.1879272v0.1879578h-0.1880493
        l-0.3758545,0.1879883l-0.5639038,0.1879578l-0.1879883,0.1879578l-0.1879272,0.1879578l-0.1879883,0.1879578v0.9398193h0.3759155
        v0.1879578l0.1879883,0.1879578h0.1879883l0.1879272,0.1879578h0.1879883l0.1879272,0.1879883v0.3759155l0.1879272,0.3759155
        l0.1880493,0.1879578l0.1879272,0.375946l0.1879883,0.1879578v0.1879578l0.1879272,0.1879578l0.1879272,0.1879272
        l0.1879883,0.1880188l0.1879883,0.1879578l0.1879883,0.5638428v0.3759766l0.1879272,0.3759155v-0.1879578h0.1879272v0.1879578
        h0.1879883l0.1879272,0.375885l0.1879883,0.1879578h0.9398193l0.1879272,0.1879578h0.3759766h1.8795776l0.3759766,0.1880188
        h1.1277466v0.375885h0.3759155v-0.1879272h0.1879883l0.1879272,0.1879272v0.1879578h-0.1879272v0.1879578l0.1879272,0.1879578
        h0.1879883v0.5639038l-0.1879883,0.1879578v0.1879578h0.1879883v0.1879578l0.3759155,0.1879883v0.1879578l-0.1879272,0.3759155
        h0.1879272v0.1879578h0.1879883l0.1879272,0.1879578h0.3759155v0.1879883h0.3759766l0.1879272-0.1879883v-0.3759155
        l0.1879883-0.1879578v-0.5639038l0.1879272-0.1879578v-0.1879578l0.1879272-0.1879578v-0.1879578h0.1880493v-0.1879272
        l0.1879272-0.1880188v-0.1879578l0.1879883-0.1879578v-0.1879578h0.5638428l0.1879272-0.1879272h0.1880493v0.1879272h0.1879272
        l0.1879272-0.1879272l0.1879883,0.1879272l0.1879272,0.1879578l0.1879883,0.1879578l-0.1879883,0.1879578h-0.1879272v0.5639038
        l0.1879272,0.1879578l0.1879883,0.1879578l0.1879883,0.1879578l0.1879272,0.1879883h0.1879883v-0.1879883h0.3759155
        l0.1879272-0.1879578h0.1879883l0.3759155,0.375946l0.1879883,0.1879578v1.1277771h-0.3759155v0.1879578h0.1879272v0.3759155
        h0.1879883v0.1879578l-0.1879883,0.1879578h-0.5639038v0.1879883l0.1879883,0.1879578h0.1879883l0.1879272,0.1879578h0.1879883
        l0.1879272,0.1879578h0.3759766l0.1879272,0.1879272v0.5639038h-0.3759155v0.1879883h-0.1879883l-0.3759155,0.3759155v0.5639038
        l0.1879883,0.1879272h0.3759155l0.1879883-0.1879272v-0.1879883h0.1879272v-0.1879883h0.5639038v0.1879883h0.1879272v-0.1879883
        h0.1879883l0.1879883-0.1879272l-0.1879883-0.1879883l0.1879883-0.3759155h0.3759155l0.1879272-0.1879272v-0.1879883h0.1879883
        l0.1879272,0.1879883v0.1879272h0.1879883l0.1879883,0.1879883v0.1879272l0.1879272,0.1879883v0.5639038h-0.3759155v0.1879272
        l-0.1879883,0.3759155v1.3157349l-0.1879272,0.1879883v0.7518311l0.1879272,0.1879883l0.1879883,0.1879272l0.1879883,0.1879883
        h0.5638428l0.1879272-0.1879883h0.1880493v0.1879883l0.1879272-0.1879883h0.7518311v-0.1879272l0.1879272-0.1879883v-0.1879272
        l-0.1879272-0.1879883v-0.3759155l-0.3759155-0.1879883v-0.1879272l-0.1879272-0.1879883v-0.1879272h-0.1879883v-0.1879883
        h0.1879883v-0.1879272h-0.1879883v-0.1879883h-0.1879272v-0.3759155h0.3759155v-0.3759155l0.1879272-0.1879883h0.1879272
        v-0.5639038v-0.1879272h-0.1879272v0.1879272l-0.1879272-0.1879272v-0.1879883l-0.1879883,0.1879883l-0.1879272-0.1879883
        h-0.1880493l-0.1879272-0.1879272l-0.1879272-0.1879883h-0.1879883v-0.1879883l-0.1879272-0.375885v-0.1879578h0.1879272v-0.375946
        h0.1879883v-0.1879578h0.1879272l0.1879272-0.1879578l0.1880493-0.1879578v-0.3759155h0.5638428v-0.1879578l0.1879272-0.1879883
        l0.1879883-0.1879578v-0.1879578h0.1879272v0.1879578h0.1880493v0.1879578l0.3758545,0.1879883h0.5639038v-0.7518616
        l-0.1879883-0.3759155v-0.375946l-0.1879272-0.1879578h-0.7518921v-0.7518616h-0.1879272v-0.5638733l0.1879272-0.1879272
        h-0.1879272v-0.1879578h0.3759766v0.375885h0.1879272v0.7518921h0.1879272v-0.1880188h0.3759155l0.1879883,0.1880188h0.3759766
        v0.1879272h0.1879272v0.1879578h0.1879272v-0.375885h0.1879883v0.9397583l-0.1879883,0.1879883h0.1879883v0.1879578
        l0.1879272,0.1879578v0.5638733h0.1880493v0.9398193l0.1879272,0.1879578h-0.1879272v0.5639038h-0.1880493l0.1880493,0.1879578
        v0.1879578h0.3758545v-0.1879578h0.1879883v-0.1879578h0.1879272v0.1879578h0.1879883v0.375885l-0.3759155,0.1879883h0.5639038
        v0.3759155l0.1879272,0.1879883h-0.1879272v0.1879272h0.1879272v0.7518921h-0.1879272l-0.1879883,0.1879272h0.1879883v0.1879883
        l0.1879272,0.1879272l0.1879883,0.1879883H754.0081787z M752.3165283,512.4508057h0.1879272v-0.1879883h-0.1879272V512.4508057z
        M756.0757446,511.1351013h0.5639038v-0.1879883h-0.5639038V511.1351013z M757.015564,515.4581909v-0.1879272v-0.1879883
        h-0.1879272v-0.1879272h-0.1879883v0.1879272v0.1879883h0.1879883v0.1879272H757.015564z M749.309082,514.8943481h0.1879883
        v-0.1879883h-0.1879883V514.8943481z M760.9627686,503.0527344h-0.1879883v0.1880188h0.1879883V503.0527344z
        M741.4146729,512.8267822l0.1880493,0.1879883v0.1879272l0.1879272,0.1879883h0.1879272l0.1879883-0.1879883h0.1879272v-0.3759155
        h-0.75177H741.4146729z M701.1908569,488.9555969v-0.1879578l0.1879883-0.1879578v-0.5638733h-0.1879883l-0.1879883,0.1879578
        v0.1879578l-0.1879272,0.1879578v0.1879578l-0.1879883,0.1879578v0.1879578l0.1879883,0.1879883l0.1879272-0.1879883
        L701.1908569,488.9555969z"
            filter="url(#dropshadow)" // Apply the drop shadow filter here
          />
        </a>
        <text x="800" y="500" fontSize={16} color="000000" className="centered-text">香港</text>

        <a xlinkHref="../about/shenzhen.html" id="dmap_shenzhen" className="focus">
          <title>深圳</title>
          <path id="Shenzhen_3_" className="gba7" d="M812.2764282,462.4529724h0.1879272l-0.1879272,0.1879578v0.1879578h-0.5639038v-0.1879578
        v-0.1879578h0.1879272v-0.1879578h0.1879883L812.2764282,462.4529724z M674.8760986,453.6187439v-0.1879578h-0.1879272
        l-0.3759155-0.1879578l-0.1879883-0.1880188v-0.1879578h-0.7518311l-0.1879883-0.1879272v-0.1879578h-0.1879272
        l-0.1879272-0.1879578l-0.1879883-0.1879578v-0.1879578l-0.1879883,0.1879578l-0.1879883,0.1879578v0.3759155v0.375885
        l0.1879883,0.1880188v0.1879578h0.3759766l0.1879272,0.375885l0.1879272,0.1879578h0.1879883l0.1879272,0.1879578v0.1880188
        h0.1879883l0.1879883,0.1879578l0.3759155,0.1879272h0.5638428l0.1879883-0.1879272l-0.1879883-0.1879578V453.6187439z
        M673.5604248,455.6862793l-0.1879272-0.1879578h-0.1880493l-0.1879272-0.1879578h-0.1879272v-0.1879578v-0.1879578h-0.1879883
        v-0.1879578h-0.1879272v-0.1879272v-0.1880188h-0.1879883v-0.1879578h-0.1879272v0.1879578v0.1880188h0.1879272v0.1879272
        v0.1879578h-0.1879272v0.1879578h-0.3759766l-0.1879272-0.1879578v0.3759155v0.3759155h0.1879272v0.1879883h0.1879883
        l0.1879883,0.1879578h0.1879272v0.1879578h0.1879883l0.1879272,0.1879578h0.1879883v0.1879578v0.1879578l0.1879272,0.1879883
        h0.1879272l0.1880493-0.1879883l0.1879272-0.1879578l0.1879883-0.1879578l0.1879272-0.1879578v-0.1879578v-0.1879578h-0.1879272
        L673.5604248,455.6862793z M668.1094971,474.2945251l-0.1879883-0.1879578h-0.1879272l-0.1879883-0.1879578h-0.1879272v-0.1879883
        l-0.1879883-0.1879578v-0.7518311h-0.1879272v-0.1879578v-0.1879883l-0.1879883-0.1879578h-0.1879883v-0.1879578
        l-0.1879272-0.1879578l-0.1879883-0.1879578v-0.1879578h-0.3759155l-0.1879883,0.1879578v0.1879578l-0.1879272,0.1879578
        h-0.1879883l-0.1879272-0.1879578h-0.5638428l-0.1880493-0.1879578v-0.5639038l-0.1879272-0.1879578l-0.1879883-0.1879578
        l-0.1879272,0.1879578h-0.3759155v0.5639038v0.1879578v0.3759155h-0.1879883v0.1879578h-0.1879883l-0.1879272,0.1879578h-0.3759155
        v-0.1879578l-0.1879272-0.1879578v-0.1879578H661.71875l-0.1879272,0.1879578H660.96698v0.5639038v0.1879578h0.5638428
        l0.1879272,0.1879578h0.5639648l0.3758545,0.1879578l0.1879883,0.1879578h0.1879272l0.3759766,0.1879578v0.1879578h0.1879272
        l0.3759155,0.1879883l0.1879883,0.1879578v0.1879578v0.5638733h0.3759766v0.1879883l0.3758545,0.1879578l0.3759155,0.1879578
        h0.1879883l0.3759155,0.1879578h0.9398193l0.1879883,0.1879578h0.1879272l0.1879883,0.1879578h0.9398193v-0.1879578v-1.1277771
        l-0.1879883-0.1879578V474.2945251z M671.4928589,447.0400391v-0.1879578h0.1879272v-0.1879578h0.3759155v-0.1879578h0.3759766
        l0.1879272-0.1879578v-0.1879272h-0.7518311v0.1879272l-0.1879883-0.1879272h-0.3759155v-0.1879578h-0.1879272v0.1879578v0.1879272
        v0.1879578l0.1879272,0.1879578v0.3759155H671.4928589z M784.4580078,433.8827209l0.1879883-0.1879578h-0.1879883V433.8827209z
        M759.2710571,439.1456604l-0.1879272-0.1879883v0.1879883H759.2710571z M829.3808594,452.8668518v-0.1879272l-0.1879272-0.1879578
        l-0.1879883-0.1879578l-0.1879883-0.1879578v-0.1880188l-0.3759155-0.375885l-0.3758545-0.3759155l-0.1879883-0.1879578
        l-0.1879883-0.1879883l-0.3759155-0.1879578l-0.1879272-0.1879578l-0.1879883-0.1879578l-0.1879272,0.1879578l-0.3759766-0.3759155
        l-0.3759155-0.1879578l-0.3759155-0.1879883V448.73172l0.3759155-0.1879578l0.1879883-0.375946h0.1879272v-0.3759155h-0.1879272
        v-0.3759155h-0.1879883v-0.1879883h0.1879883v-0.1879578h-0.7518921v0.1879578h-0.3759155l-0.3759155,0.375946h-0.1879272
        v-0.1879578h-1.8796387l-0.1879883-0.1879883l-0.3759155-0.1879578h-0.1879883l-0.1879272-0.1879578h-0.9398193
        l-0.3759155-0.1879578l-0.1879883-0.1879578h-0.1879272l-0.1879883-0.1879578h-1.3156738l0.1879272-0.1879883h-0.1879272
        l-0.1880493,0.1879883h-0.1879272l-0.5638428-0.375946l-0.1879883-0.3759155v-0.1879578l-0.1879883-0.1879272l-0.1879883-0.1880188
        l-0.1879272-0.1879578v-0.1879578h0.1879272v-0.1879578l-0.75177-0.5638428h-0.5639038l-0.1879883,0.1879578v0.1879578h-0.1879272
        l-0.1879883-0.1879578l-0.1879883-0.1879578l-0.1879272-0.3759766v-0.375885h0.1879272v-0.1879578l-0.1879272-0.1879578v-0.1880188
        h0.1879272v-0.1879272l-0.1879272-0.1879578l-0.1879883-0.1879578h-0.1879272v-0.1879578h-0.3759155v-0.1879578h-0.1879883
        v0.1879578l-0.1879883-0.1879578l-0.1879272,0.1879578h-0.1879883l0.1879883,0.1879578v0.1879578h-0.1879883v0.1879578h-0.1879272
        l-0.1879883-0.1879578l-0.3759155-0.3759155h-0.1879883l-0.1879272,0.1879578h-0.3759155l0.1879272,0.5638428l-0.1879272,0.1880188
        l-0.3759766-0.375946l-0.1879272,0.1879272l-0.1879883-0.1879272l-0.1879272-0.1879578h-0.5639038l-0.3759155,0.1879578v0.1879272
        l0.1879272,0.1880188l0.000061,1.1277771l-0.3759155,0.3759155v-0.1879578l-0.1879272,0.1879578v0.1879272h-0.1879883
        l-0.3759155,0.1879578v0.1879578h-0.1879883v0.5639038h-0.1879272l-0.7518921-0.1879272l-1.1277466-0.1880188h-0.1879883
        l-1.3157349-0.3759155l-0.3758545,0.1879578l-0.1879883-0.1879578l0.3759155-1.8795776v-0.1880188h0.1879272v-0.1879272
        l-0.1879272-0.5638733l-0.1879272-0.7518616v-1.3157349l0.5637817-0.7518616l0.5639038-0.9397888l0.7518311-0.9398193
        l0.9398193-0.1879272l0.7518921,0.7518005h0.1879272v0.1879578h0.7518921l0.1879272-0.1879578l0.1879272,0.1879578
        l0.1879883-0.1879578l0.5639038-0.1879578l0.3759155,0.1879578h0.3759155l0.1879272,0.1879578h0.1879883v-0.1879578h0.1879883
        v0.1879578h0.1879272l0.1879883-0.1879578l-0.1879883-0.1879578h-0.1879272v-0.1879578h0.1879272l0.3759155-0.1879578
        l0.1879883,0.1879578h0.5639038l0.1879272,0.1879578h0.1879883l-0.1879883-0.1879578v-0.1879578h0.1879883v0.1879578h0.1879272
        l0.1879883-0.1879578h-0.1879883l0.1879883-0.1879272v0.1879272h0.1879883v1.1277771l0.1879272-0.1879578v-0.1879883
        l0.1879883-0.1879578v-0.5638733l0.1879272-0.5639038l0.3759155-0.3759155l0.1879883-0.375885l0.5639038-0.3759766
        l1.8796387-1.3157349l1.6915894-1.5036621l0.7518921-0.1879578l0.3759155-0.1879578h0.1879272v-0.1879883l0.1879883-0.1879578
        v-0.9398193h0.1879883v-0.3759155h-0.3759766v-0.7518311l0.1879883-0.1879883l0.3759155-0.9397888l0.3759155-0.5639038
        l0.1879883-0.1879578l0.3759155-0.1879578h0.5639038l0.3759155-0.375946v-1.1277771l0.1879883-0.1879578l0.1879272-0.1879578
        h0.1879883v-0.1879272h0.1879272l0.1879883-0.1879578v-0.3759155h-0.3759155l-0.1879883-0.1880188l0.1879883-0.1879578
        l-0.1879883-0.1879272h-0.3759155l-0.1879883,0.1879272h-0.1879272l-0.3759155-0.375885v-0.1879578h-0.5639038v-0.1879578
        h-0.1879883v-0.1880188h-0.1879272v0.3759766h-0.1879883v0.3759155h-0.5639038v-0.1879578l-0.3759155-0.1879578v-0.1879578
        l-0.1879272-0.1880188h-0.1879883v0.5639343h-0.5639038v0.7518616l-0.1879272,0.1879578h-0.3759155v0.1879578h-0.3759766
        v-0.1879578h-0.3759155v0.1879578h-0.7518311v-0.3759155h-0.5638428v0.1879578h-0.1879883v0.3759155l-0.1879272-0.1879578
        h-0.7518921l-0.1879272,0.1879578h-0.1879883h-0.5639038v-0.1879578h-0.3759155v0.1879578h-0.1879272v-0.1879578h-0.9398193
        v0.1879578h-0.5639038v-0.1879578l-0.1879883-0.1879578h-0.1879272v0.3759155h-0.1879883v0.1879272h-0.1879272v-0.375885
        h-0.1879883v0.1879578h-0.1879883l-0.1879272-0.1879578h-0.1879883l0.3759155-0.3759155h-0.7518311l-0.1879272-0.1880188
        h-0.3759766l-0.1879272-0.1879578l-0.1879883,0.1879578l-1.1277466,0.3759766h-0.1879272l-0.1879883,0.1879578v0.1879578
        h-1.3157349l-0.1879883,0.1879272v0.3759155l0.1879883,0.1880188l-0.1879883,0.1879578l-0.1879272,0.1879272h-0.1879883
        l-0.1879272-0.1879272h-0.1879883v-0.3759766h-0.7518311v0.1880188l-0.1879883-0.5639343l-0.1879272-0.375885h-0.1879883
        v-0.1879578h-0.3759155v-0.1879578l0.1879883-0.1880188v-0.1879578h0.1879272v-0.375885h0.1879883v-0.1879578h0.1879272v-0.1879578
        h0.3759155l0.1879883-0.1880188v-0.1879272h0.1879883v-0.3759155h0.1879272l0.1879883-0.1879578h0.1879272v-0.375946h0.5639038
        v-0.3759155l-0.9398193-0.1879578l-0.5639038-0.3759155l-0.7518311-0.375946h-1.8796387v-0.1879578h-0.5638428v0.1879578
        h-0.9398193l-0.1879883,0.1879578h-0.9398193v0.375946l-0.1879272,0.3759155v0.1879578l-0.1879272,0.1879578h-0.3759766
        l-0.1879883,0.1879883v-0.1879883h-0.3758545l-0.1879883-0.1879578l-0.1879883-0.1879578h-0.1879883v-0.1879578
        l-0.1879272-0.1879578l-0.1879272-0.1879578h-0.1879883l-0.1879272-0.1879883h-0.9398193l-0.7518921-0.1879578h-0.5638428
        l-0.1879883-0.1879578h-0.9398193l-0.1879272,0.1879578l-0.1879883,0.1879578v0.375946l-0.1879883,0.1879578h-0.9398193
        l-0.1879272,0.1879578l-1.3157349,0.3759155l-0.5638428,0.375946h-0.5639648v-0.1879578h-0.1879272v-0.1879883h0.1879272
        v-0.3759155l0.1879883-0.1879578v-0.1879578l-0.1879883-0.1879578h-0.1879272v-0.1879883h-0.1879272l-0.1879883-0.1879578
        l-0.1879272-0.9397888v-0.1879883l-0.1880493-0.1879578v-0.1879578h0.1880493v-0.1879578h0.9397583l0.1879883-0.1879578
        l0.1879883-0.1879578l0.1879272-0.1879883v-0.1879578h0.1879272v-0.1879578h0.7518921l0.1879272-0.1879578l0.1879883-0.1879578
        v-0.7518616l0.3759155-0.1879578v-0.5639038l-0.1879883-0.1879578v-0.1879578l-0.3759155-0.375885l-0.3759155-0.3759155h-0.1879883
        v-0.1880188h-0.75177v-0.375885l-0.1879883-0.1879578h-0.9398193l0.1879883-0.1879578h0.1879272v-0.1879578h-0.1879272v-0.1880188
        h-0.3759155l-0.1880493,0.3759766l-0.3758545,0.1879578l-0.1879883-0.1879578v-0.1879578h0.1879883v-0.375946l-0.1879883-0.1879578
        v-0.3759155h0.1879883v-0.375946l-0.1879883-0.1879578v-0.1879578l-0.1879272-0.1879578l-0.3759766-0.1879578h-0.9397583
        l-0.7518921-0.1879578h-0.7518311v-0.1879883h-0.1879883v-0.3759155h-0.1879272l0.1879272-0.1879578l0.1879883-0.1879578
        v-0.3759155l-0.3759155-0.1879883v-0.1879578l-0.1879883-0.1879578l-0.1879272-0.1879578v-0.3759155l0.1879272-0.1879883
        v-0.1879578l0.1879883-0.3759155l0.1879272-0.1879578l0.3759766-0.1879272l0.1879272-0.1880188l0.1879883-0.1879578v-0.1879578
        h0.1879272l0.1879883-0.1879578l0.9398193-0.5639038h0.1879272l0.3759155-0.3759155v-0.7518005l0.5639038-0.7518616V405.3125
        l0.1879883-0.375946v-0.3759155l-0.1879883-0.3759155v-0.375946l-0.1879272-0.3759155v-0.1879578h-0.7518921l-0.1879272-0.3759155
        l-0.1879883-0.7518616v-0.1879578l-0.1879272-0.5639038v-0.1879578h-0.1880493l-0.1879272-0.1879578l-0.1879272-0.1879578
        v-0.3759155l-0.1879883-0.1879883l-0.1879272-0.1879578v-0.1879578h-0.1879883l-0.1879272-0.1879578h-0.5639038v-0.1879578
        h-0.1879883l-0.1879272-0.1879272v-0.1879578h-0.5639038v0.1879578l-0.3759155,0.1879272h-0.1879883l-0.1879272,0.1879578
        h-0.5639038l-0.3759155,0.3759766l0.1879272,0.1879578v1.5036621l0.1879883,0.1879578v0.1879578l0.1879272,0.1879578v0.5639038
        l-0.3759155,0.1879578h-0.1879272l-1.3157349,0.5638733v-0.3759155l-0.1879272-0.1879578l-0.1880493-0.1879578
        l-0.9397583-0.1879578h-0.5639038v-0.1879578l-0.3759155-0.1879272h-0.5639038v-0.1879578l-0.3759155-0.1880188v-0.1879578
        h-0.7518311l-0.1879883,0.1879578h-0.1879272v0.1880188h-0.7518921l-0.1879272-0.1880188l-0.1879883-0.1879578v-0.1879578
        h0.1879883v-0.5638428l-0.1879883-0.5639343h-0.1879272l-0.1879883-0.1879272l-0.1879272,0.1879272h-0.3759766l-0.1879272-0.375885
        v-0.3759766l-0.3759155-0.1879578l-0.1879883-0.1879272h-0.5639038l-0.5638428,0.375885v-0.5638428l-0.3759766-0.3759155
        v-0.1880188h-0.1879272v-0.1879272l0.1879272-0.1879578v-0.3759155h0.1879883v-0.1879578l0.1879883-0.1879578l0.3759155-0.1879883
        l0.3759155-0.1879578v-0.1879578l0.1879272-0.1879578v-0.1879578l0.1879883-0.1879578v-0.375946l-0.1879883-0.1879578
        l-0.1879272-0.1879578v-0.3759155l0.3759155-0.375946l0.1879883-0.1879578v-0.1879578l0.1879272-0.1879578v-0.375946h-0.1879272
        v-0.1879578h-0.7518921l-0.3759155-0.3759155h-0.9398193l-0.3758545-0.1879578l-0.3759766-0.1879272l-0.3759155,0.1879272
        h-0.3759155l-0.3759766,0.1879578h-0.1879272l-0.3759155,0.1879578l-0.1879272,0.1879578v0.1879578h-0.1879883v0.1879883
        h-0.1879883v-0.1879883h-0.1879883l-0.5638428-0.3759155h-0.9398193v0.1879578l-0.1879272,0.1879578h-0.1879883
        l-0.1879272,0.1879883h-0.3759766l-0.1879272,0.1879578l-0.1879883,0.1879578h-0.9398193l-0.3759155,0.1879578v0.1879578
        l-0.5639038,0.375946h-0.3759155v-0.5639038h-0.3759155v-0.3759155l-0.1879883-0.1879578h-0.7518311l-0.1879272-0.1879883
        h-0.1879272l-0.1880493,0.1879883v0.1879578l-0.3759155,0.3759155h-0.1879272v0.1879578l0.1879272,0.1879578v0.1879883
        l0.1879883,0.1879578h-0.1879883v0.3759155l-0.1879272,0.1879578l-0.1879272-0.1879578l-0.3759766-0.1879578l-0.5638428-0.3759155
        h-0.3759155l-0.5639038,0.3759155l-1.1278076,0.3759155l-0.1879272,0.1879578v0.1879883l-0.3759155,0.3759155v0.3759155h0.1879272
        v0.1879578l-1.1277466,0.9398193l-0.5639038,0.5639038l-0.1879883,0.1879578h-0.9398193l0.1879883-0.1879578l-0.1879883-0.1880188
        h-0.3759155l-0.1879272,0.1880188v0.1879578h-0.3759155l-0.1879883-0.1879578v-0.1880188l-0.3759155-0.5638428h-0.3759155
        l-0.1879272-0.1879578v-0.1879578l-0.1879883-0.5639038l-0.1879883-0.1879578h-1.1278076l-0.1879272-0.1879578h-0.1879883
        l-0.3758545-0.1879578h-0.1879883l-0.3759766-0.1879578v-0.9398193l-0.1879272-0.1879578l-0.1879272-0.5639038V394.03479
        l-0.1879883-0.1879883l-0.3759155-0.5638733l-0.3759155,0.5638733l0.1879272,0.1879883l0.1879883,0.3759155l-0.1879883,0.1879578
        l-0.1879272,0.1879578l-0.1879883,0.1879578l-0.1879272,0.1879883v0.3759155l-0.1879883,0.1879578l-1.1277466,0.3759155
        l-0.1879883,0.1879883l-0.3759155,0.1879578h-0.1879883l-0.5638428,0.5638733l-0.1879883,0.1879578h-1.3156738
        l-0.1879883,0.1879883l-0.1879272,0.1879578h-0.5639038l-0.1879883,0.1879578l-0.3759155,0.1879578h-1.1277466
        l-0.3759155,0.1879578h-0.1879883l-0.1879883,0.1879578l-0.1879272,0.1879272l-0.1879883,0.1880188v0.7518005l0.1879883,0.1879578
        l0.3759155,0.3759766l0.1879883,0.1879578l-0.1879883,0.1879272v0.5639343l0.1879883,0.1879578v0.375885l-0.5639038,0.3759155
        l-0.3759155,0.5639038l0.3759155,0.1879578h0.7518311l0.3759155-0.375885h0.3759766l0.1879272,0.375885h0.1879883
        l0.1879272-0.375885h0.3759155l0.1879883,0.1879272l0.1879883,0.1879578v0.3759155l0.1879272,0.1879578l0.1879883,0.1879883
        l0.1879272,0.1879578h0.1879883l0.1879883,0.1879578v0.3759155l0.1879272,0.1879578v0.1879883l0.3759155,0.1879578
        l0.3759155,0.1879578h0.1879272V405.3125l-0.1879272,0.1879578v0.7518616l0.1879272,0.1879578h0.3759766l0.1879883,0.1879578
        l0.1879272,0.375946v0.1879578h0.1879272v0.1879578h0.1879883v0.5638428l-0.1879883,0.1880188l-0.1879272,0.1879578
        l-0.3759155,0.1879578l-0.1879272,0.1879578l-0.1880493,0.1879272l-0.1879272,0.3759766v1.5036621l0.1879272,0.3759155
        l0.1880493,0.1879578v0.375946l-0.3759766,0.1879578l-0.1879272,0.1879578h-0.9398193v0.3759155h-0.9398193l-0.1879883,0.1879578
        l-0.1879272,0.1879883v0.3759155l0.1879272,0.1879578v0.1879578h0.1879883l0.1879883,0.1879578v1.1277771l-0.1879883,0.1879883
        v0.1879578l-0.1879883,0.1879578l0.1879883,0.1879578h0.1879883l0.1879272,0.1879578l-0.1879272,0.1879578v0.375946h-0.5639038
        v0.3759155l0.1879272,0.1879578v0.375885h-0.3759155l-0.3759155-0.1879272h-0.1879272l-0.1879883,0.1879272v0.3759766
        l0.3759155,0.3759155h0.3759155l0.1879883,0.1879272l0.1879272,0.1879578l-0.1879272,0.1880188l-0.1879883,0.1879578h-0.1879272
        l-0.1879883,0.1879578v0.1879272l-0.1879272,0.1879578l-0.1879883,0.1879578l-0.1879883,0.1880188h-0.1879272l-0.1879883,0.1879578
        l-0.1879272,0.1879272l-0.1879883,0.1879578v0.9398193l-0.1879883,0.1879578l-0.5638428,0.3759155v0.1879578l-0.1879883,0.5639038
        l-0.1879272,0.5638733h-0.1879272l-0.1880493,0.5639038v0.3759155h-0.1879272l-0.1879883,0.1879578h-0.5638428
        l-0.1879883-0.1879578h-0.3759155v-0.5638733l-0.1879272,0.1879578h-0.1879883v-0.3759155h-0.1879272l-0.1880493,0.1879578
        l-0.1879272,0.1879578h-0.1879272l-0.1879883-0.1879578h-0.3759155l-0.3759155,0.3759155v-0.1879578l-0.3759155-0.1879578
        l-0.1879883-0.1879578l-0.7518311-0.1879883l-0.1879883-0.1879578h-0.1879272l0.1879272-0.3759155v-0.5639038l0.1879883-0.1879578
        v-0.3759155h0.7518311l0.1879883-0.1879578h0.3759155l0.1879272,0.1879578v-0.1879578l0.1879883-0.1879578l0.1879883-0.1879272
        l0.5638428-0.1880188l0.1879272-0.1879578l-0.1879272-0.7518005l-0.1879272-0.1880188l-0.7518921-0.7518005l-0.5638428-0.7518616
        h-0.1879883l-0.5639038-0.3759155l-0.5638428-0.3759766v-0.1879272l0.1879272-0.1879578l-0.3759155-0.7518311v-0.1879883
        l-0.3759155-0.3759155l-0.1879883-0.1879578l-0.1879272-0.1879578h-0.1879883l-0.1879272-0.1879578h-0.1879883v-0.1879883
        l-0.1879883-0.1879578v-0.1879578l0.3759766-0.3759155v-0.5639038l-0.1879883-0.1879578v-0.1879578h0.1879883v-0.1879578
        l-0.1879883-0.1879578l-0.1879883-0.1879883h-0.3759155l-0.5638428-0.3759155l-0.1879883-0.1879578h-0.3759155v0.3759155
        l-0.1879883,0.1879578l-0.7518311-0.1879578h-0.1879883l-0.1879272-0.1879578v-0.1879578l-0.1879883-0.1879578h-0.7518311
        l-0.3759155,0.1879578v0.1879578h-0.1879272v-0.1879578h-0.1879883l-0.1879883-0.1879578l-0.3759155-0.5639038
        l-0.3759155-0.5638733V410.57547h-0.3759766v0.1879272h-0.3758545l-0.1879883,0.1879578h-0.9398193v-0.1879578h-0.1879272
        v-0.375885l-0.5639038-1.6916809h-0.5639038l-0.1879272-0.1879578l-0.9398193-0.9398193v-0.5638733h0.1879272v-0.5639038
        l-0.3759155-0.3759155l-0.1879272-0.3759155l-0.1879883,0.1879578h-0.1879883l-0.5638428-0.375946l-0.1879883-0.1879578h-0.3758545
        l-0.3759766-0.3759155h-0.3759155l-0.1879272,0.1879578l-0.1879883,0.3759155v0.375946h-0.1879883l-0.5638428,0.7518311
        l-0.3759155,0.1879883h-0.3759766l-0.1879272-0.1879883l-0.1879883-0.5638733h-0.3759155v-0.1879578h-0.1879883v-0.1879578
        h0.1879883v-0.5639038l0.1879883-0.1879578l-0.1879883-0.5639038l-0.1879883-0.1879578l-0.1879272-0.1879578l0.1879272-0.3759155
        h-0.1879272v-0.1879578h-0.1879883v-0.1879883h-0.9398193l-1.3157349,0.375946h-0.1879272l-0.3759155,0.1879578
        l-0.3759766-0.1879578h-0.7518311v-0.1879578h-0.1879272v-0.1879883l-0.7518921-0.3759155v-0.7518005h-0.1879272v0.1879578
        h-0.1879883v0.1879272h-0.1879272v-0.1879272h-0.7518921l-0.1879272-0.1879578v-0.1880188l-0.1879272-0.1879578h-0.1880493
        l-0.1879272,0.1879578l-0.1879883,0.3759766l-0.1879272,0.1879272v0.3759155h-0.7518921l-0.1879272,0.1879578h-1.1277466
        l-0.5639038-0.1879578h-0.1879272l-0.1879883-0.1879578v0.1879578h-0.3759155l-0.3759155-0.1879578v-0.1879578
        l-0.5639038,0.1879578l-0.1879883-0.1879578l-0.1879272-0.1879272l-0.1879883-0.1879578l-0.5639038,0.1879578v-0.1879578
        h-0.1879272l-0.1879883-0.3759766h-0.1879272v-0.1879578h-0.1879883l-0.1879883-0.1879578h-0.1879272v-0.1879272h-0.1879883
        v-0.1879578h-0.1879272v-0.1880188l0.1879272-0.3759155h0.1879883v-0.1879272l0.1879272-0.1879578l-0.1879272-0.1879578v-0.1880188
        l-0.1879883-0.1879578v-0.375885l-0.5638428-0.3759155v-0.1880188l-0.1879272-0.1879272v-0.5638733l-0.1880493-0.3759155
        v-1.1277771l-0.1879272-0.1879883l0.1879272-0.7518311l-0.1879272-0.1879578v-0.375946h-0.1879883l-0.1879272-0.3759155h-0.3759155
        v-0.1879578l-0.1879883-0.1879578h-0.5638428l-0.3759155,0.1879578h-0.3759766V394.03479h-0.1879272v-0.5639038
        l-0.1879883-0.1879578l-0.3759155-0.5638428l-0.1879883-0.1880188h-0.1879883l-0.5638428-0.3759155h-0.3759155
        l-0.1879883,0.1879578h-0.3759155l-1.8796387-0.7518005l-0.3759155-0.1880188l-0.1879272-0.1879578h-0.5639038l-0.3759155-0.375885
        v-0.1879578l-0.3759155-0.1879578h-0.5639038l-0.3759155-0.5639038h-0.3759155l-0.3759766-0.3759155h-0.5638428
        l-0.1879272-0.1879578v-0.1880188h0.1879272l0.7518921-0.9397583l0.5638428-0.5639038v-0.1879578l-0.3759155-1.1277771
        l-0.1879272-0.1879578l-1.3157959-0.7518616h-0.1879883l-1.5036621,0.1879578h-0.1879272l-1.1278076,0.7518616
        l-2.2555542,1.3157349h-0.1879272l-1.6917114-0.5638733h-0.1879272v-0.1879578l-1.8795776-1.5036621h-0.1880493
        l-0.9397583-0.1879578h-0.3759766l-1.1277466,0.375885h-0.1879272v0.1879578l-0.5639038,1.1277771l-0.1879272,0.1879578
        l-1.1278076,1.1277771l-0.9398193,0.1879578h-0.1879272l-0.3759766-0.1879578h-1.8795776l-1.5037231,0.5639038
        l-0.1879883,0.1879578h-0.1879272l-0.1879272,0.1879578v0.7518616h-0.1879883v0.1879578h-0.1879272v0.1879578l0.1879272,0.1879578
        h0.1879883l0.1879272,0.1879578h0.1879272v0.9398193l0.1879883,0.1879578l0.1879883,0.1879578v0.1879883h0.1879883v0.3759155
        l-0.1879883,0.3759155v0.375946l-0.5639038,1.1277161l-0.1879272,0.1880188l-0.3759155,0.1879578l-0.7518921,0.375885h-0.5638428
        l-0.3759766,0.3759155h-0.1879272l-0.1879883,0.1880188v0.375885h-0.3759155l-0.9398193,0.1879578h-0.7518311l-0.1879883,0.3759155
        l-0.1879272,0.1879883v0.1879578l0.1879272,0.3759155l0.1879883,0.1879578v0.1879578l-0.1879883,0.1879578l-0.3759155,0.375946
        h-0.9398193l-0.1879272-0.1879578l-0.3759766-0.5639038h-0.1879272l-0.7518311,0.3759155v0.1879883l0.3759155,0.7518311
        l0.1879272,0.1879578v0.1879883l-0.1879272,0.3759155l-0.3759155,0.1879578h-0.1879883l-0.3759155,0.1879578l-0.3759155,0.1879578
        l-0.1879272,0.375946l-0.3759766,0.3759155v0.1879578l0.1879272,0.1879272l0.1880493,0.3759766v1.6916199l-0.1880493,0.1879578
        l-0.1879272,0.3759766l-0.1879883,0.3758545l-0.75177,0.9398193l-0.1880493,0.1879578h-0.1879272l-0.1879272,0.1879883h-0.3759155
        l-1.3157349,0.5639038l-0.7518921,0.1879578l-0.1879272,0.1879578l-0.5639038,0.3759155h0.1879272l-1.5036621,1.3157349
        l-0.3759155,1.1277771l0.1879272,0.375885v1.5036926l1.1278076-0.1879578l-1.8796387,0.7518616v0.3759155l1.8796387-0.3759155
        l-1.8796387,0.5638733v0.7518616h2.0675659l-2.0675659,0.3759155l0.1879883,0.9398193l0.3759155,0.1879578l0.3759155,1.5037231
        l-0.7518311,0.1879578l0.1879272,1.3157349l1.1278076,1.6916199l0.9397583-0.1879578l0.3759766,0.7518921h-0.1879883
        l0.5639038,0.9398193l1.5036621-0.3759766l-0.5638428,0.3759766v0.1879272l0.3759155-0.1879272l0.1879272,0.1879272
        l-1.5036621,0.7518311l-0.7518921-0.5638733l-0.3759155,0.1879578l1.1278076,0.9398193l0.3759155-0.375946l0.7518311-0.3759155
        l0.9398193-0.3759155h0.1879883l-1.3157349,0.7518311l0.1879272,0.375946h0.3759155l0.5639038,0.9398193h0.3759155
        l0.3759766,0.7518311l-0.3759766,0.1879578l3.5712891,6.3907166v0.1879578l0.9398193,1.3157349l0.3759155-0.1879578
        l-0.1879883-0.1879578h0.1879883l0.1879272,0.3759155l0.1879883-0.1879578l0.3759155,0.7518616l-0.9398193,0.3759155
        l0.5639038,1.1277771h-0.1879883l0.9398193,1.3157349l0.5639038-0.3759155l-0.3759155-0.5639038l2.6314697-1.1277771
        l0.1879883,0.1879578l-1.6917114,0.7518616l0.3759766,0.1879578l0.9398193-0.3759155v0.5638733h-0.1879883l0.3759155,0.375946
        l0.3759155,0.9397583l1.5036621,2.2555542l-0.5638428,0.7518616v0.3759155l0.1879883,0.1879578l0.7518311,0.5639038
        l0.5638428,0.5638733v0.375946h-0.3758545l-0.1879883,0.1879578l0.1879883,0.1879578l1.5036621,2.0675964h-0.1879883v0.1879578
        l0.3759155,0.1879578l0.1879883-0.1879578v0.1879578h0.1879883l0.3759155-0.1879578h0.1879272l-0.5638428,0.3759155
        l0.1879272,0.1879272l0.3759155,0.1880188v0.1879578l0.7518921,3.0073547l0.1879272,0.9398193l0.7518311,2.2555542
        l0.9398193-0.375946h0.1879883l-0.1879883-0.1879578l0.9398193-0.3759155l-1.5036621-4.6990662l0.3759155-0.1879272v-0.1879578
        h-0.1879883l0.5639038-0.5639343l-0.1879883,0.7518921v0.1879272l0.1879883,0.3759766l0.1879883,0.3759155h0.1879272v0.1879272
        l0.5639038,0.5639343v0.1879578l0.1879272,0.1879272h0.3759766l0.3759155,0.1879578l0.3758545-0.375885v-0.1879578
        l0.1879883-0.1880188h0.3759766v0.1880188l0.1879272,0.1879578h0.5638428l0.1880493,0.1879272l0.5638428-0.375885v0.1879578
        l-0.3759155,0.375885h0.1879883l0.1879272,0.3759155l-0.3759155,0.3759766v0.375885l0.1879883,0.1879578l0.3758545,0.3759155
        l0.3759766,0.1879578v0.1879578h-0.1879883l-0.1879883-0.1879578v0.1879578l-0.1879272,0.1879578l-0.1879272,0.375946
        l-0.3759155,0.5638733l-0.5639648,0.375946l-3.3833008,1.6916504v0.375885h-0.1879272l0.1879272,0.3759766v0.375885
        l0.1879883,0.5638733l0.1879883,1.1277771l-0.1879883,0.1879883v0.1879578l-0.1879883,0.1879578v0.1879578l0.1879883,0.3759155
        v0.1879883l0.1879883,0.1879578h0.1879272v-0.1879578l0.1879883,0.1879578l-0.3759155,0.1879578l1.3157349,3.7592468
        l0.1879272,0.5639038l0.3759155,0.7518005h0.1879272l0.3759766,0.9398193l0.1879883,0.5638733h0.1879272v0.1880188
        l0.1879272,0.5638428h0.1879883l0.3759766-0.1879578l-0.3759766-2.4434814v-0.1879578l-0.5638428-0.7518616l0.3758545-0.7518921
        l0.1879883,0.1880188h0.1879272l0.1880493,0.1879578h0.1879272l0.1879883,0.1879578h0.3758545l-0.3758545,0.3759155
        l-0.1879883,0.1879272v1.1277771l-0.1879272,0.1880188h0.3759155l0.1879272-0.1880188h0.3759155v0.3759766l0.1879883,0.7518005
        h-0.3759766l0.1879883,0.9398193l-0.1879883,0.1879578l-0.1879272,0.1879578v1.6916809l0.3759155,0.1879578l0.3759766-0.5638733
        l0.5638428-0.5639038l0.5639038-0.7518616l0.3759155-0.3759155l-0.3759155-1.8795776l0.3759155-0.1880188l0.5638428,0.3759766
        l0.3759766-0.3759766l0.5638428-0.1879578v-0.1879272l-0.5638428-0.5638733l0.3759155-0.375946v-0.1879578l0.5639038,0.5639038
        l0.1879272,0.1879578l0.1879883,0.1879578h0.3759155v-0.1879578l0.1879272-0.3759766h-0.1879272v-0.1879272l0.5639038,0.1879272
        v-0.1879272l-0.5639038-0.1879578v-0.1879578l-0.1879883-0.1879578l-0.1879272-0.1879578l0.1879272-0.1880188h0.3759155
        l-0.1879272-0.1879272h0.1879272l0.1879883,0.1879272v-0.5638428h0.1879883l0.1879272-0.1879578l0.3759155,0.1879578h0.1879883
        l0.3759155-0.1879578v-0.375946l-0.1879272-0.5638733h0.3759155l0.1879272,0.1879578h0.1879883l0.1879272-0.1879578
        l0.1879272,0.1879578l0.1880493,0.1879578h0.3759155l-0.1879883,0.375946h-0.3759766v-0.1879883h-0.1879272v0.5639038h0.1879272
        l1.1278076,0.1879578l0.1879883,0.1879578h0.3759155v-0.1879578l0.1879272-0.3759155v-0.1879578h0.1879883v-0.1879883h0.3759766
        l0.3758545,0.1879883v0.1879578l0.5639038-0.5639038l-0.1879883-0.1879578v-0.1879578h0.1879883v-0.1879578h0.1879883v0.1879578
        h0.3759155v-0.1879578h0.3759155l0.7518311-0.5639038v-1.6916504l0.1879883-0.1879578l0.1879272-0.1879578v-0.375946h-0.1879272
        v-0.3759155h-0.1879883l0.1879883-0.1879578v-0.375946h0.1879272v-0.7518005h-0.1879272v-0.3759155h0.1879272v-0.5639038
        h-0.1879272l0.1879272-0.1879578l0.1879883-0.1879578v-0.1879578h-0.1879883v-0.375946h0.1879883v-0.1879578h0.1879272
        l0.1879883,0.1879578v-0.1879578h0.7518311v0.1879578h0.5639038l0.5639038-0.1879578h0.7518311v0.1879578h0.5638428v-0.1879578
        h1.1278076v0.1879578h0.1879272l0.1880493,0.1879272h0.3759155l0.1879272-0.1879272l0.1879272-0.1879578h1.5037842v-0.1879578
        h0.3758545l0.3759155-0.1879578l0.5639038,0.1879578h0.1879883l0.7518311-0.3759155v-0.1879883l-0.1879883-0.5638733
        l0.1879883-0.1879578h0.9398193l0.3759155,0.1879578l0.1879883,0.1879578l0.3759155,0.1879578l0.1879272,0.1879578
        l0.3759766,0.1879883h0.3759155l0.1879272,0.1879578h0.3759155v0.1879578l0.7518921,0.1879578l0.1879272-0.1879578
        l0.7518921,0.1879578l0.5638428,0.375885v0.1880188l0.5639038,0.5638733l0.3759155,0.5639038l0.3759155,0.1879578
        l-0.1879883,0.1879578v0.5638428h0.3759766v0.1879578l0.5638428,0.1880188l0.5639038,0.1879272l0.9398193,0.1879578
        l0.3759155,0.1879578l0.5639038,0.3759155h0.3759155l0.3759155-0.1879578h0.3759155l0.1879883-0.1879578h0.1879272v-1.3157349
        l0.1879883-0.1879272v-0.1879578l0.1879883-0.1879578h0.1879272l0.3759155-0.3759766l0.1879883-0.1879272l0.3759155-0.1879578
        h0.3759155l0.5638428-0.1879578h0.3759766l0.1879883-0.1879578h0.1879272v-0.1880188h0.1879272v-0.1879272l0.3759766-0.7518311
        v-0.1879883l0.1879883-0.5638733l0.1879272-0.1879578l0.3759155-0.375946h0.1879272l0.5639038-0.1879578h0.1879883
        l0.1879272-0.1879578h0.1879883v-0.1879578l0.1879272-0.1879578l0.1879883-0.1879578l0.1879883-0.1879578h0.1879272
        l0.3759155-0.1879578h0.1879883l0.1879883,0.1879578h0.1879272l0.1879883,0.1879578l0.1879272,0.1879578h1.3157349
        l0.1879883-0.1879578h0.1879883l0.1879272,0.1879578l0.3759155,0.1879578h0.1879883v0.1879578h0.1879272v0.3759155
        l0.1879272,0.1879883h0.1880493l0.1879272,0.1879578h0.1879883v-0.1879578h0.3758545l0.1879883-0.1879883v-0.5638733h0.1879883
        l0.1879883-0.1879578h0.5638428V448.73172l0.1879272-0.1879578v-0.1879578l0.1880493-0.1879272h0.1879272l0.1879272-0.1879578
        h0.7518921v-0.1880188l0.1879272-0.1879578h0.1879883l0.1879272-0.1879578h0.1879883l0.3759155,0.1879578h0.1879883
        l0.1879272-0.1879578h0.1879883v-0.1879883h0.5638428l0.1879883,0.1879883h0.3759155l0.1879883,0.1879578h0.3759155v0.1879578
        h0.1879883l0.1879272-0.1879578h0.3759155v-0.5639038h0.1879883l0.1879272-0.1879578v-0.1879578h0.3759766v-0.1879578
        l-0.1879883-0.1879578h-0.1879883l0.1879883,0.1879578h-0.3759155v-0.5639038l0.1879272-0.1879578h0.1879883l0.1879883-0.1879578
        l-0.1879883-0.1879578l0.1879883-0.375946h0.7518311l0.1879272-0.1879578v-0.1879578l0.1879883-0.1879578l0.1879883-0.1879272
        v-0.1879578h0.1879272v-0.1879578l0.1879883-0.1880188l0.1879272-0.1879578v0.1879578h0.1879272v0.3759766h0.7518921v-0.1879578
        h0.1879272v-0.1880188h0.3759766v0.1880188h0.1879883v0.1879578h0.9398193l0.1879272-0.1879578v0.1879578h0.3759155v0.7518005
        l0.1879272,0.1879578h0.1879883l0.3759155,0.1880188v-0.1880188l0.1879883-0.1879578h0.1879272v0.1879578h0.7518921
        l0.1879272-0.1879578l0.1879883,0.1879578v0.1880188h0.1879272l0.1879883-0.1880188l0.1879272-0.1879578h0.9398193v-0.3759155
        l0.1879883,0.1879578h0.5639038v-0.1879578h0.1879272v0.1879578h0.1879883l0.1879272,0.1879578l0.3759766-0.1879578h0.9397583
        l0.1879883,0.1879578l0.1879883-0.1879578l0.1879272,0.1879578l0.1879272,0.1879578h0.3759155l0.1880493-0.1879578v0.1879578
        h0.1879272v-0.1879578h0.3759155v0.1879578l0.1879272,0.1880188h0.1879883v0.375885h0.1879883l0.1879272,0.1879578h0.3759155
        v0.1879578l0.1879883,0.1879578l0.1879272,0.1879883h0.1879883l-0.1879883,0.1879578v0.1879578l-0.1879272,0.1879578
        l0.1879272,0.3759155h0.3759766l0.1879272-0.1879578l0.1879883-0.1879578l0.1879272-0.1879578v-0.1879578l0.3759155-0.375946
        l0.5639038-0.7518311l1.3157349-1.3157043l0.1879883,0.1879578l0.1879272,0.1879272v0.1879578h0.1879883l0.1879883-0.1879578
        l0.7518311-0.5638428v0.1879578l0.7518311-0.5639343v-0.1879272h-0.1879272l-0.5639038-0.7518921l-0.1879883-0.5638428
        l0.1879883-0.1879578l0.7518311,0.9398193l2.6314697-1.8796387l1.6916504,1.8796387h0.1879883v-0.1880188l0.3759155,0.1880188
        l2.0675659-0.1880188l-1.6916504-1.8795776l-1.3157349-1.5036926l-0.3759155,0.3759155l-0.3759155-0.3759155h-0.1879883
        l0.5639038-0.1879883v-0.1879578h0.1879272l0.1879883-0.1879578h0.1879272v-0.1879578l0.1879883-0.3759155l0.9398193,0.1879578
        v0.3759155h0.5639038l-0.1879883,0.1879578h-0.1879272v0.1879578h0.1879272l0.5639038,0.375946l0.5639038,0.3759155
        l0.9397583,0.9398193l0.1879883-0.1879578l0.5639038,0.9397888h0.7518311l0.3759155-1.6916504v-1.3157349h-0.1879272v-0.1879578
        h-0.1879883v-0.5639038l0.1879883-0.1879578l0.3758545-0.1879578l0.5639648-0.5639038l0.3758545-0.3759155h0.1879883
        l0.1879272-0.1879578l0.3759766-0.1879578l0.3759155-0.1879272h0.1879272v0.375885h0.3759155v0.1879578l0.3759766,0.1879578
        l0.3759155-0.1879578h0.1879272v-0.1879578h0.3759766v0.1879578l0.1879272-0.1879578l-0.5639038-0.375885l0.1879883-0.1880188
        l0.1879883-0.1879578l0.1879272-0.1879578l0.7518311-0.1879578h0.3759766l0.7518311,0.5638733h0.1879883l0.1879272,0.1880188
        v0.1879272l0.1879883,0.1879578v0.3759155l0.1879272,0.3759766v0.1879272h0.3759155v-0.1879272l0.1879883-0.3759766
        l0.5639038-0.1879578l1.6915894-0.9398193h0.5639648h0.3758545v-0.1879578h0.1879883v-0.5638428h0.1879883v-0.1879578h-0.1879883
        v-0.3759766l0.1879883-0.1879272h0.3759155v-0.1879578l0.1879272,0.1879578h0.1879883v0.1879272h0.1879272v0.1879578h0.1879883
        l0.1879883-0.1879578v0.1879578h0.1879272v0.1880188h0.3759155v-0.1880188h0.1879883v-0.1879578l0.3759155-0.1879272
        l0.3759155-0.1879578l0.3759155-0.1879578h1.3157349v-0.1879578h0.7518921l0.1879272-0.1880188l0.7518921-0.1879272
        l0.3759155,0.1879272h1.3156738l0.1879883,0.1880188h0.1879883v-0.1880188h0.1879883l0.1879272,0.3759766l-0.1879272,0.1879578
        h0.3758545l0.1879883,0.1879578h0.1879272v-0.1879578h0.7518921l0.7518921,0.1879578h0.1879272l0.1879272,0.1879272h-0.1879272
        l0.1879272,0.1879578v0.1880188l0.1879883,0.1879578v0.375885h0.1879272l0.1879883-0.1879272l1.1277466,0.1879272
        l0.1879883,0.1879578l0.3759155,0.1879578h0.3759155v0.1879578h0.1879883v0.375946l0.5639038,0.9398499l0.1879272,0.1879272
        l0.1879883,0.1879578l0.1879272,0.1879578v0.1879578l0.5639038,0.9398193h0.1879883l0.1879272,0.1879578v0.1879578
        l-0.1879272,0.1879883h-0.3759155v0.5638733l0.1879272,0.1879578l-0.3759155,0.5639038l1.1277466,0.7518311v0.375946h-0.1879272
        v0.1879578l0.1879272,0.1879578l1.1277466-0.1879578h2.4435425l0.3759155,0.1879578h0.1879883v0.5638428h0.1879272v0.1880188
        l-0.1879272-0.1880188v0.3759766l0.1879272,0.1879578l0.3759155,0.1879578v0.9398193h0.1879883v0.1879272l0.1879883,0.3759155
        h0.3759155l0.1879272,0.1879578h-0.1879272v0.1880188l0.1879272,0.1879272l0.1879883,0.3759155l0.1879883,0.1879578
        l0.1879272,0.1879578h0.3759155v-0.1879578h0.1879883l0.7518311,0.1879578l0.3759155,0.1879883h-0.1879272l0.5638428,0.7518311
        v0.1879578h-0.1879272l-0.1879883,0.1879883v0.3759155l0.3759155,0.1879578h0.1879883l0.1879883,0.1879578h0.1879272
        l0.1879883,0.1879578v0.375946h0.1879272l0.1879272-0.1879578h0.1880493v0.5638733h-0.3759766v0.7518616h0.1879272v1.3157349
        h-0.3758545l-0.1879883,0.1879578l-0.1879272,0.1879578v1.1277771h-0.1879883v0.1879272l-0.3759155,0.1879578h-0.3759155
        l-0.1879883,0.1880188h-0.1879272l0.3759155,0.5638428h0.1879272l-0.1879272,0.1879578h-0.1879883v0.1879578l0.1879883,0.3759766
        h0.1879272v0.1879272h0.3759155v0.1879578h0.1879883v0.1879578l-0.1879883,0.1879578v0.9398193h-0.1879272v0.7518616
        l-0.1879883,0.1879578h0.1879883l0.1879272,0.1879578h0.1879883l0.1879883,0.1879578h0.75177v0.1879578h0.1880493v0.375946
        l-0.1880493,0.1879578v0.1879578h-0.1879272l-0.3759155,0.1879578v0.3759155l0.1879883,0.1879883h0.1879272v0.5638733
        l-0.1879272,0.1879578l-0.1879883,0.375946v0.5638733l0.1879883,0.1879578h-0.1879883v0.375946l-0.1879272,0.1879578v0.1879578
        h0.1879272v0.1879578h0.3759155l0.1879272,0.1879272h0.7518921l0.1879272,0.1879578l0.1879883-0.1879578h0.1879883
        l0.1879883,0.1879578l0.1879272,0.1880188h0.1879272l0.3759155,0.1879578v0.1879578h-0.1879272v1.1277466h0.1879272v0.1879578
        h-0.1879272v0.1879578l-0.1879883,0.1879578v0.1879578l0.1879883,0.1879883h0.1879272l0.1880493,0.1879578h0.1879272v0.3759155
        h0.1879883v-0.1879578h0.1879272v0.3759155h0.3759155v0.375946h0.1879272v-0.375946h0.1880493v-0.1879578h0.1879272v0.1879578
        h0.1879272l0.1879883,0.1879578h0.1879272l0.1879883-0.1879578h0.1879883v-0.1879578h0.1879272v-0.1879578l0.1879883,0.1879578
        h0.7518311v-0.1879578h0.1879883v-0.1879578h0.5638428v-0.7518616l0.3759766-0.1879578v-0.1879578h0.1879272l0.5639038-0.5639038
        h0.1879272v-0.1879578h0.1879883l0.1879883,0.1879578l0.1879272-0.1879578v-0.375885h0.1879883v-0.1879578v-0.1879578h-0.5639038
        v-0.1880188h-0.1879883v-0.5638428l0.1879883-0.3759155l1.5037231-1.3157959l0.5638428-0.375885l0.3759766-0.1879578
        l0.75177-0.3759155l0.1879883-0.1879883h0.7518311l0.3759155,0.375946h0.3759766v0.3759155h0.1879272v0.1879578
        l0.1879883,0.1879272h0.7518311l0.1879883,0.1880188v0.1879578h0.1879272l0.1879883-0.1879578h-0.1879883v-0.1880188
        l0.5639038-0.5638428h0.3759155l-0.1879272-0.1879578h0.1879272l0.1879883-0.1879578v-0.1879883l0.7518311,0.1879883v0.1879578
        h0.1879883v-0.5639038h0.3759155v-0.1879578h0.3759155v-0.1879578h0.1879272v0.1879578h0.7518921l0.1879272-0.1879578h0.1879272
        l0.1880493,0.1879578h0.3759155v-0.1879578l-0.1879883-0.1879578l-0.1879272-0.1879578h-0.1880493v-0.1879578l0.1880493-0.1879883
        l0.1879272-0.3759155l0.1879883-0.1879578h0.3758545v0.1879578h1.1278076v0.1879578h0.1879272h0.5639648v-0.3759155h0.1879272
        l-0.1879272-0.3759155v-0.1879883h1.1277466l0.1879272,0.1879883v-0.1879883h0.1879883v0.1879883l0.1879272-0.1879883v-0.1879578
        h0.1879883v-0.1879578l0.1879883,0.1879578l0.1879272-0.1879578h0.3759155v0.1879578h0.9398193v-0.3759155h-0.3759155v-0.1879578
        h-0.1879883v-0.1879578l0.1879883-0.1879883h0.75177l0.5639648-0.1879578l0.1879272-0.1879578v-0.1879578l0.1879272-0.375885
        v-0.3759766h0.5639038l0.3759155-0.1879578v-0.375885l0.1879883-0.1879578v-0.3759766L829.3808594,452.8668518z
        M687.2816162,460.385376l-0.3759155-0.1880188l0.1879883,0.1880188H687.2816162z"
            filter="url(#dropshadow)" // Apply the drop shadow filter here
          />
        </a>
        <text x="730" y="438" fontSize={16} color="000000" className="centered-text">深圳</text>


        <a xlinkHref="../about/zhuhai.html" id="dmap_zhuhai" className="focus">
          <title>珠海</title>

          <path id="Foshan_3_" className="gba8" d="M706.2658691,585.5680542h0.3758545l0.1879883,0.1879883l-0.1879883,0.1879272v0.1879883
        l0.1879883,0.1879272v0.5639038h-0.3759155v-0.1879883l-0.1879272-0.1879272v-0.5639038v-0.1879272V585.5680542
        M686.7177734,547.5997314l-0.7518921,0.1879883l-0.5638428,1.1277466l0.3759155,0.3759766l0.5639038-0.1879883
        l0.9397583-0.9398193l0.1879883-0.5639038l-0.1879883-0.1879272L686.7177734,547.5997314z M671.6807861,531.059082v0.5638428
        l0.1879883,0.3759766l-0.3759155,0.1879883l-0.1879883-0.3759766v-0.5638428l0.1879883-0.5639648L671.6807861,531.059082z
        M597.435791,543.4645996l0.1879883,0.1879272v0.7518311l-0.1879883,0.1879883v0.1879883h-0.1879272v0.3759155h-1.1278076
        l-0.1879272,0.1879272l-0.1879883,0.1879883h-0.9398193l-0.1879272-0.1879883h-0.1879883l-0.1879883-0.1879272v-0.1879883
        l-0.1879272-0.1879272h-0.7518311l-0.1879883-0.1879883h-0.7518311l-0.1879883-0.1879883h-0.1879272l-0.3759766-0.1879272
        v-0.1879883l-0.1879272-0.1879272l-0.1879883-0.1879883l-0.1879272-0.1879272h-0.1879272v-0.1879883h-0.3759766
        l-0.1879883-0.1879883h-0.1879272v-0.75177l0.1879272-0.1880493h0.1879883l0.1879272-0.1879272l0.3759766-0.1879883
        l0.1879272-0.1879272l0.1879883-0.1879272l0.1879272-0.1879883v-0.1879883l0.1879883-0.1879883l0.1879883-0.3758545h0.1879272
        l0.1879883-0.1879883l0.1879272-0.1879272l0.1879883-0.1880493h0.1879272l0.1879883,0.1880493h1.6916504l0.1879883,0.1879272
        l0.1879272,0.3759155l0.3759766,0.3759155l0.1879272,0.3759766l0.1879883,0.1879272h-0.1879883l-0.3759155-0.1879272
        l-0.1879883-0.1879883l-0.3759155,0.1879883l0.1879883,0.1879272l0.1879272,0.1879272v0.9398193h0.1879883v0.1879883
        l0.1879883,0.1879272v0.1879883l1.1277466,0.3759155l0.3759155,0.1879883v-0.5639038h0.3759155V543.4645996z M585.40625,554.366394
        h0.1879272l-0.1879272,0.1879272h-0.7518921v-0.1879272h-0.1879272l-0.1879883-0.1879883h-0.3759155l-0.1879883,0.1879883
        h-0.1879272l-0.1879883,0.1879272h-0.1879272l-0.1879883,0.1879883h-0.3758545l-0.1880493,0.1879272h-0.3759155
        l-0.3758545,0.1879883l-0.1879883,0.1879883l-0.3759766,0.1879272h-0.1879272l-0.1879272,0.1879883h-0.1879883
        l-0.1879272,0.1879272l-0.3759766,0.1879883h-0.1879272l-0.1879883,0.1879272l-0.1879272,0.1879883h-0.1879883
        l-0.1879883,0.1879883v0.3759155l-0.1879272,0.3759155l-0.3759155,0.1879883v0.1879272l-0.1879883,0.1879883h-0.1879272
        l-0.5639038,0.3759155h-0.7518311l-1.1278076,0.7518311h-0.3759155l-0.3759155-0.1879272v-0.3759766l-0.1879883-0.3759155
        l0.1879883-0.1879272l0.1879272,0.1879272l0.1879883,0.3759155l0.5639038-0.3759155l-0.1879883-0.5638428l-0.3759155-0.3759766
        l0.1879272-0.3759155l-0.1879272-0.1879272l-0.1879883-0.1879883v-0.1879883l0.1879883-0.1879272l0.5639038-0.1879883h0.7518311
        l-0.3759155-0.1879272h-1.3157349l-0.1879883,0.1879272h-0.1879272l-0.5639038-0.3759155l-0.3759155-0.3759155
        l-0.1879883-0.1879883h-0.7518311l-0.7518921,0.1879883h-0.1879272l-0.1879883,0.1879883l-0.7518311,0.3759155
        l-0.3759155-0.1879883l-0.1879272-0.3759155v-0.5639038l-0.1879883-0.1879272h-0.7518311l-0.3759155,0.1879272
        l-0.7518921,0.3759155l-0.3759155,0.1879883l-1.1277466,0.3759155l-0.3759155-0.1879272l-0.1879883,0.1879272h-0.3759155
        l-0.5639038-0.1879272h-0.1879883l-0.5638428-0.5639038l-0.3759155,0.3759155l-1.3157349-1.1278076v-0.1879272
        l-0.1879883-0.1879883l-0.3759155-0.3759155l0.1879272-0.3758545l0.3759766-0.3759766l0.5638428-0.9398193v-0.1879883h-0.1879272
        l-0.1879272,0.1879883l-0.1879883,0.1879883v0.1879883h-0.1879883l-0.1879883,0.1879272l-0.1879272,0.1879272h-0.1879272
        l-0.1879883-0.1879272v-0.1879272l-0.1879272-0.1879883v-0.1879883l-0.1880493-0.1879883v-0.1879272l-0.1879272-0.1879272
        v-0.5639648l0.1879272-0.1879272l0.1880493-0.1879272l0.9397583-1.8796387l-0.5638428-0.3759155h-0.1879883l0.5638428,0.3759155
        l-0.9398193,1.8796387l-0.9397583-0.5639038l-0.3759155-2.0675659v-0.3759155h0.1879272l-0.1879272-0.1879883l-0.1879883-0.1879883
        l-0.1879272-0.1879272h0.1879272l0.1879883-0.1879883l-1.3157349-1.1277466l-0.1879883,0.7518311l-0.5638428,2.0675659
        l-1.3157959-0.7518311l-0.7518311-0.1879272h-0.7518311l-0.5639038,0.5638428h0.3759155v0.1879883l0.1879883,0.1879272
        l-0.1879883,0.5639038h-0.1879272v0.1879883l-0.1879883,0.1879272v0.1879883h0.1879883l0.1879272,0.3759155v0.1879883h-0.5638428
        l-0.1879883,0.3759155v2.819458h0.1879883v0.3758545h-0.5639038v0.5639038h0.5639038v0.1879272l0.1879272,0.1879883
        l0.3759155,0.1879883l-0.5638428,0.7518311h-0.1879883l1.1277466,3.7592773h-0.1879272l-0.9398193-3.5712891l-0.7518311,0.7518311
        l-1.3157349,1.3157349l1.8795776,1.6916504l-0.1879272,0.1879883l1.3157349,1.3156738l-0.3759155,0.3759155l0.5638428,0.3759766
        v0.1879272l0.1879883,0.1879883l0.1879883,0.3759155l0.1879272,0.9398193v0.3759155l-0.7518311,0.3759155l-1.1278076,1.5037231
        v0.9398193v0.1879272l0.5639038,0.1879883l0.3759155-0.1879883l0.7518921-0.3759155h-0.3759766l-0.1879272,0.1879883h-0.1879883
        l-0.1879272,0.1879272h-0.5639038l-0.1879883-0.1879272h0.1879883v-0.3759155l0.1879883-0.1879883v-0.1879883l0.9397583-1.1277466
        l0.1879883-0.1879883v-0.1879272l0.3759155-0.1879883h0.1879883l0.1879272,0.1879883v0.7518311l0.1879883,0.1879883v0.5638428
        l0.1879272,0.1879883v0.1879883h-0.1879272v0.1879272h-0.5639038l0.7518311,0.1879883l0.1879883-0.1879883l-1.5037231-6.2027588
        h0.1879883l1.5037231,6.2027588l0.1879272-0.1879272h0.1879883l0.3759155-0.1879883l0.3759155,0.1879883v0.3759155
        l0.1879883,0.3759155h0.5638428l-0.1879272,0.1879272v0.1879883h0.1879272l0.1879883,0.1879883h0.1879883v0.3759155
        l-0.3759766,0.7518311v0.1879883l0.3759766,0.3758545l0.3759155,0.3759155l0.3759155-0.1879272l0.3759155-0.3759155
        l0.1879883,0.1879272v0.3759155h-0.1879883l-0.1879883,0.1880493v0.75177l0.1879883,0.1879883v0.3759766l0.1879883,0.3758545
        l0.1879272,0.1879883h1.5036621l0.1879883,0.3759766h0.5639038l0.1879272,0.1879272l0.1879883,0.1879272l-0.1879883,0.1879883
        l-0.5638428,0.1879272v0.5639038h-0.3759766l-0.5638428-0.3759155h-0.1879272l-0.1880493-0.1879883h-0.3758545
        l-0.3759155,0.3759155l-0.1879883,0.3759766l0.1879883,0.3759155v0.1879272h-0.1879883l-0.5639038,0.1879883h-0.1879272v0.3759155
        l-0.1879883,0.1879883v0.1879272l0.1879883,0.1879883l0.5638428,0.1879272l0.1879883,0.1879883l0.1879883,0.1879883h0.1879272
        l0.5638428,0.3759155l0.1880493,0.1879272v0.1879883l-0.1880493,0.1879883l-0.3758545,0.1879272h-0.7518921l-1.6916504,0.3759155
        l-0.3759155,0.3759155v0.3759766l0.3759155,0.1879272v0.5638428l-0.1879272,0.1879883l-0.1879883,0.1879883l-0.3759155,0.3759155
        l-0.3759155,0.3759155v0.1879272l-0.1879883,0.3759766l-0.5638428,1.1277466l-0.9397583-0.5639038l-0.3759766-0.9398193
        l-0.5638428,0.1880493l-0.1879883,0.1879272l-0.3759155-1.1277466l-0.5639038,0.1879272l-0.3759155-0.7518921h-0.1879883
        l-0.3759155,0.3759766l-0.9398193-0.3759766v-0.3758545l-0.1879272-0.1879883l-0.1879883-0.3759766l-0.5639038-0.5638428
        h-0.1879272l-1.1278076,0.3759155v-0.1879272l1.1278076-0.3759155l-0.5639038-1.6917114l-2.0675659,0.5639038l-0.3759155-1.1277466
        h-0.1879272l0.1879272-0.1879883l0.1879883-0.1879272l-0.3759766-0.9398193v-0.5639038l0.1879272-0.1879883l0.3759155-0.1879272
        l-0.3759155-0.1879883v-0.1879272l-0.5639038-0.5639038l1.6917114-1.8795776h-0.1879883l2.0675659-2.2555542l0.1879272,0.1879272
        l0.1880493-0.1879272l-0.1880493-0.1879883l-0.1879272,0.1879883v-0.1879883l-0.1879272,0.1879883l-0.7518311-0.5639038
        l-0.3759766-0.3759155v-0.1879883l-0.1879272-0.1879272l-3.1953735,3.7592163l-0.9397583-0.7518921l-0.3759766-0.9398193
        l1.6916504-2.0675049l-0.7518311,0.7518311l-0.1879883-0.1879883l0.7518311-0.7518311l0.9398193-1.3157349h-0.1879272
        l0.7518311-0.9398193l-0.3759155-0.1879883h-0.3759155l-0.1879883,0.1879883l-0.3759155,0.1879883l-0.7518311,0.5638428
        l0.1879272,0.1879883l0.1879883,0.3759155l-1.1278076,0.7518311l-1.1277466,0.5639038l-0.1879883-0.1879272l-0.5638428-0.7518921
        v-0.1879272l-0.3759766-0.3759766l-0.9397583,0.1879883l-0.7518311-1.1277466h-1.1278076l-0.1879272,0.1879272
        l-0.9398193-1.5036621l-3.5712891-4.1351929l0.7518311-0.7518311l-0.3759155-0.3759155l-5.2629395-6.9545898l-0.1879272-0.1879883
        v-0.1879272h-0.1879883l-1.5036621-1.8796387l-0.9398193-1.3157349l-1.5036621-2.067627l-0.1879883-2.0675049l-0.3759155-0.1880493
        l-0.1879883-2.4434814l0.1879883-1.1277466l-0.3759155-0.1879883v-0.7518311l0.1879272-0.5639038l0.3759155-1.5036621
        l0.7518921-1.6917114h0.1879272l0.1879272-0.3759155h-0.1879272l0.9398193-2.6314087v-4.6990967l-2.2555542-6.5786133v-0.1879883
        h0.1879883v-0.1879272h-0.1879883l-0.3759155-1.3157349v-0.7518311l0.1879883-0.3759155l0.1879272-0.9398193v-0.1879883
        l0.5639038-0.5639038l0.3759155-0.3759155l0.1879883-0.1879272v-0.1879883l3.1953125-2.2555542l-0.1879272-0.1879272h0.3759155
        l0.9398193-0.5639038l2.0675659-0.5638733l1.3157349-0.5639038l0.5639038-0.1879578l0.3758545-0.1879578l0.3759155-0.5639038
        l0.5639038-0.3759155l0.1879883-0.1879578l0.3759155-0.3759155l0.1879272-0.375946l0.1879883-0.1879578l0.1879883-0.3759155
        l0.5638428-1.6916809l0.5639038-2.2555542v-0.5638428l-0.1879883-1.1277771v-0.5638733l0.1879883-0.7518616l0.1879883-0.7518616
        l0.1879272-0.3759155l0.1879883-0.3759155l0.5639038-0.5639038l0.1879272-0.3759155l0.1879883-0.5639038l0.3759155-0.5638428
        l0.3759155-1.3157349l0.1879883-1.3157349l0.1879272-1.5037231v-1.5036926l0.1879883-1.3157349l0.1879272-1.5036621
        l0.1879883-1.5037537l0.1879272-0.375885v-0.5638733l-0.1879272-0.5639038l-0.1879883-1.5036926v-0.5639038l-0.1879272-0.5638733
        l-0.1879883-0.3759155l-0.1879272-0.1879883l-0.1879883-0.3759155l-0.9398193-1.3157349l-0.1879272-0.3759155l-0.3759766-0.1879883
        l-1.3157349-0.9397583l-0.5638428-0.3759766l-0.3759766-0.3759155l-0.1879272-0.1879272l-0.1879883-0.3759155v-0.3759766
        l-0.1879272-0.375885v-0.3759155l-0.1879883-0.375946l-0.1879272-0.7518311l-0.1879883-0.375946l-0.7518311-1.6916504v-0.1879578
        l1.6916504,0.1879578l0.3759155,0.1879578l0.3759155,0.1879578l1.1278076,0.7518616l0.3759155,0.3759155l0.7518311,0.7518616
        l0.7518311,0.7518005h0.1879883v0.1880188l0.1879883,0.1879578h0.1879272v0.3759155l0.1879883,0.5639038l0.1879272,0.3759155
        l0.1879883,0.5638428l0.1879272,0.1880188l0.1879883,0.1879578l1.6916504-0.5639343h0.3759155l0.3759766-0.1879578h0.1879272
        l0.1879272-0.1879272l0.9398193-1.1277771l0.3759155-0.5639343l-0.1879272-0.9397583v-0.1879883l0.1879272-0.1879578h0.1879883
        l0.7518311-0.1879578h0.1879883l0.1879272,0.1879578v0.1879578l0.1879883,0.375946h0.1879883l1.1277466,0.1879578
        l0.5639038,0.1879578l1.5036621,0.1879578h0.7518921l0.3758545-0.1879578h0.7518921l0.5638428,0.1879578h2.4435425
        l0.9398193,0.375946l0.1879272,0.1879578l0.1879883,0.1879578l0.5639038,0.9398193l0.5639038,0.5638428l0.7518311,0.7518616
        l0.5639038,0.3759155l2.0675049,2.6314697l1.1278076,1.3157654l0.7518311,1.3157349l0.7518921,1.1277771l1.1277466,1.1277771
        l0.3759155,0.5638428l1.5037231,2.8193665l0.3759155,0.7518616l0.1879883,1.8796387h-0.5639038l0.7518311,2.2555542
        l0.7518921,2.443512l1.3157349,2.819397l0.75177,1.3157349l0.7518921,0.7518616l1.6916504,3.3833313l1.6916504,0.9397888
        l3.1953735,2.0675659l1.8796387,1.3157349l0.3758545,0.5639038l0.3759766,0.3759155l0.1879883,0.7518921l0.9398193,2.0675659
        l0.3758545,0.3759155l0.3759155,0.7518921l0.5639038,0.75177l0.7518311,1.3157349l-0.1879272,0.1879883l0.1879272,0.1879883
        l0.9398193,1.8795776l1.5037231,2.819458l1.1278076,1.6916504l-3.7592773,2.819397v0.3759766l-1.8795776,1.3157349
        l-0.5639648,0.3759155l-1.1277466,0.5639038l-0.7518311-1.1278076l-1.3157349-1.8796387l-0.3759155-0.5638428v-0.1879883
        l-0.3759155-0.3759155h-0.3759155v0.7518311h0.1879272v0.3759155l1.1278076,4.3231812l0.1879272,0.1879272v0.1879883
        l-0.1879272,0.1879272l1.6916504,6.5786743l3.7592163-0.9398193l1.8796387,3.3833618l0.7518311,0.1879272l1.1278076,1.8796387
        v0.3759155l-0.3759766,0.1879272l-2.819397,4.5111084l0.3759155,0.1879883l0.1879272,0.3759155l-0.7518311,0.3759155
        l-0.1879883,0.3759155l-0.3759155,0.1879883l-0.3759155,0.1879272l-0.1879272,0.3759766v0.1879272l-0.3759766,0.3759155
        l0.1880493,0.3759766l0.5638428,0.5638428l0.3759155-0.1879272h0.1879272l-0.3759155,0.3758545l0.1879883,0.3759766v0.1879883
        l-1.1278076,1.1277466l0.3759766,0.3759155h0.5638428l0.1879883-0.1879883l-0.1879883-0.1879272h0.3759155l0.7518921-0.1879272
        h0.3759155l0.1879883,0.1879272l0.1879272,0.1879272v0.3759155h-0.1879272v0.1879883l0.1879272,0.1879272l0.1879883,0.1879883
        V554.366394z M636.1560059,471.8510742v0.3759155l0.1879272,0.1879578v0.1879272h-0.1879272v0.1879578h-0.1879883
        l-0.1879272,0.1880188h-0.1879883v0.1879578h-0.3759155v0.1879578h-0.1879883v0.1879272h-0.1879272l-0.1879883,0.1879578
        h-0.1879272v0.1879578h-0.1879883l-0.1879883-0.1879578h-0.1879272v0.1879578h-0.1879883v0.1880188h-0.1879272v0.375885
        l0.1879272,0.1879578h-0.3759155v0.5638733h0.3759155v0.1879883h0.1879883v0.3759155h-0.1879883v0.1879578h-0.3759155v0.5639038
        v0.1879578h-0.1879272l-0.1879883,0.1879578h-0.5639038v-0.3759155h-0.1879272l-0.1879883-0.1879883v0.1879883h-0.1879883
        l-0.1879272,0.1879578h-0.7518311v0.1879578h-0.1879883l-0.1879883,0.1879578h-0.1879272v0.1879578h-0.1879883v1.1277771
        h-0.1879272v0.1879578h-0.7518921v-0.1879578h-0.5638428v-0.1879578h-0.1879272l-0.1880493-0.1879578h-0.1879272
        l-0.1879883-0.1879578h-0.1879272l-0.1879272-0.1879578l-0.1879883-0.1879883h-0.1879883v-0.1879578h-0.1879883
        l-0.1879272-0.1879578h-0.1879272v-0.1879578l-0.1879883-0.1879578v-0.1879578h-0.1879272v-0.1879883l-0.1880493-0.1879578
        v-0.1879578l0.1880493-0.1879578l-0.1880493-0.1879578h-0.1879272v-0.7518616l0.1879272-0.3759155v-0.5639038l0.1880493-0.1879578
        v-0.1879578l-0.1880493-0.1879272h-0.1879272v-0.3759155l0.1879272-0.3759766v-0.1879272l0.1880493-0.7518921v-0.1879578
        l0.1879272-0.1879272v-0.1879578l0.1879883-0.3759155v-0.1880188h0.1879272v-1.1277161l0.1879272-0.1879883v-0.5638733
        l0.1879883-0.1879578l0.3759766-0.1879578l0.5638428-0.5639038l0.1879272-0.1879578l0.1880493-0.1879578l0.1879272-0.1879578
        h0.1879883l0.1879272-0.1879883h0.1879272v0.1879883l0.1879883,0.1879578h0.1879272v0.1879578h0.1880493v0.1879578h0.1879272
        v0.1879578h0.1879272v-0.1879578h0.7518921v0.1879578h0.1879272v0.1879578h0.3759155l0.1879883-0.1879578h0.1879272
        l0.1879883,0.1879578h0.1879883v0.1879883h0.3759155l0.1879272,0.1879578h0.9398193l0.1879883-0.1879578l0.1879272-0.1879883
        h0.3759766v-0.1879578h0.3759155v0.375946l0.1879272,0.1879578v0.1879578l0.1879883,0.1879578h0.1879272v0.1879578h0.3759766
        v0.1879578h0.3759155v-0.1879578h0.3758545v0.5639038h0.1880493v0.1879578h-0.1880493v0.1879578h-0.1879272v0.3759155h-0.3759155
        v0.1879272h-0.1879272l-0.1879883,0.1880188v0.5638733h0.1879883v0.1879272h0.1879272v0.1879578l0.1879883-0.1879578h0.3758545
        v0.1879578h-0.1879272L636.1560059,471.8510742z M629.5772705,480.8731995l0.1880493,0.1879578l-0.3759766,0.1880188v0.5638428
        l-0.3759155,0.3759155l-0.3759155-0.1879578l-0.3759766,0.5639343l0.5639648,0.1879578l0.1879272,0.5638428l0.1879272,0.3759766
        l-0.3758545,1.1277466l-0.3759155,0.7518311l-0.3759766,0.375946l-0.7518311,0.3759155h-0.7518311l-1.8796387-0.1879578h-0.7518311
        l-0.5639038,0.1879578l-0.7518311,0.3759155l-0.1879883,1.3157349v0.375946l0.3759155,0.3759155l0.5639038,0.5638428
        l0.1879272,0.5639343l0.7518921,0.5638428v1.1277771l0.1879272,0.3759766l0.3759155,0.1879272h0.3759766v-0.1879272h0.1879272
        l0.3759155,0.1879272h0.1879272l-0.1879272,0.1879578h-0.3759155v0.1879578h-0.1879272l-0.1879883,0.1879578l-0.1879883,0.1880188
        h-0.3759155v0.1879272H623.5625l-0.3759155-0.1879272l-0.1879272-0.1880188h-0.9398193l-0.9398193,0.5639038l-0.5639038,0.3759155
        l-0.5639038,0.5639038l-0.1879272,0.5638733l-0.1879883,0.3759155h0.1879883v0.1879883h-0.1879883v0.1879578l-0.1879272-0.1879578
        l-0.1879272,0.9397888l-0.1879883,1.5037231l0.1879883,1.1277771l0.3758545,0.7518005l0.1879883,0.5639343v2.0675354
        l0.1879272,0.7518616l0.5639038,0.3759155l0.3759155,0.1879883l0.1879883-0.375946h0.1879272l0.1879883,0.1879578h0.3759155
        l0.1879883-0.1879578h0.1879272l-0.1879272,0.375946h-0.1879883l-0.3759155,0.1879578v0.1879578h0.1879883v0.5638733
        l0.3759155,0.7518616l0.3759155-0.1879578l0.3759155,1.1277771l-0.5639038,0.9398193v0.9397583h-0.1879272v-0.1879272
        l-0.9398193-0.3759155l-0.1879883,0.7518616l0.3759155,0.1879578l0.1879883,0.7518005l-1.5037231-0.375885h-0.75177
        l-0.9398193,0.375885h-1.1278076l-0.3759155,0.3759766l-0.1879883,0.1879578l-0.3759155,0.5638428l-0.5638428,0.9398193
        l-0.3759766,0.5639038l-0.3759155,0.5639038v0.1879272l0.1879883-0.1879272h0.1879272l0.1879883,0.1879272h0.3759155
        l0.3759155,0.1879272l0.1879883,0.1880493h0.1879272l0.1879883,0.1879272h0.1879883l0.1879272,0.1879883v0.1879272h-0.1879272
        l-0.1879883-0.1879272h-0.1879883l-0.1879272-0.1879883l-0.1879883-0.1879272h-0.3759155l-0.1879272-0.1880493h-0.9398193
        v0.1880493h-0.5639038v-0.1880493h-0.3759155l-0.5639038-0.1879272l-0.1879883,0.1879272l-0.3759155,0.5639648v0.1879272
        l-0.7518311-0.1879272h-0.5639038l-0.3758545,0.1879272l0.1879272,0.5639038l0.1879272,0.7518311l-0.1879272,0.1879272
        l-0.1879272,0.3759766h-0.1879883v-0.1879272h-0.1879883v-0.3759766l-0.1879883-0.1879883v-0.3758545l-0.1879272-0.3759766
        v-0.3759155l0.1879272-0.1879272h0.1879883v-0.5639648l-0.7518311-1.5036621l-0.5639038-0.9398193l-0.7518311-0.9397583
        l-0.7518311-0.9398193l-0.5639648-0.3759766l-0.75177-0.1879272l-0.7518921,0.375946l-1.5036621,0.5638733l-2.067627,0.9398193
        l-0.5638428,0.1879272l0.5638428,0.3759155l1.5037231-0.7518005l2.4435425-1.1277771l0.9397583,0.1879578l0.5639038,0.5638428
        l0.9398193,1.5037537l0.3759155,0.7518005l0.3759155,0.5639343l0.1879272,0.3759155l0.1880493,0.5639038v0.5638428
        l0.1879272,0.3759155v0.3759766l0.1879272,0.5638428l0.1879883,0.3759766l0.1879272-0.1880493l0.1879883,0.5639038
        l0.1879883,0.3759155v0.3759155l-0.5639038,0.9398193l-0.3759155,0.5639038l-0.1879272,0.7518311l-0.1880493,0.9398193
        l-0.3759155,0.7518921l-0.1879272,0.9398193l-0.1879272,0.3759155l-0.1879883,0.1879272l-0.7518311,0.5639038l-0.7518921,0.3759155
        l-0.7518311,0.3759155l-0.9398193,0.5639038l-0.9398193,0.5638428l-0.9398193,0.1879883l-1.6916504,0.3759766h-0.3758545
        l-1.3157959,0.5638428l-0.7518311,0.1879272h-0.7518311l-0.7518921-0.1879272l-0.7518311-0.1879883l-0.9398193-0.7518311
        l-1.6916504-1.1278076l0.9398193-0.75177v-0.3759766l0.1879883-0.5638428l0.1879272-0.1879883l-0.1879272-0.1879272
        l-1.5037231,1.6915894l-2.6314697-1.8796387l-1.1278076-0.9397583l-0.5638428-0.9398193l-0.7518311-0.1879272v-0.9398193
        l-0.3759155-0.3759155h-0.1880493v-0.1879883l0.1880493-1.1278076l0.5638428-0.7518311l0.3759155-0.5639038v-0.3759155h0.1879883
        l0.1879883-0.1879272h0.9398193l0.1879272-0.1879272h0.3759155l0.3759155-0.1879883l0.9398193-0.3759766l1.1277466-0.3758545
        l0.5639038-0.3759155h0.3759155l0.3759155-0.5639038h0.1879883v-0.1879883l0.3759155-0.1879272v-0.1879883l0.1879883-0.1879883
        v-0.1879578h0.7518311l0.1879883-0.1879578h0.3759155l0.1879272-0.1879578l0.5639038-0.1879578l0.1879883-0.1879272h0.1879272
        l0.3759155-0.1879578h0.5639038l0.3759155-0.1880188h0.5639038l0.1879883-0.1879578l0.1879272-0.1879578v-0.1879272h-0.1879272
        v-0.1879578l-0.5639648-0.1879578v-0.3759766h-0.1879272v-0.1879272l0.1879272-0.1879578v-0.1879578l0.3759766-0.9398193
        v-0.1879578l0.1879883-0.1879578v-0.1879883l0.5638428-0.5638733l0.1879272-0.1879578h0.1880493l0.1879272-0.1879578
        l1.1277466-0.1879578l2.0675659-0.375946v-0.1879578h0.1879883v-0.1879578h0.1879883l0.9398193-1.5036926l0.1879272-0.1879883
        l-0.1879272-0.1879578l-0.9398193-1.6916504l-0.5639038-0.7518616l-1.6916504-1.8795776l-0.1879883-0.1880188l-0.1879883-0.1879272
        l-0.3759155-0.1879578l-1.8795776-0.9398193l-0.1879883-0.1879578l-0.1879883-0.1879578l-0.3758545-0.1879578l-0.7518921-0.9398193
        l-0.1879272-0.1879578l-0.1879883-0.3759155l-0.1879272-0.1879883v-1.8796082l0.1879272-0.1879578l0.5638428-0.375946
        l0.5639648-0.5638733l0.3758545-0.1879272l0.7518921-0.5639343l0.1879272-0.1879578l0.1879883-0.375885l0.1879272-0.1879578
        l0.7518921-1.3157959l0.1879272-0.5638428l0.3759155-1.6916504v-0.375946l0.1879883-1.6916504v-0.5639038l-0.1879883-0.1879578
        l-0.5638428-1.8795776l-0.1879883-0.1880188v-0.1879578l-0.1879272-0.1879578l-0.3759766-0.7518616l-0.1879272-0.1879578
        v-0.1879272l-0.3759155-1.8796082v-0.7518616l0.3759155-2.2555542l0.1879272-0.3759155l0.1880493-0.375946l0.3758545-1.1277771
        l0.1879883-0.3759155l0.1879272-0.375885l0.7518921-0.9398193l0.1879272-0.1879578l0.1879883-0.1879578h0.1879272
        l0.3759766-0.1880188h0.5638428l0.9398193,0.3759766h2.2555542l0.1879883-0.1879578l0.3758545-0.3759766l0.1879883-0.1879272
        h0.3759766l1.3156738-0.1879578h2.2555542l0.3759155-0.1879578h5.8268433l0.7518921-0.1879578l0.9398193-0.1880188
        l0.3758545-0.1879272v0.1879272l-2.6314087,2.2555542h-1.1278076l0.5639038,5.0750122l0.5639038,1.1277771l0.9397583-1.3157349
        l2.819458,0.9397888l2.0675659,0.5639038l2.2554932,0.3759155l0.1880493,0.375885l0.1879272,0.1880188v0.5638733h0.1879883
        l-0.1879883,0.375885l0.5638428,0.5639343l0.5639648-0.7518921v-0.375885l0.1879272-0.1879578h1.1278076l0.3758545,0.3759155
        l-0.1879272,0.1879272H629.5772705z M732.7684326,561.5089111h0.5639648v0.1879272h-0.3759766v0.1879883l-0.1879883,0.1879272
        h-0.1879272l-0.1879883,0.1879883h-0.1879272l-0.1879272,0.1879883v-0.1879883h-0.1880493v0.1879883h-0.5638428
        l-0.1879272,0.1879272l-0.1879883-0.1879272h-0.1879272v0.1879272h-0.1880493v0.1879883h-0.3758545l-0.1879883,0.1879272v0.1879272
        l-0.1879272,0.1879883h-0.3759766l-0.1879272-0.1879883v-0.3758545l0.1879272-0.1879883h0.1879883l0.1879883-0.1879272h0.1879272
        l0.1879883-0.1879883h0.5639038l0.1879272-0.1879883h0.1879883l0.1879272-0.1879272v-0.1879883l0.1879272-0.1879272h0.1879883
        l0.3759766-0.1879883h0.5638428L732.7684326,561.5089111z M614.5403442,538.765564v0.3759155l0.1879883,0.1879272h0.5638428
        v0.1879883h0.1879883v0.1879272h-0.1879883v0.1879272h0.1879883v0.1880493h-0.1879883v0.1879272l-0.1879272,0.1879883
        l-0.1879883,0.1879272v0.1879272h-0.5638428h-0.1879883v-0.1879272h-0.1879883l-0.5638428-0.1879272h-0.1879883v-0.7518921
        h-0.3759155v-0.1879272h-0.1879883v-0.1879883H612.28479l-0.1879272,0.1879883h-0.3759766l-0.1879272,0.1879272h-0.7518311
        l-0.3759766-0.1879272l-0.1879272-0.1879883v0.1879883h-0.7518921v0.1879272h-0.1879272v0.1879272h-0.7518311l-3.0073853,1.8796387
        l-0.1879883,0.1879883h-0.1879272v0.1879272l-0.1879883,0.1880493v0.5638428h0.3759155v0.1879272l-0.1879272,0.1879883v0.1879883
        h-0.3759766v0.1879272h-0.7518311l-0.3759155-2.0675659l0.3759155-0.1879272v-0.5639648l-0.1879272-0.1879272h-0.1879883
        v-0.1879272h-0.1879883v-0.1879883h-0.9398193l-0.9397583-0.5639038h-0.1879272l-0.1880493-0.1879272v-0.1879883h-0.3759155
        v-0.1879272h-0.5638428v-0.1879883l-0.7518311-0.3759155l-0.3759155-0.3759155v-0.1879883l-0.1880493,0.1879883v-0.1879883
        l-0.3758545-0.5639038h0.5639038l0.5638428-0.1879272l-0.1879272-0.1879883v0.1879883h-0.1879883v-0.1879883h-0.1879272
        l-0.1880493,0.1879883h-0.3758545h-0.1879883v-0.1879883h-0.1879272v0.1879883l-0.1879883,0.1879272v-0.3759155h-0.1879883
        v-0.1879272l-0.1879272-0.1879883v-0.1879272l-0.7518311-1.6917114l-0.1879883-0.1879272l-0.7518311-1.6916504l1.6916504-0.7518311
        h0.3759155l-0.1879883-0.1879883l-2.0675659,0.5638428l-4.8869629-10.1499023v-0.3759766l0.1879272-0.1879272l4.8870239,3.5712891
        l0.5638428,0.3759155l0.5639038,0.3759155l0.3759155,0.1879883h0.1879883l1.3156738,0.1879272l2.2555542-0.5638428
        l0.3759766-0.1879883l0.9397583-0.1879883l0.3759766-0.1879272l1.6916504-0.3759155l2.0675659-0.9398193l0.5638428-0.3759155
        l0.7518921-0.3759766l0.3759155-0.1879272l0.7518311-0.3759155l0.5639648-0.1879272l0.5638428-0.1879883l0.1879272-0.1879883
        h0.1879883l0.3759766,0.7518311v0.1879883h0.1879272v0.3759766l0.1879272,0.3759155v0.1879272l0.1879883,0.1879272
        l0.5639038,0.5639648l0.9397583,1.1277466l0.1879883,0.1879272l0.1879883,0.1879883l0.1879272,0.1879272h0.1879883v0.5639038
        l0.1879272,0.5639038v0.3759155l-0.1879272,1.3157349v5.0749512l-0.1879883,0.1879883l-0.1879272,0.1879272v1.5037231
        l0.3759155,0.5639038l0.1879272,0.1879272l0.1879883,0.1879883l0.1879883,0.1879272l0.1879272,0.1879883h0.1879883
        l0.1879272,0.1879883h0.3759155v0.1879272l0.1879883,0.1879883l-0.1879883,0.1879272h-0.3759155v0.1879883h-0.1879272v0.1879883
        H614.5403442z M530.333374,564.8922729v0.3759155h0.1879883v0.3759155v0.1879883l-0.1879883-0.1879883h-0.1879272v-0.1879883
        h-0.1879272v-0.3759155l-0.1879883-0.1879272h-0.1879883v-0.1879883h0.5639038L530.333374,564.8922729z M589.1654663,542.336853
        v-0.1880493h-0.1879272v-1.5036621l0.7518311-0.3759155h0.1879883v-0.1879272h-0.3759766l-0.3759155-0.5639038
        l-2.4434814-2.6314697l-6.5787354-6.9545898l3.7592773-2.2555542l0.3759155-0.7518921l2.6314697-2.0675049l0.7518921,0.9397583
        l5.0749512,10.7138672l1.1277466,2.819397l-0.9398193,0.1879883l-0.7518311,0.3758545l-1.1277466,1.3157959l-0.5639038,0.5638428
        l-0.3759155,0.1879883h-0.5639038l-0.1879883,0.1879272v0.1880493H589.1654663z M527.1380615,577.1098022h0.1879883v0.1879272
        l-0.1879883,0.1879272v0.1880493l-0.1879883,0.1879272h-0.1879272l-0.1879883-0.1879272v-0.1880493h0.1879883v-0.1879272h0.1879272
        L527.1380615,577.1098022z M748.557312,551.7348633h-0.1879883v-0.3759155h0.1879883v-0.1879883h-0.1879883l0.1879883-0.1879272
        v-0.1879883h0.1879272l0.1879883-0.1879272l0.1879272-0.1879883h0.1879272l0.1880493-0.1879883h-0.1880493v-0.3759155h0.3759766
        l0.3759155-0.1879272l0.1879272-0.1879883h0.5639648l0.1879272-0.1879883l0.1879883-0.1879272h0.1879272v-0.1879883
        l0.1879272-0.1879272h0.1879883l0.1879883-0.1879883h0.5638428l0.1879883-0.1879272h0.7518311l0.5639038-0.1879272h0.7518311
        v-0.1880493h0.1879883v-0.1879272h0.1879272v-0.3759155h0.5639038l0.3759155-0.1879272h0.1879883l0.1879272,0.1879272h1.1278076
        l0.1879883-0.1879272v-0.1879883h-0.1879883v-0.1879883l1.5037231-0.3759155l0.3759155-0.1879883l0.1879272-0.1879272
        l0.5639038-0.1879272h0.7518311l0.1879272-0.1880493l0.1880493,0.1880493h0.9397583v-0.1880493h2.2555542v-0.1879272
        l0.1879272-0.1879883h0.1879883l0.1879883-0.1879272v-0.1879272h0.3759155v-0.1879883h0.1879272v-0.3759766h-0.1879272
        l-0.1879883-0.1879272h-0.1879272v-0.1879272l-0.1879883-0.1879883h-0.1879883l0.1879883-0.1879272l0.1879883-0.1879883h0.1879272
        l0.7518921-0.3759155h0.5638428l0.3759155-0.1879883l0.1879883,0.1879883v-0.1879883h0.7518311v0.1879883l0.1879272-0.1879883
        l0.1880493,0.1879883h0.1879272l-0.1879272,0.1879272h0.3759155v0.3759766h-0.1879883v0.1879272h-0.1879272l0.1879272,0.1879883
        h-0.1879272v0.1879272h0.1879272v0.1879272h-1.1277466l-0.1879883,0.1879883h-0.3759155l-0.1879883,0.1879883v0.1879883
        l0.1879883,0.1879272l-0.1879883,0.1879272l-0.3759155,0.1879883v0.1879272h-0.1879272l-0.1879883,0.1880493h-0.3759155v0.3758545
        l0.1879272,0.1879883l-0.1879272,0.1879272l0.1879272,0.1879883h0.1879883l0.1879883,0.1879883l-0.3759766,0.1879883h-0.3759155
        l-0.3759155,0.1879272h-0.1879883l-0.1879272,0.1879272h-0.1879883l-0.1879272,0.1879883h-0.1879883l-0.1879272,0.1879272
        l-0.1879883,0.1880493l-0.1879883,0.1879272h-0.5638428v-0.1879272h-0.1879883v0.1879272h-0.5639038l-0.1879272-0.1879272
        h-1.3157349v0.1879272l-0.1879272,0.1879272h-0.5639648l-0.1879272,0.1879883v0.1879272h-0.1879272l-0.1879883,0.1879883
        h-0.9398193l-0.3759155,0.1879272l-0.1879272,0.1879883v0.1879883l-0.3759766,0.1879272h-0.9398193l-0.1879272,0.1879883
        l-0.1879883-0.1879883h-0.5638428l-0.3759766,0.1879883h-0.5638428v0.1879272h-0.1879883v0.1879883h-0.5639038v0.1879883
        h-0.3759155l-0.1879272,0.1879272h-0.3759766l-0.1879272-0.1879272h-0.1879883l-0.1879272,0.1879272h-0.5639038v0.1879883
        l-0.1879883,0.1879272h-0.1879272l-0.1879272,0.1879883h-0.1879883v0.1879272h-0.3759766v0.1879883h-0.1879272v0.3759155
        h-0.3759155l-0.1879272,0.1879883h-0.1879883l-0.1879272-0.1879883l-0.1880493-0.1879272h-0.3758545L748.557312,551.7348633z
        M739.9110107,555.8699951l-0.1879272-0.1879272l0.1879272-0.1879272v-0.5639648h0.1879883v-0.75177h0.1879883v-0.3759766
        l-0.1879883-0.1879272h1.5037231l0.1879272-0.1879883h0.3759155v0.1879883h0.9398193l0.3759155,0.1879272h1.3157349
        l0.1879883-0.1879272h0.7518311l0.3759155,0.1879272h0.3759155l0.3759155,0.1879272h1.3157349l0.1879883,0.1880493
        l0.1879883,0.1879272h0.1879272l-0.1879272,0.1879883v0.1879272l-0.1879883,0.1879272v0.1879883l-0.1879883,0.1879883h0.1879883
        v0.1879883l-0.1879883,0.1879272v0.3759155l-0.1879272,0.1879272l-0.1879883,0.1880493l0.1879883,0.1879272l0.1879272-0.1879272
        h0.5639038l0.1879883-0.1880493h0.5639038l-0.1880493,0.1880493l-0.5638428,0.3758545l-0.7518311,0.3759155l-0.5639038-0.1879272
        h-0.1879272l-0.3759766-0.1879883h-0.1879272l-0.1879883,0.1879883h-1.1277466l-0.3759155-0.1879883h-0.3759766
        l-0.3759155,0.1879883h-0.1879272l-0.1879883-0.1879883l-0.3759155,0.1879883h-0.3759155l-0.1879883,0.1879272v0.1879883
        l-0.3758545,0.1879883l-0.1880493,0.1879272h-1.6915894l-0.3759155-0.1879272l-0.3759766-0.1879883l0.1879883-0.3759155V556.43396
        h0.1879883v-0.1880493l0.1879272-0.1879272l-0.1879272-0.1879883H739.9110107z M566.9859619,569.4033813l0.1879272,0.1879272
        l-0.1879272,0.1879883l-0.1879883,0.1879272h-0.3759155v-0.3759155l0.1879883-0.1879272H566.9859619z M530.333374,574.4783325
        h-0.3758545v0.1879272l-0.1879883,0.1879883v0.1879272h-0.3759766v0.1879883l-0.1879272,0.1879883h-0.1879272l-0.1879883,0.1879272
        l-0.1879272,0.1879883v0.1879272h-0.1880493v0.1879883h-0.1879272l-0.1879272,0.1879883v-0.1879883l0.1879272-0.3759155v-0.9398193
        l0.1879272-0.1879883v-0.3759155l0.1880493-0.1879272l0.1879272-0.1879883v-0.3759155h0.1879883l0.1879272-0.1879883h0.3759155
        v-0.1879272h0.3759766l0.1879272-0.1879883h0.1879272v-0.1879883l0.1879883-0.1879272v-0.3759155h0.3759766l0.1879272-0.1879883
        v-0.3758545h-0.3759766v-0.1880493l-0.1879272-0.1879272v-0.7518311h0.1879272l-0.1879272-0.1879883l0.1879272-0.1879883
        v-0.1879272h0.1880493v-0.3759155v-0.1879272h0.3758545l0.1879883-0.1880493l0.1879272-0.1879272l0.1879883-0.1879883v-0.1879272
        h0.1879883v-0.3759155h0.1879272v0.1879883h0.1879883v-0.1879883h0.1879272v0.1879883l0.1879883-0.1879883h0.1879272v-0.1879883
        h0.1879883v0.1879883h0.1879883v0.1879883h0.1879272v0.1879272h0.1879883v0.9398193h0.1879272v0.1879883h0.1879883v-0.1879883
        h0.3759155v0.1879883h0.1879883v0.7518311h-0.1879883v0.7518311l-0.1879883,0.1879272v0.1880493h-0.1879272l-0.1879883,0.1879272
        l-0.1879272,0.1879272h-0.3759155v0.1879883l-0.1879883,0.1879272h-0.3759155v0.1879883h-0.1879883v0.1879272h-0.9398193
        l-0.1879272,0.1879883h-0.5638428v0.5639038h-0.1880493v0.1879272h-0.1879272v0.1879883h-0.1879883V574.4783325z
        M566.2341309,570.9070435h0.3759155v0.3759155h-0.1879883v0.1879272l-0.1879272,0.1880493h0.1879272v1.1276855h-0.1879272
        v-0.3759155h-0.1879883v-0.1879272h-0.1879883V572.03479h-0.1879272v-0.5639038h-0.1879883v-0.1879272h0.3759155v0.1879272
        h0.1879883v-0.3759155L566.2341309,570.9070435z M566.2341309,570.7191162h-0.1879883v-0.1879883h0.3759155
        L566.2341309,570.7191162z M537.2879639,565.644104v-0.1879883l-0.1879883,0.1879883v0.1879883h0.1879883v0.1879272h-0.1879883
        l0.1880493,0.1879883v0.1879272h0.1879272v0.1879883h0.1879272l0.1879883,0.1879272v0.1879883l-0.1879883,0.1879883v0.1879272
        h-0.3758545v-0.1879272l-0.1879883,0.1879272h-0.1879272v-0.1879272h-0.1879883v-0.1879883h-0.1879883v-0.1879883h-0.1879272
        v-0.1879272h-0.1879883v-0.7518311H535.97229v-0.5639038l0.1878662-0.1879883v-0.1879272h0.5639038l0.1879883,0.1879272h0.1879272
        l0.1879883-0.1879272l0.1879272-0.1879883l0.1879883,0.1879883h0.1879272v0.1879272h-0.1879272v0.7518921l-0.1879883-0.1879883
        H537.2879639z M541.0472412,581.4329224l0.1879272,0.1879272h-0.1879272V581.4329224z M543.8666992,582.1847534h0.1879272
        v0.1879883h-0.3759155v0.7518311h-0.1879883V583.3125h-0.1879272v0.1879883h-0.1879883v0.1879883h-1.1277466v0.3759155h0.1879272
        l-0.1879272,0.1879272l0.1879272,0.1879883h-0.3759155v0.1879883h-0.1879272v-0.1879883h-0.3759766h-0.1879272v0.1879883
        h-0.1879883l-0.1879272-0.1879883l-0.1879272,0.1879883v0.1879272h-0.1879883v0.1879883l0.1879883,0.1879272v0.1879883h0.1879272
        v0.1879272h-0.3759155v0.3759766h-0.5639038l-0.1879272-0.1880493l-0.1879883,0.1880493h-0.1879272v-0.5639038h-0.1880493
        v-0.5639038h-0.1879272v-0.1879272h-0.1879883l-0.1879272-0.1879883h-0.1879272l-0.1879883,0.1879883h-0.7518311
        l-0.1879883-0.1879883h-0.5639038v0.1879883h-0.7518311l-0.1879272,0.1879272h-0.1879883v0.1879883h-0.3759155
        l-0.1879883,0.1879272v0.1879883h-0.1879272l-0.1879883,0.1879272l-0.1879883,0.1879272l-0.3759155,0.1880493l-0.1879272,0.1879272
        l-0.1879883,0.1879883l-0.1879272,0.1879272v0.1879272h-0.1879883v0.1879883l-0.1879883,0.1879883h-0.1879272v0.1879883h-0.1879883
        v0.1879272l-0.1879272,0.1879272h-0.1879883v0.1879883h-0.1879272v0.5639038h-0.1879883v0.7518311h-0.1879883l-0.1879272-0.1879883
        v-0.1879272l-0.1879883-0.1879883v-0.3758545l-0.1879272-0.1880493l-0.1879272-0.1879272v-0.1879883l-0.1880493-0.1879272
        v-0.5639038h-0.1879272v-0.9397583h-0.1879883v-2.2555542l0.1879883-0.1879883V583.3125l-0.1879883-0.1879272v-0.3759155
        l0.1879883-0.1879883v-0.7518311h0.1879272v-0.1879883l0.1880493-0.1879272l0.1879272-0.1879883h0.7518311v0.1879883h0.1879883
        v0.1879272h0.1879272v0.1879883h0.1879883v0.1879272h0.1879272v0.1879883h0.1879883v0.3759155h0.1879272v0.1879883h0.1879883
        v0.1879272h0.1879883v0.1879883h1.1277466l0.1879883-0.1879883l0.1879272-0.1879272h0.1879883l0.1879272-0.1879883v-0.3759155
        l-0.1879272-0.1879883l0.1879272-0.1879272v-0.1879883h0.1879883v-0.1879272l0.1879883-0.1879883h-0.1879883v-0.1879272h0.1879883
        v-0.1879883l0.1879272-0.1879883h0.7518311l0.1879883,0.1879883h0.1879883v0.1879883h0.3759155l0.1879272,0.1879272
        l-0.1879272,0.1879883v0.3759155h0.1879272v0.5639038h0.1879272v0.3759155l0.1879883,0.1879272h0.1879883l0.1879883,0.1879883
        V583.3125h0.3758545l0.1879883,0.1879883h1.3157349v-0.5639038h0.1879883v-0.1879272h0.3759155l0.1879272-0.1879883h0.1879883
        v-0.1879272l0.1879272-0.1879883l0.1879883-0.1879883v-0.1879272h-0.1879883v-0.1879883h0.5639038l0.1879883-0.1879272h0.3759155
        l0.1879272,0.1879272h0.1879883v-0.1879272h0.1879883v-0.1879883l0.1879272,0.1879883h0.3759155v0.1879272h0.1879883v0.1879883
        l0.1879883,0.1879272V582.1847534z M621.1190186,500.0453186l0.3759155-0.3759155l-0.5639038-0.7518616v-0.1879578v-0.1879272
        l0.1879883-0.1879578v-0.1879578h0.1879883l0.3759155,0.9397583l0.5638428,0.1879578l0.1879883,0.1880188l-0.9398193,1.1277466
        l-0.9398193,0.1879578l-0.1879272-0.5639038L621.1190186,500.0453186z M602.6987305,542.1488037l1.1277466,1.3157349v0.1879883
        l-0.1879272,0.1879883h-0.3759766v0.1879272h-0.1879272l-0.1879883,0.1879883v-0.3759155l-0.3759155-0.1879883
        l-2.2555542-2.4435425l-2.0675659-3.571228h0.1879272l1.8796387,3.0073853h0.5639038v-0.1879272h0.1879883v-0.1879883h0.1879272
        v0.1879883l0.3759155,0.1879272v0.1879272l0.1879883,0.1879883h0.1879272L602.6987305,542.1488037z M582.2108765,513.390625
        l-0.1879883-0.7518311v-1.3157349l0.5639038,0.1879578l0.5638428,0.3759155l0.5639038,0.7518616l0.1879883,0.1879272
        l0.3759155,0.5639038l0.7518311,0.9398193l0.5639038,1.3157349l0.1879883,0.9398193v1.1277466l-1.1278076-1.1277466
        l-1.1277466-1.5037231L582.2108765,513.390625z M562.2868652,560.7570801h0.7518311v0.1879272l-0.1879272,0.1880493h-0.1879883
        v0.1879272h-0.3759155l-0.1879272,0.1879272l-0.1879883-0.1879272v-0.1879272h0.1879883v-0.1880493L562.2868652,560.7570801z
        M588.0376587,552.4867554h0.3759155v0.1879883v0.1879883h-0.1879883v0.1879272h-0.1879272v0.1879272h-0.5639038v0.1879883
        h-0.5639038v-0.1879883v-0.1879272v-0.3759155h0.1880493v-0.1879883h0.1879272v-0.1879883h0.5638428L588.0376587,552.4867554z
        M589.5413818,552.4867554h0.1879272v0.1879272h-0.3759155l-0.1879272,0.1879883h-0.1879272l-0.1879883-0.1879883
        l-0.1879883-0.1879272h0.1879883v-0.1879883h0.1879883v-0.1879883h0.1879272l0.1879272,0.1879883L589.5413818,552.4867554z
        M590.1052246,549.8552856h0.9398193v0.1879883l-0.1879272,0.1879883v-0.1879883h-0.7518921l-0.1879272,0.1879883h-0.3759155
        l-0.1879883,0.1879272v-0.1879272h-0.1879272l0.1879272-0.1879883h0.5639038L590.1052246,549.8552856z M586.7219238,555.6821289
        h0.1879883v0.1879272L586.7219238,555.6821289z M586.9099121,555.4941406h0.1879883v0.1879883h-0.1879883V555.4941406z
        M586.7219849,555.4941406h-0.1879883l-0.1879883-0.1879272h0.1879883L586.7219849,555.4941406z M579.2034912,509.0675049
        l-0.1879883-0.1879272v-0.1879578v-0.1879578h0.1879883h0.1879883v0.1879578h0.3759155v0.1879578h0.1879272l0.1879272,0.1879272
        v0.1879578h0.1879883v0.3759155h0.1879883v0.1880188v0.1879272l0.1879883,0.1879578h-0.3759766v-0.1879578l-0.1879883-0.1879272
        h-0.1879272l-0.1879272-0.1880188l-0.1879883-0.1879578l-0.1879272-0.1879578L579.2034912,509.0675049z M571.4970093,502.3008728
        l-0.1879272-0.1879578v-0.5638733v-0.1879883h0.5638428l0.3759155,0.1879883l0.1879883-0.1879883l0.3759155,0.1879883
        l0.1879883,0.1879578h0.1879272l0.1879883,0.1879578l0.1879272,0.1879578l0.1879883,0.1879578v0.1879578h0.1879883
        l0.1879272,0.1879578l0.3759155,0.1879883l0.1879883,0.1879578v0.1879578l0.3759155,0.3759155l0.3759155,0.5639038
        l0.1879883,0.3759155l0.3758545,0.5639038l0.1880493,0.1879578l0.1879272,0.1879578l0.1879883,0.1879578l0.1879272,0.5638428
        l0.3759155,0.3759766l0.1879883,0.5638428l0.1879883,0.1879578l0.1879272,0.3759766h-0.3759155l-0.5639038-0.1879578
        l-0.5638428-0.3759766l-0.1880493-0.1879272l-0.5638428-0.1879578l-0.3759155-0.1879578l-0.1879272-0.1879578l-0.3759766-0.1880188
        l-0.1879272-0.1879578l-0.1879883-0.1879272l-0.1879272-0.1879578l-0.3759766-0.3759155v-0.1879578l-0.1879272-0.1879883
        l-0.1879883-0.1879578l-0.1879272-0.1879578l-0.3759155-0.5638733l-0.1879883-0.1879883l-0.1879883-0.1879578h-0.1879272
        v-0.1879578h0.1879272l-0.1879272-0.1879578h-0.1879883v-0.1879578v-0.1879578h-0.1879272v-0.1879883l-0.1879883-0.1879578
        V502.3008728z M587.0979004,515.4581909l-0.1879883-0.1879272v-0.3759155h0.1879883l0.1879883-0.3759766v-1.1276855
        l0.3759155-0.1880493h0.1879272v-0.1879272l1.1278076,0.1879272l0.1879272,1.1278076v0.3759155h-1.3157349v0.1879883h1.3157349
        l-0.1879272,0.1879272v0.1879883h0.1879272v0.1879272h-0.1879272v0.1879883l-0.5639648,0.7518311v0.7518921h-0.1879272
        l-0.1879272-0.1879883l-0.1879883-0.3759155l-0.1879272-0.3759155L587.0979004,515.4581909z M624.5022583,506.2480469h0.1880493
        v0.3759766h-0.5639648v-0.1879578h-0.3758545l-0.1879883,0.1879578l-0.1879272-0.1879578v-0.1880188H623.5625v-0.1879578h0.1879883
        v-0.1879272v-0.1879578h0.75177v0.375885V506.2480469z M627.8856201,504.7444153h0.1879883v0.1879578h-0.3759766v-0.1879578
        H627.8856201z M627.1337891,506.2481079h0.1879883v0.1879578v0.1879578h-0.1879883v-0.1879578V506.2481079z
        M627.1337891,507.7517395h0.1879272v0.1880188h-0.1879272v0.1879272h-0.3759766v0.1879578h-0.1879272v-0.1879578v-0.1879272
        v-0.1880188h0.1879272v-0.1879578h-0.1879272v-0.1879578v-0.1879578h0.1879272v-0.1879272v-0.1879578l0.1879883-0.1879578
        l0.1879883,0.1879578v0.1879578v0.1879272v0.1879578v0.1879578V507.7517395z M627.6976929,507.9397888l0.1879272,0.1879578
        v0.1879578v0.1879578h-0.1879272v-0.1879578h-0.1879883v-0.1879578h0.1879883V507.9397888z M628.8255005,507.7518005h0.5638428
        l-0.1879883,0.1879578h-0.3758545l-0.1879883-0.1879578v-0.1879578h0.1879883V507.7518005z M590.4812012,526.9238892v0.1879883
        l0.1879272,0.3759155l0.1879883,0.1879883v0.1879272l0.1879883,0.3759155v0.5639038h-0.3759766v0.1879883l0.1879883,0.1879272
        v0.1879883h-0.1879883l0.1879883,0.1879272l0.1879883,0.1879272v0.1879883h-0.1879883l-0.1879883,0.1879883h-0.1879272v-0.3759766
        h-0.3759155l-0.1879272-0.1879272h-0.1880493l-0.1879272-0.1879272v-0.3759155l-0.1879883-0.1879883l-0.1879272-0.3759155
        l-0.1879272-0.3759155l-0.1879883-0.1879883v-0.3759155l-0.1879883-0.1879883l-0.1879883-0.3759155v-0.3759155
        l-0.1879272-0.1879883v-0.1879272l-0.1879272-0.1879883v-0.1879272l-0.1879883-0.1879883h-0.1879272v-0.1879272
        l-0.1879883-0.1879883l0.1879883-0.1879883h0.1879272v0.1879883l0.1879883,0.1879883l0.1879272,0.1879272h0.1879272
        l0.1879883,0.1879883h0.1879883l0.1879883,0.1879272h0.3758545l0.1879883,0.1879883l0.3759766,0.1879272l0.1879272,0.1879883
        l0.1879272,0.1879883L590.4812012,526.9238892z M649.1253052,522.9766846h-0.1879272v-0.3758545h0.1879272v-0.1879883h0.1879883
        l0.1879883,0.1879883h0.9397583v0.1879272h-0.1879272v0.1879272h-0.1879883l-0.1879272,0.1879883l-0.1879883,0.1879272h-0.1879272
        l-0.1879883-0.1879272L649.1253052,522.9766846z M646.1179199,528.6156006h-1.1276855v-0.3759766l0.1879272-0.1879272v-0.1879883
        h0.1879883v-0.1879272h-0.1879883v-0.1879883l-0.1879272-0.3759155h0.3759155l0.1879272-0.1879883h1.1278076v0.3759766h0.3758545
        l0.1879883-0.1879883v-0.1879883h0.1879883v0.1879883v0.1879883h0.3759155l0.1879272,0.1879272h-0.1879272l-0.1879272,0.1879883
        h-0.1879883l-0.1879883-0.1879883v0.1879883l-0.1879883,0.1879272v0.1879883h-0.1879272v0.1879272h-0.1879272v0.1879883h-0.3759155
        L646.1179199,528.6156006z M645.5540161,529.9313354v-0.1879883h0.1879883v0.1879883v0.1879272h-0.3759155v-0.1879272H645.5540161z
        M643.8623657,532.9386597l-0.1879272-0.1879272v-0.1879883h0.3759766v-0.1879272h0.1879272v0.1879272h0.3759155v0.1879883
        l-0.1879272,0.1879272v0.1879883l-0.1879883,0.1879272v0.1879883l-0.1879272-0.1879883l-0.1880493,0.1879883v-0.1879883v-0.1879272
        h0.1880493L643.8623657,532.9386597z M643.6744385,536.3220215h-0.1879272v-0.3759155l0.1879272-0.1879883v-0.1879272h0.1879883
        v-0.1879883h0.7518311l0.1879272,0.1879883v0.1879272h0.1879883v0.1879883v0.3759155h0.3759766v0.1879272h0.3758545v0.1879883
        v0.1879883v0.1879272v0.1879883h-0.3758545v-0.1879883h-0.9398193v-0.1879272h-0.3759155v-0.1879883h-0.1879883
        l-0.1879883-0.1879883V536.3220215z M631.4569092,549.8552246h0.3759155v0.1880493v0.1879272h-0.1879272v0.1879272
        l-0.1879883-0.1879272h-0.1879272v-0.1879272L631.4569092,549.8552246z M631.0809937,549.8552246l0.1879883,0.1880493h-0.3759155
        v-0.1880493H631.0809937z M638.411499,549.2913818l0.1879272,0.1879272v0.1879883h-0.1879272l-0.1879883,0.1879272v0.3759766
        h-0.1879272l-0.1879883,0.1879272v0.1879883h-0.1879272v0.1879272l-0.1879883,0.1879883h-0.1879883l-0.1879272-0.1879883
        h-0.1879883v-0.1879272v-0.1879883v-0.1879272l0.1879883-0.1879883h0.1879272l0.1879883-0.1879883l0.1879883-0.1879272v-0.1879883
        v-0.1879272l-0.1879883-0.1879272l0.1879883-0.1880493v-0.1879272v-0.1879883h-0.7518921l-0.1879272-0.1879272
        l-0.1879883-0.1879272v-0.1879883v-0.1879883h0.1879883v-0.1879883h0.1879272v-0.1879272h0.1879883l0.1879272-0.1879272v-0.1879883
        h0.1879883v-0.1879272l0.1879883-0.1879883v-0.1879883h0.3759155v0.1879883h-0.1879883v0.3759155l-0.1879272,0.1879883v0.1879272
        h0.5638428v0.3759155h-0.1879272v0.3759766h-0.1879883v0.1879272v0.1879272h0.1879883v0.1879883h0.3759155v0.1879272V549.2913818z
        M642.5466919,547.975647l-0.1879883-0.3759155v-0.3759155l0.1879883-0.1879883h0.1879272l0.1879272-0.1879272l0.1879883-0.1879883
        h0.1879272v-0.1879272h0.5639038v0.1879272h-0.1879272v0.1879883v0.1879272l-0.1879883,0.1879883v0.3759155h-0.1879883v0.1879883
        v0.1879272h-0.5638428v0.1879883h-0.1879272V547.975647z M642.5466919,549.2913818l-0.1879883-0.1879272v-0.1879883v-0.3759155
        l0.1879883-0.1879272h0.1879883l0.1879272,0.1879272h0.1879883l-0.1879883,0.1879883v0.1879272v0.1879883v0.3758545
        l-0.1879272-0.1879272H642.5466919z M646.3059082,553.614502v-0.1879272h-0.3759155l-0.1879883,0.1879272v0.1879883h-0.1879272
        v-0.1879883h-0.1879272l-0.1880493,0.1879883h-0.3759155v0.1879272h-0.1879272l-0.1879272-0.1879272v-0.1879883h0.1879272
        v-0.1879272h0.1879272v-0.1879883l-0.1879272-0.1879883h-0.1879272v-0.3759155h0.1879272v-0.1879272v-0.3759155v-0.1879883
        h-0.1879272l-0.1879883-0.1879883h-0.1879272v0.1879883h-0.5639038v-0.1879883h-0.5639038v-0.1879272v-0.1879883h-0.3759155
        v-0.1879272h0.1879272v-0.1879272l0.1879883-0.1880493l0.1879883-0.1879272l0.1879272-0.1879883l0.1879883-0.1879272v-0.1879272
        h0.1879272v-0.1879883h0.1879272v-0.1879272h0.1880493h0.1879272l-0.1879272,0.1879272v0.1879883v0.1879272h0.3759155
        l0.1879272-0.1879272h0.1879272v0.1879272v0.1879272h0.1879883l0.1879272-0.1879272l0.1880493-0.1879272v-0.1879883h0.1879272
        v-0.1879272h0.1879272v-0.1880493h1.1278076l0.1879272,0.1880493h0.1879883v-0.1880493h0.3759766l0.1879272,0.1880493h0.1879272
        v0.3759155v0.1879272l-0.1879272,0.1879272v0.1879883l-0.1879272,0.1879272h-0.1880493v0.1880493l-0.1879272-0.1880493
        l-0.1879883,0.1880493v0.1879272h-0.1879272v0.1879272l-0.1879272,0.1879883h-0.1879883v0.1879272h-0.1879883v0.1879883v0.1879883
        h0.1879883v-0.1879883l0.1879883-0.1879883h0.3758545l0.1879883-0.1879272h0.1879272l0.1880493-0.1879883l0.1879272-0.1879272
        h1.1277466v0.1879272v0.1879883v0.1879272h0.1879883l0.1879272-0.1879272v0.3759155h-0.1879272l0.1879272,0.1879883h0.1879883
        l-0.1879883,0.1879272h-0.1879272v0.1879883h-0.3759766v0.1879272h-0.3759155v0.1879883v0.3759155h-0.1879272v0.1879883h-0.1879883
        l-0.1879272,0.1879272v-0.1879272h-0.3759766v0.1879272h-0.1879272l-0.1879883,0.1879883l-0.1879272,0.1879272h-0.1879272
        v-0.1879272l-0.1879883,0.1879272h-0.1879883V553.614502z M645.1781616,566.5839233l0.1879883-0.1879883v0.1879883v0.1879883
        h-0.1879883v0.1879272h-0.1879883v0.1879883h-0.1879883v0.1879272h-0.3758545v-0.1879272v-0.1879883h0.1879272v-0.1879272
        h-0.1879272v-0.1879883h-0.3759155v0.1879883h-0.1880493l-0.1879272,0.1879272h-0.1879272l-0.1879883-0.1879272h-0.1879272
        v0.1879272h-0.1879883l-0.1879883,0.1879883v-0.1879883h-0.3759155l-0.1879272-0.1879272l-0.1879883-0.1879883h-0.1879272
        v0.1879883v0.5638428h-0.1879883v0.1879883h-0.1879883v0.1879272h-1.5036621l-0.1879883-0.1879272v-0.1879883v-0.1879272
        l0.1879883-0.1879883v-0.1879272h0.1879272l0.1879883-0.1879883v-0.3759155v-0.1879883l-0.1879883-0.1879272l-0.1879272-0.1879883
        l0.1879272-0.1879883v-0.1879272h0.3759766l0.3759155-0.5639038l0.3759155-0.7518311l0.3759155-0.3759155v-0.3759155h0.3759155
        l0.1879883-0.1879883h0.5638428v0.3759155l0.1879883,0.1879883h0.1879883l0.1879272-0.1879883h0.1879883l0.1879272,0.1879883
        l0.1879272,0.1879272v0.1879883v0.1879272l0.1880493-0.1879272v-0.1879883h0.1879272l0.1879883,0.1879883h-0.1879883
        l0.1879883,0.1879272h0.5638428v0.1879883v0.1879883h-0.1879883v0.1879272l-0.1879272,0.1879883v0.1879272l0.1879272,0.1879883
        l0.1879883,0.1879272h-0.1879883v0.1879883h-0.1879272v0.1879883h0.1879272v0.1879272h0.1879883v0.1879883h-0.1879883v0.1879272
        v0.1879883H645.1781616z M649.5012817,564.5162964h-0.1879883v-0.1879272h0.1879883V564.5162964z M653.4484253,566.5839233
        h0.3759766v0.3758545v0.1879883v0.1879272v0.1880493v0.1879272l-0.1879272,0.1879272h-0.1880493l-0.1879272,0.1879883v0.1879272
        v0.1879883h-0.1879272v0.1879883h-0.1879883v0.1879272v0.1879883h-0.3759155v0.1879272v0.1879883h-0.1879883v0.1879272v0.1880493
        h-0.1879272v0.1879272h-0.1879883l-0.1879272,0.1879272h-0.1879883l-0.1879272,0.1879883h-0.1879883l-0.1879883,0.1879272
        l-0.1879272-0.1879272h-0.1879883v0.1879272l-0.1879272-0.1879272h-0.3759155l-0.1879883-0.1879883l-0.1879883,0.1879883
        h-0.3759155l-0.1879272,0.1879272h0.1879272v0.3759155h-0.1879272l-0.1879883,0.1879883h-0.5639038v-0.1879883
        l-0.1879272,0.1879883h-0.1879883l-0.1879272-0.1879883h-0.1879883v-0.1879272v-0.1879883l0.1879883-0.1879272v-0.3759155
        v-0.1879272v-0.3759766h0.3759155v-0.1879883h0.1879272h0.3759155v-0.1879272h-0.1879272v-0.3759155h-0.3759155v0.1879272
        h-0.3759155l0.1879272,0.3759155l-0.3759155-0.3759155v-0.1879272l-0.1879883-0.1879883h-0.1879272v0.1879883h-0.1879883
        l-0.1879272-0.1879883v-0.1879883v-0.1879272v-0.1879883l0.1879272-0.1879272v-0.3759766v-0.1879272v-0.1879883h0.1879883
        l0.1879272-0.1879272v-0.1879272v-0.1879883h0.1879883l-0.1879883-0.1879272h-0.5638428v0.1879272h-0.1879272l-0.1879883-0.1879272
        v-0.1880493l0.1879883-0.1879272h0.1879272v-0.1879272l0.1879272-0.1879883v-0.1879272h0.7518921v-0.1879883h0.3759155
        l0.1879272-0.1879883l0.1879883-0.1879272h0.1879272l0.1879883-0.1879883h0.3759155l-0.1879272,0.1879883h0.1879272
        l0.1879883,0.1879272h0.3759155v0.1879883h0.7518311l0.1879883,0.1879883h0.9398193l0.1879272,0.1879272h0.1879883v0.1879883
        h0.3759155v0.1879272h0.1879883v0.1879272v0.1880493h-0.1879883v0.1879272h-0.1879883v0.1879883h0.7518921l0.1879272,0.1879272
        h0.1879272V566.5839233z M658.5234375,555.3062134h-0.1879272v-0.1879883v-0.1879272h0.1879272v0.1879272V555.3062134z
        M659.2752686,557.7496338l0.1879883-0.1879272v0.1879272v0.1879883l-0.1879883,0.1879272h-0.1879272v0.1880493h0.1879272
        v0.1879272v0.1879272h-0.3759155v-0.1879272h-0.3759155v-0.1879272h-0.1879272v0.1879272h0.1879272v0.1879272h0.1879883
        l0.1879272,0.3759155l0.1879883,0.1879883h0.1879272v0.5639038l-0.1879272,0.1879272v0.1879883l-0.1879883,0.1879272
        l-0.1879272,0.1879883h-0.1879883l-0.1879272,0.1879883l-0.1879883,0.1879272v0.1879883h-0.3759155v0.1879272l-0.1879883-0.1879272
        h-0.1879272v-0.1879883h-0.3759766v0.3759155h0.1879883v0.3759155l-0.1879883,0.1879883h0.1879883v0.1879883l-0.1879883,0.1879272
        l0.1879883,0.1879883l-0.1879883,0.1879272l-0.1879272,0.1879883v0.1879272h-0.7518311l-0.1879272-0.1879272v-0.1879883h-0.5639648
        v-0.1879272h-0.1879272v-0.1879883l0.1879272-0.1879272v-0.1879883l0.1879883-0.1879883v-0.1879272h-0.1879883v-0.1879883
        h-0.1879272l-0.1879272-0.1879272h-0.1879883v-0.1879883h0.1879883v-0.3759155h-0.1879883l-0.1879883,0.1879883h-0.1879883
        v0.1879272h-0.3758545v-0.1879272h-0.1879883v-0.1879883v-0.1879883l-0.1879272-0.1879272v-0.3759155v-0.1879883v-0.1879883
        h0.1879272l-0.1879272-0.1879272v-0.1879883l-0.1880493,0.1879883v-0.1879883v-0.1879272h-0.3758545v-0.1879883v-0.1879272
        h-0.1879883v-0.1879272l-0.1879272,0.1879272h-0.1879883v-0.1879272h-0.1879272l-0.1879883-0.1880493v-0.1879272v-0.1879883
        h-0.1879883v-0.1879272v-0.1879272l0.1879883-0.1879883h0.3759155v0.1879883l0.1879883,0.1879272h0.1879272l0.1879883-0.1879272
        h-0.1879883v-0.1879883v-0.1879883v-0.1879883l-0.1879272-0.1879272h0.1879272V556.43396h0.3759155v0.1879272h0.1879272V556.43396
        l0.1880493,0.1879272v0.1879272v0.1879883h0.1879272v0.1879883h0.1879883v-0.1879883l0.1879272,0.1879883v0.1879883h0.1879272
        v-0.3759766v-0.1879883h-0.1879272v-0.1879272h0.7518921v0.1879272h0.1879272l0.1879272,0.1879883h0.5639648l0.1879272,0.1879883
        h0.7518311v-0.1879883v-0.3759155V556.43396h-0.1879883l0.1879883-0.1879883v-0.1879272h0.3759155v0.1879272h0.3759155
        l0.3759155-0.1879272l0.1879883-0.1880493h0.3759155v0.1880493v0.1879272v0.3759155l0.1879883-0.1879272h0.1879272v0.1879272
        v0.1879272h0.1879883v0.1879883v0.3759766v0.1879272L659.2752686,557.7496338z M662.6586304,558.6895142h0.3759155v0.1879883
        v0.1879272v0.1879883h-0.1879272v0.1879272h-0.1879883v0.1879272h-0.1879272v0.1880493h-0.3759766v0.1879272v0.1879883h-0.1879272
        v-0.1879883v-0.1879272v-0.1880493v-0.1879272v-0.1879272l-0.1879883-0.1879883v-0.1879272l0.1879883-0.1879883v0.1879883
        h0.3759155h0.1879883v-0.1879883v-0.1879272h0.1879272V558.6895142z M667.357666,555.1182251l0.1879272,0.1879272h-0.3759155
        v0.1879883l-0.1879272,0.1879883h-0.5639038v0.1879272h-0.1879883l-0.1879272,0.1879883h-0.1879883v-0.1879883l0.1879883-0.1879272
        h-0.5639038l-0.1879883-0.1879883h-0.3759155v-0.1879883h-0.1879272l-0.1879272-0.1879272v-0.3759155h0.1879272
        l0.1879272-0.1879883h0.1879883l0.1879272-0.1879272h0.3759766v0.1879272l0.1879272,0.1879883v0.1879272v0.1879883h0.1879883
        v0.1879272v0.1879883h0.5638428l0.1879883-0.1879883l0.1879883-0.1879272H667.357666z M672.4326782,556.9978638h0.5638428
        v0.1879272h-0.1879272v0.1879883h-0.1879883l-0.1879272-0.1879883V556.9978638z M671.6807861,555.6820679h0.5638428v0.1879272
        h-0.1879272v0.1880493v0.1879272l-0.1879272,0.1879883v0.1879272h-0.3759155v0.1879272v0.1879883v0.1879883h-0.1880493
        l-0.1879272-0.1879883v0.1879883v0.1879883h-0.3759155v0.1879272v0.1879272h-1.5036621l-0.1879883-0.1879272h-0.1879883v0.1879272
        l-0.1879272-0.1879272v-0.1879272h0.1879272v-0.3759766h-0.1879272v-0.1879883h0.1879272v-0.1879272V556.43396h-0.1879272
        v-0.1879883h0.1879272v-0.1879272l0.1879883-0.1880493h-0.1879883v-0.1879272v-0.1879272h-0.3759155v-0.1879883v-0.1879272
        v-0.1879883l0.1879883-0.1879272h0.3759155h0.5639038v0.1879272l-0.1879883,0.1879883v0.1879272h0.1879883v0.1879883
        l0.1879272,0.1879272l0.1879883,0.1879272v0.1880493h-0.1879883v0.1879272h0.1879883l0.1879272-0.1879272h0.7518921v-0.1880493
        h0.1879272l0.1879272-0.1879272h0.1880493v0.1879272L671.6807861,555.6820679z M684.0863037,531.8109131h0.3759155v0.1879272
        v0.1880493v0.1879272h-0.3759155v0.1879272h-0.1879272v0.1879883l-0.1879883,0.1879272l-0.1879883,0.1879883h-0.1879272
        l-0.1879883,0.1879883h-0.1879272v0.1879272h-0.1879883l-0.1879272,0.1879883h-0.3759766l-0.1879272-0.1879883h-0.7518311
        l-0.1879883-0.1879272v-0.1879883v-0.1879883v-0.1879272h0.3759155v-0.1879883l0.1879883-0.1879272v-0.1879272v-0.1880493
        v-0.1879272l0.1879272-0.1879883v-0.1879272v-0.1879272h0.1879883v-0.1879883h0.1879272v-0.1879272h0.1879883l0.1879883-0.1880493
        v-0.1879272h0.1879272v0.1879272v0.1880493l0.1879883-0.1880493h0.1879272v0.1880493h0.3759155l0.1879883,0.1879272
        l0.1879883,0.1879883v0.1879272v0.1879272h0.1879272V531.8109131z M678.4474487,532.9386597v0.1880493l-0.1879883-0.1880493
        H678.4474487z M679.9511108,533.126709h0.5639038v0.1879272v0.1879272v0.1879883h-0.1879272v0.1879272h-0.3759766
        l-0.1879272,0.1879883h-0.5639038v-0.1879883h-0.7518311v0.1879883h-0.1879883v-0.1879883l-0.1879272-0.1879272v-0.1879883
        h-0.1879883v-0.3758545h0.1879883h0.1879272v0.1879272h0.1879883v-0.1879272l0.1879272,0.1879272h0.1879883v-0.1879272v-0.1880493
        v-0.1879272v-0.1879883v-0.1879272v-0.1879272h0.3759155v0.1879272h0.1879883l0.1879272,0.1879272h0.3759155v0.1879883v0.1879272
        V533.126709z M677.8835449,532.9386597v0.1880493h-0.1879272v-0.1880493H677.8835449z M663.5984497,520.5332031
        l-0.1879883-0.1879272v-0.1879883h0.1879883h0.1879883v0.5639038h-0.1879883V520.5332031z M666.605835,522.7886963
        l-0.1879272,0.1879883v-0.1879883H666.605835z M666.7937622,524.1044312v0.1879883h-0.1879272v-0.1879883H666.7937622z
        M667.357666,524.668335v0.1879883l-0.1879883-0.1879883h-0.1879272v-0.1879272l-0.1879883-0.1879883h0.1879883
        L667.357666,524.668335z M666.605835,523.9165039v0.1879272h-0.1879272l-0.1880493-0.1879272h-0.3759155v-0.1879883h-0.3758545
        v-0.1879272v-0.1879883h-0.3759766v-0.1879272v-0.1879883l-0.1879883-0.1879883h-0.3758545v0.1879883h0.1879272v0.1879883
        h-0.3759155l-0.1879272-0.1879883h-0.3759766v-0.1879883h-0.1879883v-0.1879272l-0.1879272-0.1879883h0.1879272v-0.3759155
        l0.1879883-0.1879883v-0.1879272h0.1879883v0.1879272h0.1879883l0.1879272-0.1879272h-0.3759155v-0.1879883v-0.1879272h-0.1879883
        v-0.1879883v-0.1879272l0.1879883-0.1879272h0.5639038l0.1879272-0.1880493h0.3759155l0.1879883,0.1880493v0.1879272v0.3759155
        h-0.1879883v0.1879272h-0.1879883v0.1879883l0.1879883,0.1879272v0.1879883h-0.1879883l0.1879883,0.1879883h0.1879883v-0.1879883
        h0.1879883l0.1879272-0.1879883v-0.1879272h0.1879272v0.1879272l0.1879883,0.1879883v0.1879883l0.1879272,0.1879272v0.1879883
        v0.1879272v0.1879883l-0.1879272,0.1879883h0.1879272v0.1879272v0.1879883h0.3759766v-0.1879883v-0.1879272l0.1879272,0.1879272
        l0.1879883,0.1879883h-0.1879883v0.1879272v0.1879883H666.605835z M667.9215698,531.6229248v-0.1879272h-0.1879883v-0.1879883
        v-0.1879883v-0.1879272h0.1879883v-0.1879883v-0.1879272l-0.1879883,0.1879272h-0.1879272v-0.1879272v-0.1879272v-0.1880493
        v-0.1879272h-0.1879883v-0.1879883h-0.1879883l-0.1879272,0.1879883v-0.1879883v-0.3758545l0.1879272-0.1879883v-0.1879272
        l-0.1879272-0.7518921h0.1879272l0.1879883,0.5638428v0.1880493l0.1879883-0.1880493h0.1879272v-0.1879272h0.3759155v-0.1879272
        l0.1879883-0.9398193v-0.9398193l-0.1879883-0.1879883l-0.1879272-0.3759155l-0.3759155-0.7518311v-0.1879883h-0.1879883
        v-0.1879272h0.1879883v-0.1879883h0.1879272v-0.1879272h0.9398193l0.1879883,0.1879272v-0.1879272v-0.1879883h0.3759155v0.1879883
        v0.1879272v0.3759155v0.1879883v0.1879272v0.1879883l-0.1879883,0.1879883v0.1879272v0.3759155l0.1879883,0.1879883h0.7518311
        v0.1879883h0.1879883v0.1879272h-0.1879883v0.1879883h0.1879883v0.1879272h0.1879272v0.1879883v0.1879272h0.5639038v0.1879883
        v0.1879883l0.1879883,0.1879272v0.3759155v0.1879272v0.1880493h0.3759155v0.1879272v0.1879883l-0.1879883,0.1879272h-0.1879272
        v0.1879272h-0.1879883v0.1879883h-0.1879883l-0.1879272,0.1879272v0.1880493h-0.1879883v0.1879272h-0.1879272v0.1879272h-0.1879883
        v-0.1879272h-0.1879883v0.1879272l0.1879883,0.1879883l-0.1879883,0.1879272v0.1879883h-0.1879272v0.1879883v0.3759155h-0.1879883
        v0.1879272h-0.5638428v-0.1879272h-0.1879883v-0.1879883H667.9215698z M667.357666,530.1192017h0.1879883l-0.1879883,0.1880493
        V530.1192017z M656.0799561,532.9386597h-0.3759155v-0.1879272v-0.1879883v-0.1879272h0.1879883v-0.1879272l0.1879272-0.1879883
        h0.1879272l0.1879883-0.1879272h0.1879883h0.3759155l-0.1879272,0.1879272v0.1879883l-0.1879883,0.1879272h-0.1879883v0.1879272
        h-0.1879883v0.1879883v0.1879272v0.1880493h-0.1879272V532.9386597z M692.3566284,541.9608765h0.5638428v0.1879272h-0.1879272
        v0.1879883h-0.1879272l-0.1879883-0.1879883V541.9608765z M699.123291,542.7127075h-0.3759766v-0.1879272h0.1879883v-0.1879883
        l0.1879883,0.1879883V542.7127075z M698.9353027,543.2766113h-0.1879883v-0.1879883h0.1879883V543.2766113z
        M701.0028687,544.5923462h0.1879883v0.1879272v0.1879883h-0.1879883v0.1879883h-0.1879272v0.1879272h-0.1879883V545.15625
        v-0.1879883h-0.1879272v0.1879883h-0.1879883v0.1879272h-0.1879883V545.15625h-0.1879272v-0.1879883h-0.1879883v-0.1879883
        h-0.3759155v-0.1879272v-0.1879883h-0.1879272v-0.5638428h0.1879272v-0.1879883h-0.1879272v-0.1879883l0.1879272-0.1879272
        v0.1879272h0.3759155v-0.1879272h0.3759155v0.1879272v0.1879883l0.1879883,0.1879883h-0.1879883v0.1879272h0.1879883v0.1879883
        h0.1879883v0.1879272h0.5638428V544.5923462z M704.3861694,546.2839966h0.3759155v0.1879883v0.1879272l-0.1879272,0.1879883
        l-0.1879883,0.1879883v0.5638428h-0.1879883v0.3759155l-0.1879272,0.1879883v0.1879883h-0.5639038v-0.1879883v-0.1879883
        h-0.3758545l0.1879272-0.1879272v-0.1879883v-0.3759155h0.3759155v-0.1879272l-0.1879883-0.1879883h0.1879883v-0.1879883
        v-0.1879272h-0.1879883v0.1879272h-0.7518311v-0.1879272h-0.1879883v-0.1879883l0.1879883-0.1879272v-0.1879883h0.1879272
        v-0.1879272h0.1880493v0.1879272h0.9397583v-0.1879272h0.1879272v0.1879272l0.1879883,0.1879883V546.2839966z
        M706.2658081,545.5321655h-0.1879883v-0.1879883l0.1879883-0.1879272h0.1879883l0.1879272,0.1879272v0.1879883h-0.1879272
        v0.1879272h-0.1879883V545.5321655z M707.0176392,547.975647l-0.1879272-0.1879272l0.1879272-0.1879883h0.3759155
        l0.1879883-0.1879272v-0.1879883h0.1879883v-0.1879272l-0.1879883-0.1879883l0.1879883-0.1879883l0.1879272,0.1879883h0.7518311
        l0.1879883,0.1879883v0.3759155h-0.1879883l-0.1879272,0.1879272h-0.1879883l-0.1879272,0.1879883h-0.5639038v0.1879272h0.1879883
        v0.1879883h-0.5639038L707.0176392,547.975647z M705.7019043,550.4191284h-0.3759155v-0.1879272v-0.1879883v-0.1879883h0.1879883
        v-0.1879272h0.1879272l0.1879883-0.1879883v-0.1879272l0.1879272-0.1879272v-0.1880493l0.1879883-0.1879272v-0.1879883v-0.1879272
        v-0.1879272v-0.1879883h0.1879272v-0.1879272h0.1879883v0.1879272h0.1879883v0.1879883v0.1879272l0.1879272,0.1879272
        l0.1879883,0.1879883v0.1879272h0.1879272v0.1880493v0.1879272h0.5639038v0.1879272h-0.3759155v0.1879883h-0.1879883v0.1879272
        h-0.1879272l-0.1879883,0.1879883h-0.1879272l-0.1879883,0.1879883h-0.7518311L705.7019043,550.4191284z M709.2731934,535.0062866
        v0.1879883h-0.1879272v-0.1879883H709.2731934z M710.7769165,537.4498291l-0.1879883-0.1879883h-0.1879272l-0.1879883-0.1879883
        h-0.1879272l-0.1879883-0.1879272v-0.1879883h-0.1879272v-0.1879272h-0.1879883v-0.1879883l-0.1879883-0.1879883
        l0.1879883-0.1879272v-0.1879883h-0.1879883l0.1879883-0.1879272l-0.1879883-0.1879883v-0.1879272h0.1879883v-0.1879883h0.1879883
        l0.1879272,0.1879883v-0.1879883v-0.1879883l0.1879883,0.1879883v0.1879883h0.1879272l0.1879883-0.1879883v-0.1879883v-0.1879272
        h0.5639038v-0.1879883l0.1879272-0.1879272v-0.1879272h0.1879883l0.1879272-0.1879883h0.3759766l0.1879272,0.1879883h0.1879883
        l0.1879272-0.1879883l0.1879272-0.1879883l0.1879883-0.1879883l0.1879272,0.1879883l0.1880493,0.1879883v0.3759155
        l0.1879272,0.1879272h-0.1879272l0.1879272,0.1879883h-0.1879272v0.1879272h0.1879272v0.3759766h-0.1879272v0.1879272h0.1879272
        l0.1879272-0.1879272h0.1879883l0.1879272-0.1879883h0.1879883v0.1879883h0.1879883v0.1879272l0.1879272,0.1879883v0.1879272
        h0.1879883v0.3759155h-0.1879883l-0.1879272,0.1879883v0.1879883l0.1879272,0.1879272h0.1879883l0.1879272,0.1879883v0.3759155
        v0.1879883h-0.1879272v0.1879272h-0.1879883v0.3759155l-0.1879272,0.1879883l-0.1879883,0.1879272h-0.3759155l-0.1879883-0.1879272
        l-0.1879272-0.1879883l-0.1879272-0.1879272v-0.1879883h-0.5639648v0.1879883h-0.3758545v-0.1879883h-0.1879883v-0.1879272
        H710.7769165z M738.0313721,555.8699951h0.3759766v0.1879883v0.3759155v0.3759155v0.1879883l-0.1879883,0.1879272h-0.1879883
        l-0.1879272,0.1879883h-0.1879883v0.1879272h-0.5638428l0.1879272,0.1879883v0.1879883h-0.1879272v0.1879272l0.1879272,0.1879883
        l0.1879883,0.1879272v0.1879883h-0.1879883l-0.1879272,0.1879883h-0.1879883v0.1879272h-1.1277466l-0.1879883,0.1879883h-0.1879883
        l-0.1879272,0.1879272v0.1879883v0.1879272h-0.3759155v0.1879883h-0.5639038v-0.1879883v-0.1879272h0.1879272v-0.1879883
        h-0.7518311l-0.1879272-0.1879272v-0.1879883h-0.3759766l-0.1879272,0.1879883h-0.1879883l-0.1879272-0.1879883v-0.1879272
        h-0.3759155v-0.1879883h-0.1879883l-0.1879883,0.1879883v0.1879272h-0.3759155v0.1879883h-0.3758545l-0.1880493-0.1879883
        h-1.5036621l-0.1879272,0.1879883h-0.1879883l-0.1879272-0.1879883h-0.1879883v-0.1879272v-0.1879883h0.5639038v0.1879883
        h0.3758545v-0.1879883l0.1879883-0.1879883l0.1879883-0.1879272l0.1879883-0.1879883h0.1879272v-0.1879272h0.3759155
        l0.1879272-0.1879883h0.7518921v0.1879883h0.5639038v0.1879272h0.1879272v-0.1879272h0.3759155l0.1879883-0.1879883v0.1879883
        h0.1879272l0.1879883-0.1879883h0.1879883l0.1879272-0.1879883h0.1879883l0.1879272-0.1879272l0.1879883-0.1879883v-0.1879272
        h-0.1879883v-0.1879883h-0.1879272v-0.3759155l0.1879272-0.1879883v-0.1879272v-0.3759155h0.3759155v0.1879272h0.1879883
        l0.1879883,0.1879883v0.1879272l0.1879272,0.1879883v0.1879883h0.5639038l0.1879883,0.1879272v0.1879883h0.1879272v-0.1879883
        h0.1879883l0.1879272-0.1879272l0.1879883-0.1879883v-0.1879883l0.1879272-0.1879272h0.7518921v-0.1879883h0.1879272v-0.1879272
        h0.1879883L738.0313721,555.8699951z M747.2415771,553.0505981h-0.1879883v-0.1879272h0.3759155l0.1879883,0.1879272v0.1879883
        h-0.1879883L747.2415771,553.0505981z M691.0408936,547.7877197h0.3759155v0.3759155h-0.1879272l-0.1879883,0.1879883h-0.5639038
        v-0.1879883h-0.5638428v0.1879883h-0.5639038v0.1879272l-0.1879883,0.1879883h-0.3759155v0.1879272h-0.1879272
        l-0.1879883,0.1879883h-0.1879883l-0.1879272-0.1879883H687.84552l-0.1879272-0.1879272v-1.3157349l0.1879272-0.1879883v-0.1879272
        h0.3759155v-0.1879883v-0.1879883l0.1879883-0.1879272v-0.1879883h-0.1879883l0.1879883-0.1879272v-0.1879883h0.1879883v-0.1879272
        l0.1879272,0.3759155v0.1879272h0.1879883v0.1879883l0.1879272,0.1879272h1.1278076l0.1879272-0.1879272h0.1879883v0.1879272
        v0.5639038l0.1879272,0.1879883v0.1879272h0.1879883V547.7877197z M718.8592529,575.2301636v0.1879272h0.1879883v0.1879272
        v0.1879883h-0.1879883v0.1879272h-0.5639038v0.1880493h-0.1879272v0.1879272h-0.1879883v0.1879272l-0.1879272,0.1879883h-0.1879883
        l-0.1879272,0.1879272l-0.1879883-0.1879272v0.1879272h-0.1879883v0.1879883h-0.1879272l-0.1879883,0.1879272h-0.3759155v0.1879883
        h-0.5639038l-0.1879272-0.1879883h-0.5639038l-0.1879883-0.1879272h-0.1879272l-0.1879883-0.1879883h-0.1879272v0.1879883
        h-0.1879883v0.1879272h-0.9398193v0.1879883v0.1879883h-0.1879272v0.5638428l0.1879272,0.1879883l0.1879883,0.1879883v0.1879272
        h0.1879272v0.1879883h0.1879883v0.3759155l-0.1879883-0.1879883h-0.3759155v-0.1879272l-0.1879272-0.1879883h-0.3759766v0.1879883
        h-0.1879272l-0.1879883,0.1879272v0.1879883h-0.1879272v-0.1879883l-0.1879883,0.1879883h-0.3759155v-0.1879883h-0.3759155
        v-0.1879272l-0.1879883-0.1879883v-0.1879272v-0.3759766l0.1879883-0.1879272v-0.1879883h0.1879272v-0.1879272h0.1879883
        l0.1879883-0.1879883v-0.1879883l0.1879272-0.1879272v-0.1879883h0.3759155l0.1879883-0.1879272l0.1879272-0.1879883h0.3759766
        l0.1879272-0.1879272h0.1879883l0.1879272-0.1879272h0.3759766h0.5638428v-0.1880493h0.1879883l0.1879272-0.1879272h0.5639038
        l0.1879883-0.1879883l0.1879272-0.1879272v-0.7518921v-0.3758545h0.1879883v-0.1879883v-0.1879272v-0.1880493h0.3759155v-0.1879272
        l0.1879883-0.1879272h0.1879272v0.1879272v0.1879272v0.1880493h0.1879883v0.1879272v0.1879883l0.1879272,0.1879272v0.1879272
        h-0.1879272v0.1879883l-0.1879883,0.1879883h-0.1879272l-0.1879883,0.1879883h-0.1879883v0.1879272h-0.1879272v0.3759155
        l0.1879272,0.1879272h0.7518921v0.1880493h0.3759155l0.1879883,0.1879272v-0.1879272h0.3759155v-0.1880493h0.1879272
        l0.1879883-0.1879272h0.1879272l0.1879272-0.1879883v-0.1879272l0.1880493-0.1879272l0.1879272-0.1879883l0.1879883,0.1879883
        H718.8592529z M709.8370972,580.8690186l0.1879883,0.1879272v0.1879883h-0.1879883v0.1879272h-0.1879883v0.1879883
        l-0.1879272,0.1879272v-0.1879272l-0.1879883,0.1879272v0.1879883h-0.1879272l-0.1879883,0.1879883v0.1879272h-0.3759155v0.1879883
        h-0.3759155l-0.3759155,0.1879272h-0.3759155l-0.1879883,0.1879272h-0.1879883l-0.1879272,0.1879883v0.5639038v0.3759155
        h-0.1879883v0.3759766l-0.1879272,0.1879272h-0.1879883v0.5638428l-0.1879883,0.1879883l0.1879883,0.1879883h-0.3759155v0.1879272
        h-0.5639038v0.1879883h-0.1879272v-0.3759155l0.1879272-0.1879883l0.1879883-0.1879883h0.1879272v-0.1879272h0.1879883v-0.1879883
        v-0.1879272v-0.1879272l0.1879272-0.1880493v-0.3759155h-0.1879272l-0.1879883-0.1879272v-0.5639038h0.1879883v-0.3759155
        h0.1879272l0.1879883-0.1879272l0.1879883,0.1879272v-0.3759155h-0.1879883v-0.1879272h0.5639038v-0.1879883h0.1879272v-0.1879883
        h0.1879883l0.1879883,0.1879883h0.1879272v0.1879883l0.1879883-0.1879883l0.1879272-0.1879883v-0.1879272h-0.3759155
        l0.1879883-0.1879883h-0.1879883v-0.1879272h0.5639038v0.1879272v0.1879883l0.1879272-0.1879883l1.1278076-0.5638428H709.8370972z
        M698.5593872,588.951355l-0.1879883-0.1879883h0.1879883v-0.1879272h0.3759155h0.5639038l0.1879272,0.1879272v0.1879883
        h-0.1879272v0.3758545h-0.7518921v-0.1879272h-0.1879272V588.951355z M697.8074951,591.3948364v-0.1879883h-0.1879272v-0.1879883
        l-0.1879883-0.1879272v0.1879272h-0.1879272v0.1879883h-0.1879883v-0.1879883v-0.1879272l0.1879883-0.1879883v-0.1879272
        l-0.1879883-0.1879883v-0.1879272h0.3759155l0.1879883,0.1879272v0.1879883l0.1879272,0.1879272l0.1879883,0.3759155
        l0.1879272,0.1879883h0.1879883v0.1879883v0.1879272h-0.1879883l-0.1879272,0.1879883h-0.1879883l-0.1879272-0.1879883h0.1879272
        V591.3948364z M699.8751221,591.770813l0.1879272,0.1879272v0.1879272h-0.9397583v-0.1879272h-0.1879883v-0.1879272v-0.1879883
        h0.1879883h0.1879272l0.1879883-0.1879883l0.1879272,0.1879883L699.8751221,591.770813z M698.7473145,593.2744751h0.1879883
        v0.1879272h-0.1879883v0.1879883h-0.1879272v0.5638428l-0.1879883,0.1879883h-0.1879272v-0.3759155h0.1879272v-0.1879883
        h-0.5639038v-0.3759155h0.3759766v-0.1879272h-0.1879883v-0.1879883h0.7518311V593.2744751z M696.303833,591.2068481
        l0.1879272,0.1880493v0.1879272v0.3759155h-0.1879272l-0.1879883,0.1879272h-0.3759155v-0.1879272v-0.3759155l0.1879883-0.1879272
        l0.1879272-0.1880493H696.303833z M703.8223267,591.2068481h0.1879883v0.1879883h-0.3759155v-0.1879883v-0.1879883h0.1879272
        V591.2068481z M706.4537964,587.0717163h0.1879272l-0.1879272,0.1879883l-0.1879883,0.1879272h-0.1879883v-0.1879272v-0.1879883
        h0.1879883l0.1879883-0.1879883V587.0717163z M701.3788452,582.5606689h0.3759155v0.3758545h-0.1879883v-0.1879272h-0.1879272
        V582.5606689z M626.5699463,490.459259l-0.1879883-0.1879578v-0.1879883h0.1879883l0.1879272,0.1879883h0.1879272v0.1879578
        H626.5699463z M625.4421387,489.5195007v0.1879578h-0.1879272l-0.1879883-0.1879578H625.4421387z M625.4421387,489.8954163
        v-0.1879578h0.1879883l0.1879272,0.1879578H625.4421387z"
            filter="url(#dropshadow)" // Apply the drop shadow filter here
          />
        </a>
        <text x="555" y="540" fontSize={16} color="000000" className="centered-text">珠海</text>

        <a xlinkHref="../about/guangzhou.html" id="dmap_guangzhou" className="focus">
          <title>廣州</title>
          <path id="Guangzhou_3_" className="gba9"
            d="M634.2763672,437.4539795v0.1879578l1.5036621,2.0675964l-4.5111084,3.5712891
        l-1.3157349-2.0675659l-0.1879272-0.1879883l-1.3157349-1.8796082l-0.1879883-0.375946l-1.1277466-1.8795776l-1.8796387-1.3157349
        l-0.1879883-0.1879578l-1.5036621-1.3157349l-0.1879272-0.1879578l-1.1278076-1.8796387h-0.5639038v-0.1879578
        l-0.1879272-0.1879578l-0.3759155-0.1879578l-0.1879883-0.375946v-0.1879578h0.1879883v-0.3759155l-0.1879883,0.3759155h-0.1879883
        l-0.1879272-0.1879578v-0.375885v-0.1879578h-0.1879883v-0.3759766h-0.1879272v-0.1879578v-0.1879272l0.1879272-0.1879578
        v-0.1879578h-0.1879272v0.1879578v0.1879578h-0.1879883v-0.1879578l-0.3759155-0.5639343l-0.1879883-0.1879272v-0.3759155
        l-0.1879272,0.1879578h-0.3759155v-0.1879578h0.1879272v-0.1879578l-0.1879272-0.1880188l-3.1953735-2.4434814
        l-0.7518921-0.7518311l-0.9397583-0.7518616l-1.5037231-1.5036926l-0.9397583-0.7518616l-2.067627-1.5036621l-2.0675659-1.5036926
        v0.1879578h-0.1879883v-0.1879578h-0.1879272l-0.1879272-0.1880188h-0.3759766v-0.1879272l-1.3157349-0.9397888
        l-2.0675659-1.3157654l-0.1879883-0.1879578l-1.3157349-0.9398193l-0.3759155-0.3759155h-0.3759155l-0.1879883-0.1879578
        l-0.1879272-0.1879578h-0.3759155l-0.1879883-0.1879578l-0.1879883-0.1879883v-0.5638733h-0.1879272l-0.1879883-0.1879578
        l-0.5638428-0.5639038l-0.1879883-0.1879578l-1.3157349-0.7518005l-0.9397583-0.5639343h-0.3759766l-0.1879272-0.1879272
        l-0.1879883-0.1879578l-0.1879272-0.1879578l-0.1879883-0.1880188v-0.1879578h0.1879883v-0.1879272l0.1879272-0.1879578
        l2.067627,0.7518616h0.3758545l0.9398193,0.1879578h0.3759155l1.5037231-0.5639343l1.6916504-1.1277466l1.3157349-0.7518311
        l1.5036621-0.5639038l2.819458-0.5638733l0.7518311-0.5639038h1.8796387l2.2555542,0.375946h0.3759155l0.3759155,0.1879578
        l0.3759766,0.1879578l0.1879272,0.1879578l0.1879883,0.1879578l0.3758545,0.1879578l0.3759766,0.375946l0.1879883,0.1879578
        l0.3758545,0.3759155h0.1879883l0.1879883,0.1879578v0.1879883l1.8795776,1.6916504l-0.1879272,0.375885l1.5036621,1.5037537
        l0.7518921,2.0675354v0.1879578l1.1277466,1.3157654l0.1879883,0.5638733l1.6915894,1.1277771l1.3157959,0.3759155
        l0.9397583,0.7518616l1.1278076,1.3157043l6.7666626,7.7064819l0.5639038,3.1953125l0.1879272,0.1879578v0.5639038
        l0.7518311,1.3157349l1.3157349,2.6314697L634.2763672,437.4539795z M596.4959717,408.8838196v0.1879578l0.1879883,0.1879578
        h0.3758545l0.7518921,0.375946h0.7518921h1.1276855l0.5639038-0.375946l0.5639038-0.1879578l1.3157349-0.7518616
        l0.7518311-0.5638733l0.5639038-0.3759155h0.1879883l1.6915894-0.7518616l3.9472656-0.7518616l3.9472046-0.1879578v-0.3759155
        l-0.1879883-0.1879272v-0.1879578l-0.3759155-0.1880188l-0.1879883-0.1879578l-0.3759155-0.1879578l-0.3759155-0.1879272
        l-1.6916504-0.3759155l-0.1879883-0.1880188l-0.9398193-0.1879578l-0.1879272-0.1879272h-3.0073853l-1.3157959-0.1879578
        h-0.3758545l-0.1879883-0.1879578l-0.3759155,0.1879578h-0.5639038l-0.5638428,0.1879578h-0.9398193v0.1879272h-0.9398193
        l-0.1879883,0.1879578h-0.1879272l-0.1879883,0.1880188h-0.3759155l-0.3759155,0.1879578l-0.1879883,0.1879578
        l-0.1879272,0.1879272l-1.5037231,1.5036926l-0.5638428,0.375946l-0.7518921,0.1879578l-0.5638428,0.3759155l-0.1879883,0.1879578
        h-0.3759155l-0.1879883,0.1879578l-0.1879272,0.1879883v0.1879578h-0.1879883v0.3759155l0.1879883,0.1879578l1.3157349,0.7518616
        H596.4959717z M593.6765747,408.5078735h-0.1879883l-0.1879272-0.1879578h-0.3759766l-0.3759155-0.1879578h-0.3759155
        l-0.3759155-0.1879578h-0.3759155l-0.1879883,0.1879578h-0.3758545v0.1879578h0.1879272l0.1879272,0.1879578h0.3759155
        l0.1879883,0.1879578h0.7518311l0.3759155,0.1879883h1.1278076l0.1879272-0.1879883h-0.3759155L593.6765747,408.5078735z
        M620.9310303,391.9672241l0.1879883,0.1879272h0.3759155l0.1879883-0.1879272h0.3759155v-0.1879578h-0.3759155v-0.1879578
        v-0.1879578h-0.1879883v-0.1880188h-0.1879883v-0.1879272h-0.1879272v-0.1879578h-0.1879883v-0.1879578v-0.1879578h-0.1879272
        v-0.1879578l-0.1879883-0.1880188h-0.1879883l-0.3759155-0.1879272h-0.3759155l-0.1879272-0.1879578l-0.1879272-0.1879578
        l-0.1880493,0.1879578v0.1879578h-0.1879272v0.1879272v0.1880188h-0.3759155l0.1879272,0.1879578h0.1879883v0.1879578h0.1879272
        l0.1880493,0.1879578v0.1879578h0.1879272l0.1879272,0.1879272l0.1879883,0.1880188h0.3759155v0.1879578h0.1879272
        l0.1879883,0.1879578h0.1879883v0.1879578H620.9310303z M626.0059814,396.478302l-0.1879272-0.1879578h-0.3759155
        l-0.1879272,0.1879578v0.1880188v0.1879272h0.3759155l0.1879272,0.1879578l0.1879272,0.1879578v0.1879578h0.3759155v-0.1879578
        v-0.1879578l-0.1879272-0.1879578v-0.1879272L626.0059814,396.478302z M626.381958,397.9820251h0.3759155v-0.1879578h-0.3759155
        h-0.1879883v0.1879578h-0.3759155v0.1879578h0.5639038V397.9820251z M710.0249634,192.5395355l0.5639038-0.563858
        l1.1278076-1.3157349l1.6915894-1.5037231l1.1278076-1.1277771l0.3759766-1.1277771l-0.3759766-1.3157196l-1.1278076-1.3157349
        l-0.75177-0.9398193l-0.3759766-0.7518158l-0.5638428-1.6916809l-0.5639038-1.6916504l-0.3759155-1.5036774v-2.0675964
        l0.1879272-1.8796387l0.5639038-2.0675964l-0.1879272-1.1277161l-0.7518921-0.3759613l-0.3759155-0.3759003l-1.5037231-0.1879578
        l-1.5036621-0.1879578l-2.4434814-1.1277771h-4.3231812l-1.5036621,0.1879578l-2.2555542,0.3759155l-1.6916504,0.3759003
        l-3.7592773,1.3157806l-3.0073853,0.3759003l-0.9398193,0.1879578l-2.2554932,0.3759613l-1.3157349-0.7518616l-0.5639038-0.9398193
        l-0.9398193-1.1277771l-1.3157349-0.3759155l-1.6916504,0.3759155l-1.8795776,0.1879578l-2.4436035,0.5639038l-1.6915894,0.9398193
        l-1.5037231,0.7518158l-1.5037231,0.9398193l-1.8795776,0.9398193l-1.5036621,0.563858l-1.3157959,0.1879578l-1.3157349-0.1879578
        l-0.5638428,0.3759613l-1.3157349,0.3759155l-2.4435425,0.563858l-0.7518311,1.5037384l-0.1879883,2.6314545l-0.5639038,1.5036774
        l-0.5638428,1.1277771l-1.6917114,1.1277771l-0.9397583,0.3759003l-1.6916504,0.3759613h-2.6314697l-2.4434814-0.5639191
        l-2.067627,0.7518768l-0.3759155,2.6314545v1.6916809l-1.5036621,1.8795929l-0.7518921,1.1277771l-1.3157349,0.9398193
        l-2.4434814,0.3759003h-0.7518921l-1.3156738-0.3759003l-0.9398193-0.3759155l-2.2555542-0.375946l-2.6314697,0.375946
        l-0.9398193,1.3157349l-1.8795776,3.7592316l-0.5639648,2.0675964l0.3759155,3.0073547l0.9398193,2.0675964l0.3759766,2.443512
        l-0.3759766,2.819458v1.1277161l0.1879883,1.1277771v2.6315002l-1.3157349,0.9397736l-1.8795776,2.0675964l-1.5037231,2.4434967
        l-1.1278076,1.1277771l-0.9397583,0.7518768l-2.6315308-0.1879578l-1.3157349-1.1277771l-1.5036621-2.4434967l-1.1277466-1.1277771
        l-1.8796387-1.3157349h-3.0073853l-1.5036621,0.9397736l-2.4435425,1.5037384h-2.4435425l-2.819397,0.1879425h-3.5712891
        l-1.6916504-2.0675812l-1.3157349-1.1277771l-1.6916504-0.5638733l-0.7518921-0.5639038l-1.8795776-2.2555542l-2.2555542-1.1277618
        l-2.4434814-0.3759155l-3.9472656-0.1879578l-2.4434814-0.1879578h-1.6916504l-1.3157349,0.3759155l-0.7518311,0.1879578
        l-0.3759155,0.7518616l-0.5639038,0.7518616l-0.3759155,1.5036926l-0.1879883,1.6916351v1.8796387l-0.3759155,1.1277771
        l-1.3157349,1.6916351l-1.3157349,2.0675964l-0.9397583,1.5036774l-0.7518921,1.1277771l-0.5639038,0.7518616l-0.3759155,0.563858
        l-0.7518311,0.9398193l-1.1278076,0.3759155l-1.3157349-0.3759155l-1.6916504-0.3759003h-1.6916504l-0.7518311,0.563858
        l-0.3759155,1.3157806l0.5639038,2.2554932l-0.3759155,1.5037384l-0.3759766,0.7518158l-0.9397583,0.7518616l-0.5639038,0.1879578
        l-0.7518921-0.563858l-1.5036621-0.1879578l-2.4435425,0.7518158l-0.5638428,0.1879578l-0.7518921,0.7518616l-2.0675659,1.5036774
        l-1.6916504,0.7518616l-1.1277466,0.5638733l-2.4435425,0.7518616l-1.6916504,0.5639191l-1.5036621,0.7518158l-0.9398193,0.7518616
        l-0.7518311,1.3157196l0.1879272,1.5036926l0.3759155,0.5639038l0.7518921,0.9398193l0.75177,1.1277313l0.1879883,1.3157349
        l-0.1879883,0.375946l-0.3758545,0.5638733l-0.9398193,1.5036774l-0.9398804,1.5037231l-0.5637817,1.3157349l-0.5639038,2.0675964
        l-0.9397583,0.7518158l-0.9398193,0.1879578l-1.1278076-0.3759155l-2.819397-0.7518616l-1.5037231-0.563858l-1.6916504-0.1879578
        l-1.5036621,0.1879578l-1.6917114,0.563858l-3.5712891,0.9398193l-2.0675659,0.5639038l-1.3157349,0.3759155l-2.067627,0.5639038
        l-1.6915894,0.7518311l-1.5037231,1.1277771l-1.3157349,0.9398193l-1.3157349,0.5638428l-2.0675659,0.9398193l-1.8796387,0.1879578
        l-1.1277771,0.1880188h-1.5036621l-1.3157349,0.375885l-1.6916809,0.1879578l-1.8796082,0.1879578l-0.9398193,0.1879578h-1.3157349
        l-1.6916809,0.7518616l-1.3157349-0.1879578l0.1879578,0.7518311l-0.5638428,1.5037231l-0.7518616,1.3157349l0.375946,0.9398193
        l1.1277771,0.3759155h1.3157349l0.9397583,0.7518616l0.5639038,0.9397583l0.5638733,1.6916809l0.5639038,1.8796082
        l0.5638733,1.3157654l0.1879883,1.3157349l-0.375946,1.3157349l-0.7518311,1.1277771l-0.7518616,1.6916199l-0.1879578,1.3157349
        l0.1879578,1.6916809l0.5638733,0.5638733l0.5639038,0.375946l1.3157349,0.7518311l1.6916504,0.3759155l0.5639038,0.1879883
        l0.7518616,0.3759155l1.3157349,0.5638428l1.5036621-0.375885l0.9398193-0.3759155l0.9398193-0.1879578l0.5638733,0.7518311
        l-0.5638733,1.3157043l-1.1277771,1.1277771v0.5639343l1.1277771,1.6916199l0.5638733,1.1277771l0.7518616,1.6916809v1.5036926
        l-0.1879578,1.1277771v1.1277771l-0.1879883,0.9398193v1.1277771l0.5639038,1.8795776l0.1879578,1.1277771l-0.3759155,1.3157349
        l-0.1879578,1.1277771l0.5638733,1.1277466l2.2555542-0.1879272l2.4434814,0.375885h1.5037231l1.1277466-0.7518005
        l0.1879272-1.3157349l-0.1879272-1.1277771l-0.1879272-0.375946l-0.7518921-2.2555542l0.5639038-1.8795776l1.1278076-0.9398499
        l1.3156738-0.5638428l1.1278076,0.1879578h0.9398193l1.1277466-0.9398193l0.1879883-0.5639343l0.9398193-0.7518005
        l0.9397583-0.5639038l1.3157959-0.5638733h1.1277466l1.3157349,0.1879578l0.7518311,0.3759155l1.1277466,0.375946
        l0.3759766,0.9397583l0.3759155,0.9398193l1.1277466,1.1277771l0.7518921,0.5639343l0.7518311,0.1879578l0.7518311,0.375885
        l2.067627,1.1277771v1.1277771l0.5638428,1.5036926l0.5639038,0.5639038l0.1879883,0.9397583l0.1879272,0.9398193
        l0.1879272,0.9398193l-0.1879272,0.7518311l-0.1879272,0.7518616l-0.3759766,0.9398193v0.5638733l0.1879883,0.9398193
        l0.3759155,0.9398193l0.5638428,0.7518616l0.3759766,0.5638733l0.3759155,0.9398193l0.5638428,0.7518005l0.5639038,0.5639038
        l0.7518921,0.5638733l0.5638428,0.5639038l0.3759155,0.3759155l0.5639038,0.5639038l0.1879883,0.7518311v2.443512
        l-0.1879883,0.5639038l-0.3759155,0.1879578h-0.1879883l-1.5036621,1.5036621l-1.1278076,1.1277771l-0.7518311,0.5639343
        l-0.5639038,1.3157043v1.5036926l1.1278076,0.9398193h2.2555542l1.6916504,0.1879578h0.1879272v0.9398193l-0.1879272,1.5036926
        l-0.1879883,0.7518616v0.5638428l-0.1879272,0.3759766h-1.3157349v0.375885l0.3759155,1.3157349v0.9398193l1.6916504,1.8796082
        l0.3759155,0.7518616l-0.3759155,1.1277771l-0.3759155,0.5639038l-1.1278076,0.1879578l-1.1277466-0.1879578l-0.9397583,0.1879578
        l-3.1953735,0.9398193l-0.1879883,0.3759155l0.3759155,1.3157043l2.067627,2.8194275l0.75177,0.7518616l3.1953735,1.3157349
        l1.6917114,0.9398193l0.9397583,0.5638733h0.3759766l0.3759155,0.1879883h3.3833008l0.5638428-0.1879883l0.7518921-0.1879578
        l0.7518311-0.1879578l0.1879883,2.6314392l0.7518311,0.5639343v0.7518005l-0.1879883,0.5639038l-0.5638428,0.7518616
        l-0.5639038,0.5638733l-0.3759155,1.1277771l-0.1879883,1.3157349l0.3759155,1.1277771l0.3759155,0.5639038l0.3759766,0.7518311
        l0.5638428,0.9398193l0.3759766-0.1879578l0.3759155,1.3157043l0.5638428,1.3157349l1.1277466,0.9398193l1.1278076,0.9398193
        l1.8796387,0.7518311l1.5036621,0.5639038l1.1278076,0.7518311l0.7518311,2.0675659l-0.3759155,1.1277771l-1.1277466,0.9398193
        l-1.1277466,0.3759155l-1.1278076,0.5639038l-0.3759766,0.7518311l-0.1879272,0.7518616v1.5036926l-0.1879272,1.6916809v1.5036926
        l0.3758545,0.9398193l0.9398193,0.7518005l1.1278076,0.9398193l0.7518311,0.3759155l0.3759155,0.375946l0.7518921,0.3759155
        l0.3759155,0.5638733l0.5638428,0.9398193l0.1879883,0.7518616l0.1879883,0.9398193l0.1879272,0.9398193l0.1879883,1.1277161
        l0.1879272,1.3157349l0.1879883,1.1277771l0.3759155,1.1277771l1.1278076,1.1277771l0.9397583,0.5638428l0.9398193,0.5639343
        l0.9398193,0.1879578h0.7518311l0.3759155,0.1879272l1.1278076-0.1879272l0.9398193-0.3759155l0.9398193,0.1879578
        l1.1277466-0.1879578l1.5037231-0.7518616l1.1277466-0.5638733l1.8795776,0.1879578l1.6917114,0.7518005l0.3759155,1.6917114
        l-0.5639038,2.819397l-1.5037231,1.1277771l-1.5036621-0.1879578l-1.5036621-0.5638733l-1.1278076,0.3759155h-0.3759155
        l-0.1879883,0.1879578l-0.1879272,0.1879578v1.3157349l0.3759155,0.7518616l0.3759155,1.3157349l0.3759155,0.7518616v0.1879578
        l0.1879272,0.1879272l0.1880493,0.1879578h0.1879272l1.3157349,0.9398193l0.9398193,0.7518921l0.7518311,0.5638428
        l1.1277466,0.7518616l1.3157349,0.7518311l0.7518921,0.5639038l1.3157349,0.5638733l2.0675659,1.3157654l1.6916504,0.7518311
        l0.9398193,0.375885l1.1277466,0.5639343h0.3759155v-1.3157349l2.2555542,0.1879578h1.3157349l0.3759766-0.3759155
        l1.5036621-0.9398193l1.3157349-0.7518616l1.6917114-1.6916504l1.3156738-0.7518616l0.9398193-0.1879578h9.210144v-0.1879578
        l-3.1953125-1.6916809l2.2554932-0.1879578l0.5639038-0.1879578l3.0073853,2.443512l1.6916504,1.5036926l1.5036621,1.5037231
        l1.5037842,1.1277771l1.5036621,0.3759155v0.1879578h2.2555542v-0.3759155h2.6314697l2.2554932-0.1879578l0.5639038-0.5639038
        l0.5639038-0.3759155l0.1879272-0.1879578l0.9398193-0.7518616l-0.1879272-0.9398193v-0.1879578l-0.1879883-0.3759155
        l-0.7518311-1.1277771l-0.1879883-0.5639038l-1.8796387-1.6916199l-1.1277466-0.3759155v-0.1880188l-0.1879272-0.1879578
        l-0.3759155-0.375885l-0.3759766-0.7518616l-0.3759155-0.7518311l-0.1879272-0.375946l-0.5639038-1.1277771h0.1879883
        l-0.1879883-0.3759155l-0.1879883-0.1879578h-0.5638428v-0.1879578h0.5638428l-0.1879272-0.375946l-0.1879883-0.7518311
        l-0.1879272-0.5639038l-5.0750122-2.819397l0.1879883-1.1277771v-0.7518616l0.3759155-0.5638733l0.1879272-0.7518616
        l-0.9398193-1.1277771l-0.5638428-0.7518311v-0.375946h0.3759155l-0.5638428-1.3157349l-0.7518921-0.7518005l-1.3157349-1.1277771
        v0.1879578l-0.5639038-0.3759766h-0.3759155l-0.1879272-0.1879578l-1.1278076-0.375885l-3.7592163-3.7592468l-1.3157349-2.2555542
        l-1.1277466-3.0074158l-0.5639038-1.6916199l-0.1879883-4.3231506v-1.5036926l0.1879883-0.375885l0.5639038-1.8796387
        l1.8795776-4.5111084l0.5639038-1.8795776v-1.1277771l-0.1879272-0.7518616v-0.1879578l-0.3759766-1.1277771v-0.3759155
        l-0.5638428-1.5037231v-0.1879578l-0.1879883-0.3759155l-0.7518311-2.6314392l-0.5639038-1.3157349l-0.7518311-0.9398193
        l-1.3157349-0.9398193l-0.7518921-0.5638733l-0.9398193-0.5638733l2.2555542-2.2555237l0.5639038,1.3157043l0.5639038,0.7518311
        l1.5036621,1.1277771l1.5037231,2.6315002l0.5638428,0.5638733l0.1879883-0.7518616l0.5639038-0.1879578l0.9398193-0.5639343
        l1.1277466-1.3157043l1.1278076-1.1277771l0.7518311-0.9397888l0.3759155-1.3157349l0.5639038-1.1277466l0.5639038-0.9398193
        l0.9397583-0.7518921l0.5639038-0.7518005l0.9398193-0.9398193l0.5638428-0.3759155l0.5639648-0.375946l0.9397583-0.1879578
        l1.1277466-0.1879578l1.3157349-0.375946l0.9398193-0.9398193l0.5639038-0.5638733l1.1278076-0.5639038l1.3157349-0.3759155
        l0.3758545,0.7518616l1.5037231,0.5638733h2.4434814l2.6315308-0.1879578h1.1277466l2.6314697-0.7518616l1.5036621-0.7518311
        h2.067627l2.0675659-0.375946h2.0675659l1.5037231-0.7518311l1.5036621-1.3157349l2.067627-1.1277466l1.5036011-0.9398193
        l1.5037231-0.5639343l0.1879272-0.375885l1.3157959,0.7518616l1.1277466,0.7518005l0.7518311,0.5639038l1.6917114,0.7518921
        h1.8795776l1.1277466-0.1880188l1.1278076-0.1879578l1.5036621,0.1879578l2.067627,0.375946h1.5036621l1.6917114-0.1879272
        l2.6314087-0.3759766l0.9398193-0.1879578l0.5639038-0.1879578h1.8796387l0.7518311-0.375885l0.7518311-0.5639343
        l1.1277466-1.3157043l0.3759766-0.9398193l0.9397583-0.9398193l0.7518921-0.5638733l1.5036621-0.7518616h0.3759766
        l2.6314697-3.5712891l0.1879272-0.9398193v-1.5036621l-1.6916504-1.6916504v-1.6916809l0.9398193-1.5036926l1.3157349-1.5036621
        l-0.3759155-2.6315308l-0.5639038-1.1277771l-0.1879272-0.9397583l-0.1879883-2.6315002l-1.3157349-0.7518311l-1.8796387-0.7518616
        l0.1879883-1.5036621l0.9398193-1.1277771l1.1277466-0.7518616l1.1278076-2.6314697l0.5638428-2.8194275l-0.1879272-1.6916809
        l-0.5639038-2.4434814v-2.8194275l1.5037231-1.3157349l3.3833008,0.3759155l2.4434814,0.9398193l2.4435425,0.9398193
        l2.819397,0.9397888l3.0073853,0.1879578l2.0675659-0.9397888l1.5037231-1.3157349l0.9398193-0.375946l0.5639038-0.5638733
        l-0.5639038-2.2555542l-0.9398193-1.5036621l-0.5639038-1.5037231l0.3759766-1.8796082l0.5638428-0.7518616l0.5639038-0.7518616
        l0.7518311-0.7518311l0.1879883-1.5036621l-0.3759155-1.3157959l-1.3157349-1.3157349l-0.7518921-1.3157043l0.1879883-0.7518311
        l0.9398193-1.1277771l-0.1879883-0.7518616l-1.3157349-0.1879578l-2.819397,0.3759155l-1.6917114-0.5638733l-0.7518311-1.8796387
        v-2.2554932l0.1879883-1.3157654v-0.5638733l0.9397583-0.7518616l0.7518921-0.1879578l1.6916504-0.1879578l0.9398193-0.9398193
        l-2.0675659-0.3759155h-1.8796387l-1.6917114,0.3759155l-1.3156738-0.1879578l-1.6916504-0.9398193l1.6916504-1.1277771v-0.1879578
        l-0.9398193-0.375885l-1.6916504-1.6916962l-1.8795776-0.7518158l-6.9546509-5.4509125l-0.9398193-1.1277771l-1.6916504-0.9398193
        l-0.9397583-0.7518158l-0.5639648-1.3157349l-0.3758545-1.1277771l-1.1278076-1.3157654l-0.9398193-1.5036926l0.3759155-1.1277618
        l0.3759766-1.5036926l-0.1880493-1.6916351l-0.1879272-2.6315002l-0.5638428-1.3157349l-1.8796387-0.7518158l-1.6916504-0.5639038
        h-0.3759766l-0.3759155-1.1277771l-0.9398193-1.8795929l-0.9397583-1.3157349v-2.819458l0.5638428-1.8795929l1.1278076-1.8796387
        l1.6916504-0.7518616l1.6916504,0.3759613l1.1278076,0.3759003l1.1277466-0.1879425l0.3759155-1.8796539l1.5036621-0.3759003
        l2.067627-0.5639191l2.4434814-0.563858l2.067627-1.3157196l1.1277466-1.5036926l0.5638428-1.5037231l0.3759766-1.8795929
        l0.1879883-1.1277771l1.8796387-1.5037384l2.4434814-0.1879425l5.0749512-0.1879578l2.6314697-0.9398193l1.5036621-1.6916351
        l1.5037231-1.6916809l1.6916504-0.3759155l0.9398193-0.1879578l1.1278076-0.1879578h0.9398193l0.5638428-0.1879425
        l1.3157349-0.3759613l1.8796387-0.3759155l1.3157349-0.3759003l1.3157349-0.1880035l0.7518311-0.3759155l0.7518311-1.1277618
        v-0.7518768l-0.5638428-0.7518158l-1.5037231-0.7518616l-0.9398193-0.1879578l-0.9398193-1.3157196l-0.1879272-0.9398193
        v-1.3157349L710.0249634,192.5395355z M638.9754028,422.4170532v-0.1879578h-0.1879883v0.1879578H638.9754028z
        M642.9226074,435.9502869l-0.5639038-1.8795776l-0.7518311-3.0074158v-0.3759155l-0.1879883-0.1879578h-0.1879272
        l-0.3759155,0.1879578h-0.7518921v-0.375946l1.5037231-0.1879578l-0.9398193-4.1351318l-0.9398193-2.2555542l-1.6915894,0.375946
        l-0.1880493-0.5639038l1.5037231-0.5638733l-0.1879883-0.1879272h-0.1879272l-0.1879883-0.1880188h-0.3759155l-0.1879272-0.1879578
        v-0.1879578h-0.1879272v-0.1879578h0.5638428l0.1879272,0.1879578v-0.1879578l-0.1879272-0.1879272l-0.9398193-2.0675964
        l-3.1953735-4.6990662l-1.1277466,0.9398193l-0.9398193-1.5036621l1.3157349-0.7518921l-0.5639038-0.9398193l-0.7518311,0.1879578
        l-0.5639038-0.7518005l-2.0675049-1.8796387l-3.5713501,2.4434814l-0.3759155-0.375885l-3.0073853-0.9398193l-1.8796387-0.1879578
        l0.5639038,2.0675964l0.9398193,1.5036621l0.5639038,0.3759155l1.6916504,1.3157349l0.1879272-0.1879578l3.5712891,2.0675964
        l3.0073853,3.1953735l3.0073853,3.1953125l1.1277466,1.3157349l-0.75177,0.1879883l0.75177,1.5036316l0.7518921-0.375885
        l0.3759155,1.1277466l0.9398193,3.5712891l0.1879272,0.7518616l0.3759766,0.7518311l0.1879272,0.7518616l0.3759155,0.7518616
        l0.1879883,0.1879578l0.3759155,0.5638428l1.5037231,2.0675964l0.7518311,0.9398193l0.9398193,1.1277771l0.5639038,0.9398193
        l0.1879272,0.1879578h0.3759155v-1.8796387l-0.3759155-1.5036621L642.9226074,435.9502869z M614.7282715,379.3737793v-0.5639038
        l0.1879272-1.1277771v-0.5638733v-1.1277466l-0.1879272-0.7518921l-0.5638428-1.3157043l-0.9398193-2.443512l-0.1879883-0.5639038
        l-0.3759155-0.7518311h0.1879272v-0.5639038l-0.1879272-0.3759155l-0.1879883-0.3759155l-0.3759155-0.375946l-1.1277466-0.5638733
        l0.1879272-0.375885l-1.1277466-1.8796387l-0.1879883-0.1879578l-0.1879272-0.9398193h0.1879272l-0.1879272-1.1277771h-0.1879883
        v-0.1879578v-0.1879578l-1.1277466-3.7592163l-0.3759766-0.3759766h-0.1879272l-1.3157349,2.2555542l-1.1278076,2.6314697
        l-0.5638428,1.3157043v0.3759766l-0.1879883,0.1879578v1.3157043l0.3759766,2.6314697l0.3758545,0.9398193l1.3157349,4.5111084
        l0.9398193,1.5036621l0.9398193,1.1277771l1.3157349,1.1277771h0.1879883l0.7518311,0.7518616l0.5639038,0.3759155
        l0.1879272,0.1879578l0.5639038,0.375946l0.7518311,0.3759155l-0.1879272,0.1879578v0.1879578h0.7518311v-0.1879578
        l0.7518921,0.3759155h0.75177l-0.1879272-1.6916504l-0.1879272-0.1879578v-0.1879578H614.7282715z"
            filter="url(#dropshadow)" // Apply the drop shadow filter here
          />
        </a>
        <text x="600" y="300" fontSize={16} color="000000" className="centered-text">廣州</text>

        <a xlinkHref="../about/zhaoqing.html" id="dmap_zhaoqing" className="focus">
          <title>肇慶</title>


          <path id="Zhaoqing_3_" className="gba10" d="M468.4938354,244.9810028 469.2456665,245.5448761 470.3734436,246.2967224 
        471.5012207,247.6124573 472.4410095,249.8679962 472.8169556,252.8753815 471.8771362,256.2586975 470.9373474,258.3262939 
        468.869751,260.3938599 466.6141968,262.0855408 464.9225464,263.4012451 463.4188538,265.6567993 462.1031189,268.288269 
        460.9753418,272.2354736 460.787384,273.3632507 460.2234802,274.8669434 460.2234802,278.6261902 461.3512573,280.3178406 
        469.0577087,285.204834 470.1854858,286.1446533 471.6891785,288.0242615 472.6289978,291.219635 472.0651245,293.2872009 
        471.3132629,294.6029358 470.7493591,295.7307129 470.3734436,298.3621826 470.9373474,299.8658752 470.9373474,301.1816406 
        470.1854858,302.6853027 469.4336243,303.8130798 468.3058472,304.3769836 467.3660583,305.5047302 468.3058472,306.2565918 
        469.0577087,307.5723267 468.6817932,309.0760193 467.9299622,310.2037964 467.1781006,312.2713623 465.4864197,314.151001 
        465.2984619,314.7149048 463.9827576,315.2787781 463.7947998,314.5269165 463.6068115,313.5871277 463.230896,311.707489 
        462.4790344,311.3315735 461.5392456,311.5195313 460.787384,312.2713623 459.6596069,313.3991394 458.7198181,313.3991394 
        458.3438721,312.6473083 458.7198181,311.3315735 458.1559143,310.5797424 457.592041,310.9556274 457.216095,311.1436157 
        456.8401794,311.8954468 456.0883179,313.5871277 455.7124329,315.2787781 456.0883179,317.346344 456.6522217,319.0379944 
        457.4040833,319.9778137 457.592041,320.3537292 458.7198181,321.4815063 459.2836914,322.4213257 459.8475952,323.3611145 
        460.035553,323.5491028 461.5392456,325.6166687 462.1031189,326.9324036 462.4790344,328.2481384 462.4790344,332.195343 
        462.6670227,332.9472046 462.8549805,333.3231201 462.8549805,334.0749817 463.230896,334.8268127 463.6068115,336.1425476 
        463.9827576,336.8943787 464.1707153,337.4582825 464.7345886,338.0221558 465.2984924,338.5860596 465.8623657,339.7138062 
        466.2383118,340.6536255 466.6141968,342.3453064 466.8021851,344.6008606 466.9901428,346.2924805 467.5540161,347.2322998 
        468.4938354,348.7360229 469.245697,350.4276428 469.4336548,352.3072815 468.4938354,352.8711853 467.7419739,353.0591125 
        466.426239,353.8109741 464.7345886,353.9989319 463.4188538,354.5628052 462.8549805,355.126709 462.2910767,355.6905823 
        461.3512573,357.0063477 460.2235107,357.5702209 459.2836914,357.3822327 457.7799988,357.0063477 457.216095,357.0063477 
        456.4642639,357.1943054 455.9003906,357.9461365 454.7726135,358.8859558 454.020752,358.6979675 453.2689209,357.9461365 
        452.7050476,357.0063477 451.5772705,356.4424438 450.2615051,356.8183594 449.6976318,358.322052 449.8855896,360.765564 
        450.6374512,362.8331604 450.8254089,364.1488953 450.6374512,365.8405457 450.2615051,366.9683228 449.1337585,368.0960693 
        448.5698853,368.2840576 448.0059814,368.4720154 447.2541199,368.0960693 446.1263428,367.5322266 444.9985962,366.5924072 
        444.0587769,366.0285034 443.3069458,365.2766724 442.3671265,365.0886841 441.6152649,365.0886841 440.4874878,365.2766724 
        439.7356567,365.4646301 439.3597412,365.8405457 438.9837952,366.4044495 439.1717834,367.5322266 439.5476685,368.2840576 
        440.1115723,369.2238464 440.4874878,370.1636658 440.6754456,371.4794006 440.8634033,372.6071777 440.8634033,373.5469971 
        440.6754456,374.2988281 439.7356567,374.8627319 438.4199219,375.4266052 437.8560181,375.8025513 437.104187,376.9303284 
        435.2245789,377.6821594 433.908844,377.8701172 432.4051208,377.8701172 431.0894165,378.058075 429.0218201,378.2460327 
        426.9542236,378.6219482 425.8264771,379.3738098 424.1347961,380.3135986 422.4431458,381.0654907 420.1875916,381.629364 
        417.9320374,380.6895447 416.2404175,380.6895447 415.4885559,381.8173218 415.6765137,382.7571106 416.4283752,384.4487915 
        416.9922485,385.3886108 417.1802063,387.268219 415.8644714,389.3358154 414.172821,390.6515198 411.9172668,391.2154236 
        410.4135742,391.0274658 408.5339355,390.6515503 406.2784119,389.5237732 404.7747192,389.1478271 403.2710266,389.3358154 
        402.3312073,390.2756042 401.9552917,392.7191162 400.8275146,394.5987549 398.7599487,395.9144592 396.8803101,396.8542786 
        393.8729248,398.1700134 391.4294128,399.1098328 388.0461121,399.4857483 385.9785156,399.8616943 382.9711304,400.2375793 
        381.4674072,401.1773987 381.2794495,400.9894409 381.0915222,400.4255371 380.7155762,399.1098328 380.1517029,397.6061401 
        378.835968,395.9144897 377.5202332,395.5385437 376.5804138,395.7265015 373.0091248,396.6663208 371.1295166,397.2301941 
        368.6860046,397.7940979 367.3702698,397.2301941 366.806366,396.2903748 368.3100891,393.4709778 368.8739624,391.7793274 
        368.8739624,390.6515503 368.1221313,389.3358154 367.182312,388.2080383 365.4906616,387.8321228 366.0545349,387.0802612 
        365.8665771,386.1404419 365.3027039,385.3886108 364.7388,384.824707 364.1749268,384.072876 363.0471497,382.9450989 
        362.2953186,380.5016174 362.6712036,378.9978943 363.6110229,378.058075 364.3628845,377.1182861 365.6786194,375.4266357 
        366.2425232,373.7349548 365.8665771,372.41922 365.6786194,372.0433044 363.986969,371.2914734 361.9193726,371.2914734 
        360.9795837,371.6673889 359.6638184,372.2312927 358.1601257,373.1710815 356.2805176,373.5469971 354.9647827,372.7951355 
        354.5888672,370.9155273 354.4008789,369.5997925 354.776825,366.780365 355.3406982,365.4646301 356.4684753,364.5248108 
        358.3480835,363.585022 359.6638184,362.6452026 359.6638184,360.7655945 359.4758606,359.4498596 359.4758606,357.946167 
        360.2277222,356.6304321 361.543457,355.126709 362.1073303,352.8711853 361.7314148,351.5554504 360.4156799,349.4878845 
        360.4156799,347.7962036 360.7915955,345.3527222 361.7314148,342.7212219 362.1073303,340.0897827 363.4230652,338.9620056 
        364.7388,338.3981018 366.6184082,338.0221863 367.9341431,336.7064514 368.1221313,334.0749817 367.5582275,332.9472351 
        365.4906311,331.443512 364.362854,330.8796387 363.6110229,330.1277771 362.2952881,329.1879578 360.9795837,328.8120728 
        359.8518066,328.8120728 358.9119873,329.375946 357.5962524,330.1277771 356.6564331,330.5037231 354.9647522,330.8796387 
        354.776825,330.8796387 354.0249634,331.2555542 353.2731018,331.443512 352.5212708,331.819458 351.5814819,332.0074158 
        350.6416626,332.3833313 349.3259277,332.9472351 348.0101624,333.8870239 346.8824158,334.6388855 346.1305542,335.3907166 
        345.3787231,336.1425781 344.6268616,337.0823975 343.6870422,338.3981018 342.3713379,339.3379517 340.8676147,340.2777405 
        339.9278259,340.653656 337.1083984,340.653656 336.3565369,340.4656982 335.040802,339.901825 331.6575012,338.3981323 
        330.9056396,338.2101746 329.9658203,338.2101746 328.4621277,338.3981323 327.1463928,338.3981323 326.0186462,338.2101746 
        325.4547424,338.0221863 325.0788269,337.8342285 324.3269653,337.8342285 323.1991882,337.6462708 322.0714111,337.6462708 
        321.3195801,337.8342285 320.7557068,338.2101746 320.0038452,338.7740479 319.4399719,338.9620056 319.0640259,339.3379517 
        318.3121948,339.5259094 314.552948,339.5259094 313.4251709,340.0897827 311.9214783,340.653656 310.6057434,340.653656 
        309.6659241,340.2777405 309.1020508,339.901825 308.538147,339.5259094 307.9742737,339.1499634 307.5983582,338.5860901 
        306.8464966,338.2101746 306.0946655,338.0221863 305.5307617,338.0221863 304.7789307,338.2101746 304.2150269,338.0221863 
        301.7715149,338.0221863 300.4558105,337.6462708 299.3280334,336.7064514 298.7641296,335.5787048 298.3882141,334.4509277 
        298.2002563,333.6990662 298.0122986,332.1953735 297.6363525,330.5037231 296.5085754,329.0000305 295.0048828,328.6240845 
        293.6891785,328.6240845 291.9974976,329.0000305 290.1178894,329.5639038 288.2382507,329.9398499 286.5466003,330.1278076 
        284.85495,330.1278076 284.2910767,329.9398499 282.0355225,329.9398499 280.9077454,329.7518616 279.5920105,329.3759766 
        278.8401489,328.4361572 277.9003601,327.6842957 277.1484985,327.3083801 276.2086792,327.1204224 275.0809326,327.1204224 
        273.7651978,327.4963379 273.2012939,327.8722534 272.8253784,328.0602112 272.0735168,328.2481995 270.9457703,328.8120728 
        269.4420471,329.1879883 267.562439,329.5639343 266.2467041,329.3759766 264.7430115,329.1879883 260.2319031,329.1879883 
        257.6004639,329.0000305 255.7208252,328.624115 253.0893555,327.8722534 251.5856476,327.3083801 250.6458588,326.9324341 
        249.5180817,326.3685608 248.2023621,325.6167297 246.5106964,325.2408142 244.2551422,324.6769104 242.7514343,324.3009949 
        241.247757,323.9250488 240.4959106,323.9250488 239.5560913,324.1130371 238.4283295,324.3009949 236.3607483,324.4889526 
        233.9172211,324.3009949 232.7894592,323.5491638 232.4135437,322.4213867 231.8496552,321.4815674 231.4737244,320.7297058 
        231.0978088,319.9778748 230.7218781,319.4140015 229.970047,318.6621094 229.594101,318.0982361 228.8422699,317.9102783 
        228.0903931,317.7223206 227.1506042,317.5343628 226.2107849,317.346405 224.143219,317.346405 222.0756226,317.5343628 
        220.5719452,317.346405 219.0682373,316.970459 217.5645447,316.4066162 216.2487946,315.8427124 214.7451172,314.9028931 
        213.2414093,314.151062 212.1136322,313.5871582 211.3617859,313.2112427 210.9858551,312.8352966 210.6099548,312.4593811 
        209.858078,311.70755 209.1062469,310.9556885 208.7303314,309.6399536 209.6701355,308.3242188 211.1738434,307.9483032 
        212.489563,307.5723877 213.2414093,307.3843994 214.3691864,307.1964722 216.4367676,306.6325684 217.7525024,305.3168335 
        218.316391,303.8131409 218.1284027,302.12146 217.9404602,301.1816711 217.1886139,300.053894 216.6247253,299.4900208 
        215.3090057,297.9862976 213.6173401,296.2946472 212.489563,294.9789124 211.5497437,293.8511353 210.9858551,292.9113464 
        210.7978973,292.3474731 210.2340088,291.9715271 209.858078,291.5956116 209.2941895,291.4076538 208.3544006,291.7835693 
        207.6025543,292.5354309 207.2266235,293.4752502 206.0988464,295.5428162 204.9710846,296.6705933 203.8433075,297.4224243 
        202.5275726,298.1742859 201.0238647,299.1140747 198.3924103,300.053894 197.4525909,299.8659363 196.1368561,299.6779785 
        195.1970367,298.5502014 194.4452057,297.6104126 193.1294708,296.4826355 191.4378052,296.1066895 190.6859741,296.1066895 
        189.558197,295.5428162 188.6183777,294.4150391 187.6785889,293.4752502 186.3628235,292.3474731 184.6711884,291.219696 
        183.1674805,290.8437805 180.348053,290.8437805 178.6563873,291.219696 177.904541,291.7835999 178.4684296,292.3474731 
        177.904541,291.7835999 176.5888214,290.4678345 175.4610443,289.7160034 174.3332672,287.8363953 174.709198,286.1447144 
        176.0249329,283.8891602 177.15271,282.7613831 177.7165985,281.2577209 178.2804718,280.5058594 179.7841644,279.9419556 
        180.348053,279.7539978 180.7239838,278.6262207 180.536026,277.3105164 180.348053,276.370697 180.348053,275.8067932 
        180.1600952,275.0549622 179.7841644,273.5512695 180.1600952,272.2355347 180.7239838,270.731842 180.7239838,269.2281494 
        180.348053,266.9725952 180.536026,265.6568604 181.8517456,264.7170715 182.9795227,264.1531677 183.5434113,263.2133484 
        182.4156342,262.2735291 182.4156342,261.3337402 183.731369,260.9577942 184.8591461,260.7698669 185.986908,260.3939209 
        188.0544891,259.2661438 189.3702393,258.1383667 190.6859589,256.8226318 192.5655823,254.9430237 194.2572479,252.6874847 
        196.3248138,249.6800995 197.0766754,247.9884491 198.2044525,246.1088104 199.1442413,244.2292023 199.3322296,243.2893982 
        199.5201721,242.5375366 199.8960876,241.5977325 200.2720184,240.0940399 200.4599762,237.2745972 199.7081299,235.0190735 
        198.9562836,232.7635193 198.7683411,230.8839111 199.8960876,229.0042725 201.587738,228.0644684 203.0914459,227.8765106 
        204.7831116,228.0644684 205.7229156,228.2524414 207.2266083,228.8163147 208.7303162,228.8163147 210.2340088,228.2524414 
        211.9256592,227.6885529 213.4293518,227.6885529 214.5571289,228.0644684 215.872879,228.2524414 217.3765564,227.6885529 
        218.3163757,226.5607758 219.8200531,225.6209717 221.6996918,225.8089142 222.8274536,226.3728027 224.1432037,226.9366913 
        225.6468811,226.3728027 226.2107697,224.4931946 226.2107697,223.5533752 226.5867004,222.2376404 227.150589,220.1700592 
        228.6542664,218.4783936 230.345932,216.7867584 232.6014862,215.6589813 234.1051941,214.719162 235.4209137,212.6515808 
        234.4810944,211.1478882 233.3533173,210.5839996 231.6616821,210.0201263 229.7820435,210.0201263 228.0903778,210.2080688 
        226.3987427,210.0201263 225.6468811,209.080307 226.2107697,208.1404877 226.5867004,207.7645721 227.150589,206.4488373 
        227.150589,204.0053253 226.7746582,202.6896057 227.3385468,200.4340515 228.6542664,199.6822052 229.7820435,197.8025818 
        229.9700165,196.4868469 230.345932,195.1711121 230.7218628,193.1035309 231.6616821,191.4118805 233.5412903,190.0961456 
        234.2931366,189.7202148 235.0449829,189.1563416 235.9848022,189.1563416 237.1125793,189.9081879 238.2403412,191.0359497 
        239.3681183,191.5998383 240.4958954,190.8479919 240.6838531,189.1563416 241.2477417,187.6526337 242.5634613,187.0887451 
        244.255127,187.6526337 245.9467926,188.592453 248.2023315,190.2841034 249.7060242,190.8479919 251.9615631,191.787796 
        253.8411865,191.9757538 255.1569061,192.5396423 257.0365601,192.9155579 258.7282104,192.7276154 259.855957,192.9155579 
        260.9837341,193.1035309 262.1115112,192.9155579 263.4272766,192.5396423 265.1188965,191.4118652 265.4948425,190.8479767 
        265.8707581,189.5322571 266.8105469,187.8405914 267.562439,185.3970947 267.1864929,182.765625 266.6225891,181.6378479 
        265.4948425,180.5100708 264.9309387,177.6906586 266.8105469,175.9989929 269.6300049,174.6832581 271.1336975,173.9314117 
        273.3892517,173.1795502 275.0809021,171.4878998 274.5169983,169.4203186 273.2012939,168.1045837 272.4494324,166.6008911 
        273.2012939,165.473114 275.2688599,165.473114 277.148468,166.6008911 278.0882874,166.7888489 278.8401489,166.9768066 
        280.7197571,166.6008911 282.0354919,166.0370026 283.5391846,165.661087 285.4187927,165.0971985 286.7345581,163.7814484 
        287.6743469,162.6536865 288.2382507,161.7138672 288.2382507,161.3379517 288.6141663,160.5861053 288.802124,160.0222168 
        288.9900818,158.894455 288.4262085,157.2027893 288.2382507,156.450943 288.2382507,155.5111237 285.7947388,154.1954041 
        285.9826965,152.5037384 285.7947388,150.8120728 285.7947388,148.9324646 284.8549194,146.6769104 283.7271423,144.98526 
        282.7873535,144.0454559 281.8475342,142.7297211 280.3438416,141.2260132 278.8401184,139.9102936 278.0882874,138.5945435 
        277.7123718,137.2788239 277.7123718,135.3992004 277.9003296,133.519577 277.7123718,131.8279114 278.4642029,129.5723877 
        279.0281067,127.880722 279.0281067,125.4372177 278.8401184,123.3696365 278.0882874,122.2418671 277.5244141,121.4900131 
        276.9605103,120.5502014 276.7725525,119.9863129 276.7725525,119.4224319 276.9605103,117.5428162 276.7725525,116.2270737 
        276.7725525,113.0317154 276.9605103,111.3400726 277.5244141,110.0243301 277.9003296,109.4604416 279.2160645,109.2724915 
        280.1558838,109.648407 281.2836304,110.0243301 282.4114075,110.5882187 283.5391846,110.9641342 284.6669617,110.4002609 
        285.0428772,110.2122955 285.4187927,109.4604416 286.1706543,108.5206375 286.7345276,108.1447144 287.4863892,107.956749 
        288.9900818,107.956749 289.5539551,108.1447144 289.7419128,107.956749 289.9299011,107.956749 291.6215515,106.8289719 
        294.2530212,108.1447144 296.3206177,109.648407 297.636322,111.7159882 298.9520569,113.5956039 299.8918762,114.9113388 
        300.45578,115.8511505 301.2076111,116.6030045 301.9594727,116.97892 303.0872498,117.1668854 304.0270386,117.1668854 
        304.9668579,116.97892 306.094635,116.97892 307.4103394,117.1668854 308.9140625,117.1668854 309.4779358,117.3548431 
        310.0418091,117.1668854 310.6057129,116.97892 310.7936707,116.6029968 310.4177551,116.0391159 310.0418091,115.287262 
        309.6658936,114.1594849 309.6658936,113.4076462 310.2297974,112.4678345 310.4177551,111.5280151 311.5455017,109.460434 
        312.8612671,107.2049026 313.6130981,105.7012024 314.1769714,103.821579 314.5529175,103.4456558 315.3047485,103.0697403 
        316.4325256,102.3178864 317.7482605,102.5058517 318.5001221,103.2576904 319.4399109,104.0095444 319.8158569,105.3252716 
        320.0038147,105.8891602 320.567688,106.8289642 321.6954651,107.5808182 323.1991577,109.0845108 324.7028503,109.8363647 
        325.4547119,110.4002533 325.8306274,110.7761765 326.582489,111.3400574 327.1463623,112.4678345 328.2741394,113.5955963 
        329.777832,114.7233658 330.7176514,115.4752197 331.8454285,116.790947 332.973175,117.9187241 333.7250366,118.670578 
        334.664856,118.8585281 335.9805908,118.670578 336.5444641,118.4826126 336.5444641,116.4150314 337.1083374,115.8511429 
        338.0481567,115.2872543 339.3638916,114.7233658 340.4916687,114.5354156 342.1833496,113.7835617 343.3110962,113.0317078 
        344.0629578,112.0918961 344.2509155,110.9641266 344.6268311,109.2724762 345.1907349,108.1446991 345.3786621,107.3928452 
        345.5666199,107.0169296 346.694397,106.6410065 348.0101318,106.4530411 349.513855,106.6410065 350.8295593,107.204895 
        351.9573364,107.0169296 352.709198,106.6410065 352.8971558,105.7011948 353.2730713,104.3854599 353.4610596,103.0697327 
        353.6490173,101.7539902 353.4610596,100.4382629 353.2730713,99.3104858 353.2730713,98.7465973 354.2128906,97.8067932 
        354.4008484,97.2429047 354.4008484,95.3632813 354.0249329,94.047554 353.6490173,92.5438538 353.4610596,91.4160843 
        353.0851135,90.1003418 352.3332825,88.97258 351.9573364,87.8448105 351.7693787,86.3411102 351.7693787,84.0855637 
        351.5814209,83.3337097 351.9573364,82.3939056 352.5212402,81.4540939 352.8971558,80.1383667 352.8971558,78.8226318 
        352.7091675,77.506897 352.7091675,76.0031967 352.8971558,75.4393158 353.6489868,75.063385 355.15271,75.2513504 
        357.0323181,75.4393158 358.5360107,75.815239 359.4758301,76.3791275 361.3554382,77.3189316 362.4832153,78.6346588 
        364.1748657,79.1985474 365.6785889,79.7624359 367.558197,79.9504013 368.8739319,79.9504013 370.001709,79.5744781 
        370.75354,79.7624359 371.5053711,79.9504013 372.4451904,80.3263168 373.0090942,81.2661285 373.3850098,82.2059479 
        373.7609253,83.145752 374.3248291,83.8975983 375.0766907,84.6494446 375.8285217,85.4012909 376.3924255,86.1531448 
        376.7683105,87.2809143 377.3322144,88.220726 378.0840759,88.9725723 378.835907,89.3484955 379.211853,89.5364609 
        380.5275879,89.3484955 381.2794189,89.912384 382.03125,90.8521881 382.5951538,91.9799652 383.1590271,93.1077347 
        383.9108887,93.859581 385.0386353,94.4234619 385.6025391,94.7993851 385.9784546,94.9873505 385.9784546,97.6188202 
        386.1664124,98.1827087 387.2941895,98.7465973 388.4219666,98.5586243 389.1738281,98.3706741 389.7377014,98.3706741 
        390.8654785,98.5586243 391.6173401,98.9345474 392.5571289,99.498436 392.7451172,100.0623245 393.1210327,101.0021439 
        393.1210327,101.9419479 392.933075,102.6937866 392.933075,103.6335983 393.3089905,104.5734177 393.4969482,105.7011871 
        393.684906,106.828949 393.4969482,107.7687683 393.4969482,110.0243073 393.3089905,110.7761612 392.933075,111.9039307 
        392.1812439,112.8437424 391.4293518,113.2196655 390.8654785,113.7835464 390.489563,114.7233582 390.3016052,116.039093 
        390.1136475,117.1668701 389.7377014,118.1066818 388.9858704,119.0464859 388.9858704,119.2344513 388.6099548,119.98629 
        388.4219666,121.6779556 388.7979126,122.9936829 388.7979126,124.1214523 387.8580933,125.813118 387.2941895,127.8806992 
        387.6701355,129.1964264 388.4219666,129.5723572 389.5497437,130.5121613 391.2414246,130.7001343 392.5571289,131.2640228 
        393.4969482,131.6399384 394.6247253,132.3917847 395.5645447,132.2038269 396.3164063,132.2038269 397.0682373,131.8278961 
        397.6321106,131.4519653 398.7598877,131.4519653 399.3237915,132.0158539 399.8876648,133.143631 400.2635803,134.4593658 
        400.4515686,135.7751007 401.3913574,137.2787933 402.143219,138.970459 402.89505,140.4741516 403.2709961,142.5417328 
        403.4589539,144.2333984 403.4589539,146.1130066 403.6469116,147.4287415 403.6469116,148.9324341 404.0228271,149.6842804 
        404.3987427,150.436142 405.7145081,151.0000153 406.6542969,151.5639038 407.782074,151.7518616 408.533905,151.7518616 
        409.4737244,152.5037079 409.6616821,153.0675964 409.6616821,153.631485 409.0978088,155.5111084 408.3459473,156.0749969 
        407.4061584,157.2027588 406.6542969,158.8944244 407.2181702,160.3981171 408.9098511,160.5860748 411.1654053,160.3981171 
        414.1727905,160.3981171 416.992218,160.2101593 417.3681641,160.5860748 418.1199951,160.9620056 419.2477722,160.9620056 
        420.3755493,162.8416138 421.8792114,165.2851257 423.5708923,166.036972 425.6384888,166.4128876 426.5782776,166.4128876 
        428.4578857,166.6008606 430.3375244,166.4128876 432.0291748,166.036972 434.2846985,165.4730835 437.1041565,166.2249298 
        438.7958069,168.1045532 440.675415,170.7360229 440.8633728,172.2397156 441.0513611,175.2471008 439.923584,176.9387665 
        438.9837646,178.6304321 438.4198914,179.758194 437.6680298,180.8859558 437.480072,183.3294678 436.9161987,185.9609375 
        437.1041565,186.1488953 436.3523254,187.8405609 434.8486023,189.7201691 433.5328674,191.5998077 433.7208252,193.1035004 
        435.0365906,193.6673889 437.480072,193.4794159 440.1115417,192.9155273 441.6152344,193.1035004 442.9309692,194.2312622 
        443.494873,197.0506897 443.118927,199.6821594 442.9309692,202.1256714 442.9309692,203.2534332 443.6828308,204.9450989 
        444.2467041,204.9450989 447.0661316,203.8173218 448.1939087,203.4414063 448.7578125,204.3812103 448.1939087,205.884903 
        447.6300354,207.5765686 447.8179932,209.268219 448.1939087,209.8321075 448.3818665,210.5839539 448.3818665,211.8996887 
        447.4420776,213.5913544 446.8782043,214.7191315 446.8782043,216.2228241 448.0059509,216.9746552 449.133728,217.1626282 
        450.2614746,216.9746552 450.8253784,217.3505859 451.0133667,218.8542938 450.6374207,220.1700134 450.6374207,221.6737061 
        451.7651978,222.2375946 452.7050171,221.6737061 453.6448059,220.545929 455.3364868,219.7940979 457.4040527,219.6061249 
        458.7197876,220.733902 459.0957336,223.7412872 458.7197876,225.8088684 457.4040527,226.9366455 455.9003601,227.5005341 
        454.020752,228.4403381 453.6448059,229.3801422 453.6448059,229.5681152 453.2688904,231.2597809 454.2087097,233.5153046 
        456.2763062,234.2671661 457.4040527,234.8310547 459.0957336,237.4625244 460.0355225,239.3421326 460.7873535,240.2819519 
        461.9151306,241.5976868 462.85495,243.2893372 463.4188538,244.0411835 464.3586426,244.9810028 465.8623657,245.1689606 
        467.1780701,244.9810028 468.4938354,244.9810028"
            filter="url(#dropshadow)" // Apply the drop shadow filter here
          />
        </a>
        <text x="350" y="250" fontSize={16} color="000000" className="centered-text">肇慶</text>

        {/* ... other groups and elements */}
      </g>
    </svg>
  );
};

export default svgmap;