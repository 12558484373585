// CardComponent.js
import React from 'react';
import { Card } from 'react-bootstrap';
import './Resources.css';

const CardComponent = ({ image, title, text }) => {
  return (
    <Card className="fixed-width-card">
      <div className="aspect-ratio-16-9">
        <Card.Img
          variant="top"
          src={image}
          className="card-img-top"
        />
      </div>
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        <hr className="custom-card-divider" />

        <Card.Text>{text}</Card.Text>
      </Card.Body>
    </Card>
  );
};

export default CardComponent;