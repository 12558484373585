import React from 'react';
import HeroSlider from '../HeroSlider/HeroSlider.js';
// import AI4Good from '../../components/web/AI4Good/AI4Good';
import Tools from './Tools.js';
// import AboutUs from '../../components/web/AboutUs/AboutUs';

const HomePage = () => {
  return (
    <>
      <section id="home">
        <HeroSlider />
      </section>

      <section id="tools">
        <Tools />
      </section>

    </>
  );
};

export default HomePage;