// Resources.jsx
import React, { useState } from 'react';
import { Row, Col, Container, Pagination } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Resources.css';
import { Helmet } from 'react-helmet';
import CardComponent from './CardComponent'; // Import the new CardComponent
import Resources_Data from './Resources_Data.json'; // Import the news data

const ThreeColumnCards = () => {
  const cardsPerPage = 6; // Set to 9 to have 3 rows of 3 cards each
  const [currentPage, setCurrentPage] = useState(1); // Current active page number

  // Card data array
    const cardInfo = Resources_Data;
    // ... Your card data
    // Your card objects here


  // Calculate the number of pages needed
  const pageCount = Math.ceil(cardInfo.length / cardsPerPage);

  // Get current cards for the page
  const currentCards = cardInfo.slice(
    (currentPage - 1) * cardsPerPage,
    currentPage * cardsPerPage
  );

  // Function to handle page change
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Create pagination items
  let paginationItems = [];
  for (let number = 1; number <= pageCount; number++) {
    paginationItems.push(
      <Pagination.Item key={number} active={number === currentPage} onClick={() => handlePageClick(number)}>
        {number}
      </Pagination.Item>,
    );
  }

  return (
    <>
      <Helmet>
        {/* ... your helmet code */}
      </Helmet>

      <Container>
        <div className="separator" /> {/* Custom separator */}
        <h1>Resources</h1>
        <div className="separator" /> {/* Custom separator */}

        <Row>
          {currentCards.map((card, index) => (
            <Col lg={2} md={6} sm={12} className="mb-4 d-flex" key={index}>
              <CardComponent image={card.image} title={card.title} text={card.text} />
            </Col>
          ))}
        </Row>

        <Pagination className="justify-content-center my-pagination">{paginationItems}</Pagination>        <div className="separator" /> {/* Custom separator */}

      </Container>
    </>
  );
};

export default ThreeColumnCards;